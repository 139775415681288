import React, {ReactElement} from "react";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameOneModal from "./modalComponents/FrameOneModal";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import {BrandMap, BrandMapStatus} from "@devour/client";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import FrameButton from "../buttons/FrameButton";
import {LinkProps} from "react-router-dom";

interface Props {
	isOpen: boolean;
	brandMap: BrandMap;
	new: boolean;
	onContinueEditing?: () => void;
	originalBrandMap?: BrandMap;
}

function BrandMapSavePublishedSuccessModal(props: Props): ReactElement {
	const mapName = props.brandMap?.name;

	return (
		<FrameOneModal
			isOpen={props.isOpen}
			size="sm"
			contentClassName="brand-map-save-published-success-modal"
		>
			<FrameModalHeader title="Map published successfully!"/>

			<FrameModalBody>
				{props.new ? (
					<p>
						{`Your new map${mapName && `, '${mapName}'`} was successfully published!`}
					</p>
				) : (
					<React.Fragment>
						{props.originalBrandMap?.status === BrandMapStatus.DRAFT ? (
							<p>
								{`Your map${mapName && `, '${mapName}'`} has been successfully updated & published!`}
							</p>
						) : (
							<p>
								{`Your map${mapName && `, '${mapName}'`} has been successfully updated!`}
							</p>
						)}
					</React.Fragment>
				)}
			</FrameModalBody>

			<FrameModalFooter>
				<FrameButton
					<React.AnchorHTMLAttributes<HTMLAnchorElement>>
					color="purple"
					size="normal"
					href={`${process.env.REACT_APP_WEB_URL}/brands/${props.brandMap?.slug}`}
					forwardProps={{
						target: "_blank",
						rel: "noopener noreferrer",
					}}
				>
					View Live Map
				</FrameButton>

				{props.new ? (
					<FrameButton
						<LinkProps>
						color="purple"
						size="normal"
						forwardProps={{
							to: `/brands/maps/${props.brandMap?.slug || props.brandMap?.id}/edit`,
							replace: true
						}}
					>
						Continue Editing
					</FrameButton>
				) : (
					<FrameButton
						color="purple"
						size="normal"
						onClick={props.onContinueEditing}
					>
						Continue Editing
					</FrameButton>
				)}

				<FrameButton
					color="purple-outline"
					size="normal"
					to={`/brands/${props.brandMap?.brand?.id}/maps`}
				>
					Return to My Maps
				</FrameButton>
			</FrameModalFooter>
		</FrameOneModal>
	);
}

export default BrandMapSavePublishedSuccessModal;