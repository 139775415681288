"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderDiscountOperatorToJSON = exports.OrderDiscountOperatorFromJSONTyped = exports.OrderDiscountOperatorFromJSON = exports.OrderDiscountOperator = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var OrderDiscountOperator;
(function (OrderDiscountOperator) {
    OrderDiscountOperator["ANY"] = "ANY";
    OrderDiscountOperator["ALL"] = "ALL";
})(OrderDiscountOperator = exports.OrderDiscountOperator || (exports.OrderDiscountOperator = {}));
function OrderDiscountOperatorFromJSON(json) {
    return OrderDiscountOperatorFromJSONTyped(json, false);
}
exports.OrderDiscountOperatorFromJSON = OrderDiscountOperatorFromJSON;
function OrderDiscountOperatorFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.OrderDiscountOperatorFromJSONTyped = OrderDiscountOperatorFromJSONTyped;
function OrderDiscountOperatorToJSON(value) {
    return value;
}
exports.OrderDiscountOperatorToJSON = OrderDiscountOperatorToJSON;
