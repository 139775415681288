"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokenLogTypeToJSON = exports.TokenLogTypeFromJSONTyped = exports.TokenLogTypeFromJSON = exports.TokenLogType = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var TokenLogType;
(function (TokenLogType) {
    TokenLogType["CREATED"] = "CREATED";
    TokenLogType["EXTENDED"] = "EXTENDED";
})(TokenLogType = exports.TokenLogType || (exports.TokenLogType = {}));
function TokenLogTypeFromJSON(json) {
    return TokenLogTypeFromJSONTyped(json, false);
}
exports.TokenLogTypeFromJSON = TokenLogTypeFromJSON;
function TokenLogTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.TokenLogTypeFromJSONTyped = TokenLogTypeFromJSONTyped;
function TokenLogTypeToJSON(value) {
    return value;
}
exports.TokenLogTypeToJSON = TokenLogTypeToJSON;
