import React, {ReactElement, useEffect, useState} from "react";
import FrameOneModal from "./modalComponents/FrameOneModal";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import FrameButton from "../buttons/FrameButton";
import FrameOneCheckbox from "../inputs/FrameOneCheckbox";
import {AgreementTerm, AgreementTermsApi, AgreementTermType, Token, UsersApi} from "@devour/client";
import getConfig from "../../utils/getConfig";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {addError, decrementLoading, incrementLoading, updateCurrentUser} from "../../redux/meta/metaActions";
import {login} from "../../redux/auth/authActions";

interface StateProps {
	fullToken: Token;
}

interface Props {
	isOpen: boolean;
	agreement: AgreementTerm;
	onClose: () => void;
	onDone: () => Promise<void>;
}

function MerchantAgreeTermsModal(props: MerchantAgreeTermsModalProps): ReactElement {

	const [agree, setAgree] = useState(false);

	function toggleAgree(): void {
		setAgree(a => !a);
	}

	async function agreeToLegalTerms(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			await new AgreementTermsApi(getConfig(props.fullToken)).agreeToLegalTerm({
				agreeToLegalTermBody: {
					type: AgreementTermType.MERCHANT,
					agreementTerm: props.agreement?.id,
				},
			});

			const userRes = await new UsersApi(getConfig(props.fullToken)).getProfile();
			await props.dispatch(updateCurrentUser(userRes.user));

			props.onDone().then().catch();
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	return (
		<FrameOneModal
			isOpen={props.isOpen}
			toggle={props.onClose}
			contentClassName="merchant-agreement-terms-modal"
		>
			<FrameModalHeader
				title="Accept Legal Terms"
				toggle={props.onClose}
			/>

			<FrameModalBody className="merchant-agreement-terms-modal_body">
				<p className="merchant-agreement-terms-modal_body_message">
					Before proceeding with connecting a new Stripe, please read & agree to our updated{" "}
					<a
						href={props.agreement?.file?.redirectUrl}
						target="_blank"
						rel="noopener noreferrer"
					>
						Merchant Agreement.
					</a>
				</p>

				<FrameOneCheckbox
					onToggle={toggleAgree}
					checked={agree}
				>
					I Agree
				</FrameOneCheckbox>
			</FrameModalBody>

			<FrameModalFooter>
				<FrameButton
					<React.ButtonHTMLAttributes<HTMLButtonElement>>
					color="purple"
					size="normal"
					onClick={agreeToLegalTerms}
					forwardProps={{disabled: agree === false}}
				>
					Continue
				</FrameButton>
			</FrameModalFooter>
		</FrameOneModal>
	);
}

function connector() {
	return connect((store: IStore, props: Props): StateProps & Props => {
		return {
			fullToken: store.authStore.fullToken,
			...props,
		}
	});
}

type MerchantAgreeTermsModalProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(MerchantAgreeTermsModal);