"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NftsApi = void 0;
var runtime = __importStar(require("../runtime"));
var models_1 = require("../models");
/**
 * no description
 */
var NftsApi = /** @class */ (function (_super) {
    __extends(NftsApi, _super);
    function NftsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Adds a new nft grouping to the system
     */
    NftsApi.prototype.addNftGroupingRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("Admin", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/nft-groupings",
                                method: 'POST',
                                headers: headerParameters,
                                query: queryParameters,
                                body: (0, models_1.AddNftGroupingBodyToJSON)(requestParameters.addNftGroupingBody),
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * Adds a new nft grouping to the system
     */
    NftsApi.prototype.addNftGrouping = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.addNftGroupingRaw(requestParameters)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Adds a new prize to the system
     */
    NftsApi.prototype.addNftRewardPrizeRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("Admin", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/nft-reward-prizes",
                                method: 'POST',
                                headers: headerParameters,
                                query: queryParameters,
                                body: (0, models_1.CreateNftRewardPrizeBodyToJSON)(requestParameters.createNftRewardPrizeBody),
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * Adds a new prize to the system
     */
    NftsApi.prototype.addNftRewardPrize = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.addNftRewardPrizeRaw(requestParameters)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Adds a new nft tracker to the system
     */
    NftsApi.prototype.addNftTrackerRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("Admin", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/nft-trackers",
                                method: 'POST',
                                headers: headerParameters,
                                query: queryParameters,
                                body: (0, models_1.AddNftTrackerBodyToJSON)(requestParameters.addNftTrackerBody),
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * Adds a new nft tracker to the system
     */
    NftsApi.prototype.addNftTracker = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.addNftTrackerRaw(requestParameters)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Delete a single NFT Grouping\'s associated NFT Trackers & Ownerships, then set it as disabled.
     */
    NftsApi.prototype.deleteNftGroupingRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.id === null || requestParameters.id === undefined) {
                            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling deleteNftGrouping.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("Admin", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/nft-groups/{id}".replace("{".concat("id", "}"), encodeURIComponent(String(requestParameters.id))),
                                method: 'PUT',
                                headers: headerParameters,
                                query: queryParameters,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * Delete a single NFT Grouping\'s associated NFT Trackers & Ownerships, then set it as disabled.
     */
    NftsApi.prototype.deleteNftGrouping = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.deleteNftGroupingRaw(requestParameters)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Delete a single NFT Tracker along with its associated NFT Ownerships and any simplehash webhooks.
     */
    NftsApi.prototype.deleteNftTrackerRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.id === null || requestParameters.id === undefined) {
                            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling deleteNftTracker.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("Admin", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/nft-trackers/{id}".replace("{".concat("id", "}"), encodeURIComponent(String(requestParameters.id))),
                                method: 'DELETE',
                                headers: headerParameters,
                                query: queryParameters,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * Delete a single NFT Tracker along with its associated NFT Ownerships and any simplehash webhooks.
     */
    NftsApi.prototype.deleteNftTracker = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.deleteNftTrackerRaw(requestParameters)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Updates all NFT Ownerships in the system with SimpleHash data, creates webhooks if needed.
     */
    NftsApi.prototype.forceNftOwnershipUpdateRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("Admin", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/update-nft-ownership",
                                method: 'POST',
                                headers: headerParameters,
                                query: queryParameters,
                                body: (0, models_1.ForceUpdateNftOwnershipBodyToJSON)(requestParameters.forceUpdateNftOwnershipBody),
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.ForceUpdateNftOwnershipResponseFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Updates all NFT Ownerships in the system with SimpleHash data, creates webhooks if needed.
     */
    NftsApi.prototype.forceNftOwnershipUpdate = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.forceNftOwnershipUpdateRaw(requestParameters)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Gets a single Nft Grouping with all of its associated Nft trackers.
     */
    NftsApi.prototype.getNftGroupingRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.id === null || requestParameters.id === undefined) {
                            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getNftGrouping.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("Admin", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/nft-groupings/{id}".replace("{".concat("id", "}"), encodeURIComponent(String(requestParameters.id))),
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.GetNftGroupingResponseFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Gets a single Nft Grouping with all of its associated Nft trackers.
     */
    NftsApi.prototype.getNftGrouping = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getNftGroupingRaw(requestParameters)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     */
    NftsApi.prototype.getNftGroupingsRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        if (requestParameters.onlyShowEligible !== undefined) {
                            queryParameters['onlyShowEligible'] = requestParameters.onlyShowEligible;
                        }
                        if (requestParameters.evmChain !== undefined) {
                            queryParameters['evmChain'] = requestParameters.evmChain;
                        }
                        if (requestParameters.categoryId !== undefined) {
                            queryParameters['categoryId'] = requestParameters.categoryId;
                        }
                        if (requestParameters.search !== undefined) {
                            queryParameters['search'] = requestParameters.search;
                        }
                        if (requestParameters.sort !== undefined) {
                            queryParameters['sort'] = requestParameters.sort;
                        }
                        if (requestParameters.order !== undefined) {
                            queryParameters['order'] = requestParameters.order;
                        }
                        if (requestParameters.limit !== undefined) {
                            queryParameters['limit'] = requestParameters.limit;
                        }
                        if (requestParameters.offset !== undefined) {
                            queryParameters['offset'] = requestParameters.offset;
                        }
                        if (requestParameters.numberOfAvailableOffsets !== undefined) {
                            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
                        }
                        headerParameters = {};
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("Optional", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/nft-groupings",
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.GetNftGroupingsResponseFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     */
    NftsApi.prototype.getNftGroupings = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getNftGroupingsRaw(requestParameters)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     */
    NftsApi.prototype.getNftOwnershipsRaw = function () {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("User", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/get-nft-ownerships",
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.GetNftOwnershipsResponseFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     */
    NftsApi.prototype.getNftOwnerships = function () {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getNftOwnershipsRaw()];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Gets a single reward metadata for the specified tracker and all of its associated prizes.
     */
    NftsApi.prototype.getNftRewardRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.id === null || requestParameters.id === undefined) {
                            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getNftReward.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("User", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/nft-trackers/{id}/reward".replace("{".concat("id", "}"), encodeURIComponent(String(requestParameters.id))),
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.GetNftRewardResponseFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Gets a single reward metadata for the specified tracker and all of its associated prizes.
     */
    NftsApi.prototype.getNftReward = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getNftRewardRaw(requestParameters)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     */
    NftsApi.prototype.getNftRewardPrizesForAdminRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        if (requestParameters.search !== undefined) {
                            queryParameters['search'] = requestParameters.search;
                        }
                        if (requestParameters.limit !== undefined) {
                            queryParameters['limit'] = requestParameters.limit;
                        }
                        if (requestParameters.offset !== undefined) {
                            queryParameters['offset'] = requestParameters.offset;
                        }
                        if (requestParameters.numberOfAvailableOffsets !== undefined) {
                            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
                        }
                        headerParameters = {};
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("Admin", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/nft-reward-prizes-for-admin",
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.GetNftRewardPrizesForAdminResponseFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     */
    NftsApi.prototype.getNftRewardPrizesForAdmin = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getNftRewardPrizesForAdminRaw(requestParameters)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Gets a single Nft Tracker with all of its associated Nft trackers.
     */
    NftsApi.prototype.getNftTrackerRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.id === null || requestParameters.id === undefined) {
                            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getNftTracker.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("Admin", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/nft-tracker/{id}".replace("{".concat("id", "}"), encodeURIComponent(String(requestParameters.id))),
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.GetNftTrackerResponseFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Gets a single Nft Tracker with all of its associated Nft trackers.
     */
    NftsApi.prototype.getNftTracker = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getNftTrackerRaw(requestParameters)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Gets all the NFT trackers which belong to a grouping.
     */
    NftsApi.prototype.getNftTrackerFromGroupingRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.id === null || requestParameters.id === undefined) {
                            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getNftTrackerFromGrouping.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("Admin", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/nft-trackers-from-grouping/{id}".replace("{".concat("id", "}"), encodeURIComponent(String(requestParameters.id))),
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.GetNftTrackersFromGroupingResponseFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Gets all the NFT trackers which belong to a grouping.
     */
    NftsApi.prototype.getNftTrackerFromGrouping = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getNftTrackerFromGroupingRaw(requestParameters)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     */
    NftsApi.prototype.getNftTrackersRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        if (requestParameters.evmChain !== undefined) {
                            queryParameters['evmChain'] = requestParameters.evmChain;
                        }
                        if (requestParameters.contractAddress !== undefined) {
                            queryParameters['contractAddress'] = requestParameters.contractAddress;
                        }
                        if (requestParameters.search !== undefined) {
                            queryParameters['search'] = requestParameters.search;
                        }
                        if (requestParameters.sort !== undefined) {
                            queryParameters['sort'] = requestParameters.sort;
                        }
                        if (requestParameters.order !== undefined) {
                            queryParameters['order'] = requestParameters.order;
                        }
                        if (requestParameters.limit !== undefined) {
                            queryParameters['limit'] = requestParameters.limit;
                        }
                        if (requestParameters.offset !== undefined) {
                            queryParameters['offset'] = requestParameters.offset;
                        }
                        if (requestParameters.numberOfAvailableOffsets !== undefined) {
                            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
                        }
                        headerParameters = {};
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("Admin", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/nft-trackers",
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.GetNftTrackersResponseFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     */
    NftsApi.prototype.getNftTrackers = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getNftTrackersRaw(requestParameters)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Get all redeemable NFT ownerships in user\'s Magic Wallet
     */
    NftsApi.prototype.getUserRedeemableNftOwnershipsRaw = function () {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("User", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/redeemable-nft-ownerships",
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.GetUserRedeemableNftOwnershipsResponseFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Get all redeemable NFT ownerships in user\'s Magic Wallet
     */
    NftsApi.prototype.getUserRedeemableNftOwnerships = function () {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getUserRedeemableNftOwnershipsRaw()];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Validates burn transaction hash and returns the prize won by user from redeeming their NFT reward.
     */
    NftsApi.prototype.redeemNftRewardRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("User", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/redeem-nft-reward",
                                method: 'POST',
                                headers: headerParameters,
                                query: queryParameters,
                                body: (0, models_1.RedeemNftRewardBodyToJSON)(requestParameters.redeemNftRewardBody),
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.RedeemNftRewardResponseFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Validates burn transaction hash and returns the prize won by user from redeeming their NFT reward.
     */
    NftsApi.prototype.redeemNftReward = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.redeemNftRewardRaw(requestParameters)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * finds all the NFTs belonging to this user and refreshes the tokenUri on them if empty string
     */
    NftsApi.prototype.refreshNftTokenUriRaw = function () {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("User", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/refresh-nft-token-uri",
                                method: 'PUT',
                                headers: headerParameters,
                                query: queryParameters,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * finds all the NFTs belonging to this user and refreshes the tokenUri on them if empty string
     */
    NftsApi.prototype.refreshNftTokenUri = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.refreshNftTokenUriRaw()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * listens for webhook events emitted by SimpleHash
     */
    NftsApi.prototype.simplehashWebhookListenerRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.id === null || requestParameters.id === undefined) {
                            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling simplehashWebhookListener.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        return [4 /*yield*/, this.request({
                                path: "/simplehash/webhook-listener/{id}".replace("{".concat("id", "}"), encodeURIComponent(String(requestParameters.id))),
                                method: 'POST',
                                headers: headerParameters,
                                query: queryParameters,
                                body: (0, models_1.SimpleHashWebhookPayloadToJSON)(requestParameters.simpleHashWebhookPayload),
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * listens for webhook events emitted by SimpleHash
     */
    NftsApi.prototype.simplehashWebhookListener = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.simplehashWebhookListenerRaw(requestParameters)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Update nft grouping in the system
     */
    NftsApi.prototype.updateNftGroupingRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("Admin", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/nft-groupings",
                                method: 'PUT',
                                headers: headerParameters,
                                query: queryParameters,
                                body: (0, models_1.UpdateNftGroupingBodyToJSON)(requestParameters.updateNftGroupingBody),
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * Update nft grouping in the system
     */
    NftsApi.prototype.updateNftGrouping = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.updateNftGroupingRaw(requestParameters)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Updates an existing NFT reward prize in the system for Admin.
     */
    NftsApi.prototype.updateNftRewardPrizeRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.id === null || requestParameters.id === undefined) {
                            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling updateNftRewardPrize.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("Admin", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/nft-reward-prizes/{id}".replace("{".concat("id", "}"), encodeURIComponent(String(requestParameters.id))),
                                method: 'PUT',
                                headers: headerParameters,
                                query: queryParameters,
                                body: (0, models_1.UpdateNftRewardPrizeBodyToJSON)(requestParameters.updateNftRewardPrizeBody),
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * Updates an existing NFT reward prize in the system for Admin.
     */
    NftsApi.prototype.updateNftRewardPrize = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.updateNftRewardPrizeRaw(requestParameters)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Updates a nft tracker in the system
     */
    NftsApi.prototype.updateNftTrackerRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("Admin", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/nft-trackers",
                                method: 'PUT',
                                headers: headerParameters,
                                query: queryParameters,
                                body: (0, models_1.UpdateNftTrackerBodyToJSON)(requestParameters.updateNftTrackerBody),
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * Updates a nft tracker in the system
     */
    NftsApi.prototype.updateNftTracker = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.updateNftTrackerRaw(requestParameters)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Updates or Creates a NFT reward\'s metadata in the system and add prizes to the reward
     */
    NftsApi.prototype.upsertNftRewardRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("Admin", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/nft-rewards",
                                method: 'PUT',
                                headers: headerParameters,
                                query: queryParameters,
                                body: (0, models_1.UpsertNftRewardBodyToJSON)(requestParameters.upsertNftRewardBody),
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * Updates or Creates a NFT reward\'s metadata in the system and add prizes to the reward
     */
    NftsApi.prototype.upsertNftReward = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.upsertNftRewardRaw(requestParameters)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Pre-burn validation so that users do not destroy their NFTs when there\'s no eligible prizes remaining.
     */
    NftsApi.prototype.validateEligiblePrizesRemainingRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.id === null || requestParameters.id === undefined) {
                            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling validateEligiblePrizesRemaining.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("User", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/validate-eligible-prizes-remaining/{id}".replace("{".concat("id", "}"), encodeURIComponent(String(requestParameters.id))),
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * Pre-burn validation so that users do not destroy their NFTs when there\'s no eligible prizes remaining.
     */
    NftsApi.prototype.validateEligiblePrizesRemaining = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.validateEligiblePrizesRemainingRaw(requestParameters)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return NftsApi;
}(runtime.BaseAPI));
exports.NftsApi = NftsApi;
