"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.LootboxMintRecordToJSON = exports.LootboxMintRecordFromJSONTyped = exports.LootboxMintRecordFromJSON = void 0;
var runtime_1 = require("../runtime");
function LootboxMintRecordFromJSON(json) {
    return LootboxMintRecordFromJSONTyped(json, false);
}
exports.LootboxMintRecordFromJSON = LootboxMintRecordFromJSON;
function LootboxMintRecordFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'user': json['user'],
        'userLevel': json['userLevel'],
        'transactionHash': !(0, runtime_1.exists)(json, 'transactionHash') ? undefined : json['transactionHash'],
        'nftTracker': !(0, runtime_1.exists)(json, 'nftTracker') ? undefined : json['nftTracker'],
        'nftId': !(0, runtime_1.exists)(json, 'nftId') ? undefined : json['nftId'],
        'mintFailed': !(0, runtime_1.exists)(json, 'mintFailed') ? undefined : json['mintFailed'],
    };
}
exports.LootboxMintRecordFromJSONTyped = LootboxMintRecordFromJSONTyped;
function LootboxMintRecordToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'user': value.user,
        'userLevel': value.userLevel,
        'transactionHash': value.transactionHash,
        'nftTracker': value.nftTracker,
        'nftId': value.nftId,
        'mintFailed': value.mintFailed,
    };
}
exports.LootboxMintRecordToJSON = LootboxMintRecordToJSON;
