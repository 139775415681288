"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserMonthlyPointsToJSON = exports.UserMonthlyPointsFromJSONTyped = exports.UserMonthlyPointsFromJSON = void 0;
function UserMonthlyPointsFromJSON(json) {
    return UserMonthlyPointsFromJSONTyped(json, false);
}
exports.UserMonthlyPointsFromJSON = UserMonthlyPointsFromJSON;
function UserMonthlyPointsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'user': json['user'],
        'totalPointsContributedToCommunity': json['totalPointsContributedToCommunity'],
        'monthlyPointsEarned': json['monthlyPointsEarned'],
    };
}
exports.UserMonthlyPointsFromJSONTyped = UserMonthlyPointsFromJSONTyped;
function UserMonthlyPointsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'user': value.user,
        'totalPointsContributedToCommunity': value.totalPointsContributedToCommunity,
        'monthlyPointsEarned': value.monthlyPointsEarned,
    };
}
exports.UserMonthlyPointsToJSON = UserMonthlyPointsToJSON;
