"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompleteWithdrawBodyToJSON = exports.CompleteWithdrawBodyFromJSONTyped = exports.CompleteWithdrawBodyFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function CompleteWithdrawBodyFromJSON(json) {
    return CompleteWithdrawBodyFromJSONTyped(json, false);
}
exports.CompleteWithdrawBodyFromJSON = CompleteWithdrawBodyFromJSON;
function CompleteWithdrawBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'notes': !(0, runtime_1.exists)(json, 'notes') ? undefined : json['notes'],
        'hash': json['hash'],
        'wallet': json['wallet'],
        'walletChain': !(0, runtime_1.exists)(json, 'walletChain') ? undefined : (0, _1.WithdrawWalletChainFromJSON)(json['walletChain']),
    };
}
exports.CompleteWithdrawBodyFromJSONTyped = CompleteWithdrawBodyFromJSONTyped;
function CompleteWithdrawBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'notes': value.notes,
        'hash': value.hash,
        'wallet': value.wallet,
        'walletChain': (0, _1.WithdrawWalletChainToJSON)(value.walletChain),
    };
}
exports.CompleteWithdrawBodyToJSON = CompleteWithdrawBodyToJSON;
