import React, {ReactElement, useState} from "react";
import {Address, UtilsApi} from "@devour/client";
import FrameOneAutocompleteInput from "./FrameOneAutocompleteInput";

interface Props {
	callback: (newAddress: Address) => void;
	onError?: () => void;
}


function FrameOneAddressAutocomplete(props: Props): ReactElement {

	const [autocompleteResults, setAutocompleteResults] = useState<Array<{ formattedAddress: string; placeId: string; }>>([]);
	const [lastUnusableQuery, setLastUnusableQuery] = useState<string>("");

	async function fetchAutocompleteResults(query: string): Promise<void> {
		try {
			const previousQueryHasResults = (query.indexOf(lastUnusableQuery) !== 0 || lastUnusableQuery === "");
			if (query && previousQueryHasResults) {
				const res = await new UtilsApi().getAddressAutocomplete({
					formattedAddress: query,
				});
				setAutocompleteResults(res.addresses);
				if (!res.addresses.length) {
					setLastUnusableQuery(query);
				}
			} else {
				setAutocompleteResults([]);
			}
		} catch {
			props?.onError();
		}
	}

	async function onAddressSelect(placeId: string): Promise<void> {
		const res = await new UtilsApi().getAddressFromPlaceId({
			placeId: placeId,
		});
		props.callback(res);
	}

	const autocompleteArray: Array<{ data: string; render: string }> = autocompleteResults.map((result) => {
		return {
			data: result.placeId,
			render: result.formattedAddress,
		}
	});

	return (
		<FrameOneAutocompleteInput
			<string>
			emptyText="Address not found"
			placeholder="Search for your address"
			results={autocompleteArray}
			onSelect={onAddressSelect}
			onInputChange={fetchAutocompleteResults}
			resetOnSelect={true}
			minLength={10}
		/>
	);
}

export default FrameOneAddressAutocomplete;
