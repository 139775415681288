"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetUserResponseToJSON = exports.GetUserResponseFromJSONTyped = exports.GetUserResponseFromJSON = void 0;
var _1 = require("./");
function GetUserResponseFromJSON(json) {
    return GetUserResponseFromJSONTyped(json, false);
}
exports.GetUserResponseFromJSON = GetUserResponseFromJSON;
function GetUserResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'user': (0, _1.UserFromJSON)(json['user']),
        'wallets': (json['wallets'].map(_1.EvmWalletFromJSON)),
    };
}
exports.GetUserResponseFromJSONTyped = GetUserResponseFromJSONTyped;
function GetUserResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'user': (0, _1.UserToJSON)(value.user),
        'wallets': (value.wallets.map(_1.EvmWalletToJSON)),
    };
}
exports.GetUserResponseToJSON = GetUserResponseToJSON;
