import React, {ReactElement, useCallback, useEffect, useState} from "react";
import {
    Business,
    BusinessesApi,
    BusinessPermission,
    BusinessUserFull,
    GetBusinessPermissionsResponse,
    GetBusinessPermissionsSort,
    SortOrder
} from "@devour/client";
import {createColumnHelper, getCoreRowModel, SortingState} from "@tanstack/react-table";
import FrameOneTableContainer from "../../../components/tables/FrameOneTableContainer";
import {defaultFrontendPagination, FrontendPagination} from "../../../components/tables/FrameOnePaginator";
import {addError, decrementLoading, incrementLoading} from "../../../redux/meta/metaActions";
import getConfig from "../../../utils/getConfig";
import {useDispatch} from "react-redux";
import FrameButton from "../../../components/buttons/FrameButton";
import {IoPencil} from "react-icons/io5";
import {AiOutlineSearch} from "react-icons/ai";
import {FaPlus} from "react-icons/fa";
import BusinessEditUserPermissionModal from "../../../components/modals/BusinessEditUserPermissionModal";
import classNames from "classnames";
import _ from "lodash";

interface Props {
    business: Business;
    onUpdateBusinessPermissions: () => Promise<void>;
}
const columnHelper = createColumnHelper<BusinessUserFull>();

function BusinessDetailsUserPermissions(props: Props): ReactElement {
    const dispatch = useDispatch();
    const [sorting, setSorting] = useState<SortingState>([{id: "email", desc: false}]);
    const [frontendPagination, setFrontendPagination] = useState<FrontendPagination>(defaultFrontendPagination);
    const [authPermissions, setAuthPermissions] = useState<Array<BusinessPermission>>([]);
    const [businessPermissions, setBusinessPermissions] = useState<GetBusinessPermissionsResponse>();
    const [showAddModal, setShowAddModal] = useState<boolean>(false);
    const [editPermission, setEditPermission] = useState<BusinessUserFull>(undefined);
    const [searchValue, setSearchValue] = useState<string>(undefined);

    const debounceLoadData = useCallback(_.debounce(fetchBusinessPermissions, 1000), []);

    useEffect(() => {
        void fetchBusinessPermissions();
    }, [props.business?.id, frontendPagination, sorting]);

    useEffect(() => {
        if (searchValue != null) {
            void debounceLoadData(searchValue);
        }
    }, [searchValue]);

    async function fetchBusinessPermissions(newSearchValue?: string): Promise<void> {
        if (!props.business) {
            return;
        }
        dispatch(incrementLoading());

        try {
            const res = await new BusinessesApi(getConfig()).getBusinessPermissions({
                id: props.business.id,
                limit: frontendPagination.limit,
                offset: frontendPagination.offset,
                sort: sorting[0]?.id as GetBusinessPermissionsSort,
                order: (sorting[0]?.desc) ? SortOrder.Desc : SortOrder.Asc,
                search: newSearchValue || searchValue,
            });
            setBusinessPermissions(res);
            setAuthPermissions(res.authUser);
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }
    }
    function renderPermission(permission: boolean) {
        return (
            <span className={permission ? "has-permission" : "no-permission"}>
                {permission ? "Yes" : "No"}
            </span>
        )
    }

    const columns = [
        columnHelper.accessor("email", {
            enableSorting: true,
            header: "Email",
        }),
        columnHelper.accessor("firstName", {
            cell: (info) => `${info.getValue() || ""} ${info.row.original.lastName || ""}`,
            header: "User",
        }),
        columnHelper.accessor(undefined, {
            cell: (info) => renderPermission(info.row.original.permissions.includes(BusinessPermission.OPERATIONS)),
            header: "Operations",
        }),
        columnHelper.accessor(undefined, {
            cell: (info) => renderPermission(info.row.original.permissions.includes(BusinessPermission.BILLING)),
            header: "Billing",
        }),
        columnHelper.accessor(undefined, {
            cell: (info) => renderPermission(info.row.original.permissions.includes(BusinessPermission.USERS)),
            header: "Users",
        }),
        columnHelper.accessor(undefined, {
            cell: (info) => renderPermission(info.row.original.permissions.includes(BusinessPermission.MENUS)),
            header: "Menus",
        }),
        columnHelper.accessor(undefined, {
            cell: (info) => renderPermission(info.row.original.permissions.includes(BusinessPermission.CONTACT)),
            header: "Contact",
        }),
        columnHelper.accessor(undefined, {
            cell: (info) => renderPermission(info.row.original.permissions.includes(BusinessPermission.ORDERSVIEW)),
            header: "View Menu Orders",
        }),
        columnHelper.accessor(undefined, {
            cell: (info) => (
                // <FrameButton color="purple" size="narrow">Update Permissions</FrameButton>
                <div
                    className={classNames(
                        "business-page_users_update",
                        {"is-disabled": !authPermissions.includes(BusinessPermission.USERS)},
                    )}
                    onClick={() => setEditPermission(info.row.original)}
                >
                    <IoPencil />
                    Update Permissions
                </div>
            ),
            header: "Update Permissions",
        }),
    ];

    function onModalClose(): void {
        setShowAddModal(false);
        setEditPermission(undefined);
    }

    function onFinishUpdatePermission(): void {
        onModalClose();
        void fetchBusinessPermissions();
        void props.onUpdateBusinessPermissions();
    }

    if (!businessPermissions) {
        return null;
    }

    return (
        <div className="business-page_users">
            <BusinessEditUserPermissionModal
                authPermissions={authPermissions}
                userPermission={editPermission}
                business={(showAddModal || editPermission) ? props.business : undefined}
                onClose={() => onModalClose()}
                onUpdate={() => onFinishUpdatePermission()}
            />
            <div className="business-page_users_header">
                <div className="business-page_taxonomies_search">
                    <AiOutlineSearch/>
                    <input
                        type="search"
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value.toLowerCase())}
                        placeholder="Search for a user"
                    />
                </div>
                <FrameButton
                    color="purple"
                    size="narrow"
                    leftIcon={FaPlus}
                    onClick={() => setShowAddModal(true)}
                    disabled={!authPermissions.includes(BusinessPermission.USERS)}
                >
                    New User
                </FrameButton>
            </div>
            <FrameOneTableContainer
                <BusinessUserFull>
                tableOptions={{
                    columns,
                    data: businessPermissions?.currentUsers,
                    getCoreRowModel: getCoreRowModel(),
                }}
                sorting={sorting}
                onSort={setSorting}
                pagination={{
                    ...businessPermissions?.paginationInfo,
                    ...frontendPagination,
                }}
                onPaginationChange={setFrontendPagination}
            />
        </div>
    )
}

export default BusinessDetailsUserPermissions;