import {CreateBrandMapBodyFrontend} from "../../pages/brands/components/BrandMapFormValues";
import React, {ChangeEventHandler, ReactElement} from "react";
import ReactQuill from 'react-quill';
import "react-quill/dist/quill.snow.css";

interface Props {
    onChange: (description: string) => void;
    value: string;
}

function MerchantBrandDescription(props: Props): ReactElement {
    return (
        <div className="merchant-brand-title-description">
            <div className="merchant-brand-title-description_content">
                <h5>
                    Description
                    <span className="create-map-page_optional">Optional</span>
                </h5>
                <p className="merchant-brand-title-description_content_subtitle">
                    Provide an overview of your brand's presence.
                </p>


                 <ReactQuill
                    value={props.value}
                    onChange={props.onChange}
                    placeholder="E.g. Explore our interactive brand map to discover exclusive rewards and experiences at key locations!"
                    formats={[
                        "header", "bold", "italic", "underline", "strike", "blockquote",
                        "list", "bullet", "indent", "link", "image", "video", "code-block", "align"
                    ]}
                 />
            </div>
        </div>
    );
}

export default MerchantBrandDescription;