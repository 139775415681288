import React, {PropsWithChildren, ReactElement, useCallback, useEffect} from "react";
import {connect, ConnectedProps} from "react-redux";
import {useNavigate} from "react-router-dom";
import {ErrorType, Token} from "@devour/client";
import {IStore} from "../../redux/defaultStore";
import {addError} from "../../redux/meta/metaActions";

interface StateProps {
	fullToken: Token;
}

function AuthenticatedRoute(props: AuthenticatedRouteProps): ReactElement {

	const {fullToken} = props;
	const history = useNavigate();

	/**
	 * Async function added, so we can leverage "await" on addError passed to the Redux dispatch action.
	 *
	 */
	const handleErrorDispatch = useCallback(async () => {
		props.dispatch(await addError({
			type: ErrorType.APP,
			message: "You must have a token to access that page. If you were previously logged in, your access token may have expired and you must log in again to get a new one.",
		}));
	}, [props]);

	useEffect(() => {
		if (!fullToken?.token) {
			handleErrorDispatch().then().catch();
			history("/",{replace: true});
		}
	}, [fullToken, handleErrorDispatch, history]);

	if (!fullToken?.token) {
		return null;
	}

	return (
		<React.Fragment>
			{props.children}
		</React.Fragment>
	);
}

function connector() {
	return connect((store: IStore, props: PropsWithChildren<{}>): StateProps & PropsWithChildren<{}> => {
		return {
			fullToken: store.authStore.fullToken,
			...props,
		}
	});
}

type AuthenticatedRouteProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(AuthenticatedRoute);
