import React, {ChangeEvent, ReactElement, ReactNode, useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {Token, User, CreateAssetRequest, AssetsApi, Asset} from "@devour/client";
import {BsCardImage} from "react-icons/bs";
import FileInputButton from "../../components/inputs/FileInputButton";
import getConfig from "../../utils/getConfig";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/metaActions";
import FrameButton from "../../components/buttons/FrameButton";
import getImageUploadAcceptedTypes from "../../utils/getImageUploadAcceptedTypes";

const defaultForm: CreateAssetRequest = {
	asset: undefined,
	name: "",
	description: "",
};

interface Props {
	disabled?: boolean;
	asset?: Asset;
	assetName: string;
	assetDescription: string;
	label: string;
	description: ReactNode;
	/** https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/accept **/
	acceptTypes?: string;
	onNew?: (assetId: string) => void;
	onUpdate?: (assetId: string) => void;
	onDelete?: () => void;
	onDeleteError?: () => void;
}

interface StateProps {
	fullToken: Token;
	currentUser: User;
}

function ImageUploadCard(props: ImageUploadCardProps): ReactElement {
	const [counter, setCounter] = useState<number>(0);
	const [form, setForm] = useState<CreateAssetRequest>({
		...defaultForm,
		name: props.assetName,
		description: props.assetDescription,
	});

	useEffect(() => {
		// Upload on form asset change
		if (form.asset) {
			uploadAsset().then().catch();
		}
	}, [form.asset]);

	async function uploadAsset(): Promise<void> {
		props.dispatch(incrementLoading());
		try {
			// if (props.asset) {
			// 	await new AssetsApi(getConfig(props.fullToken)).updateAsset({
			// 		...form,
			// 		id: props.asset.id,
			// 	});
			// 	if (props.onUpdate) {
			// 		props.onUpdate(props.asset.id);
			// 	}
			// } else {
				const asset = await new AssetsApi(getConfig(props.fullToken)).createAsset(form);
				if (props.onNew) {
					props.onNew(asset.id);
				}
			// }
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
			setCounter(counter + 1);
		}

	}

	async function onDelete(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			await new AssetsApi(getConfig(props.fullToken)).deleteAsset({
				id: props.asset.id,
			});
			props.dispatch(decrementLoading());
			setForm({
				...defaultForm,
				name: props.assetName,
				description: props.assetDescription,
			});
			if (props.onDelete) {
				props.onDelete();
			}
		} catch (e) {
			if (props.onDeleteError) {
				props.onDeleteError();
			} else {
				props.dispatch(await addError(e));
			}
		} finally {
			props.dispatch(decrementLoading());
		}

	}

	async function onFileChange(e: ChangeEvent<HTMLInputElement>): Promise<void> {
		setForm({
			...form,
			asset: e.target.files[0],
		});
	}

	return (
		<div className="image-upload-card">
			<div className="image-upload-card_image">
				{(props.asset) ? (
					<div className="image-upload-card_image_photo">
						<img
							src={`${props.asset.url}?v=${counter}`}
							alt=""
						/>
					</div>
				) : (
					<div className="image-upload-card_image_placeholder">
						<BsCardImage/>
					</div>
				)}
			</div>
			<div className="image-upload-card_info">
				<h4 className="image-upload-card_label">
					{props.label}
				</h4>
				<div className="image-upload-card_description">
					{props.description}
				</div>
				<div className="image-upload-card_actions">
					{(!props.disabled) && (
						<React.Fragment>
							<FileInputButton
								accept={props.acceptTypes}
								onChange={onFileChange}
							>
								<FrameButton
									<React.ButtonHTMLAttributes<HTMLButtonElement>>
									forwardProps={{type: "button"}}
									color="purple"
									size="narrow"
								>
									Upload
								</FrameButton>
							</FileInputButton>
							{(props.asset) && (
								<FrameButton
									<React.ButtonHTMLAttributes<HTMLButtonElement>>
									forwardProps={{type: "button"}}
									color="danger"
									size="narrow"
									onClick={() => onDelete()}
								>
									Delete
								</FrameButton>
							)}
						</React.Fragment>
					)}
				</div>
			</div>
		</div>
	);
}

ImageUploadCard.defaultProps = {
	acceptTypes: getImageUploadAcceptedTypes(),
};

function connector() {
	return connect((store: IStore, props: Props): StateProps & Props => {
		return {
			currentUser: store.metaStore.currentUser,
			fullToken: store.authStore.fullToken,
			...props,
		}
	});
}

type ImageUploadCardProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(ImageUploadCard);
