"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FrontendErrorToJSON = exports.FrontendErrorFromJSONTyped = exports.FrontendErrorFromJSON = void 0;
var runtime_1 = require("../runtime");
function FrontendErrorFromJSON(json) {
    return FrontendErrorFromJSONTyped(json, false);
}
exports.FrontendErrorFromJSON = FrontendErrorFromJSON;
function FrontendErrorFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'search': !(0, runtime_1.exists)(json, 'search') ? undefined : json['search'],
        'route': !(0, runtime_1.exists)(json, 'route') ? undefined : json['route'],
        'message': !(0, runtime_1.exists)(json, 'message') ? undefined : json['message'],
        'stack': !(0, runtime_1.exists)(json, 'stack') ? undefined : json['stack'],
        'ipAddress': !(0, runtime_1.exists)(json, 'ipAddress') ? undefined : json['ipAddress'],
        'user': !(0, runtime_1.exists)(json, 'user') ? undefined : json['user'],
    };
}
exports.FrontendErrorFromJSONTyped = FrontendErrorFromJSONTyped;
function FrontendErrorToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'search': value.search,
        'route': value.route,
        'message': value.message,
        'stack': value.stack,
        'ipAddress': value.ipAddress,
        'user': value.user,
    };
}
exports.FrontendErrorToJSON = FrontendErrorToJSON;
