import InitialMetaStore, {IMetaStore} from "./meta/InitialMetaStore";
import InitialAuthStore, {IAuthStore} from "./auth/InitialAuthStore";

export interface IStore {
	metaStore: IMetaStore;
	authStore: IAuthStore;
}

export default {
	metaStore: InitialMetaStore,
	authStore: InitialAuthStore,
} as IStore;
