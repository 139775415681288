"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreatePaymentMethodBodyToJSON = exports.CreatePaymentMethodBodyFromJSONTyped = exports.CreatePaymentMethodBodyFromJSON = void 0;
function CreatePaymentMethodBodyFromJSON(json) {
    return CreatePaymentMethodBodyFromJSONTyped(json, false);
}
exports.CreatePaymentMethodBodyFromJSON = CreatePaymentMethodBodyFromJSON;
function CreatePaymentMethodBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'cardNumber': json['cardNumber'],
        'expMonth': json['expMonth'],
        'expYear': json['expYear'],
        'cvc': json['cvc'],
        'country': json['country'],
        'postalCode': json['postalCode'],
    };
}
exports.CreatePaymentMethodBodyFromJSONTyped = CreatePaymentMethodBodyFromJSONTyped;
function CreatePaymentMethodBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'cardNumber': value.cardNumber,
        'expMonth': value.expMonth,
        'expYear': value.expYear,
        'cvc': value.cvc,
        'country': value.country,
        'postalCode': value.postalCode,
    };
}
exports.CreatePaymentMethodBodyToJSON = CreatePaymentMethodBodyToJSON;
