"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddressToJSON = exports.AddressFromJSONTyped = exports.AddressFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function AddressFromJSON(json) {
    return AddressFromJSONTyped(json, false);
}
exports.AddressFromJSON = AddressFromJSON;
function AddressFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'location': !(0, runtime_1.exists)(json, 'location') ? undefined : (0, _1.GeoPointFromJSON)(json['location']),
        'line1': !(0, runtime_1.exists)(json, 'line1') ? undefined : json['line1'],
        'line2': !(0, runtime_1.exists)(json, 'line2') ? undefined : json['line2'],
        'locality': json['locality'],
        'administrativeArea': json['administrativeArea'],
        'postalCode': !(0, runtime_1.exists)(json, 'postalCode') ? undefined : json['postalCode'],
        'country': json['country'],
        'placeId': !(0, runtime_1.exists)(json, 'placeId') ? undefined : json['placeId'],
    };
}
exports.AddressFromJSONTyped = AddressFromJSONTyped;
function AddressToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'location': (0, _1.GeoPointToJSON)(value.location),
        'line1': value.line1,
        'line2': value.line2,
        'locality': value.locality,
        'administrativeArea': value.administrativeArea,
        'postalCode': value.postalCode,
        'country': value.country,
        'placeId': value.placeId,
    };
}
exports.AddressToJSON = AddressToJSON;
