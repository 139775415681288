import {BusinessUserFull, BusinessAddUserBody, Business, BusinessesApi, BusinessPermission} from "@devour/client";
import React, {ChangeEventHandler, FormEvent, ReactElement, ReactNode, useEffect, useState} from "react";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/metaActions";
import getConfig from "../../utils/getConfig";
import {useDispatch} from "react-redux";
import {cloneDeep} from "lodash";
import FrameOneModal from "./modalComponents/FrameOneModal";
import {FaArrowLeft} from "react-icons/fa";
import FrameModalBody from "./modalComponents/FrameModalBody";
import { TbTrashXFilled } from "react-icons/tb";
import FrameButton from "../buttons/FrameButton";
import classNames from "classnames";

const defaultValues: BusinessAddUserBody = {
    email: "",
    permissions: [],
};

interface Props {
    authPermissions: Array<BusinessPermission>;
    userPermission?: BusinessUserFull;
    business: Business;
    onClose: () => void;
    onUpdate: () => void;
}

interface PermissionDescription {
    permission: BusinessPermission;
    name: string;
}

const businessPermissions:Array<PermissionDescription> = [
    {
        permission: BusinessPermission.OPERATIONS,
        name: "Operations",
    },
    {
        permission: BusinessPermission.BILLING,
        name: "Billing",
    },
    {
        permission: BusinessPermission.USERS,
        name: "Users",
    },
    {
        permission: BusinessPermission.MENUS,
        name: "Menus",
    },
    {
        permission: BusinessPermission.CONTACT,
        name: "Contact",
    },
    {
        permission: BusinessPermission.ORDERSVIEW,
        name: "View Menu Orders",
    },
]

export default function BusinessEditUserPermissionModal(props: Props): ReactElement {
    const dispatch = useDispatch();
    const [formValues, setFormValues] = useState<BusinessAddUserBody>(defaultValues);

    useEffect(() => {
        setFormValues(defaultValues);
    }, [props.business]);

    useEffect(() => {
        if (props.userPermission) {
            setFormValues({
                email: props.userPermission.email,
                permissions: props.userPermission.permissions,
            })
        }
    }, [props.userPermission]);

    /**
     * Handle all text input onChange events.
     *
     * @param key
     */
    function inputOnChange(key: keyof BusinessAddUserBody): ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement> {
        return (e) => {
            setFormValues({
                ...formValues,
                [key]: e.target.value,
            });
        }
    }

    /**
     * Handle all checkbox input onChange events.
     *
     * @param permission
     */
    function checkboxOnToggle(permission: BusinessPermission): void {
        // Attempt to find this option in the checked items array
        const newPermissions: Array<BusinessPermission> = cloneDeep(formValues.permissions);


        const thisIndex = newPermissions.indexOf(permission);

        if (thisIndex > -1) {
            // Remove this option from list of selected items
            newPermissions.splice(thisIndex, 1);
        } else {
            newPermissions.push(permission);
        }
        setFormValues({
            ...formValues,
            permissions: newPermissions,
        });
    }

    async function submitAddedUser(e: FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();
        dispatch(incrementLoading());

        try {
            await new BusinessesApi(getConfig()).addBusinessUser({
                id: props.business.id,
                businessAddUserBody: formValues,
            });
            setFormValues(defaultValues);
            props.onUpdate();
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }
    }

    async function submitUpdatedPermission(e: FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();
        dispatch(incrementLoading());

        try {
            await new BusinessesApi(getConfig()).updateBusinessUser({
                id: props.business.id,
                businessUpdateUserBody: {
                    userId: props.userPermission.user,
                    permissions: formValues.permissions
                },
            });
            props.onUpdate();
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }
    }

    async function removeBusinessUser(userId: string): Promise<void> {
        dispatch(incrementLoading());

        try {
            await new BusinessesApi(getConfig()).deleteBusinessUser({
                id: props.business.id,
                businessDeleteUserBody: {userId},
            });
            props.onUpdate();
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }
    }

    function renderSwitchInput(businessPermission: BusinessPermission) {
        const hasPermission = formValues.permissions.includes(businessPermission);

        return (
            <div className="business-add-user-modal_body_field_permission_input">
                <span>{hasPermission ? "Yes" : "No"}</span>
                <div className="switch-input pretty p-switch p-fill">
                    <input
                        type="checkbox"
                        onChange={() => checkboxOnToggle(businessPermission)}
                        checked={hasPermission}
                        disabled={!props.authPermissions?.includes(businessPermission)}
                    />
                    <div className="state">
                        <label/>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <FrameOneModal
            isOpen={!!props?.business}
            toggle={props.onClose}
            size="sm"
            contentClassName="business-add-user-modal"
        >
            <div className="business-add-user-modal_header">
                <FaArrowLeft onClick={props.onClose}/>
                <h5>{props.userPermission ? `Edit User (${props.userPermission.email})` : "New User"}</h5>
            </div>
            <FrameModalBody className="business-add-user-modal_body">
                <form onSubmit={props.userPermission ? submitUpdatedPermission : submitAddedUser}>
                    <div className="business-add-user-modal_body_field">
                        <label>Email Address *</label>
                        <input
                            type="email"
                            placeholder="User's Email"
                            value={formValues.email}
                            onChange={inputOnChange("email")}
                            required={true}
                            disabled={!!props.userPermission}
                        />
                    </div>
                    <div className="business-add-user-modal_body_field permissions-list">
                        <label>Permissions</label>
                        {businessPermissions.map(permission => (
                            <div
                                key={permission.permission}
                                className={classNames(
                                "business-add-user-modal_body_field_permission",
                                    {"is-disabled": !props.authPermissions?.includes(permission.permission)}
                                )}
                            >
                                <span>{permission.name}</span>
                                {renderSwitchInput(permission.permission)}
                            </div>
                        ))}
                    </div>
                    <div className="business-add-user-modal_body_buttons">
                        {props.userPermission && (
                            <div
                                className="business-add-user-modal_body_buttons_left"
                                onClick={() => removeBusinessUser(props.userPermission.user)}
                            >
                                <TbTrashXFilled />
                                Delete
                            </div>
                        )}
                        <div className="business-add-user-modal_body_buttons_right">
                            <span
                                className="business-add-user-modal_body_buttons_right_cancel"
                                onClick={props.onClose}
                            >
                                Cancel
                            </span>
                            <FrameButton
                                <React.ButtonHTMLAttributes<HTMLButtonElement>>
                                color="purple"
                                size="narrow"
                                className="business-add-user-modal_body_buttons_right_submit"
                                forwardProps={{type: "submit"}}
                            >
                                Save
                            </FrameButton>
                        </div>

                    </div>
                </form>
            </FrameModalBody>
        </FrameOneModal>
    )
}

