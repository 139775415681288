"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetBrandsEnabledStatusToJSON = exports.GetBrandsEnabledStatusFromJSONTyped = exports.GetBrandsEnabledStatusFromJSON = exports.GetBrandsEnabledStatus = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var GetBrandsEnabledStatus;
(function (GetBrandsEnabledStatus) {
    GetBrandsEnabledStatus["ENABLED"] = "ENABLED";
    GetBrandsEnabledStatus["DISABLED"] = "DISABLED";
})(GetBrandsEnabledStatus = exports.GetBrandsEnabledStatus || (exports.GetBrandsEnabledStatus = {}));
function GetBrandsEnabledStatusFromJSON(json) {
    return GetBrandsEnabledStatusFromJSONTyped(json, false);
}
exports.GetBrandsEnabledStatusFromJSON = GetBrandsEnabledStatusFromJSON;
function GetBrandsEnabledStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.GetBrandsEnabledStatusFromJSONTyped = GetBrandsEnabledStatusFromJSONTyped;
function GetBrandsEnabledStatusToJSON(value) {
    return value;
}
exports.GetBrandsEnabledStatusToJSON = GetBrandsEnabledStatusToJSON;
