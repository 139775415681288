"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetTotalDpayResponseToJSON = exports.GetTotalDpayResponseFromJSONTyped = exports.GetTotalDpayResponseFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function GetTotalDpayResponseFromJSON(json) {
    return GetTotalDpayResponseFromJSONTyped(json, false);
}
exports.GetTotalDpayResponseFromJSON = GetTotalDpayResponseFromJSON;
function GetTotalDpayResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'vDpayTotal': json['vDpayTotal'],
        'totalInUsd': json['totalInUsd'],
        'topUsers': !(0, runtime_1.exists)(json, 'topUsers') ? undefined : (json['topUsers'].map(_1.DpayUserItemFromJSON)),
    };
}
exports.GetTotalDpayResponseFromJSONTyped = GetTotalDpayResponseFromJSONTyped;
function GetTotalDpayResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'vDpayTotal': value.vDpayTotal,
        'totalInUsd': value.totalInUsd,
        'topUsers': value.topUsers === undefined ? undefined : (value.topUsers.map(_1.DpayUserItemToJSON)),
    };
}
exports.GetTotalDpayResponseToJSON = GetTotalDpayResponseToJSON;
