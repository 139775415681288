"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MenuCustomizationToJSON = exports.MenuCustomizationFromJSONTyped = exports.MenuCustomizationFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function MenuCustomizationFromJSON(json) {
    return MenuCustomizationFromJSONTyped(json, false);
}
exports.MenuCustomizationFromJSON = MenuCustomizationFromJSON;
function MenuCustomizationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'menuItem': !(0, runtime_1.exists)(json, 'menuItem') ? undefined : json['menuItem'],
        'customizationItem': !(0, runtime_1.exists)(json, 'customizationItem') ? undefined : json['customizationItem'],
        'name': json['name'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'minAggregate': !(0, runtime_1.exists)(json, 'minAggregate') ? undefined : json['minAggregate'],
        'maxAggregate': !(0, runtime_1.exists)(json, 'maxAggregate') ? undefined : json['maxAggregate'],
        'minSelects': !(0, runtime_1.exists)(json, 'minSelects') ? undefined : json['minSelects'],
        'maxSelects': !(0, runtime_1.exists)(json, 'maxSelects') ? undefined : json['maxSelects'],
        'minChoice': !(0, runtime_1.exists)(json, 'minChoice') ? undefined : json['minChoice'],
        'maxChoice': !(0, runtime_1.exists)(json, 'maxChoice') ? undefined : json['maxChoice'],
        'quantityIncrement': !(0, runtime_1.exists)(json, 'quantityIncrement') ? undefined : json['quantityIncrement'],
        'multipleQuantities': !(0, runtime_1.exists)(json, 'multipleQuantities') ? undefined : json['multipleQuantities'],
        'sortOrder': !(0, runtime_1.exists)(json, 'sortOrder') ? undefined : json['sortOrder'],
        'serviceAvailabilities': !(0, runtime_1.exists)(json, 'serviceAvailabilities') ? undefined : (json['serviceAvailabilities'].map(_1.BusinessServiceAvailabilityFromJSON)),
        'options': (json['options'].map(_1.MenuCustomizationItemFromJSON)),
        'oloId': !(0, runtime_1.exists)(json, 'oloId') ? undefined : json['oloId'],
        'oloChainId': !(0, runtime_1.exists)(json, 'oloChainId') ? undefined : json['oloChainId'],
        'chowlyId': !(0, runtime_1.exists)(json, 'chowlyId') ? undefined : json['chowlyId'],
        'itsaCheckmateId': !(0, runtime_1.exists)(json, 'itsaCheckmateId') ? undefined : json['itsaCheckmateId'],
    };
}
exports.MenuCustomizationFromJSONTyped = MenuCustomizationFromJSONTyped;
function MenuCustomizationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'menuItem': value.menuItem,
        'customizationItem': value.customizationItem,
        'name': value.name,
        'description': value.description,
        'minAggregate': value.minAggregate,
        'maxAggregate': value.maxAggregate,
        'minSelects': value.minSelects,
        'maxSelects': value.maxSelects,
        'minChoice': value.minChoice,
        'maxChoice': value.maxChoice,
        'quantityIncrement': value.quantityIncrement,
        'multipleQuantities': value.multipleQuantities,
        'sortOrder': value.sortOrder,
        'serviceAvailabilities': value.serviceAvailabilities === undefined ? undefined : (value.serviceAvailabilities.map(_1.BusinessServiceAvailabilityToJSON)),
        'options': (value.options.map(_1.MenuCustomizationItemToJSON)),
        'oloId': value.oloId,
        'oloChainId': value.oloChainId,
        'chowlyId': value.chowlyId,
        'itsaCheckmateId': value.itsaCheckmateId,
    };
}
exports.MenuCustomizationToJSON = MenuCustomizationToJSON;
