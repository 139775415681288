"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetConnectedAccountResponseToJSON = exports.GetConnectedAccountResponseFromJSONTyped = exports.GetConnectedAccountResponseFromJSON = void 0;
var runtime_1 = require("../runtime");
function GetConnectedAccountResponseFromJSON(json) {
    return GetConnectedAccountResponseFromJSONTyped(json, false);
}
exports.GetConnectedAccountResponseFromJSON = GetConnectedAccountResponseFromJSON;
function GetConnectedAccountResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'userId': !(0, runtime_1.exists)(json, 'userId') ? undefined : json['userId'],
    };
}
exports.GetConnectedAccountResponseFromJSONTyped = GetConnectedAccountResponseFromJSONTyped;
function GetConnectedAccountResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'userId': value.userId,
    };
}
exports.GetConnectedAccountResponseToJSON = GetConnectedAccountResponseToJSON;
