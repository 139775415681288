import React, {ChangeEventHandler, ReactElement} from "react";
import {EditBrandMapBodyFrontend} from "./BrandMapFormValues";
import UploadImagePreview from "../../../components/cards/UploadImagePreview";

interface Props {
	formValues: EditBrandMapBodyFrontend;
	onImageChange: (key: "promoHero" | "promoBackground") => ChangeEventHandler<HTMLInputElement>;
	onRemoveImage: (key: "promoHero" | "promoBackground") => void;
}

function EditBrandMapMainPromoImages(props: Props): ReactElement {

	return (
		<div className="create-map-page_container create-map-page_main-promo">
			<div className="create-map-page_container_inner create-map-page_main-promo_images">
				<h5>
					Upload Main Promotion Brand Map Images
				</h5>
				<p>
					The promotion image will appear on the main promotion card on the brand map page, and the background
					image will be used as the background. This will only appear if there is a main promotion set.
				</p>

				<div className="create-map-page_main-promo_images_row">
					<div className="create-map-page_main-promo_images_row_hero">
						<p>
							Preview of Promotion Image
						</p>
						<UploadImagePreview
							<EditBrandMapBodyFrontend>
							onImageChange={props.onImageChange}
							onRemoveImage={props.onRemoveImage}
							image={props.formValues.promoHero}
							fieldName="promoHero"
							optional={true}
						/>
					</div>
					<div className="create-map-page_main-promo_images_row_background">
						<p>
							Preview of Promotion Background Image
						</p>
						<UploadImagePreview
							<EditBrandMapBodyFrontend>
							onImageChange={props.onImageChange}
							onRemoveImage={props.onRemoveImage}
							image={props.formValues.promoBackground}
							fieldName="promoBackground"
							optional={true}
							isBackground={true}
						/>
					</div>
				</div>
			</div>

		</div>
	);
}

export default EditBrandMapMainPromoImages;
