"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BonusDpayOptionToJSON = exports.BonusDpayOptionFromJSONTyped = exports.BonusDpayOptionFromJSON = exports.BonusDpayOption = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var BonusDpayOption;
(function (BonusDpayOption) {
    BonusDpayOption["FLATAMOUNT"] = "FLAT_AMOUNT";
    BonusDpayOption["MULTIPLEAMOUNT"] = "MULTIPLE_AMOUNT";
})(BonusDpayOption = exports.BonusDpayOption || (exports.BonusDpayOption = {}));
function BonusDpayOptionFromJSON(json) {
    return BonusDpayOptionFromJSONTyped(json, false);
}
exports.BonusDpayOptionFromJSON = BonusDpayOptionFromJSON;
function BonusDpayOptionFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.BonusDpayOptionFromJSONTyped = BonusDpayOptionFromJSONTyped;
function BonusDpayOptionToJSON(value) {
    return value;
}
exports.BonusDpayOptionToJSON = BonusDpayOptionToJSON;
