"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetApiResponsesSortToJSON = exports.GetApiResponsesSortFromJSONTyped = exports.GetApiResponsesSortFromJSON = exports.GetApiResponsesSort = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var GetApiResponsesSort;
(function (GetApiResponsesSort) {
    GetApiResponsesSort["CreatedAt"] = "createdAt";
    GetApiResponsesSort["UpdatedAt"] = "updatedAt";
    GetApiResponsesSort["Status"] = "status";
    GetApiResponsesSort["Business"] = "business";
    GetApiResponsesSort["Order"] = "order";
})(GetApiResponsesSort = exports.GetApiResponsesSort || (exports.GetApiResponsesSort = {}));
function GetApiResponsesSortFromJSON(json) {
    return GetApiResponsesSortFromJSONTyped(json, false);
}
exports.GetApiResponsesSortFromJSON = GetApiResponsesSortFromJSON;
function GetApiResponsesSortFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.GetApiResponsesSortFromJSONTyped = GetApiResponsesSortFromJSONTyped;
function GetApiResponsesSortToJSON(value) {
    return value;
}
exports.GetApiResponsesSortToJSON = GetApiResponsesSortToJSON;
