"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommunityRankingRecordToJSON = exports.CommunityRankingRecordFromJSONTyped = exports.CommunityRankingRecordFromJSON = void 0;
function CommunityRankingRecordFromJSON(json) {
    return CommunityRankingRecordFromJSONTyped(json, false);
}
exports.CommunityRankingRecordFromJSON = CommunityRankingRecordFromJSON;
function CommunityRankingRecordFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'month': json['month'],
        'year': json['year'],
        'monthlyTotalPoints': json['monthlyTotalPoints'],
        'grandTotalPoints': json['grandTotalPoints'],
        'endOfMonthRank': json['endOfMonthRank'],
    };
}
exports.CommunityRankingRecordFromJSONTyped = CommunityRankingRecordFromJSONTyped;
function CommunityRankingRecordToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'month': value.month,
        'year': value.year,
        'monthlyTotalPoints': value.monthlyTotalPoints,
        'grandTotalPoints': value.grandTotalPoints,
        'endOfMonthRank': value.endOfMonthRank,
    };
}
exports.CommunityRankingRecordToJSON = CommunityRankingRecordToJSON;
