"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetBusinessBillingResponseToJSON = exports.GetBusinessBillingResponseFromJSONTyped = exports.GetBusinessBillingResponseFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function GetBusinessBillingResponseFromJSON(json) {
    return GetBusinessBillingResponseFromJSONTyped(json, false);
}
exports.GetBusinessBillingResponseFromJSON = GetBusinessBillingResponseFromJSON;
function GetBusinessBillingResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'isAccountConnected': json['isAccountConnected'],
        'stripeAccount': !(0, runtime_1.exists)(json, 'stripeAccount') ? undefined : json['stripeAccount'],
        'clientSecret': !(0, runtime_1.exists)(json, 'clientSecret') ? undefined : json['clientSecret'],
        'user': !(0, runtime_1.exists)(json, 'user') ? undefined : (0, _1.MerchantFromJSON)(json['user']),
    };
}
exports.GetBusinessBillingResponseFromJSONTyped = GetBusinessBillingResponseFromJSONTyped;
function GetBusinessBillingResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'isAccountConnected': value.isAccountConnected,
        'stripeAccount': value.stripeAccount,
        'clientSecret': value.clientSecret,
        'user': (0, _1.MerchantToJSON)(value.user),
    };
}
exports.GetBusinessBillingResponseToJSON = GetBusinessBillingResponseToJSON;
