"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReferralRelationshipToJSON = exports.ReferralRelationshipFromJSONTyped = exports.ReferralRelationshipFromJSON = void 0;
var _1 = require("./");
function ReferralRelationshipFromJSON(json) {
    return ReferralRelationshipFromJSONTyped(json, false);
}
exports.ReferralRelationshipFromJSON = ReferralRelationshipFromJSON;
function ReferralRelationshipFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'code': json['code'],
        'referredUser': json['referredUser'],
        'relationshipType': (0, _1.RelationshipTypeFromJSON)(json['relationshipType']),
    };
}
exports.ReferralRelationshipFromJSONTyped = ReferralRelationshipFromJSONTyped;
function ReferralRelationshipToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'code': value.code,
        'referredUser': value.referredUser,
        'relationshipType': (0, _1.RelationshipTypeToJSON)(value.relationshipType),
    };
}
exports.ReferralRelationshipToJSON = ReferralRelationshipToJSON;
