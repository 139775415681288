"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.InTheWeedsRequestBodyToJSON = exports.InTheWeedsRequestBodyFromJSONTyped = exports.InTheWeedsRequestBodyFromJSON = void 0;
function InTheWeedsRequestBodyFromJSON(json) {
    return InTheWeedsRequestBodyFromJSONTyped(json, false);
}
exports.InTheWeedsRequestBodyFromJSON = InTheWeedsRequestBodyFromJSON;
function InTheWeedsRequestBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'stars_obtained': json['stars_obtained'],
        'level_status': json['level_status'],
        'level_played': json['level_played'],
    };
}
exports.InTheWeedsRequestBodyFromJSONTyped = InTheWeedsRequestBodyFromJSONTyped;
function InTheWeedsRequestBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'stars_obtained': value.stars_obtained,
        'level_status': value.level_status,
        'level_played': value.level_played,
    };
}
exports.InTheWeedsRequestBodyToJSON = InTheWeedsRequestBodyToJSON;
