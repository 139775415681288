"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetUsersCsvResponseAllOfToJSON = exports.GetUsersCsvResponseAllOfFromJSONTyped = exports.GetUsersCsvResponseAllOfFromJSON = void 0;
var _1 = require("./");
function GetUsersCsvResponseAllOfFromJSON(json) {
    return GetUsersCsvResponseAllOfFromJSONTyped(json, false);
}
exports.GetUsersCsvResponseAllOfFromJSON = GetUsersCsvResponseAllOfFromJSON;
function GetUsersCsvResponseAllOfFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'users': (json['users'].map(_1.UserLvlFromJSON)),
    };
}
exports.GetUsersCsvResponseAllOfFromJSONTyped = GetUsersCsvResponseAllOfFromJSONTyped;
function GetUsersCsvResponseAllOfToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'users': (value.users.map(_1.UserLvlToJSON)),
    };
}
exports.GetUsersCsvResponseAllOfToJSON = GetUsersCsvResponseAllOfToJSON;
