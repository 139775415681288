"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateOWLogRequestBodyToJSON = exports.CreateOWLogRequestBodyFromJSONTyped = exports.CreateOWLogRequestBodyFromJSON = void 0;
var _1 = require("./");
function CreateOWLogRequestBodyFromJSON(json) {
    return CreateOWLogRequestBodyFromJSONTyped(json, false);
}
exports.CreateOWLogRequestBodyFromJSON = CreateOWLogRequestBodyFromJSON;
function CreateOWLogRequestBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'events': (json['events'].map(_1.OWEventFromJSON)),
        'category': json['category'],
    };
}
exports.CreateOWLogRequestBodyFromJSONTyped = CreateOWLogRequestBodyFromJSONTyped;
function CreateOWLogRequestBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'events': (value.events.map(_1.OWEventToJSON)),
        'category': value.category,
    };
}
exports.CreateOWLogRequestBodyToJSON = CreateOWLogRequestBodyToJSON;
