"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderRefundReasonToJSON = exports.OrderRefundReasonFromJSONTyped = exports.OrderRefundReasonFromJSON = exports.OrderRefundReason = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var OrderRefundReason;
(function (OrderRefundReason) {
    OrderRefundReason["AUTOREFUND"] = "AUTO_REFUND";
    OrderRefundReason["WRONGORDERRECEIVED"] = "WRONG_ORDER_RECEIVED";
    OrderRefundReason["ORDERNEVERARRIVED"] = "ORDER_NEVER_ARRIVED";
    OrderRefundReason["ITEMQUALITYISSUES"] = "ITEM_QUALITY_ISSUES";
    OrderRefundReason["MISSINGORINCORRECTITEMS"] = "MISSING_OR_INCORRECT_ITEMS";
    OrderRefundReason["ORDERARRIVEDLATE"] = "ORDER_ARRIVED_LATE";
    OrderRefundReason["THIRDPARTYISSUED"] = "THIRD_PARTY_ISSUED";
    OrderRefundReason["OTHER"] = "OTHER";
})(OrderRefundReason = exports.OrderRefundReason || (exports.OrderRefundReason = {}));
function OrderRefundReasonFromJSON(json) {
    return OrderRefundReasonFromJSONTyped(json, false);
}
exports.OrderRefundReasonFromJSON = OrderRefundReasonFromJSON;
function OrderRefundReasonFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.OrderRefundReasonFromJSONTyped = OrderRefundReasonFromJSONTyped;
function OrderRefundReasonToJSON(value) {
    return value;
}
exports.OrderRefundReasonToJSON = OrderRefundReasonToJSON;
