"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchRestaurantsPromosResponseToJSON = exports.SearchRestaurantsPromosResponseFromJSONTyped = exports.SearchRestaurantsPromosResponseFromJSON = void 0;
var _1 = require("./");
function SearchRestaurantsPromosResponseFromJSON(json) {
    return SearchRestaurantsPromosResponseFromJSONTyped(json, false);
}
exports.SearchRestaurantsPromosResponseFromJSON = SearchRestaurantsPromosResponseFromJSON;
function SearchRestaurantsPromosResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'restaurantsGoFrens': (json['restaurantsGoFrens'].map(_1.RestaurantFromJSON)),
    };
}
exports.SearchRestaurantsPromosResponseFromJSONTyped = SearchRestaurantsPromosResponseFromJSONTyped;
function SearchRestaurantsPromosResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'restaurantsGoFrens': (value.restaurantsGoFrens.map(_1.RestaurantToJSON)),
    };
}
exports.SearchRestaurantsPromosResponseToJSON = SearchRestaurantsPromosResponseToJSON;
