"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GameDataToJSON = exports.GameDataFromJSONTyped = exports.GameDataFromJSON = void 0;
var runtime_1 = require("../runtime");
function GameDataFromJSON(json) {
    return GameDataFromJSONTyped(json, false);
}
exports.GameDataFromJSON = GameDataFromJSON;
function GameDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'userId': !(0, runtime_1.exists)(json, 'userId') ? undefined : json['userId'],
        'gameId': !(0, runtime_1.exists)(json, 'gameId') ? undefined : json['gameId'],
        'gameData': !(0, runtime_1.exists)(json, 'gameData') ? undefined : json['gameData'],
    };
}
exports.GameDataFromJSONTyped = GameDataFromJSONTyped;
function GameDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'userId': value.userId,
        'gameId': value.gameId,
        'gameData': value.gameData,
    };
}
exports.GameDataToJSON = GameDataToJSON;
