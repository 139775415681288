import React, {ChangeEvent, PropsWithChildren, ReactElement, ReactNode, useEffect, useState} from "react";

interface Props<D = string> {
	placeholder?: string;
	resetOnSelect?: boolean;
	emptyText?: string;
	minLength?: number;
	onSelect: (d: D) => void;
	onInputChange: (q: string) => void;
	results: Array<{
		render: ReactNode;
		data: D;
	}>;
}

function FrameOneAutocompleteInput<D>(props: PropsWithChildren<Props<D>>): ReactElement {

	const [inputValue, setInputValue] = useState<string>("");

	useEffect(() => {
		if (inputValue.length >= props.minLength) {
			props.onInputChange(inputValue);
		}
	}, [inputValue]);

	function onClickHelper(data: D): void {
		props.onSelect(data);

		if (props.resetOnSelect) {
			setInputValue("");
		}
	}

	const renderResults: JSX.Element = (
		<ul className="frame-one-autocomplete-input_results_list">
			{props.results.map((result, index) => (
				<li
					key={`autocomplete-result-${index}`}
					className="frame-one-autocomplete-input_results_list_item"
				>
					<button
						type="button"
						onClick={() => onClickHelper(result.data)}
					>
						{result.render}
					</button>
				</li>
			))}
		</ul>
	);

	/**
	 * No results from autocomplete api, but check if the input component actually has text before showing the empty response.
	 */
	const renderNoResults: JSX.Element = (props.emptyText) ? (
		<div className="frame-one-autocomplete-input_results_empty">
			{props.emptyText}
		</div>
	) : <React.Fragment/>;

	function onChangeHelper(e: ChangeEvent<HTMLInputElement>) {
		setInputValue(e.target.value);
	}

	return (
		<div className="frame-one-autocomplete-input">
			<div className="frame-one-autocomplete-input_value">
				<input
					type="text"
					placeholder={props.placeholder}
					value={inputValue}
					onChange={onChangeHelper}
				/>
			</div>
			{(inputValue.length >= props.minLength) && (
				<div className="frame-one-autocomplete-input_results">
					{(props.results.length > 0) ? renderResults : renderNoResults}
				</div>
			)}
		</div>
	);

}

FrameOneAutocompleteInput.defaultProps = {
	minLength: 1,
	emptyText: "No results found",
}

export default FrameOneAutocompleteInput;
