"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReferralLinkTextBodyToJSON = exports.ReferralLinkTextBodyFromJSONTyped = exports.ReferralLinkTextBodyFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function ReferralLinkTextBodyFromJSON(json) {
    return ReferralLinkTextBodyFromJSONTyped(json, false);
}
exports.ReferralLinkTextBodyFromJSON = ReferralLinkTextBodyFromJSON;
function ReferralLinkTextBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'header': !(0, runtime_1.exists)(json, 'header') ? undefined : json['header'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'relationshipType': (0, _1.RelationshipTypeFromJSON)(json['relationshipType']),
    };
}
exports.ReferralLinkTextBodyFromJSONTyped = ReferralLinkTextBodyFromJSONTyped;
function ReferralLinkTextBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'header': value.header,
        'description': value.description,
        'relationshipType': (0, _1.RelationshipTypeToJSON)(value.relationshipType),
    };
}
exports.ReferralLinkTextBodyToJSON = ReferralLinkTextBodyToJSON;
