"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimeZoneCacheToJSON = exports.TimeZoneCacheFromJSONTyped = exports.TimeZoneCacheFromJSON = void 0;
var runtime_1 = require("../runtime");
function TimeZoneCacheFromJSON(json) {
    return TimeZoneCacheFromJSONTyped(json, false);
}
exports.TimeZoneCacheFromJSON = TimeZoneCacheFromJSON;
function TimeZoneCacheFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'expiresAt': json['expiresAt'],
        'longitude': json['longitude'],
        'latitude': json['latitude'],
        'timeZoneResponse': !(0, runtime_1.exists)(json, 'timeZoneResponse') ? undefined : json['timeZoneResponse'],
    };
}
exports.TimeZoneCacheFromJSONTyped = TimeZoneCacheFromJSONTyped;
function TimeZoneCacheToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'expiresAt': value.expiresAt,
        'longitude': value.longitude,
        'latitude': value.latitude,
        'timeZoneResponse': value.timeZoneResponse,
    };
}
exports.TimeZoneCacheToJSON = TimeZoneCacheToJSON;
