"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BusinessDaysToJSON = exports.BusinessDaysFromJSONTyped = exports.BusinessDaysFromJSON = exports.BusinessDays = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var BusinessDays;
(function (BusinessDays) {
    BusinessDays["SUNDAY"] = "SUNDAY";
    BusinessDays["MONDAY"] = "MONDAY";
    BusinessDays["TUESDAY"] = "TUESDAY";
    BusinessDays["WEDNESDAY"] = "WEDNESDAY";
    BusinessDays["THURSDAY"] = "THURSDAY";
    BusinessDays["FRIDAY"] = "FRIDAY";
    BusinessDays["SATURDAY"] = "SATURDAY";
})(BusinessDays = exports.BusinessDays || (exports.BusinessDays = {}));
function BusinessDaysFromJSON(json) {
    return BusinessDaysFromJSONTyped(json, false);
}
exports.BusinessDaysFromJSON = BusinessDaysFromJSON;
function BusinessDaysFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.BusinessDaysFromJSONTyped = BusinessDaysFromJSONTyped;
function BusinessDaysToJSON(value) {
    return value;
}
exports.BusinessDaysToJSON = BusinessDaysToJSON;
