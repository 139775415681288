"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.InlineResponse20013ToJSON = exports.InlineResponse20013FromJSONTyped = exports.InlineResponse20013FromJSON = void 0;
var _1 = require("./");
function InlineResponse20013FromJSON(json) {
    return InlineResponse20013FromJSONTyped(json, false);
}
exports.InlineResponse20013FromJSON = InlineResponse20013FromJSON;
function InlineResponse20013FromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'chatMessages': (json['chatMessages'].map(_1.ChatMessageFromJSON)),
    };
}
exports.InlineResponse20013FromJSONTyped = InlineResponse20013FromJSONTyped;
function InlineResponse20013ToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'chatMessages': (value.chatMessages.map(_1.ChatMessageToJSON)),
    };
}
exports.InlineResponse20013ToJSON = InlineResponse20013ToJSON;
