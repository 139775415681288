"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.NftCommunityMonthlyPointsToJSON = exports.NftCommunityMonthlyPointsFromJSONTyped = exports.NftCommunityMonthlyPointsFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function NftCommunityMonthlyPointsFromJSON(json) {
    return NftCommunityMonthlyPointsFromJSONTyped(json, false);
}
exports.NftCommunityMonthlyPointsFromJSON = NftCommunityMonthlyPointsFromJSON;
function NftCommunityMonthlyPointsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'nftCommunity': (0, _1.NftCommunityFromJSON)(json['nftCommunity']),
        'month': json['month'],
        'year': json['year'],
        'monthlyTotalPoints': json['monthlyTotalPoints'],
        'grandTotalPoints': json['grandTotalPoints'],
        'topContributors': !(0, runtime_1.exists)(json, 'topContributors') ? undefined : (json['topContributors'].map(_1.UserMonthlyPointsFromJSON)),
    };
}
exports.NftCommunityMonthlyPointsFromJSONTyped = NftCommunityMonthlyPointsFromJSONTyped;
function NftCommunityMonthlyPointsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'nftCommunity': (0, _1.NftCommunityToJSON)(value.nftCommunity),
        'month': value.month,
        'year': value.year,
        'monthlyTotalPoints': value.monthlyTotalPoints,
        'grandTotalPoints': value.grandTotalPoints,
        'topContributors': value.topContributors === undefined ? undefined : (value.topContributors.map(_1.UserMonthlyPointsToJSON)),
    };
}
exports.NftCommunityMonthlyPointsToJSON = NftCommunityMonthlyPointsToJSON;
