"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateAddressBookBodyToJSON = exports.CreateAddressBookBodyFromJSONTyped = exports.CreateAddressBookBodyFromJSON = void 0;
function CreateAddressBookBodyFromJSON(json) {
    return CreateAddressBookBodyFromJSONTyped(json, false);
}
exports.CreateAddressBookBodyFromJSON = CreateAddressBookBodyFromJSON;
function CreateAddressBookBodyFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.CreateAddressBookBodyFromJSONTyped = CreateAddressBookBodyFromJSONTyped;
function CreateAddressBookBodyToJSON(value) {
    return value;
}
exports.CreateAddressBookBodyToJSON = CreateAddressBookBodyToJSON;
