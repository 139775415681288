"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GleamEntryToJSON = exports.GleamEntryFromJSONTyped = exports.GleamEntryFromJSON = void 0;
var _1 = require("./");
function GleamEntryFromJSON(json) {
    return GleamEntryFromJSONTyped(json, false);
}
exports.GleamEntryFromJSON = GleamEntryFromJSON;
function GleamEntryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'entry_method_id': json['entry_method_id'],
        'action': json['action'],
        'created_at': json['created_at'],
        'type': json['type'],
        'value': json['value'],
        'worth': json['worth'],
        'landing_url': json['landing_url'],
        'referring_url': json['referring_url'],
        'upload': (0, _1.GleamEntryUploadFromJSON)(json['upload']),
    };
}
exports.GleamEntryFromJSONTyped = GleamEntryFromJSONTyped;
function GleamEntryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'entry_method_id': value.entry_method_id,
        'action': value.action,
        'created_at': value.created_at,
        'type': value.type,
        'value': value.value,
        'worth': value.worth,
        'landing_url': value.landing_url,
        'referring_url': value.referring_url,
        'upload': (0, _1.GleamEntryUploadToJSON)(value.upload),
    };
}
exports.GleamEntryToJSON = GleamEntryToJSON;
