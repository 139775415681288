import React, {ReactElement} from "react";
import {BrandToMerchantPermissions} from "@devour/client";
import FrameButton from "../buttons/FrameButton";

interface Props {
	brandPermission: BrandToMerchantPermissions;
}

function MerchantBrandPermissionCard(props: Props): ReactElement {

	return (
		<div className="brand-permission-card">
			<div className="brand-permission-card_img">
				<img
					src={props.brandPermission.brand.image?.url}
					alt={props.brandPermission.brand.name}
				/>
			</div>

			<h3 className="brand-permission-card_name">
				{props.brandPermission.brand.name}
			</h3>

			<div className="brand-permission-card_btns">
				<FrameButton
					<any>
					color="purple"
					size="narrow"
					disabled={!props.brandPermission.createMaps}
					to={`/brands/${props.brandPermission.brand.id}/maps`}
				>
					Manage Maps
				</FrameButton>
			</div>
		</div>
	);
}

export default MerchantBrandPermissionCard;