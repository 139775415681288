"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.NftRewardBurnRecordToJSON = exports.NftRewardBurnRecordFromJSONTyped = exports.NftRewardBurnRecordFromJSON = void 0;
function NftRewardBurnRecordFromJSON(json) {
    return NftRewardBurnRecordFromJSONTyped(json, false);
}
exports.NftRewardBurnRecordFromJSON = NftRewardBurnRecordFromJSON;
function NftRewardBurnRecordFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'user': json['user'],
        'transactionHash': json['transactionHash'],
        'nftTracker': json['nftTracker'],
        'nftRewardPrize': json['nftRewardPrize'],
    };
}
exports.NftRewardBurnRecordFromJSONTyped = NftRewardBurnRecordFromJSONTyped;
function NftRewardBurnRecordToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'user': value.user,
        'transactionHash': value.transactionHash,
        'nftTracker': value.nftTracker,
        'nftRewardPrize': value.nftRewardPrize,
    };
}
exports.NftRewardBurnRecordToJSON = NftRewardBurnRecordToJSON;
