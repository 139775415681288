"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetOverwolfXPResponseToJSON = exports.GetOverwolfXPResponseFromJSONTyped = exports.GetOverwolfXPResponseFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function GetOverwolfXPResponseFromJSON(json) {
    return GetOverwolfXPResponseFromJSONTyped(json, false);
}
exports.GetOverwolfXPResponseFromJSON = GetOverwolfXPResponseFromJSON;
function GetOverwolfXPResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'transactions': (json['transactions'].map(_1.OverwolfXPTransactionFromJSON)),
        'isWeeklyLimitFeedbackDismissed': !(0, runtime_1.exists)(json, 'isWeeklyLimitFeedbackDismissed') ? undefined : json['isWeeklyLimitFeedbackDismissed'],
    };
}
exports.GetOverwolfXPResponseFromJSONTyped = GetOverwolfXPResponseFromJSONTyped;
function GetOverwolfXPResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'transactions': (value.transactions.map(_1.OverwolfXPTransactionToJSON)),
        'isWeeklyLimitFeedbackDismissed': value.isWeeklyLimitFeedbackDismissed,
    };
}
exports.GetOverwolfXPResponseToJSON = GetOverwolfXPResponseToJSON;
