import React from "react";
import {createRoot} from "react-dom/client";
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import {persistor, store} from "./redux"
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import "./style/index.scss";
import GA4React from "ga-4-react";

import {WagmiConfig} from "wagmi";
import {createWeb3Modal} from "@web3modal/wagmi/react";
import {defaultWagmiConfig} from "@web3modal/wagmi/react/config";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {arbitrum, base, mainnet, polygon} from "viem/chains";

const ga4react = new GA4React(
	process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_CODE,
	{
		debug_mode: process.env.NODE_ENV === "development",
	},
	[ /* additional code, optional */ ],
	5000 /* timeout, optional, defaults is 5000 */,
	{} /* { nonce: ['first-script-is-async','second-script'] } */
);

const projectId = "ff49f3c5cdbf08b1995f2687b0858cad";
const chains = [arbitrum, mainnet, base, polygon]

const queryClient = new QueryClient()

const metadata = {
	name: "DevourGo",
	description: "DevourGo",
	url: "https://devourgo.io",
	icons: ["https://avatars.githubusercontent.com/u/37784886"]
}

// @ts-ignore chains type error builds fine in docker, and does not affect functionality, however it prevents project from building in a mirror env
const wagmiConfig = defaultWagmiConfig({chains, projectId, metadata})

// @ts-ignore chains type error builds fine in docker, and does not affect functionality, however it prevents project from building in a mirror env
createWeb3Modal({wagmiConfig, projectId, chains});

(async () => {
	try {
		await ga4react.initialize();
	} catch (err) {
		console.error("GA init error", err);
	}
	createRoot(container!).render(
		<React.Fragment>
			<Provider store={store}>
				<PersistGate
					persistor={persistor}
					loading={null}
				>
					<WagmiConfig config={wagmiConfig}>
						<QueryClientProvider client={queryClient}>
							<App/>
						</QueryClientProvider>
					</WagmiConfig>
				</PersistGate>
			</Provider>

		</React.Fragment>
	);
})();
const container = document.getElementById("root");


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

