"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GameEventRewardResponseToJSON = exports.GameEventRewardResponseFromJSONTyped = exports.GameEventRewardResponseFromJSON = void 0;
var runtime_1 = require("../runtime");
function GameEventRewardResponseFromJSON(json) {
    return GameEventRewardResponseFromJSONTyped(json, false);
}
exports.GameEventRewardResponseFromJSON = GameEventRewardResponseFromJSON;
function GameEventRewardResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'result': json['result'],
        'rotationAmount': json['rotationAmount'],
        'pointsEarned': !(0, runtime_1.exists)(json, 'pointsEarned') ? undefined : json['pointsEarned'],
    };
}
exports.GameEventRewardResponseFromJSONTyped = GameEventRewardResponseFromJSONTyped;
function GameEventRewardResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'result': value.result,
        'rotationAmount': value.rotationAmount,
        'pointsEarned': value.pointsEarned,
    };
}
exports.GameEventRewardResponseToJSON = GameEventRewardResponseToJSON;
