"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetGlobalOrderDiscountsResponseToJSON = exports.GetGlobalOrderDiscountsResponseFromJSONTyped = exports.GetGlobalOrderDiscountsResponseFromJSON = void 0;
var _1 = require("./");
function GetGlobalOrderDiscountsResponseFromJSON(json) {
    return GetGlobalOrderDiscountsResponseFromJSONTyped(json, false);
}
exports.GetGlobalOrderDiscountsResponseFromJSON = GetGlobalOrderDiscountsResponseFromJSON;
function GetGlobalOrderDiscountsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'orderDiscounts': (json['orderDiscounts'].map(_1.OrderDiscountFromJSON)),
    };
}
exports.GetGlobalOrderDiscountsResponseFromJSONTyped = GetGlobalOrderDiscountsResponseFromJSONTyped;
function GetGlobalOrderDiscountsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'orderDiscounts': (value.orderDiscounts.map(_1.OrderDiscountToJSON)),
    };
}
exports.GetGlobalOrderDiscountsResponseToJSON = GetGlobalOrderDiscountsResponseToJSON;
