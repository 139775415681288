"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImportBusinessResponseItemToJSON = exports.ImportBusinessResponseItemFromJSONTyped = exports.ImportBusinessResponseItemFromJSON = void 0;
var runtime_1 = require("../runtime");
function ImportBusinessResponseItemFromJSON(json) {
    return ImportBusinessResponseItemFromJSONTyped(json, false);
}
exports.ImportBusinessResponseItemFromJSON = ImportBusinessResponseItemFromJSON;
function ImportBusinessResponseItemFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'isSuccess': !(0, runtime_1.exists)(json, 'isSuccess') ? undefined : json['isSuccess'],
        'isNew': !(0, runtime_1.exists)(json, 'isNew') ? undefined : json['isNew'],
        'errorMessage': !(0, runtime_1.exists)(json, 'errorMessage') ? undefined : json['errorMessage'],
        'businessId': !(0, runtime_1.exists)(json, 'businessId') ? undefined : json['businessId'],
        'chowlyApi': !(0, runtime_1.exists)(json, 'chowlyApi') ? undefined : json['chowlyApi'],
        'oloVendorId': !(0, runtime_1.exists)(json, 'oloVendorId') ? undefined : json['oloVendorId'],
        'createdAt': !(0, runtime_1.exists)(json, 'createdAt') ? undefined : json['createdAt'],
    };
}
exports.ImportBusinessResponseItemFromJSONTyped = ImportBusinessResponseItemFromJSONTyped;
function ImportBusinessResponseItemToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'isSuccess': value.isSuccess,
        'isNew': value.isNew,
        'errorMessage': value.errorMessage,
        'businessId': value.businessId,
        'chowlyApi': value.chowlyApi,
        'oloVendorId': value.oloVendorId,
        'createdAt': value.createdAt,
    };
}
exports.ImportBusinessResponseItemToJSON = ImportBusinessResponseItemToJSON;
