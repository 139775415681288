import React, {ReactElement, useState} from "react";
import {Business, Merchant, BusinessesApi} from "@devour/client";
import {StripeAccountObject} from "../../types/Stripe";
import getConfig from "../../utils/getConfig";
import {loadConnectAndInitialize} from '@stripe/connect-js';
import {
	ConnectPayouts,
	ConnectComponentsProvider,
} from "@stripe/react-connect-js";

interface Props {
	business: Business;
	currentAccount: GetBusinessBillingResponse;
}

interface GetBusinessBillingResponse {
	stripeAccount?: StripeAccountObject;
	user?: Merchant;
	isAccountConnected: boolean;
	clientSecret?: string;
}


function BusinessInfoPayouts(props: Props): ReactElement {
	// We use `useState` to ensure the Connect instance is only initialized once
	const [stripeConnectInstance] = useState(() => {
		async function fetchClientSecret(): Promise<string> {
			return props.currentAccount?.clientSecret;
		}
		return loadConnectAndInitialize({
			// This is a placeholder - it should be replaced with your publishable API key.
			// Sign in to see your own test API key embedded in code samples.
			// Don’t submit any personally identifiable information in requests made with this key.
			publishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
			fetchClientSecret: fetchClientSecret,
		});
	});

	return (
		<>
			{(props.currentAccount?.isAccountConnected) ? (
				<div>
					<ConnectComponentsProvider connectInstance={stripeConnectInstance}>
						<ConnectPayouts/>
					</ConnectComponentsProvider>
				</div>
			) : (
				<p>
					No account connected. <br/>
					Connect a bank account to this restaurant to view the payout history.
				</p>
			)}
		</>
	);
}

export default BusinessInfoPayouts;
