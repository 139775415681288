import {BrandMapStatus} from "@devour/client";

export function parseMapStatus(status: BrandMapStatus): string {
	switch (status) {
		case BrandMapStatus.DRAFT:
			return "Draft";
		case BrandMapStatus.PUBLISHED:
			return "Published";
		case BrandMapStatus.DISABLED:
			return "Disabled";
		default:
			return status;
	}
}