import React, {ChangeEvent, Dispatch, ReactElement, useState} from "react";
import {AiOutlineSearch} from "react-icons/ai";
import {BusinessTaxonomy} from "@devour/client";
import {CreateBusinessBodyFrontend, UpdatedBusinessBodyFrontend} from "./BusinessFormValues";
import {cloneDeep} from "lodash";
import FrameOneCheckbox from "../../../components/inputs/FrameOneCheckbox";
import classNames from "classnames";
import {IoIosSave} from "react-icons/io";
import FrameButton from "../../../components/buttons/FrameButton";

interface Props {
    businessTaxonomies: Array<BusinessTaxonomy>;
    businessBody: CreateBusinessBodyFrontend | UpdatedBusinessBodyFrontend;
    businessBodyOnChange: (updatedValues: Partial<CreateBusinessBodyFrontend>) => void;
    contactPermission?: boolean;
    onSubmitBusiness?: () => Promise<void>;
}

function BusinessDetailsCategories(props: Props): ReactElement {
    const [searchValue, setSearchValue] = useState("");

    function taxonomyOnChange(taxonomyId: string): void {
        const newTaxonomies: Array<string> = cloneDeep(props.businessBody?.taxonomies);

        const thisIndex = newTaxonomies.indexOf(taxonomyId);

        if (thisIndex > -1) {
            // Remove this option from list of selected items
            newTaxonomies.splice(thisIndex, 1);
        } else {
            newTaxonomies.push(taxonomyId);
        }
        props.businessBodyOnChange({
            taxonomies: newTaxonomies,
        });
    }

    return (
        <div className="business-page_taxonomies">
            <div className="business-page_taxonomies_header">
                <div className="business-page_taxonomies_search">
                    <AiOutlineSearch/>
                    <input
                        type="search"
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value.toLowerCase())}
                        placeholder="Search for a category"
                    />
                </div>
                {props.onSubmitBusiness && (
                    <FrameButton
                        color="purple"
                        size="narrow"
                        leftIcon={IoIosSave}
                        disabled={!props.contactPermission}
                        className="business-page_top-bar_nav_save"
                        onClick={props.onSubmitBusiness}
                    >
                        Save Changes
                    </FrameButton>
                )}
            </div>

            <div className="business-page_taxonomies_container">
                {props.businessTaxonomies?.filter(taxonomy => taxonomy.name.toLowerCase().includes(searchValue))
                    .map(taxonomy => {
                    const isSelected = props.businessBody?.taxonomies.includes(taxonomy.id);
                    return (
                        <div
                            className={classNames("business-page_taxonomies_container_option",
                                {"is-selected": isSelected}
                            )}
                            key={taxonomy.id}>
                            <FrameOneCheckbox
                                onToggle={() => taxonomyOnChange(taxonomy.id)}
                                checked={isSelected}
                                disabled={!props.contactPermission}
                            >
                                {taxonomy.name}
                            </FrameOneCheckbox>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

BusinessDetailsCategories.defaultProps = {
    contactPermission: true,
} as Props;

export default BusinessDetailsCategories;