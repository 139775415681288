"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateMenuOrderResponseAllOfToJSON = exports.CreateMenuOrderResponseAllOfFromJSONTyped = exports.CreateMenuOrderResponseAllOfFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function CreateMenuOrderResponseAllOfFromJSON(json) {
    return CreateMenuOrderResponseAllOfFromJSONTyped(json, false);
}
exports.CreateMenuOrderResponseAllOfFromJSON = CreateMenuOrderResponseAllOfFromJSON;
function CreateMenuOrderResponseAllOfFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'validationErrors': !(0, runtime_1.exists)(json, 'validationErrors') ? undefined : (json['validationErrors'].map(_1.ValidateMenuOrderItemsResultErrorFromJSON)),
    };
}
exports.CreateMenuOrderResponseAllOfFromJSONTyped = CreateMenuOrderResponseAllOfFromJSONTyped;
function CreateMenuOrderResponseAllOfToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'validationErrors': value.validationErrors === undefined ? undefined : (value.validationErrors.map(_1.ValidateMenuOrderItemsResultErrorToJSON)),
    };
}
exports.CreateMenuOrderResponseAllOfToJSON = CreateMenuOrderResponseAllOfToJSON;
