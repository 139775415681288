"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetOnboardingThirdPartiesSortToJSON = exports.GetOnboardingThirdPartiesSortFromJSONTyped = exports.GetOnboardingThirdPartiesSortFromJSON = exports.GetOnboardingThirdPartiesSort = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var GetOnboardingThirdPartiesSort;
(function (GetOnboardingThirdPartiesSort) {
    GetOnboardingThirdPartiesSort["CreatedAt"] = "createdAt";
})(GetOnboardingThirdPartiesSort = exports.GetOnboardingThirdPartiesSort || (exports.GetOnboardingThirdPartiesSort = {}));
function GetOnboardingThirdPartiesSortFromJSON(json) {
    return GetOnboardingThirdPartiesSortFromJSONTyped(json, false);
}
exports.GetOnboardingThirdPartiesSortFromJSON = GetOnboardingThirdPartiesSortFromJSON;
function GetOnboardingThirdPartiesSortFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.GetOnboardingThirdPartiesSortFromJSONTyped = GetOnboardingThirdPartiesSortFromJSONTyped;
function GetOnboardingThirdPartiesSortToJSON(value) {
    return value;
}
exports.GetOnboardingThirdPartiesSortToJSON = GetOnboardingThirdPartiesSortToJSON;
