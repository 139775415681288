"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetBusinessOwnArchivedStatusToJSON = exports.GetBusinessOwnArchivedStatusFromJSONTyped = exports.GetBusinessOwnArchivedStatusFromJSON = exports.GetBusinessOwnArchivedStatus = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var GetBusinessOwnArchivedStatus;
(function (GetBusinessOwnArchivedStatus) {
    GetBusinessOwnArchivedStatus["UNARCHIVED"] = "UNARCHIVED";
    GetBusinessOwnArchivedStatus["ARCHIVED"] = "ARCHIVED";
})(GetBusinessOwnArchivedStatus = exports.GetBusinessOwnArchivedStatus || (exports.GetBusinessOwnArchivedStatus = {}));
function GetBusinessOwnArchivedStatusFromJSON(json) {
    return GetBusinessOwnArchivedStatusFromJSONTyped(json, false);
}
exports.GetBusinessOwnArchivedStatusFromJSON = GetBusinessOwnArchivedStatusFromJSON;
function GetBusinessOwnArchivedStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.GetBusinessOwnArchivedStatusFromJSONTyped = GetBusinessOwnArchivedStatusFromJSONTyped;
function GetBusinessOwnArchivedStatusToJSON(value) {
    return value;
}
exports.GetBusinessOwnArchivedStatusToJSON = GetBusinessOwnArchivedStatusToJSON;
