"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DevourIQUpdateUberEatsUrlToJSON = exports.DevourIQUpdateUberEatsUrlFromJSONTyped = exports.DevourIQUpdateUberEatsUrlFromJSON = void 0;
function DevourIQUpdateUberEatsUrlFromJSON(json) {
    return DevourIQUpdateUberEatsUrlFromJSONTyped(json, false);
}
exports.DevourIQUpdateUberEatsUrlFromJSON = DevourIQUpdateUberEatsUrlFromJSON;
function DevourIQUpdateUberEatsUrlFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'uberEatsUrl': json['uberEatsUrl'],
        'reimportMenu': json['reimportMenu'],
        'verifyMatch': json['verifyMatch'],
    };
}
exports.DevourIQUpdateUberEatsUrlFromJSONTyped = DevourIQUpdateUberEatsUrlFromJSONTyped;
function DevourIQUpdateUberEatsUrlToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'uberEatsUrl': value.uberEatsUrl,
        'reimportMenu': value.reimportMenu,
        'verifyMatch': value.verifyMatch,
    };
}
exports.DevourIQUpdateUberEatsUrlToJSON = DevourIQUpdateUberEatsUrlToJSON;
