"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetBusinessOwnMenuStatusToJSON = exports.GetBusinessOwnMenuStatusFromJSONTyped = exports.GetBusinessOwnMenuStatusFromJSON = exports.GetBusinessOwnMenuStatus = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var GetBusinessOwnMenuStatus;
(function (GetBusinessOwnMenuStatus) {
    GetBusinessOwnMenuStatus["ACTIVE"] = "ACTIVE";
    GetBusinessOwnMenuStatus["INACTIVE"] = "INACTIVE";
})(GetBusinessOwnMenuStatus = exports.GetBusinessOwnMenuStatus || (exports.GetBusinessOwnMenuStatus = {}));
function GetBusinessOwnMenuStatusFromJSON(json) {
    return GetBusinessOwnMenuStatusFromJSONTyped(json, false);
}
exports.GetBusinessOwnMenuStatusFromJSON = GetBusinessOwnMenuStatusFromJSON;
function GetBusinessOwnMenuStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.GetBusinessOwnMenuStatusFromJSONTyped = GetBusinessOwnMenuStatusFromJSONTyped;
function GetBusinessOwnMenuStatusToJSON(value) {
    return value;
}
exports.GetBusinessOwnMenuStatusToJSON = GetBusinessOwnMenuStatusToJSON;
