"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RankedMemberToJSON = exports.RankedMemberFromJSONTyped = exports.RankedMemberFromJSON = void 0;
var runtime_1 = require("../runtime");
function RankedMemberFromJSON(json) {
    return RankedMemberFromJSONTyped(json, false);
}
exports.RankedMemberFromJSON = RankedMemberFromJSON;
function RankedMemberFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'nickname': json['nickname'],
        'gravatar': json['gravatar'],
        'pointsEarnedForCommunity': json['pointsEarnedForCommunity'],
        'rankWithinCommunity': !(0, runtime_1.exists)(json, 'rankWithinCommunity') ? undefined : json['rankWithinCommunity'],
    };
}
exports.RankedMemberFromJSONTyped = RankedMemberFromJSONTyped;
function RankedMemberToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'nickname': value.nickname,
        'gravatar': value.gravatar,
        'pointsEarnedForCommunity': value.pointsEarnedForCommunity,
        'rankWithinCommunity': value.rankWithinCommunity,
    };
}
exports.RankedMemberToJSON = RankedMemberToJSON;
