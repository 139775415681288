import React, {ReactElement} from "react";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import FrameButton from "../buttons/FrameButton";
import FrameOneModal from "./modalComponents/FrameOneModal";

interface Props {
	isOpen: boolean;
	toggle: () => void;
}

function ContactModal(props: Props): ReactElement {

	return (
		<FrameOneModal
			isOpen={props.isOpen}
			toggle={props.toggle}
			contentClassName="contact-modal"
		>
			<FrameModalHeader
				title="Contact Support"
				toggle={props.toggle}
			/>

			<FrameModalBody className="contact-modal_body">
				<p>Need help with your DevourGO Merchant Account?</p>

				<div className="contact-modal_body_link-container">
					Email: <a href={`mailto:onboarding@devourgo.io?subject=${encodeURI("DevourGO Merchant Support")}`}>
					onboarding@devourgo.io
					</a>
				</div>
			</FrameModalBody>

			<FrameModalFooter>
				<FrameButton
					color="purple"
					size="normal"
					onClick={props.toggle}
				>
					Close
				</FrameButton>
			</FrameModalFooter>
		</FrameOneModal>
	);
}

export default ContactModal;
