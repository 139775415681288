"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetNftTrackersSortToJSON = exports.GetNftTrackersSortFromJSONTyped = exports.GetNftTrackersSortFromJSON = exports.GetNftTrackersSort = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var GetNftTrackersSort;
(function (GetNftTrackersSort) {
    GetNftTrackersSort["CreatedAt"] = "createdAt";
})(GetNftTrackersSort = exports.GetNftTrackersSort || (exports.GetNftTrackersSort = {}));
function GetNftTrackersSortFromJSON(json) {
    return GetNftTrackersSortFromJSONTyped(json, false);
}
exports.GetNftTrackersSortFromJSON = GetNftTrackersSortFromJSON;
function GetNftTrackersSortFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.GetNftTrackersSortFromJSONTyped = GetNftTrackersSortFromJSONTyped;
function GetNftTrackersSortToJSON(value) {
    return value;
}
exports.GetNftTrackersSortToJSON = GetNftTrackersSortToJSON;
