"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BusinessTypeToJSON = exports.BusinessTypeFromJSONTyped = exports.BusinessTypeFromJSON = exports.BusinessType = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var BusinessType;
(function (BusinessType) {
    BusinessType["CORPORATE"] = "CORPORATE";
    BusinessType["RESTAURANT"] = "RESTAURANT";
})(BusinessType = exports.BusinessType || (exports.BusinessType = {}));
function BusinessTypeFromJSON(json) {
    return BusinessTypeFromJSONTyped(json, false);
}
exports.BusinessTypeFromJSON = BusinessTypeFromJSON;
function BusinessTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.BusinessTypeFromJSONTyped = BusinessTypeFromJSONTyped;
function BusinessTypeToJSON(value) {
    return value;
}
exports.BusinessTypeToJSON = BusinessTypeToJSON;
