"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateBrandMapBodyToJSON = exports.UpdateBrandMapBodyFromJSONTyped = exports.UpdateBrandMapBodyFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function UpdateBrandMapBodyFromJSON(json) {
    return UpdateBrandMapBodyFromJSONTyped(json, false);
}
exports.UpdateBrandMapBodyFromJSON = UpdateBrandMapBodyFromJSON;
function UpdateBrandMapBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': json['name'],
        'slug': !(0, runtime_1.exists)(json, 'slug') ? undefined : json['slug'],
        'titleImage': !(0, runtime_1.exists)(json, 'titleImage') ? undefined : json['titleImage'],
        'bannerImage': !(0, runtime_1.exists)(json, 'bannerImage') ? undefined : (0, _1.BrandMapBannerImageBodyFromJSON)(json['bannerImage']),
        'defaultLocation': !(0, runtime_1.exists)(json, 'defaultLocation') ? undefined : (0, _1.AddressFromJSON)(json['defaultLocation']),
        'status': (0, _1.BrandMapStatusFromJSON)(json['status']),
        'businesses': !(0, runtime_1.exists)(json, 'businesses') ? undefined : json['businesses'],
        'mainPromo': !(0, runtime_1.exists)(json, 'mainPromo') ? undefined : json['mainPromo'],
        'promos': !(0, runtime_1.exists)(json, 'promos') ? undefined : json['promos'],
        'promoHero': !(0, runtime_1.exists)(json, 'promoHero') ? undefined : json['promoHero'],
        'promoBackground': !(0, runtime_1.exists)(json, 'promoBackground') ? undefined : json['promoBackground'],
        'promoCartHero': !(0, runtime_1.exists)(json, 'promoCartHero') ? undefined : json['promoCartHero'],
        'promoCartBackground': !(0, runtime_1.exists)(json, 'promoCartBackground') ? undefined : json['promoCartBackground'],
        'defaultBusiness': !(0, runtime_1.exists)(json, 'defaultBusiness') ? undefined : json['defaultBusiness'],
        'promoTitle': !(0, runtime_1.exists)(json, 'promoTitle') ? undefined : json['promoTitle'],
        'promoDescription': !(0, runtime_1.exists)(json, 'promoDescription') ? undefined : json['promoDescription'],
        'promoTagline': !(0, runtime_1.exists)(json, 'promoTagline') ? undefined : json['promoTagline'],
        'colorTheme': (0, _1.BrandMapColorThemeFromJSON)(json['colorTheme']),
        'colorBackground': !(0, runtime_1.exists)(json, 'colorBackground') ? undefined : json['colorBackground'],
        'autoMintContract': !(0, runtime_1.exists)(json, 'autoMintContract') ? undefined : json['autoMintContract'],
        'autoMintChain': !(0, runtime_1.exists)(json, 'autoMintChain') ? undefined : (0, _1.EVMChainFromJSON)(json['autoMintChain']),
        'advertisementTitle': !(0, runtime_1.exists)(json, 'advertisementTitle') ? undefined : json['advertisementTitle'],
        'advertisementBlocks': (json['advertisementBlocks'].map(_1.BrandMapAdvertisementBlocksBodyFromJSON)),
        'loginAccessPoint': !(0, runtime_1.exists)(json, 'loginAccessPoint') ? undefined : (0, _1.LoginAccessPointFromJSON)(json['loginAccessPoint']),
        'brandPageType': !(0, runtime_1.exists)(json, 'brandPageType') ? undefined : (0, _1.BrandPageTypeFromJSON)(json['brandPageType']),
        'autoMintToastMessage': !(0, runtime_1.exists)(json, 'autoMintToastMessage') ? undefined : json['autoMintToastMessage'],
        'autoMintToastBackground': !(0, runtime_1.exists)(json, 'autoMintToastBackground') ? undefined : json['autoMintToastBackground'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'autoMintToastTextColor': !(0, runtime_1.exists)(json, 'autoMintToastTextColor') ? undefined : (0, _1.BrandMapColorThemeFromJSON)(json['autoMintToastTextColor']),
        'preAutoMintToastMessage': !(0, runtime_1.exists)(json, 'preAutoMintToastMessage') ? undefined : json['preAutoMintToastMessage'],
        'preAutoMintToastBackground': !(0, runtime_1.exists)(json, 'preAutoMintToastBackground') ? undefined : json['preAutoMintToastBackground'],
        'allowMarketplaceAccess': !(0, runtime_1.exists)(json, 'allowMarketplaceAccess') ? undefined : json['allowMarketplaceAccess'],
        'promoHeader': !(0, runtime_1.exists)(json, 'promoHeader') ? undefined : (0, _1.BrandMapPromoHeaderBodyFromJSON)(json['promoHeader']),
        'htmlSectionFirstRow': !(0, runtime_1.exists)(json, 'htmlSectionFirstRow') ? undefined : (json['htmlSectionFirstRow'].map(_1.BrandMapHtmlSectionFromJSON)),
        'htmlSectionSecondRow': !(0, runtime_1.exists)(json, 'htmlSectionSecondRow') ? undefined : (json['htmlSectionSecondRow'].map(_1.BrandMapHtmlSectionFromJSON)),
    };
}
exports.UpdateBrandMapBodyFromJSONTyped = UpdateBrandMapBodyFromJSONTyped;
function UpdateBrandMapBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'slug': value.slug,
        'titleImage': value.titleImage,
        'bannerImage': (0, _1.BrandMapBannerImageBodyToJSON)(value.bannerImage),
        'defaultLocation': (0, _1.AddressToJSON)(value.defaultLocation),
        'status': (0, _1.BrandMapStatusToJSON)(value.status),
        'businesses': value.businesses,
        'mainPromo': value.mainPromo,
        'promos': value.promos,
        'promoHero': value.promoHero,
        'promoBackground': value.promoBackground,
        'promoCartHero': value.promoCartHero,
        'promoCartBackground': value.promoCartBackground,
        'defaultBusiness': value.defaultBusiness,
        'promoTitle': value.promoTitle,
        'promoDescription': value.promoDescription,
        'promoTagline': value.promoTagline,
        'colorTheme': (0, _1.BrandMapColorThemeToJSON)(value.colorTheme),
        'colorBackground': value.colorBackground,
        'autoMintContract': value.autoMintContract,
        'autoMintChain': (0, _1.EVMChainToJSON)(value.autoMintChain),
        'advertisementTitle': value.advertisementTitle,
        'advertisementBlocks': (value.advertisementBlocks.map(_1.BrandMapAdvertisementBlocksBodyToJSON)),
        'loginAccessPoint': (0, _1.LoginAccessPointToJSON)(value.loginAccessPoint),
        'brandPageType': (0, _1.BrandPageTypeToJSON)(value.brandPageType),
        'autoMintToastMessage': value.autoMintToastMessage,
        'autoMintToastBackground': value.autoMintToastBackground,
        'description': value.description,
        'autoMintToastTextColor': (0, _1.BrandMapColorThemeToJSON)(value.autoMintToastTextColor),
        'preAutoMintToastMessage': value.preAutoMintToastMessage,
        'preAutoMintToastBackground': value.preAutoMintToastBackground,
        'allowMarketplaceAccess': value.allowMarketplaceAccess,
        'promoHeader': (0, _1.BrandMapPromoHeaderBodyToJSON)(value.promoHeader),
        'htmlSectionFirstRow': value.htmlSectionFirstRow === undefined ? undefined : (value.htmlSectionFirstRow.map(_1.BrandMapHtmlSectionToJSON)),
        'htmlSectionSecondRow': value.htmlSectionSecondRow === undefined ? undefined : (value.htmlSectionSecondRow.map(_1.BrandMapHtmlSectionToJSON)),
    };
}
exports.UpdateBrandMapBodyToJSON = UpdateBrandMapBodyToJSON;
