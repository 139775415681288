"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MenuCategorySearchEssentialToJSON = exports.MenuCategorySearchEssentialFromJSONTyped = exports.MenuCategorySearchEssentialFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function MenuCategorySearchEssentialFromJSON(json) {
    return MenuCategorySearchEssentialFromJSONTyped(json, false);
}
exports.MenuCategorySearchEssentialFromJSON = MenuCategorySearchEssentialFromJSON;
function MenuCategorySearchEssentialFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !(0, runtime_1.exists)(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'menuSubcategories': !(0, runtime_1.exists)(json, 'menuSubcategories') ? undefined : (json['menuSubcategories'].map(MenuCategorySearchEssentialFromJSON)),
        'menuItems': !(0, runtime_1.exists)(json, 'menuItems') ? undefined : (json['menuItems'].map(_1.MenuItemSearchEssentialFromJSON)),
        'serviceAvailabilities': !(0, runtime_1.exists)(json, 'serviceAvailabilities') ? undefined : (json['serviceAvailabilities'].map(_1.BusinessServiceAvailabilityFromJSON)),
    };
}
exports.MenuCategorySearchEssentialFromJSONTyped = MenuCategorySearchEssentialFromJSONTyped;
function MenuCategorySearchEssentialToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'name': value.name,
        'menuSubcategories': value.menuSubcategories === undefined ? undefined : (value.menuSubcategories.map(MenuCategorySearchEssentialToJSON)),
        'menuItems': value.menuItems === undefined ? undefined : (value.menuItems.map(_1.MenuItemSearchEssentialToJSON)),
        'serviceAvailabilities': value.serviceAvailabilities === undefined ? undefined : (value.serviceAvailabilities.map(_1.BusinessServiceAvailabilityToJSON)),
    };
}
exports.MenuCategorySearchEssentialToJSON = MenuCategorySearchEssentialToJSON;
