import React, {ReactElement, useEffect, useState} from "react";
import {
	SortOrder,
	BusinessesApi,
	GetBusinessesOwnResponse,
	GetBusinessOwnSort
} from "@devour/client";
import getConfig from "../../utils/getConfig";
import AuthHeaderLogo from "../../components/auth/AuthHeaderLogo";
import BusinessCard from "../../components/businesses/BusinessCard";
import classNames from "classnames";
import {IoIosArrowDown, IoIosArrowUp} from "react-icons/io";
import Skeleton from "react-loading-skeleton";


function BusinessesManagePage(): ReactElement {
	const [searchValue, setSearchValue] = useState<string>("");
	const [businesses, setBusinesses] = useState<GetBusinessesOwnResponse>();
	const [sort, setSort] = useState<{ field: GetBusinessOwnSort; order: SortOrder; }>({
		field: GetBusinessOwnSort.CreatedAt,
		order: SortOrder.Desc,
	});

	useEffect(() => {
		fetchBusinesses().then();
	}, [sort, searchValue]);


	/**
	 * Get the list of businesses that this user has staff access to.
	 */
	async function fetchBusinesses(): Promise<void> {
		try {
			const res = await new BusinessesApi(getConfig()).getBusinessesOwn({
				search: searchValue,
				sort: sort.field,
				order: sort.order,
				limit: 1000,
				offset: 0,
			});
			setBusinesses(res);
		} catch (e) {
		}
	}

	function searchOnChange(e: React.ChangeEvent<HTMLInputElement>): void {
		setSearchValue(e.target.value);
	}

	function onSortName() {
		if (sort.field === GetBusinessOwnSort.Name) {
			if (sort.order === SortOrder.Asc) {
				setSort({
					...sort,
					order: SortOrder.Desc,
				});
			} else {
				setSort({
					...sort,
					order: SortOrder.Asc,
				});
			}
		} else {
			setSort({
				...sort,
				field: GetBusinessOwnSort.Name,
				order: SortOrder.Asc,
			});
		}
	}

	function onSortRecent() {
		if (sort.field === GetBusinessOwnSort.CreatedAt) {
			if (sort.order === SortOrder.Asc) {
				setSort({
					...sort,
					order: SortOrder.Desc,
				});
			} else {
				setSort({
					...sort,
					order: SortOrder.Asc,
				});
			}
		} else {
			setSort({
				...sort,
				field: GetBusinessOwnSort.CreatedAt,
				order: SortOrder.Desc,
			});
		}
	}

	function renderBusinessesPageSkeleton(): ReactElement {
		return (
			<div className="manage-businesses-page_list">
				{Array.from({length: 12}, (_, i) => (
					<li
						key={i}
						className="manage-businesses-page_list_item"
					>
						<div className="business-card">
							<div className="business-card_header">
								<div className="business-card_header_image">
									<Skeleton height={100}/>
								</div>
								<div className="business-card_header_logo">
									<Skeleton width={64} height={64}/>
								</div>

							</div>
							<h3><Skeleton width="70%"/></h3>
							<p><Skeleton height={20}/></p>
							<p><Skeleton height={20}/></p>
						</div>
					</li>
				))}

			</div>
		)
	}

	return (
		<div className="manage-businesses-page">
			<div className="manage-businesses-page_spacer-top">
				<AuthHeaderLogo/>
			</div>

			<div className="manage-businesses-page_content">
				<h3>Your Businesses</h3>
				<div className="manage-businesses-page_header-row">
					<div className="manage-businesses-page_sort">
						<span>Sort by: </span>
						<button
							onClick={onSortName}
							className={classNames("manage-businesses-page_sort_button", {
								"is-active": sort.field === GetBusinessOwnSort.Name,
							})}
						>
							<span>Name</span>
							{(sort.field === GetBusinessOwnSort.Name && sort.order === SortOrder.Asc) && (
								<IoIosArrowUp/>
							)}
							{(sort.field === GetBusinessOwnSort.Name && sort.order === SortOrder.Desc) && (
								<IoIosArrowDown/>
							)}
						</button>
						<button
							onClick={onSortRecent}
							className={classNames("manage-businesses-page_sort_button", {
								"is-active": sort.field === GetBusinessOwnSort.CreatedAt,
							})}
						>
							<span>Recent</span>
							{(sort.field === GetBusinessOwnSort.CreatedAt && sort.order === SortOrder.Asc) && (
								<IoIosArrowUp/>
							)}
							{(sort.field === GetBusinessOwnSort.CreatedAt && sort.order === SortOrder.Desc) && (
								<IoIosArrowDown/>
							)}
						</button>
					</div>

					<div className="manage-businesses-page_search">
						<input
							type="text"
							placeholder="Search businesses"
							value={searchValue}
							onChange={searchOnChange}
						/>
					</div>
				</div>
				{!businesses ? renderBusinessesPageSkeleton() : (
					<ul className="manage-businesses-page_list">
						{businesses.businesses.map((business) => (
							<li
								key={business.id}
								className="manage-businesses-page_list_item"
							>
								<BusinessCard business={business}/>
							</li>
						))}
					</ul>
				)}
			</div>
		</div>
	);
}

export default BusinessesManagePage;
