"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BusinessPermissionToJSON = exports.BusinessPermissionFromJSONTyped = exports.BusinessPermissionFromJSON = exports.BusinessPermission = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var BusinessPermission;
(function (BusinessPermission) {
    BusinessPermission["BILLING"] = "BILLING";
    BusinessPermission["USERS"] = "USERS";
    BusinessPermission["MENUS"] = "MENUS";
    BusinessPermission["CONTACT"] = "CONTACT";
    BusinessPermission["ORDERSVIEW"] = "ORDERS_VIEW";
    BusinessPermission["OPERATIONS"] = "OPERATIONS";
})(BusinessPermission = exports.BusinessPermission || (exports.BusinessPermission = {}));
function BusinessPermissionFromJSON(json) {
    return BusinessPermissionFromJSONTyped(json, false);
}
exports.BusinessPermissionFromJSON = BusinessPermissionFromJSON;
function BusinessPermissionFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.BusinessPermissionFromJSONTyped = BusinessPermissionFromJSONTyped;
function BusinessPermissionToJSON(value) {
    return value;
}
exports.BusinessPermissionToJSON = BusinessPermissionToJSON;
