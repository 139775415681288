"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.LeaderboardsDateRangeToJSON = exports.LeaderboardsDateRangeFromJSONTyped = exports.LeaderboardsDateRangeFromJSON = void 0;
function LeaderboardsDateRangeFromJSON(json) {
    return LeaderboardsDateRangeFromJSONTyped(json, false);
}
exports.LeaderboardsDateRangeFromJSON = LeaderboardsDateRangeFromJSON;
function LeaderboardsDateRangeFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'dateParam': json['dateParam'],
        'dateValue': json['dateValue'],
    };
}
exports.LeaderboardsDateRangeFromJSONTyped = LeaderboardsDateRangeFromJSONTyped;
function LeaderboardsDateRangeToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'dateParam': value.dateParam,
        'dateValue': value.dateValue,
    };
}
exports.LeaderboardsDateRangeToJSON = LeaderboardsDateRangeToJSON;
