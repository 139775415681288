"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ForceUpdateNftOwnershipResponseToJSON = exports.ForceUpdateNftOwnershipResponseFromJSONTyped = exports.ForceUpdateNftOwnershipResponseFromJSON = void 0;
var _1 = require("./");
function ForceUpdateNftOwnershipResponseFromJSON(json) {
    return ForceUpdateNftOwnershipResponseFromJSONTyped(json, false);
}
exports.ForceUpdateNftOwnershipResponseFromJSON = ForceUpdateNftOwnershipResponseFromJSON;
function ForceUpdateNftOwnershipResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'updatedOwnerships': (json['updatedOwnerships'].map(_1.UpdatedOwnershipsFromJSON)),
    };
}
exports.ForceUpdateNftOwnershipResponseFromJSONTyped = ForceUpdateNftOwnershipResponseFromJSONTyped;
function ForceUpdateNftOwnershipResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'updatedOwnerships': (value.updatedOwnerships.map(_1.UpdatedOwnershipsToJSON)),
    };
}
exports.ForceUpdateNftOwnershipResponseToJSON = ForceUpdateNftOwnershipResponseToJSON;
