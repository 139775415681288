"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImportUserResponseItemToJSON = exports.ImportUserResponseItemFromJSONTyped = exports.ImportUserResponseItemFromJSON = void 0;
var runtime_1 = require("../runtime");
function ImportUserResponseItemFromJSON(json) {
    return ImportUserResponseItemFromJSONTyped(json, false);
}
exports.ImportUserResponseItemFromJSON = ImportUserResponseItemFromJSON;
function ImportUserResponseItemFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'isSuccess': !(0, runtime_1.exists)(json, 'isSuccess') ? undefined : json['isSuccess'],
        'isNew': !(0, runtime_1.exists)(json, 'isNew') ? undefined : json['isNew'],
        'errorMessage': !(0, runtime_1.exists)(json, 'errorMessage') ? undefined : json['errorMessage'],
        'email': !(0, runtime_1.exists)(json, 'email') ? undefined : json['email'],
        'userId': !(0, runtime_1.exists)(json, 'userId') ? undefined : json['userId'],
        'createdAt': !(0, runtime_1.exists)(json, 'createdAt') ? undefined : json['createdAt'],
    };
}
exports.ImportUserResponseItemFromJSONTyped = ImportUserResponseItemFromJSONTyped;
function ImportUserResponseItemToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'isSuccess': value.isSuccess,
        'isNew': value.isNew,
        'errorMessage': value.errorMessage,
        'email': value.email,
        'userId': value.userId,
        'createdAt': value.createdAt,
    };
}
exports.ImportUserResponseItemToJSON = ImportUserResponseItemToJSON;
