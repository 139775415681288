"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiResponseTypeToJSON = exports.ApiResponseTypeFromJSONTyped = exports.ApiResponseTypeFromJSON = exports.ApiResponseType = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var ApiResponseType;
(function (ApiResponseType) {
    ApiResponseType["FIRSTDELIVERYSENDORDER"] = "FIRST_DELIVERY_SEND_ORDER";
    ApiResponseType["FIRSTDELIVERYWEBHOOK"] = "FIRST_DELIVERY_WEBHOOK";
    ApiResponseType["FIRSTDELIVERYSTATUSCALLBACK"] = "FIRST_DELIVERY_STATUS_CALLBACK";
    ApiResponseType["FIRSTDELIVERYERRORCANCELORDER"] = "FIRST_DELIVERY_ERROR_CANCEL_ORDER";
    ApiResponseType["CHOWLYSENDORDER"] = "CHOWLY_SEND_ORDER";
    ApiResponseType["CHOWLYWEBHOOK"] = "CHOWLY_WEBHOOK";
    ApiResponseType["CHOWLYORDERCALLBACK"] = "CHOWLY_ORDER_CALLBACK";
    ApiResponseType["CHOWLYERRORMENU"] = "CHOWLY_ERROR_MENU";
    ApiResponseType["ITSACHECKMATESENDORDER"] = "ITSACHECKMATE_SEND_ORDER";
    ApiResponseType["ITSACHECKMATEWEBHOOK"] = "ITSACHECKMATE_WEBHOOK";
    ApiResponseType["ITSACHECKMATEERRORMENU"] = "ITSACHECKMATE_ERROR_MENU";
    ApiResponseType["ITSACHECKMATEERRORLOCATION"] = "ITSACHECKMATE_ERROR_LOCATION";
    ApiResponseType["ITSACHECKMATEERRORACTIVATE"] = "ITSACHECKMATE_ERROR_ACTIVATE";
    ApiResponseType["ITSACHECKMATEERRORACCESS"] = "ITSACHECKMATE_ERROR_ACCESS";
    ApiResponseType["ITSACHECKMATEERRORREFRESH"] = "ITSACHECKMATE_ERROR_REFRESH";
    ApiResponseType["OLOSUBMITBASKET"] = "OLO_SUBMIT_BASKET";
    ApiResponseType["OLOWEBHOOK"] = "OLO_WEBHOOK";
    ApiResponseType["OLOERRORGETRESTAURANT"] = "OLO_ERROR_GET_RESTAURANT";
    ApiResponseType["OLOERRORDOWNLOADMENU"] = "OLO_ERROR_DOWNLOAD_MENU";
    ApiResponseType["OLOERROREIGHTYSIXED"] = "OLO_ERROR_EIGHTY_SIXED";
    ApiResponseType["OLOERRORCREATEBASKET"] = "OLO_ERROR_CREATE_BASKET";
    ApiResponseType["OLOERRORUPDATEBASKET"] = "OLO_ERROR_UPDATE_BASKET";
    ApiResponseType["OLOERRORADVANCEBASKET"] = "OLO_ERROR_ADVANCE_BASKET";
    ApiResponseType["OLOERRORSUBMITBASKET"] = "OLO_ERROR_SUBMIT_BASKET";
    ApiResponseType["OLOERRORGETORDER"] = "OLO_ERROR_GET_ORDER";
    ApiResponseType["OLOERRORCANCELORDER"] = "OLO_ERROR_CANCEL_ORDER";
    ApiResponseType["COINMARKETCAPERROR"] = "COINMARKETCAP_ERROR";
    ApiResponseType["MORALISERROR"] = "MORALIS_ERROR";
    ApiResponseType["COINGECKOERROR"] = "COINGECKO_ERROR";
    ApiResponseType["COINBASECHARGECONFIRM"] = "COINBASE_CHARGE_CONFIRM";
    ApiResponseType["COINBASECHARGEERROR"] = "COINBASE_CHARGE_ERROR";
    ApiResponseType["ORDERREWARDSERROR"] = "ORDER_REWARDS_ERROR";
    ApiResponseType["DEVOURIQWEBHOOK"] = "DEVOURIQ_WEBHOOK";
    ApiResponseType["DEVOURIQSENDORDER"] = "DEVOURIQ_SEND_ORDER";
    ApiResponseType["DEVOURIQINJECTORDER"] = "DEVOURIQ_INJECT_ORDER";
    ApiResponseType["DEVOURIQERROR"] = "DEVOURIQ_ERROR";
    ApiResponseType["OTHER"] = "OTHER";
})(ApiResponseType = exports.ApiResponseType || (exports.ApiResponseType = {}));
function ApiResponseTypeFromJSON(json) {
    return ApiResponseTypeFromJSONTyped(json, false);
}
exports.ApiResponseTypeFromJSON = ApiResponseTypeFromJSON;
function ApiResponseTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.ApiResponseTypeFromJSONTyped = ApiResponseTypeFromJSONTyped;
function ApiResponseTypeToJSON(value) {
    return value;
}
exports.ApiResponseTypeToJSON = ApiResponseTypeToJSON;
