"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetOrderAgainRestaurantsQueryToJSON = exports.GetOrderAgainRestaurantsQueryFromJSONTyped = exports.GetOrderAgainRestaurantsQueryFromJSON = void 0;
var runtime_1 = require("../runtime");
function GetOrderAgainRestaurantsQueryFromJSON(json) {
    return GetOrderAgainRestaurantsQueryFromJSONTyped(json, false);
}
exports.GetOrderAgainRestaurantsQueryFromJSON = GetOrderAgainRestaurantsQueryFromJSON;
function GetOrderAgainRestaurantsQueryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'placeId': !(0, runtime_1.exists)(json, 'placeId') ? undefined : json['placeId'],
        'handoff': !(0, runtime_1.exists)(json, 'handoff') ? undefined : json['handoff'],
    };
}
exports.GetOrderAgainRestaurantsQueryFromJSONTyped = GetOrderAgainRestaurantsQueryFromJSONTyped;
function GetOrderAgainRestaurantsQueryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'placeId': value.placeId,
        'handoff': value.handoff,
    };
}
exports.GetOrderAgainRestaurantsQueryToJSON = GetOrderAgainRestaurantsQueryToJSON;
