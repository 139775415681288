import React, {ReactElement} from "react";

function AuthHeaderLogo(): ReactElement {

	return (
		<div className="auth-header-logo">
			<img
				src={process.env.PUBLIC_URL + "/images/DevourGO_Final-01.png"}
				alt="DevourGO Logo"
			/>
		</div>
	);
}

export default AuthHeaderLogo;
