"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserLvlAllOfToJSON = exports.UserLvlAllOfFromJSONTyped = exports.UserLvlAllOfFromJSON = void 0;
var runtime_1 = require("../runtime");
function UserLvlAllOfFromJSON(json) {
    return UserLvlAllOfFromJSONTyped(json, false);
}
exports.UserLvlAllOfFromJSON = UserLvlAllOfFromJSON;
function UserLvlAllOfFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': json['name'],
        'level': json['level'],
        'email': json['email'],
        'id': !(0, runtime_1.exists)(json, 'id') ? undefined : json['id'],
    };
}
exports.UserLvlAllOfFromJSONTyped = UserLvlAllOfFromJSONTyped;
function UserLvlAllOfToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'level': value.level,
        'email': value.email,
        'id': value.id,
    };
}
exports.UserLvlAllOfToJSON = UserLvlAllOfToJSON;
