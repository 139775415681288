"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OverwolfXPTransactionToJSON = exports.OverwolfXPTransactionFromJSONTyped = exports.OverwolfXPTransactionFromJSON = void 0;
function OverwolfXPTransactionFromJSON(json) {
    return OverwolfXPTransactionFromJSONTyped(json, false);
}
exports.OverwolfXPTransactionFromJSON = OverwolfXPTransactionFromJSON;
function OverwolfXPTransactionFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.OverwolfXPTransactionFromJSONTyped = OverwolfXPTransactionFromJSONTyped;
function OverwolfXPTransactionToJSON(value) {
    return value;
}
exports.OverwolfXPTransactionToJSON = OverwolfXPTransactionToJSON;
