import {Token, TokensApi, UsersApi} from "@devour/client";
import {useCallback, useEffect, useState} from "react";
import {addError, decrementLoading, incrementLoading, updateCurrentUser} from "../redux/meta/metaActions";
import {logout} from "../redux/auth/authActions";
import getConfig from "../utils/getConfig";
import {useNavigate} from "react-router-dom";
import {useSelector, useStore} from "react-redux";
import {IStore} from "../redux/defaultStore";

export enum VerifyTokenStatus {
	VERIFYING,
	VERIFIED,
	EXPIRED,
	NO_TOKEN,
}

/**
 * Check if a user has a token and if it's valid or expired. If they have no token we won't do anything.
 * If they have a token we check if it's expired. If it's expired we log them out and redirect them to the login page.
 * If they have a token, and it's not expired, redirect them to the redirectRoute.
 *
 * @param redirectRoute
 * @param globalLoading
 */
export function useVerifyTokenExpiry(redirectRoute: string = "/account", globalLoading: boolean = false): VerifyTokenStatus {

	const history = useNavigate();
	const store = useStore<IStore>();
	const fullToken = useSelector<IStore>(state => state.authStore.fullToken);
	const [tokenStatus, setTokenStatus] = useState<VerifyTokenStatus>(VerifyTokenStatus.VERIFYING);

	const verifyTokenExpiry = useCallback(async (_fullToken: Token) => {
		setTokenStatus(VerifyTokenStatus.VERIFYING);
		if (globalLoading) {
			store.dispatch(incrementLoading());
		}

		try {
			const res = await new TokensApi(getConfig()).checkTokenExpiration({
				tokenBody: {
					token: _fullToken?.token,
				},
			});

			if (res.expired) {
				setTokenStatus(VerifyTokenStatus.EXPIRED);
				await store.dispatch(logout());
				history("/",{replace: true});
			} else {
				setTokenStatus(VerifyTokenStatus.VERIFIED);
				const userRes = await new UsersApi(getConfig()).getProfile();
				store.dispatch(updateCurrentUser(userRes.user));
				history(redirectRoute,{replace: true});
			}
		} catch (e) {
			store.dispatch(await addError(e));
		} finally {
			if (globalLoading) {
				store.dispatch(decrementLoading());
			}
		}
	}, [globalLoading, history, redirectRoute, store]);

	useEffect(() => {
		if (fullToken) {
			verifyTokenExpiry(fullToken as Token).then().catch();
		} else {
			setTokenStatus(VerifyTokenStatus.NO_TOKEN);
		}
	}, [fullToken, verifyTokenExpiry]);

	return tokenStatus;
}
