import React, {ReactElement} from "react";
import {omit} from "lodash";
import {PaginationInfo} from "@devour/client";
import FrameOnePaginationButtons from "./FrameOnePaginationButtons";
import FrameOnePaginatorLimiter, {paginationLimiterOptions} from "./FrameOnePaginatorLimiter";

export type FrontendPagination = Pick<PaginationInfo, "offset" | "limit">;

// Use this for any api requests with pagination to have consistent defaults across apis. If there are api requests
// that you specifically want to use different defaults for, then go right ahead.
export const defaultFrontendPagination: FrontendPagination = {
	offset: 0,
	limit: paginationLimiterOptions?.[0]?.value as number,
}

interface Props extends PaginationInfo {
	onPaginationChange: (newPagination: FrontendPagination) => void;
}

function FrameOnePaginator(props: Props): ReactElement {

	/**
	 * Handle user changing the selected limit. Write handling for updating the api request (or change local data if no api)
	 * in your implementing component - "offset" gets automatically set to 0 here for best user experience of resetting
	 * to first page of data with new limit.
	 *
	 * @param limit
	 */
	function onChangeLimit(limit): void {
		props.onPaginationChange(omit({
			offset: 0,
			limit,
		}, "onPaginationChange"));
	}

	/**
	 * Change table offset. Write handing in your implementing component to update the api request (or change local data if no api).
	 *
	 * @param offset
	 */
	function onChangeOffset(offset: number): void {
		props.onPaginationChange(omit({
			limit: props.limit,
			offset,
		}, "onPaginationChange"));
	}

	return (
		<div className="frame-one-paginator">
			<div className="frame-one-paginator_results">
				Showing {props.lowerRange} to {props.upperRange} of {props.total}
			</div>

			<FrameOnePaginationButtons
				{...omit(props, "onPaginationChange")}
				onChangeOffset={onChangeOffset}
			/>

			<FrameOnePaginatorLimiter
				{...omit(props, "onPaginationChange")}
				onChangeLimit={onChangeLimit}
			/>
		</div>
	);
}

export default FrameOnePaginator;
