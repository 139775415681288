"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GleamRewardToJSON = exports.GleamRewardFromJSONTyped = exports.GleamRewardFromJSON = void 0;
function GleamRewardFromJSON(json) {
    return GleamRewardFromJSONTyped(json, false);
}
exports.GleamRewardFromJSON = GleamRewardFromJSON;
function GleamRewardFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': json['type'],
        'code': json['code'],
    };
}
exports.GleamRewardFromJSONTyped = GleamRewardFromJSONTyped;
function GleamRewardToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': value.type,
        'code': value.code,
    };
}
exports.GleamRewardToJSON = GleamRewardToJSON;
