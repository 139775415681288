"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ForceUpdateNftOwnershipBodyToJSON = exports.ForceUpdateNftOwnershipBodyFromJSONTyped = exports.ForceUpdateNftOwnershipBodyFromJSON = void 0;
var runtime_1 = require("../runtime");
function ForceUpdateNftOwnershipBodyFromJSON(json) {
    return ForceUpdateNftOwnershipBodyFromJSONTyped(json, false);
}
exports.ForceUpdateNftOwnershipBodyFromJSON = ForceUpdateNftOwnershipBodyFromJSON;
function ForceUpdateNftOwnershipBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'trackerId': !(0, runtime_1.exists)(json, 'trackerId') ? undefined : json['trackerId'],
        'isUpdateAll': json['isUpdateAll'],
    };
}
exports.ForceUpdateNftOwnershipBodyFromJSONTyped = ForceUpdateNftOwnershipBodyFromJSONTyped;
function ForceUpdateNftOwnershipBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'trackerId': value.trackerId,
        'isUpdateAll': value.isUpdateAll,
    };
}
exports.ForceUpdateNftOwnershipBodyToJSON = ForceUpdateNftOwnershipBodyToJSON;
