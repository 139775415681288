"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetBusinessOwnPosToJSON = exports.GetBusinessOwnPosFromJSONTyped = exports.GetBusinessOwnPosFromJSON = exports.GetBusinessOwnPos = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var GetBusinessOwnPos;
(function (GetBusinessOwnPos) {
    GetBusinessOwnPos["NONE"] = "NONE";
    GetBusinessOwnPos["CHOWLY"] = "CHOWLY";
    GetBusinessOwnPos["ITSACHECKMATE"] = "ITSACHECKMATE";
    GetBusinessOwnPos["DEVOURIQMANAGED"] = "DEVOURIQ_MANAGED";
    GetBusinessOwnPos["DEVOURIQUNMANAGED"] = "DEVOURIQ_UNMANAGED";
    GetBusinessOwnPos["OLO"] = "OLO";
})(GetBusinessOwnPos = exports.GetBusinessOwnPos || (exports.GetBusinessOwnPos = {}));
function GetBusinessOwnPosFromJSON(json) {
    return GetBusinessOwnPosFromJSONTyped(json, false);
}
exports.GetBusinessOwnPosFromJSON = GetBusinessOwnPosFromJSON;
function GetBusinessOwnPosFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.GetBusinessOwnPosFromJSONTyped = GetBusinessOwnPosFromJSONTyped;
function GetBusinessOwnPosToJSON(value) {
    return value;
}
exports.GetBusinessOwnPosToJSON = GetBusinessOwnPosToJSON;
