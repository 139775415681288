"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GenerateAllReferralsResponseToJSON = exports.GenerateAllReferralsResponseFromJSONTyped = exports.GenerateAllReferralsResponseFromJSON = void 0;
var _1 = require("./");
function GenerateAllReferralsResponseFromJSON(json) {
    return GenerateAllReferralsResponseFromJSONTyped(json, false);
}
exports.GenerateAllReferralsResponseFromJSON = GenerateAllReferralsResponseFromJSON;
function GenerateAllReferralsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'referrals': (json['referrals'].map(_1.ReferralFromJSON)),
    };
}
exports.GenerateAllReferralsResponseFromJSONTyped = GenerateAllReferralsResponseFromJSONTyped;
function GenerateAllReferralsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'referrals': (value.referrals.map(_1.ReferralToJSON)),
    };
}
exports.GenerateAllReferralsResponseToJSON = GenerateAllReferralsResponseToJSON;
