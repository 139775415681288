"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GleamCompetitionActionToJSON = exports.GleamCompetitionActionFromJSONTyped = exports.GleamCompetitionActionFromJSON = void 0;
function GleamCompetitionActionFromJSON(json) {
    return GleamCompetitionActionFromJSONTyped(json, false);
}
exports.GleamCompetitionActionFromJSON = GleamCompetitionActionFromJSON;
function GleamCompetitionActionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': json['name'],
        'entries': json['entries'],
    };
}
exports.GleamCompetitionActionFromJSONTyped = GleamCompetitionActionFromJSONTyped;
function GleamCompetitionActionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'entries': value.entries,
    };
}
exports.GleamCompetitionActionToJSON = GleamCompetitionActionToJSON;
