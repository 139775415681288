"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OWEventLogToJSON = exports.OWEventLogFromJSONTyped = exports.OWEventLogFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function OWEventLogFromJSON(json) {
    return OWEventLogFromJSONTyped(json, false);
}
exports.OWEventLogFromJSON = OWEventLogFromJSON;
function OWEventLogFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'userId': json['userId'],
        'game': (0, _1.OWGameFromJSON)(json['game']),
        'rewards': !(0, runtime_1.exists)(json, 'rewards') ? undefined : (json['rewards'].map(_1.OWRewardFromJSON)),
        'event': json['event'],
        'eventType': json['eventType'],
        'isUsed': json['isUsed'],
        'referralCampaign': !(0, runtime_1.exists)(json, 'referralCampaign') ? undefined : json['referralCampaign'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'id': json['id'],
    };
}
exports.OWEventLogFromJSONTyped = OWEventLogFromJSONTyped;
function OWEventLogToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'userId': value.userId,
        'game': (0, _1.OWGameToJSON)(value.game),
        'rewards': value.rewards === undefined ? undefined : (value.rewards.map(_1.OWRewardToJSON)),
        'event': value.event,
        'eventType': value.eventType,
        'isUsed': value.isUsed,
        'referralCampaign': value.referralCampaign,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
        'id': value.id,
    };
}
exports.OWEventLogToJSON = OWEventLogToJSON;
