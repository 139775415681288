"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.NftCommunityToJSON = exports.NftCommunityFromJSONTyped = exports.NftCommunityFromJSON = void 0;
function NftCommunityFromJSON(json) {
    return NftCommunityFromJSONTyped(json, false);
}
exports.NftCommunityFromJSON = NftCommunityFromJSON;
function NftCommunityFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'nftGrouping': json['nftGrouping'],
        'totalPoints': json['totalPoints'],
        'monthlyPoints': json['monthlyPoints'],
        'bannedUsers': json['bannedUsers'],
    };
}
exports.NftCommunityFromJSONTyped = NftCommunityFromJSONTyped;
function NftCommunityToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'nftGrouping': value.nftGrouping,
        'totalPoints': value.totalPoints,
        'monthlyPoints': value.monthlyPoints,
        'bannedUsers': value.bannedUsers,
    };
}
exports.NftCommunityToJSON = NftCommunityToJSON;
