import React, {ReactElement, useEffect, useState} from "react";
import {StripeAccountObject} from "../../../types/Stripe";
import { FaUser } from "react-icons/fa";
import FrameButton from "../../../components/buttons/FrameButton";
import Skeleton from "react-loading-skeleton";
import {CreateBusinessBodyFrontend} from "./BusinessFormValues";
import {GetBusinessBillingResponse} from "./BusinessDetailsPayoutSettings";
import {useDispatch, useSelector} from "react-redux";
import {addError, decrementLoading, incrementLoading} from "../../../redux/meta/metaActions";
import {AgreementTermsApi, AgreementTermType, Merchant, AgreementTerm, CommerceApi, Business} from "@devour/client";
import getConfig from "../../../utils/getConfig";
import {IStore} from "../../../redux/defaultStore";
import MerchantAgreeTermsModal from "../../../components/modals/MerchantAgreeTermsModal";


interface Props {
    billingAccountOnChange: (stripeAccountId: string) => Promise<void> | void;
    stripeAccounts: Array<StripeAccountObject>;
    business?: Business;
    businessBody?: CreateBusinessBodyFrontend;
    currentAccount?: GetBusinessBillingResponse;
    isLoadingBillingDetails?: Boolean;
}


function BusinessDetailsPayoutAccount(props: Props): ReactElement {
    const dispatch = useDispatch();
    const urlParams = new URLSearchParams(window.location.search);
    const stripeAccountIdParam = urlParams.get('stripeAccountId');
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser as Merchant);
    const [selectedAccountId, setSelectedAccountId] = useState<string>("");
    const currentAccountId = props.currentAccount?.stripeAccount?.id || props.businessBody?.stripeAccountId;
    const otherAvailableStripeAccounts = props.stripeAccounts?.filter((account) => account.payouts_enabled && account.id !== currentAccountId);

    // Stripe onboarding
    const [latestMerchantAgreement, setLatestMerchantAgreement] = useState<AgreementTerm>(undefined);
    const [showAgreementTermsModal, setShowAgreementTermsModal] = useState(false);

    useEffect(() => {
        if (stripeAccountIdParam) {
            void props.billingAccountOnChange(stripeAccountIdParam);
        }
    }, []);

    useEffect(() => {
        if (props.currentAccount) {
            setSelectedAccountId(props.currentAccount?.stripeAccount?.id);
        } else if (props.businessBody) {
            // business is not created yet, get stripeAccountId from the businessBody
            setSelectedAccountId(props.businessBody?.stripeAccountId);
        }
    }, [props.currentAccount, props.businessBody]);


    function renderPaymentAccountSkeleton() {
        return (
            <div className="business-page_payout-settings_account_review">
                <div>
                    <Skeleton height={22} width={220}/>
                    <div className="business-page_payout-settings_account_review_info">
                        {Array.from({length: 4}, (_, i) => (
                            <div key={i} className="business-page_payout-settings_account_review_info_section">
                                <p><Skeleton width={110}/></p>
                                <span><Skeleton width={110}/></span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        )
    }

    function renderPaymentAccountInfo(stripeAccount: StripeAccountObject, isCurrentAccount?: boolean) {
        if (!stripeAccount) {
            return;
        }

        if (!stripeAccount.payouts_enabled) {
            return (
                <div className="business-page_payout-settings_account_review_no-account">
                    Account {stripeAccount.id} has not been connected.
                </div>
            )
        }

        return (
            <>
                <h4>{stripeAccount.id}</h4>
                <div className="business-page_payout-settings_account_review_info">
                    <div className="business-page_payout-settings_account_review_info_section">
                        <p>Bank</p>
                        <span>{stripeAccount.external_accounts?.data[0]?.bank_name}</span>
                    </div>
                    <div className="business-page_payout-settings_account_review_info_section">
                        <p>Routing Number</p>
                        <span>{stripeAccount.external_accounts?.data[0]?.routing_number}</span>
                    </div>
                    <div className="business-page_payout-settings_account_review_info_section">
                        <p>Last 4 digits</p>
                        <span>{stripeAccount.external_accounts?.data[0]?.last4}</span>
                    </div>
                    {isCurrentAccount && props.currentAccount && (
                        <div className="business-page_payout-settings_account_review_info_section">
                            <p>Manager</p>
                            <div className="business-page_payout-settings_account_review_info_section_manager">
                                <FaUser />
                                <span>{props.currentAccount.user?.firstName} {props.currentAccount.user?.lastName}</span>
                            </div>
                        </div>
                    )}
                </div>
                <FrameButton
                    color="purple"
                    size="narrow"
                    onClick={() => props.billingAccountOnChange(isCurrentAccount ? "" : selectedAccountId)}
                >
                    {isCurrentAccount ? "Remove" : "Add"} Bank Account
                </FrameButton>
            </>
        )
    }

    function renderBillingAccountUpdate() {
        return (
            <>
                {(props.currentAccount?.isAccountConnected && selectedAccountId === props.currentAccount.stripeAccount.id) ? (
                    // currently connected account
                    renderPaymentAccountInfo(props.currentAccount.stripeAccount, true)
                ) : (selectedAccountId && selectedAccountId !== props.currentAccount?.stripeAccount?.id) ? (
                    // newly selected account
                    <>
                        <h4>New Account</h4>
                        {renderPaymentAccountInfo(props.stripeAccounts?.find((account) => account.id === selectedAccountId))}
                    </>
                ) : (
                    <p className="business-page_payout-settings_account_review_no-account">No account connected. Funds held by Devour</p>
                )}
            </>
        )
    }

    /**
     * Get Stripe connect link and redirect user browser.
     * @param accountId
     */
    async function onStripeAccountOnboarding(accountId: string): Promise<void> {
        dispatch(incrementLoading());

        try {
            const res = await new CommerceApi(getConfig()).stripeAccountsOnboarding({
                id: accountId,
                businessId: props.business?.id || "",
            });
            window.location.href = res.accountUrl;
        } catch (e) {
            dispatch(await addError(e));
        }
        dispatch(decrementLoading());
    }

    /**
     * When user clicks the "Create New Stripe Merchant Account" button, check to make sure they've agreed to the latest
     * legal doc, and if not, show them the modal prompting them to. If there is no document of this agreement type in
     * the db yet, or if they have agreed, proceed with the Stripe stuff.
     *
     */
    async function createOnClick(): Promise<void> {
        dispatch(incrementLoading());
        try {
            const res = await new AgreementTermsApi(getConfig(fullToken)).getLatestAgreementTerm({
                type: AgreementTermType.MERCHANT,
            });

            if (res.agreementTerm && (currentUser?.latestMerchantAgreementId !== res.agreementTerm.id)) {
                setLatestMerchantAgreement(res.agreementTerm);
                setShowAgreementTermsModal(true);
            } else {
                await onNewAccount();
            }
        } catch (e) {
            console.error(e);
        } finally {
            dispatch(decrementLoading());
        }
    }

    async function onNewAccount(): Promise<void> {
        dispatch(incrementLoading());

        try {
            const res = await new CommerceApi(getConfig()).stripeAccountsAdd();
            onStripeAccountOnboarding((res.account as StripeAccountObject).id).then();
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }
    }

    function renderBillingAccountInitialSetup() {
        return (
            <>
                {(props.businessBody?.stripeAccountId && selectedAccountId === props.businessBody?.stripeAccountId) ? (
                    renderPaymentAccountInfo(props.stripeAccounts?.find((account) => account.id === props.businessBody?.stripeAccountId), true)
                ) : (selectedAccountId && selectedAccountId !== props.businessBody?.stripeAccountId) ? (
                    <>
                        <h4>New Account</h4>
                        {renderPaymentAccountInfo(props.stripeAccounts?.find((account) => account.id === selectedAccountId))}
                    </>
                ) : (
                    <p className="business-page_payout-settings_account_review_no-account">No account connected. Funds held by Devour</p>
                )}
            </>
        )
    }

    function toggleMerchantAgreeTermsModal(): void {
        setShowAgreementTermsModal(s => !s);
    }

    async function handleFinishAgree(): Promise<void> {
        setShowAgreementTermsModal(false);
        await onNewAccount();
    }

    return (
        <div className="business-page_payout-settings_account">
            <MerchantAgreeTermsModal
                isOpen={showAgreementTermsModal}
                agreement={latestMerchantAgreement}
                onClose={toggleMerchantAgreeTermsModal}
                onDone={handleFinishAgree}
            />
            <div className="business-page_payout-settings_account_header-row">
                <div>
                    <h4>Current Bank Account</h4>
                    <p>This bank account will be strictly for business use</p>
                </div>
                <FrameButton
                    <React.ButtonHTMLAttributes<HTMLButtonElement>>
                    color="gray"
                    size="normal"
                    onClick={createOnClick}
                    forwardProps={{type: "button"}}
                >
                    Create New Stripe Merchant Account
                </FrameButton>

            </div>
            <div className="business-page_payout-settings_account_header-row_stripe-selection">
                <select
                    value={selectedAccountId || ""}
                    onChange={(e) => setSelectedAccountId(e.target.value)}
                >
                    <option value={currentAccountId || ""}>Current account</option>
                    {otherAvailableStripeAccounts?.map((account) => (
                        <option key={account.id} value={account.id}>
                            {account.external_accounts?.data[0]?.routing_number} ****{account.external_accounts?.data[0]?.last4} - {account.id}
                        </option>
                    ))}
                </select>
            </div>
            {props.isLoadingBillingDetails ? renderPaymentAccountSkeleton() : (
                <div className="business-page_payout-settings_account_review">
                    {/* Update billing account */}
                    {props.currentAccount && renderBillingAccountUpdate()}
                    {/* Set up billing account during initial business set up*/}
                    {props.businessBody && renderBillingAccountInitialSetup()}
                </div>
            )}
        </div>
    )
}

export default BusinessDetailsPayoutAccount;