"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.NftCommunityMemberMonthlyPointsToJSON = exports.NftCommunityMemberMonthlyPointsFromJSONTyped = exports.NftCommunityMemberMonthlyPointsFromJSON = void 0;
var _1 = require("./");
function NftCommunityMemberMonthlyPointsFromJSON(json) {
    return NftCommunityMemberMonthlyPointsFromJSONTyped(json, false);
}
exports.NftCommunityMemberMonthlyPointsFromJSON = NftCommunityMemberMonthlyPointsFromJSON;
function NftCommunityMemberMonthlyPointsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'nftCommunity': (0, _1.NftCommunityFromJSON)(json['nftCommunity']),
        'month': json['month'],
        'year': json['year'],
        'monthlyPointsEarned': json['monthlyPointsEarned'],
        'totalPointsContributedToCommunity': json['totalPointsContributedToCommunity'],
        'user': (0, _1.UserFromJSON)(json['user']),
    };
}
exports.NftCommunityMemberMonthlyPointsFromJSONTyped = NftCommunityMemberMonthlyPointsFromJSONTyped;
function NftCommunityMemberMonthlyPointsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'nftCommunity': (0, _1.NftCommunityToJSON)(value.nftCommunity),
        'month': value.month,
        'year': value.year,
        'monthlyPointsEarned': value.monthlyPointsEarned,
        'totalPointsContributedToCommunity': value.totalPointsContributedToCommunity,
        'user': (0, _1.UserToJSON)(value.user),
    };
}
exports.NftCommunityMemberMonthlyPointsToJSON = NftCommunityMemberMonthlyPointsToJSON;
