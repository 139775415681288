"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetOverwolfGamesSortToJSON = exports.GetOverwolfGamesSortFromJSONTyped = exports.GetOverwolfGamesSortFromJSON = exports.GetOverwolfGamesSort = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var GetOverwolfGamesSort;
(function (GetOverwolfGamesSort) {
    GetOverwolfGamesSort["CreatedAt"] = "createdAt";
    GetOverwolfGamesSort["Name"] = "name";
})(GetOverwolfGamesSort = exports.GetOverwolfGamesSort || (exports.GetOverwolfGamesSort = {}));
function GetOverwolfGamesSortFromJSON(json) {
    return GetOverwolfGamesSortFromJSONTyped(json, false);
}
exports.GetOverwolfGamesSortFromJSON = GetOverwolfGamesSortFromJSON;
function GetOverwolfGamesSortFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.GetOverwolfGamesSortFromJSONTyped = GetOverwolfGamesSortFromJSONTyped;
function GetOverwolfGamesSortToJSON(value) {
    return value;
}
exports.GetOverwolfGamesSortToJSON = GetOverwolfGamesSortToJSON;
