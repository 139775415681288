import React, {ReactElement} from "react";
import FrameOneModal from "./modalComponents/FrameOneModal";
import FrameModalBody from "./modalComponents/FrameModalBody";
import {IoIosCheckmarkCircle} from "react-icons/io";
import FrameButton from "../buttons/FrameButton";

interface Props {
	isOpen: boolean;
}

function ForgotPasswordResetSuccessModal(props: Props): ReactElement {

	return (
		<FrameOneModal
			isOpen={props.isOpen}
			contentClassName="forgot-password-reset-success-modal"
		>
			<FrameModalBody className="forgot-password-reset-success-modal_body">
				<div className="forgot-password-reset-success-modal_body_check-con">
					<IoIosCheckmarkCircle/>
				</div>

				<p className="forgot-password-reset-success-modal_body_success-msg">
					Your password has been reset successfully!
				</p>

				<FrameButton
					to="/log-in"
					color="gray"
					size="normal"
					className="forgot-password-reset-success-modal_body_button"
				>
					Log in with new password
				</FrameButton>
			</FrameModalBody>
		</FrameOneModal>
	);
}

export default ForgotPasswordResetSuccessModal;
