"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderRefundBodyToJSON = exports.OrderRefundBodyFromJSONTyped = exports.OrderRefundBodyFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function OrderRefundBodyFromJSON(json) {
    return OrderRefundBodyFromJSONTyped(json, false);
}
exports.OrderRefundBodyFromJSON = OrderRefundBodyFromJSON;
function OrderRefundBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'reason': !(0, runtime_1.exists)(json, 'reason') ? undefined : (0, _1.OrderRefundReasonFromJSON)(json['reason']),
        'responsibility': !(0, runtime_1.exists)(json, 'responsibility') ? undefined : (0, _1.OrderRefundResponsibilityFromJSON)(json['responsibility']),
        'notes': !(0, runtime_1.exists)(json, 'notes') ? undefined : json['notes'],
        'fiat': !(0, runtime_1.exists)(json, 'fiat') ? undefined : json['fiat'],
        'dpay': !(0, runtime_1.exists)(json, 'dpay') ? undefined : json['dpay'],
        'rewardsClawback': !(0, runtime_1.exists)(json, 'rewardsClawback') ? undefined : json['rewardsClawback'],
        'responsiblePartyAmount': !(0, runtime_1.exists)(json, 'responsiblePartyAmount') ? undefined : json['responsiblePartyAmount'],
        'transferReverseAmount': !(0, runtime_1.exists)(json, 'transferReverseAmount') ? undefined : json['transferReverseAmount'],
        'discountNullify': !(0, runtime_1.exists)(json, 'discountNullify') ? undefined : json['discountNullify'],
    };
}
exports.OrderRefundBodyFromJSONTyped = OrderRefundBodyFromJSONTyped;
function OrderRefundBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'reason': (0, _1.OrderRefundReasonToJSON)(value.reason),
        'responsibility': (0, _1.OrderRefundResponsibilityToJSON)(value.responsibility),
        'notes': value.notes,
        'fiat': value.fiat,
        'dpay': value.dpay,
        'rewardsClawback': value.rewardsClawback,
        'responsiblePartyAmount': value.responsiblePartyAmount,
        'transferReverseAmount': value.transferReverseAmount,
        'discountNullify': value.discountNullify,
    };
}
exports.OrderRefundBodyToJSON = OrderRefundBodyToJSON;
