"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateDpayCsvDataBodyToJSON = exports.CreateDpayCsvDataBodyFromJSONTyped = exports.CreateDpayCsvDataBodyFromJSON = void 0;
function CreateDpayCsvDataBodyFromJSON(json) {
    return CreateDpayCsvDataBodyFromJSONTyped(json, false);
}
exports.CreateDpayCsvDataBodyFromJSON = CreateDpayCsvDataBodyFromJSON;
function CreateDpayCsvDataBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'dateStart': json['dateStart'],
        'dateEnd': json['dateEnd'],
    };
}
exports.CreateDpayCsvDataBodyFromJSONTyped = CreateDpayCsvDataBodyFromJSONTyped;
function CreateDpayCsvDataBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'dateStart': value.dateStart,
        'dateEnd': value.dateEnd,
    };
}
exports.CreateDpayCsvDataBodyToJSON = CreateDpayCsvDataBodyToJSON;
