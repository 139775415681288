"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TopCommunityLeaderToJSON = exports.TopCommunityLeaderFromJSONTyped = exports.TopCommunityLeaderFromJSON = void 0;
var runtime_1 = require("../runtime");
function TopCommunityLeaderFromJSON(json) {
    return TopCommunityLeaderFromJSONTyped(json, false);
}
exports.TopCommunityLeaderFromJSON = TopCommunityLeaderFromJSON;
function TopCommunityLeaderFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'communityIcon': !(0, runtime_1.exists)(json, 'communityIcon') ? undefined : json['communityIcon'],
        'communityName': json['communityName'],
        'totalUserPoints': json['totalUserPoints'],
        'userNickname': json['userNickname'],
        'userPfpUrl': json['userPfpUrl'],
    };
}
exports.TopCommunityLeaderFromJSONTyped = TopCommunityLeaderFromJSONTyped;
function TopCommunityLeaderToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'communityIcon': value.communityIcon,
        'communityName': value.communityName,
        'totalUserPoints': value.totalUserPoints,
        'userNickname': value.userNickname,
        'userPfpUrl': value.userPfpUrl,
    };
}
exports.TopCommunityLeaderToJSON = TopCommunityLeaderToJSON;
