"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetBrandMapRestaurantsResponseAllOfToJSON = exports.GetBrandMapRestaurantsResponseAllOfFromJSONTyped = exports.GetBrandMapRestaurantsResponseAllOfFromJSON = void 0;
var _1 = require("./");
function GetBrandMapRestaurantsResponseAllOfFromJSON(json) {
    return GetBrandMapRestaurantsResponseAllOfFromJSONTyped(json, false);
}
exports.GetBrandMapRestaurantsResponseAllOfFromJSON = GetBrandMapRestaurantsResponseAllOfFromJSON;
function GetBrandMapRestaurantsResponseAllOfFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'paginatedRestaurants': (json['paginatedRestaurants'].map(_1.RestaurantFromJSON)),
        'allRestaurants': (json['allRestaurants'].map(_1.RestaurantFromJSON)),
    };
}
exports.GetBrandMapRestaurantsResponseAllOfFromJSONTyped = GetBrandMapRestaurantsResponseAllOfFromJSONTyped;
function GetBrandMapRestaurantsResponseAllOfToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'paginatedRestaurants': (value.paginatedRestaurants.map(_1.RestaurantToJSON)),
        'allRestaurants': (value.allRestaurants.map(_1.RestaurantToJSON)),
    };
}
exports.GetBrandMapRestaurantsResponseAllOfToJSON = GetBrandMapRestaurantsResponseAllOfToJSON;
