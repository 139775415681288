"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetUploadAssetUrlBodyToJSON = exports.GetUploadAssetUrlBodyFromJSONTyped = exports.GetUploadAssetUrlBodyFromJSON = void 0;
function GetUploadAssetUrlBodyFromJSON(json) {
    return GetUploadAssetUrlBodyFromJSONTyped(json, false);
}
exports.GetUploadAssetUrlBodyFromJSON = GetUploadAssetUrlBodyFromJSON;
function GetUploadAssetUrlBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'contentType': json['contentType'],
    };
}
exports.GetUploadAssetUrlBodyFromJSONTyped = GetUploadAssetUrlBodyFromJSONTyped;
function GetUploadAssetUrlBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'contentType': value.contentType,
    };
}
exports.GetUploadAssetUrlBodyToJSON = GetUploadAssetUrlBodyToJSON;
