import React, {ReactElement} from "react";
import {createBrowserRouter, RouterProvider, Navigate} from "react-router-dom";
import {isDev} from "./utils/isDev";
import PageWrapper from "./components/routing/PageWrapper";
import ComponentDemoPage from "./pages/ComponentDemoPage";
import Login from "./pages/Login";
import BusinessCreatePage from "./pages/businesses/BusinessCreatePage";
import AccountPage from "./pages/accounts/AccountPage";
import BusinessesManagePage from "./pages/businesses/BusinessesManagePage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import ManageBrandsPage from "./pages/brands/ManageBrandsPage";
import ManageBrandMapsPage from "./pages/brands/ManageBrandMapsPage";
import CreateBrandMapPage from "./pages/brands/CreateBrandMapPage";
import EditBrandMapPage from "./pages/brands/EditBrandMapPage";
import ItsaCheckmateAuth from "./pages/ItsaCheckmateAuth";
import TermsOfUsePage from "./pages/TermsOfUsePage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import BusinessDetailsPage from "./pages/businesses/BusinessDetailsPage";
import 'react-loading-skeleton/dist/skeleton.css';

function App(): ReactElement {

	return (
		<RouterProvider
			router={createBrowserRouter([
				isDev() && {
					path: "/demo",
					element: (
						<PageWrapper
							page={<ComponentDemoPage/>}
							hasSidebar={false}
						/>
					),
				},
				{
					path: "/",
					element: (
						<PageWrapper
							page={<Login/>}
							hasSidebar={false}
						/>
					),
				},
				{
					path: "/forgot-password",
					element: (
						<PageWrapper
							page={<ForgotPasswordPage/>}
							hasSidebar={false}
						/>
					),
				},
				{
					path: "/forgot-password/:requestId",
					element: (
						<PageWrapper
							page={<ForgotPasswordPage/>}
							hasSidebar={false}
						/>
					),
				},
				{
					path: "/account",
					element: (
						<PageWrapper
							page={<AccountPage/>}
							hasSidebar={true}
							authRequired={true}
						/>
					),
				},
				{
					path: "/itsacheckmate-auth",
					element: (
						<PageWrapper
							page={<ItsaCheckmateAuth/>}
							hasSidebar={true}
							authRequired={true}
						/>
					),
				},
				{
					path: "/businesses",
					element: (
						<PageWrapper
							page={<BusinessesManagePage/>}
							hasSidebar={true}
							authRequired={true}
						/>
					),
				},
				{
					path: "/businesses/create",
					element: (
						<PageWrapper
							page={<BusinessCreatePage/>}
							hasSidebar={true}
							authRequired={true}
						/>
					),
				},
				{
					path: "/businesses/:businessId/details",
					element: (
						<PageWrapper
							page={<BusinessDetailsPage/>}
							hasSidebar={true}
							authRequired={true}
						/>
					),
				},
				{
					path: "/businesses/*",
					element: (
						<Navigate
							to="/businesses"
							replace={true}
						/>
					),
				},
				{
					path: "/brands",
					element: (
						<PageWrapper
							page={<ManageBrandsPage/>}
							hasSidebar={true}
							authRequired={true}
						/>
					),
				},
				{
					path: "/brands/:brandId/maps",
					element: (
						<PageWrapper
							page={<ManageBrandMapsPage/>}
							hasSidebar={true}
							authRequired={true}
						/>
					),
				},
				{
					path: "/brands/:brandId/maps/create",
					element: (
						<PageWrapper
							page={<CreateBrandMapPage/>}
							hasSidebar={false}
							authRequired={true}
						/>
					),
				},
				{
					path: "/brands/maps/:slug/edit",
					element: (
						<PageWrapper
							page={<EditBrandMapPage/>}
							hasSidebar={false}
							authRequired={true}
						/>
					),
				},
				{
					path: "/terms-and-conditions",
					element: (
						<PageWrapper
							page={<TermsOfUsePage />}
							hasSidebar={true}
						/>
					)
				},
				{
					path: "/privacy-policy",
					element: (
						<PageWrapper
							page={<PrivacyPolicyPage />}
							hasSidebar={true}
						/>
					)
				},
				{
					path: "/brands/*",
					element: (
						<Navigate
							to="/brands"
							replace={true}
						/>
					),
				},
				{
					path: "/*",
					element: (
						<Navigate
							to="/"
							replace={true}
						/>
					),
				},
			])}
		/>
	);
}

export default App;
