"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetWithdrawsSortToJSON = exports.GetWithdrawsSortFromJSONTyped = exports.GetWithdrawsSortFromJSON = exports.GetWithdrawsSort = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var GetWithdrawsSort;
(function (GetWithdrawsSort) {
    GetWithdrawsSort["CreatedAt"] = "createdAt";
    GetWithdrawsSort["UpdatedAt"] = "updatedAt";
    GetWithdrawsSort["Status"] = "status";
    GetWithdrawsSort["User"] = "user";
})(GetWithdrawsSort = exports.GetWithdrawsSort || (exports.GetWithdrawsSort = {}));
function GetWithdrawsSortFromJSON(json) {
    return GetWithdrawsSortFromJSONTyped(json, false);
}
exports.GetWithdrawsSortFromJSON = GetWithdrawsSortFromJSON;
function GetWithdrawsSortFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.GetWithdrawsSortFromJSONTyped = GetWithdrawsSortFromJSONTyped;
function GetWithdrawsSortToJSON(value) {
    return value;
}
exports.GetWithdrawsSortToJSON = GetWithdrawsSortToJSON;
