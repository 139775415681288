"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetAdminCommunityInfoResponseToJSON = exports.GetAdminCommunityInfoResponseFromJSONTyped = exports.GetAdminCommunityInfoResponseFromJSON = void 0;
var _1 = require("./");
function GetAdminCommunityInfoResponseFromJSON(json) {
    return GetAdminCommunityInfoResponseFromJSONTyped(json, false);
}
exports.GetAdminCommunityInfoResponseFromJSON = GetAdminCommunityInfoResponseFromJSON;
function GetAdminCommunityInfoResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'communityInfo': (0, _1.ManageCommunityInfoFromJSON)(json['communityInfo']),
    };
}
exports.GetAdminCommunityInfoResponseFromJSONTyped = GetAdminCommunityInfoResponseFromJSONTyped;
function GetAdminCommunityInfoResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'communityInfo': (0, _1.ManageCommunityInfoToJSON)(value.communityInfo),
    };
}
exports.GetAdminCommunityInfoResponseToJSON = GetAdminCommunityInfoResponseToJSON;
