"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetBrandsResponseAllOfToJSON = exports.GetBrandsResponseAllOfFromJSONTyped = exports.GetBrandsResponseAllOfFromJSON = void 0;
var _1 = require("./");
function GetBrandsResponseAllOfFromJSON(json) {
    return GetBrandsResponseAllOfFromJSONTyped(json, false);
}
exports.GetBrandsResponseAllOfFromJSON = GetBrandsResponseAllOfFromJSON;
function GetBrandsResponseAllOfFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'brands': (json['brands'].map(_1.BrandFromJSON)),
    };
}
exports.GetBrandsResponseAllOfFromJSONTyped = GetBrandsResponseAllOfFromJSONTyped;
function GetBrandsResponseAllOfToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'brands': (value.brands.map(_1.BrandToJSON)),
    };
}
exports.GetBrandsResponseAllOfToJSON = GetBrandsResponseAllOfToJSON;
