"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderDiscountTypeToJSON = exports.OrderDiscountTypeFromJSONTyped = exports.OrderDiscountTypeFromJSON = exports.OrderDiscountType = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var OrderDiscountType;
(function (OrderDiscountType) {
    OrderDiscountType["ORDER"] = "ORDER";
    OrderDiscountType["DELIVERY"] = "DELIVERY";
    OrderDiscountType["PAYMENT"] = "PAYMENT";
    OrderDiscountType["SALESTAX"] = "SALES_TAX";
    OrderDiscountType["CATEGORY"] = "CATEGORY";
    OrderDiscountType["MENUITEM"] = "MENU_ITEM";
})(OrderDiscountType = exports.OrderDiscountType || (exports.OrderDiscountType = {}));
function OrderDiscountTypeFromJSON(json) {
    return OrderDiscountTypeFromJSONTyped(json, false);
}
exports.OrderDiscountTypeFromJSON = OrderDiscountTypeFromJSON;
function OrderDiscountTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.OrderDiscountTypeFromJSONTyped = OrderDiscountTypeFromJSONTyped;
function OrderDiscountTypeToJSON(value) {
    return value;
}
exports.OrderDiscountTypeToJSON = OrderDiscountTypeToJSON;
