"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminDebitOrCreditLevelPointRequestBodyToJSON = exports.AdminDebitOrCreditLevelPointRequestBodyFromJSONTyped = exports.AdminDebitOrCreditLevelPointRequestBodyFromJSON = void 0;
var runtime_1 = require("../runtime");
function AdminDebitOrCreditLevelPointRequestBodyFromJSON(json) {
    return AdminDebitOrCreditLevelPointRequestBodyFromJSONTyped(json, false);
}
exports.AdminDebitOrCreditLevelPointRequestBodyFromJSON = AdminDebitOrCreditLevelPointRequestBodyFromJSON;
function AdminDebitOrCreditLevelPointRequestBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'userId': json['userId'],
        'amount': json['amount'],
        'debit': json['debit'],
        'note': !(0, runtime_1.exists)(json, 'note') ? undefined : json['note'],
        'adminNote': !(0, runtime_1.exists)(json, 'adminNote') ? undefined : json['adminNote'],
    };
}
exports.AdminDebitOrCreditLevelPointRequestBodyFromJSONTyped = AdminDebitOrCreditLevelPointRequestBodyFromJSONTyped;
function AdminDebitOrCreditLevelPointRequestBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'userId': value.userId,
        'amount': value.amount,
        'debit': value.debit,
        'note': value.note,
        'adminNote': value.adminNote,
    };
}
exports.AdminDebitOrCreditLevelPointRequestBodyToJSON = AdminDebitOrCreditLevelPointRequestBodyToJSON;
