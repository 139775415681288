"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetRestaurantsSortToJSON = exports.GetRestaurantsSortFromJSONTyped = exports.GetRestaurantsSortFromJSON = exports.GetRestaurantsSort = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var GetRestaurantsSort;
(function (GetRestaurantsSort) {
    GetRestaurantsSort["CreatedAt"] = "createdAt";
})(GetRestaurantsSort = exports.GetRestaurantsSort || (exports.GetRestaurantsSort = {}));
function GetRestaurantsSortFromJSON(json) {
    return GetRestaurantsSortFromJSONTyped(json, false);
}
exports.GetRestaurantsSortFromJSON = GetRestaurantsSortFromJSON;
function GetRestaurantsSortFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.GetRestaurantsSortFromJSONTyped = GetRestaurantsSortFromJSONTyped;
function GetRestaurantsSortToJSON(value) {
    return value;
}
exports.GetRestaurantsSortToJSON = GetRestaurantsSortToJSON;
