"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AutocompleteAddressCacheToJSON = exports.AutocompleteAddressCacheFromJSONTyped = exports.AutocompleteAddressCacheFromJSON = void 0;
function AutocompleteAddressCacheFromJSON(json) {
    return AutocompleteAddressCacheFromJSONTyped(json, false);
}
exports.AutocompleteAddressCacheFromJSON = AutocompleteAddressCacheFromJSON;
function AutocompleteAddressCacheFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'expiresAt': json['expiresAt'],
        'formattedAddress': json['formattedAddress'],
        'placeAutocompleteResponse': json['placeAutocompleteResponse'],
    };
}
exports.AutocompleteAddressCacheFromJSONTyped = AutocompleteAddressCacheFromJSONTyped;
function AutocompleteAddressCacheToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'expiresAt': value.expiresAt,
        'formattedAddress': value.formattedAddress,
        'placeAutocompleteResponse': value.placeAutocompleteResponse,
    };
}
exports.AutocompleteAddressCacheToJSON = AutocompleteAddressCacheToJSON;
