"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReferredUserDataToJSON = exports.ReferredUserDataFromJSONTyped = exports.ReferredUserDataFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function ReferredUserDataFromJSON(json) {
    return ReferredUserDataFromJSONTyped(json, false);
}
exports.ReferredUserDataFromJSON = ReferredUserDataFromJSON;
function ReferredUserDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'email': json['email'],
        'createdAt': json['createdAt'],
        'userCreatedAt': json['userCreatedAt'],
        'referralId': json['referralId'],
        'isFirstReferral': json['isFirstReferral'],
        'isRegistrationReferral': json['isRegistrationReferral'],
        'relationshipType': !(0, runtime_1.exists)(json, 'relationshipType') ? undefined : (0, _1.RelationshipTypeFromJSON)(json['relationshipType']),
        'referralHistory': (json['referralHistory'].map(_1.ReferralRelationshipFromJSON)),
    };
}
exports.ReferredUserDataFromJSONTyped = ReferredUserDataFromJSONTyped;
function ReferredUserDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
        'createdAt': value.createdAt,
        'userCreatedAt': value.userCreatedAt,
        'referralId': value.referralId,
        'isFirstReferral': value.isFirstReferral,
        'isRegistrationReferral': value.isRegistrationReferral,
        'relationshipType': (0, _1.RelationshipTypeToJSON)(value.relationshipType),
        'referralHistory': (value.referralHistory.map(_1.ReferralRelationshipToJSON)),
    };
}
exports.ReferredUserDataToJSON = ReferredUserDataToJSON;
