"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetUserCommunityInfoResponseToJSON = exports.GetUserCommunityInfoResponseFromJSONTyped = exports.GetUserCommunityInfoResponseFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function GetUserCommunityInfoResponseFromJSON(json) {
    return GetUserCommunityInfoResponseFromJSONTyped(json, false);
}
exports.GetUserCommunityInfoResponseFromJSON = GetUserCommunityInfoResponseFromJSON;
function GetUserCommunityInfoResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'nftCommunity': (0, _1.NftCommunityFromJSON)(json['nftCommunity']),
        'nftGrouping': (0, _1.NftGroupingFromJSON)(json['nftGrouping']),
        'nftCommunityMembers': json['nftCommunityMembers'],
        'nftCommunityRank': !(0, runtime_1.exists)(json, 'nftCommunityRank') ? undefined : json['nftCommunityRank'],
        'members': !(0, runtime_1.exists)(json, 'members') ? undefined : (json['members'].map(_1.RankedMemberFromJSON)),
    };
}
exports.GetUserCommunityInfoResponseFromJSONTyped = GetUserCommunityInfoResponseFromJSONTyped;
function GetUserCommunityInfoResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'nftCommunity': (0, _1.NftCommunityToJSON)(value.nftCommunity),
        'nftGrouping': (0, _1.NftGroupingToJSON)(value.nftGrouping),
        'nftCommunityMembers': value.nftCommunityMembers,
        'nftCommunityRank': value.nftCommunityRank,
        'members': value.members === undefined ? undefined : (value.members.map(_1.RankedMemberToJSON)),
    };
}
exports.GetUserCommunityInfoResponseToJSON = GetUserCommunityInfoResponseToJSON;
