"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BrandMapPromoHeaderBodyToJSON = exports.BrandMapPromoHeaderBodyFromJSONTyped = exports.BrandMapPromoHeaderBodyFromJSON = void 0;
var runtime_1 = require("../runtime");
function BrandMapPromoHeaderBodyFromJSON(json) {
    return BrandMapPromoHeaderBodyFromJSONTyped(json, false);
}
exports.BrandMapPromoHeaderBodyFromJSON = BrandMapPromoHeaderBodyFromJSON;
function BrandMapPromoHeaderBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'title': !(0, runtime_1.exists)(json, 'title') ? undefined : json['title'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
    };
}
exports.BrandMapPromoHeaderBodyFromJSONTyped = BrandMapPromoHeaderBodyFromJSONTyped;
function BrandMapPromoHeaderBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'title': value.title,
        'description': value.description,
    };
}
exports.BrandMapPromoHeaderBodyToJSON = BrandMapPromoHeaderBodyToJSON;
