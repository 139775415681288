"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BanOrUnbanUserFromAllCommunitiesBodyToJSON = exports.BanOrUnbanUserFromAllCommunitiesBodyFromJSONTyped = exports.BanOrUnbanUserFromAllCommunitiesBodyFromJSON = void 0;
function BanOrUnbanUserFromAllCommunitiesBodyFromJSON(json) {
    return BanOrUnbanUserFromAllCommunitiesBodyFromJSONTyped(json, false);
}
exports.BanOrUnbanUserFromAllCommunitiesBodyFromJSON = BanOrUnbanUserFromAllCommunitiesBodyFromJSON;
function BanOrUnbanUserFromAllCommunitiesBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'userId': json['userId'],
        'ban': json['ban'],
    };
}
exports.BanOrUnbanUserFromAllCommunitiesBodyFromJSONTyped = BanOrUnbanUserFromAllCommunitiesBodyFromJSONTyped;
function BanOrUnbanUserFromAllCommunitiesBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'userId': value.userId,
        'ban': value.ban,
    };
}
exports.BanOrUnbanUserFromAllCommunitiesBodyToJSON = BanOrUnbanUserFromAllCommunitiesBodyToJSON;
