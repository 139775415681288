"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorLevelToJSON = exports.ErrorLevelFromJSONTyped = exports.ErrorLevelFromJSON = exports.ErrorLevel = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var ErrorLevel;
(function (ErrorLevel) {
    ErrorLevel["OPERATIONAL"] = "OPERATIONAL";
    ErrorLevel["CRITICAL"] = "CRITICAL";
})(ErrorLevel = exports.ErrorLevel || (exports.ErrorLevel = {}));
function ErrorLevelFromJSON(json) {
    return ErrorLevelFromJSONTyped(json, false);
}
exports.ErrorLevelFromJSON = ErrorLevelFromJSON;
function ErrorLevelFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.ErrorLevelFromJSONTyped = ErrorLevelFromJSONTyped;
function ErrorLevelToJSON(value) {
    return value;
}
exports.ErrorLevelToJSON = ErrorLevelToJSON;
