"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DevourIQWebhookBodyToJSON = exports.DevourIQWebhookBodyFromJSONTyped = exports.DevourIQWebhookBodyFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function DevourIQWebhookBodyFromJSON(json) {
    return DevourIQWebhookBodyFromJSONTyped(json, false);
}
exports.DevourIQWebhookBodyFromJSON = DevourIQWebhookBodyFromJSON;
function DevourIQWebhookBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'errorMessage': !(0, runtime_1.exists)(json, 'errorMessage') ? undefined : json['errorMessage'],
        'isTest': !(0, runtime_1.exists)(json, 'isTest') ? undefined : json['isTest'],
        'jobUuid': !(0, runtime_1.exists)(json, 'jobUuid') ? undefined : json['jobUuid'],
        'secret': !(0, runtime_1.exists)(json, 'secret') ? undefined : json['secret'],
        'status': !(0, runtime_1.exists)(json, 'status') ? undefined : (0, _1.MenuOrderStatusFromJSON)(json['status']),
        'orderCreationPayload': !(0, runtime_1.exists)(json, 'orderCreationPayload') ? undefined : json['orderCreationPayload'],
        'uberEatsResult': !(0, runtime_1.exists)(json, 'uberEatsResult') ? undefined : (0, _1.DevourIQWebhookBodyUberEatsResultFromJSON)(json['uberEatsResult']),
    };
}
exports.DevourIQWebhookBodyFromJSONTyped = DevourIQWebhookBodyFromJSONTyped;
function DevourIQWebhookBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'errorMessage': value.errorMessage,
        'isTest': value.isTest,
        'jobUuid': value.jobUuid,
        'secret': value.secret,
        'status': (0, _1.MenuOrderStatusToJSON)(value.status),
        'orderCreationPayload': value.orderCreationPayload,
        'uberEatsResult': (0, _1.DevourIQWebhookBodyUberEatsResultToJSON)(value.uberEatsResult),
    };
}
exports.DevourIQWebhookBodyToJSON = DevourIQWebhookBodyToJSON;
