"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoginAccessPointToJSON = exports.LoginAccessPointFromJSONTyped = exports.LoginAccessPointFromJSON = exports.LoginAccessPoint = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var LoginAccessPoint;
(function (LoginAccessPoint) {
    LoginAccessPoint["PAGE"] = "PAGE";
    LoginAccessPoint["ORDERING"] = "ORDERING";
})(LoginAccessPoint = exports.LoginAccessPoint || (exports.LoginAccessPoint = {}));
function LoginAccessPointFromJSON(json) {
    return LoginAccessPointFromJSONTyped(json, false);
}
exports.LoginAccessPointFromJSON = LoginAccessPointFromJSON;
function LoginAccessPointFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.LoginAccessPointFromJSONTyped = LoginAccessPointFromJSONTyped;
function LoginAccessPointToJSON(value) {
    return value;
}
exports.LoginAccessPointToJSON = LoginAccessPointToJSON;
