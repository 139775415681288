"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateProfilePictureBodyToJSON = exports.UpdateProfilePictureBodyFromJSONTyped = exports.UpdateProfilePictureBodyFromJSON = void 0;
var runtime_1 = require("../runtime");
function UpdateProfilePictureBodyFromJSON(json) {
    return UpdateProfilePictureBodyFromJSONTyped(json, false);
}
exports.UpdateProfilePictureBodyFromJSON = UpdateProfilePictureBodyFromJSON;
function UpdateProfilePictureBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'assetID': !(0, runtime_1.exists)(json, 'assetID') ? undefined : json['assetID'],
        'nftID': !(0, runtime_1.exists)(json, 'nftID') ? undefined : json['nftID'],
    };
}
exports.UpdateProfilePictureBodyFromJSONTyped = UpdateProfilePictureBodyFromJSONTyped;
function UpdateProfilePictureBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'assetID': value.assetID,
        'nftID': value.nftID,
    };
}
exports.UpdateProfilePictureBodyToJSON = UpdateProfilePictureBodyToJSON;
