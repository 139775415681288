import React, {ChangeEventHandler, ReactElement, useRef} from "react";
import {EditBrandMapBodyFrontend} from "../../pages/brands/components/BrandMapFormValues";
import {BrandMapColorTheme} from "@devour/client";
import FrameOneSwitchInput from "../inputs/FrameOneSwitchInput";
import FrameButton from "../buttons/FrameButton";


interface Props {
    textColorValue: BrandMapColorTheme;
    onTextColorChange: (theme: BrandMapColorTheme) => void;
    colorBackgroundValue: string;
    onColorBackgroundChange: (key: keyof EditBrandMapBodyFrontend) => ChangeEventHandler<HTMLInputElement>;
}

function MerchantBrandPageTheme(props: Props): ReactElement {

    const colorPickerRef = useRef<HTMLInputElement>(null);

    const handleClick = () => {
        if (colorPickerRef.current) {
            const colorPickerInput = colorPickerRef.current as HTMLInputElement;
            colorPickerInput.click();
        }
    };

    return (
        <div className="merchant-brand-title-description merchant-brand-margin-bottom-adjust">
            <div className="merchant-brand-title-description_content">
                <div className="merchant-brand-page-theme_top">
                    <div className="merchant-brand-page-theme_top_section">
                        <h5>Text Color</h5>
                        <p className="merchant-brand-title-description_content_subtitle">
                            Customize your brand page with white colored text or black colored text.
                        </p>
                        <FrameOneSwitchInput
                            <BrandMapColorTheme>
                            name="business-list-type"
                            className="merchant-brand-page-theme_top_switch"
                            value={props.textColorValue}
                            onToggle={props.onTextColorChange}
                            options={[
                                {
                                    render: "White",
                                    value: BrandMapColorTheme.LIGHT,
                                },
                                {
                                    render: "Black",
                                    value: BrandMapColorTheme.DARK,
                                },
                            ]}
                        />

                    </div>

                    <div className="merchant-brand-page-theme_top_vertical" />

                    <div className="merchant-brand-page-theme_top_section">
                        <h5>Background Color</h5>
                        <p className="merchant-brand-title-description_content_subtitle">
                            Specify a background color by clicking below. Please choose a color that will
                            work with your text color.
                        </p>

                        <div className="merchant-brand-page-theme_top_input-container">
                            <input
                                type="color"
                                ref={colorPickerRef}
                                className="merchant-brand-page-theme_top_input-container_picker"
                                value={props.colorBackgroundValue}
                                onChange={props.onColorBackgroundChange("colorBackground")}
                            />
                            <FrameButton
                                color="purple"
                                size="narrow"
                                onClick={handleClick}
                            >
                                Select Color
                            </FrameButton>
                        </div>

                    </div>
                </div>

                <div className="merchant-brand-page-theme_preview">

                    <p className="merchant-brand-page-theme_preview_subheader">Preview</p>
                    <div
                        className="merchant-brand-page-theme_preview_container"
                    >
                        <div
                            className="merchant-brand-page-theme_preview_container_contents"
                            style={{backgroundColor: props.colorBackgroundValue}}
                        >
                            <p
                                className="merchant-brand-page-theme_preview_container_contents_text"
                                style={{color: props.textColorValue === BrandMapColorTheme.DARK ? "#000" : "#FFF"}}
                            >
                                {`This is a preview of ${props.textColorValue === BrandMapColorTheme.DARK ? "black" : "white"} text with ${props.colorBackgroundValue}`}
                            </p>

                        </div>
                    </div>

                </div>

            </div>
        </div>
    );
}

export default MerchantBrandPageTheme;