import React, {useState} from "react";
import {LoginAccessPoint} from "@devour/client";

interface Props {
    updateLoginMode: (login: LoginAccessPoint) => void;
    loginAccessPoint: LoginAccessPoint;
}
function MerchantBrandUserLoginSettings(props: Props) {

    const handleLoginAccessPointChange = (event) => {
        props.updateLoginMode(event.target.value);
    };

    return (
        <div className="merchant-brand-title-description">
            <div className="merchant-brand-title-description_content">
                <h5>
                    Set User Login Access Point
                </h5>
                <p className="merchant-brand-title-description_content_subtitle">
                    Set when the user is prompted to login: <br/>
                    At brand map page access, or when the user wants to order from a restaurant (clicks on the "order
                    here" button of a restaurant). <br/>
                    By default, the login access point is set to "Ordering Flow".
                </p>
                <select
                    value={props.loginAccessPoint}
                    onChange={handleLoginAccessPointChange}
                >
                    <option value={LoginAccessPoint.ORDERING}>Ordering Flow</option>
                    <option value={LoginAccessPoint.PAGE}>Page Access</option>
                </select>
            </div>
        </div>
    )
}

export default MerchantBrandUserLoginSettings;
