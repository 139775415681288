"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FavoriteRestaurantsResponseToJSON = exports.FavoriteRestaurantsResponseFromJSONTyped = exports.FavoriteRestaurantsResponseFromJSON = void 0;
var _1 = require("./");
function FavoriteRestaurantsResponseFromJSON(json) {
    return FavoriteRestaurantsResponseFromJSONTyped(json, false);
}
exports.FavoriteRestaurantsResponseFromJSON = FavoriteRestaurantsResponseFromJSON;
function FavoriteRestaurantsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'restaurants': (json['restaurants'].map(_1.RestaurantFromJSON)),
    };
}
exports.FavoriteRestaurantsResponseFromJSONTyped = FavoriteRestaurantsResponseFromJSONTyped;
function FavoriteRestaurantsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'restaurants': (value.restaurants.map(_1.RestaurantToJSON)),
    };
}
exports.FavoriteRestaurantsResponseToJSON = FavoriteRestaurantsResponseToJSON;
