import React, {ReactElement} from "react";
import {
	User,
	Token,
} from "@devour/client";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import AuthHeaderLogo from "../../components/auth/AuthHeaderLogo";
import StripeConnectedAccounts from "./components/StripeConnectedAccounts";

interface StateProps {
	fullToken: Token;
	currentUser: User;
}

function AccountPage(props: AccountPageProps): ReactElement {

	return (
		<div className="account-page">
			<div className="account-page_spacer-top">
				<AuthHeaderLogo/>
			</div>

			<div className="account-page_content">
				<h3 className="account-page_title">
					My Account
				</h3>

				<p>Name: {props.currentUser.firstName} {props.currentUser.lastName}</p>
				<p>Email: {props.currentUser.email}</p>
				<p>Phone: {props.currentUser.phoneNumber?.normalized}</p>

				<StripeConnectedAccounts/>
			</div>
		</div>
	);
}

function connector() {
	return connect((store: IStore): StateProps => {
		return {
			currentUser: store.metaStore.currentUser,
			fullToken: store.authStore.fullToken,
		}
	});
}

type AccountPageProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(AccountPage);
