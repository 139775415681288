"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BrandMapHtmlSectionToJSON = exports.BrandMapHtmlSectionFromJSONTyped = exports.BrandMapHtmlSectionFromJSON = void 0;
var runtime_1 = require("../runtime");
function BrandMapHtmlSectionFromJSON(json) {
    return BrandMapHtmlSectionFromJSONTyped(json, false);
}
exports.BrandMapHtmlSectionFromJSON = BrandMapHtmlSectionFromJSON;
function BrandMapHtmlSectionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'content': !(0, runtime_1.exists)(json, 'content') ? undefined : json['content'],
        'widthPercentage': !(0, runtime_1.exists)(json, 'widthPercentage') ? undefined : json['widthPercentage'],
    };
}
exports.BrandMapHtmlSectionFromJSONTyped = BrandMapHtmlSectionFromJSONTyped;
function BrandMapHtmlSectionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'content': value.content,
        'widthPercentage': value.widthPercentage,
    };
}
exports.BrandMapHtmlSectionToJSON = BrandMapHtmlSectionToJSON;
