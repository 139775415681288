import React, {ChangeEventHandler, FormEvent, ReactElement, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {RequestPasswordResetBody, Token, VerificationsApi} from "@devour/client";
import AuthHeaderLogo from "../components/auth/AuthHeaderLogo";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/metaActions";
import getConfig from "../utils/getConfig";
import FrameButton from "../components/buttons/FrameButton";
import {Link} from "react-router-dom";
import ConfirmPasswordResetRequestModal from "../components/modals/ConfirmPasswordResetRequestModal";
import {useParams} from "react-router";

const defaultForgotPasswordForm: RequestPasswordResetBody = {
	email: "",
};

interface StateProps {
	fullToken: Token;
}

function ForgotPasswordPage(props: ForgotPasswordPageProps): ReactElement {
	const {requestId} = useParams<{ requestId?: string; }>();
	const [forgotPasswordForm, setForgotPasswordForm] = useState<RequestPasswordResetBody>(defaultForgotPasswordForm);
	const [showConfirmModal, setShowConfirmModal] = useState<{ show: boolean, requestId: string }>({
		show: !!requestId,
		requestId: requestId || "",
	});

	/**
	 * Handle all text input onChange events.
	 *
	 * @param key
	 */
	function inputOnChange(key: keyof Omit<RequestPasswordResetBody, "phoneNumber">): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setForgotPasswordForm((_forgotPasswordForm) => {
				return {
					...forgotPasswordForm,
					[key]: e.target.value,
				}
			});
		}
	}

	/**
	 * Call the api to submit the password reset request, and open the modal with the form for the user to complete.
	 *
	 * @param e
	 */
	async function submitForgotPassword(e: FormEvent<HTMLFormElement>): Promise<void> {
		e.preventDefault();
		props.dispatch(incrementLoading());

		try {
			const res = await new VerificationsApi(getConfig()).requestPasswordReset({
				requestPasswordResetBody: {
					email: forgotPasswordForm.email,
				},
			});

			setShowConfirmModal({
				show: true,
				requestId: res.passwordResetRequestId,
			});
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	/**
	 * Close the confirmation modal.
	 *
	 */
	function hideConfirmModal(): void {
		setShowConfirmModal({
			show: false,
			requestId: "",
		});
	}

	return (
		<React.Fragment>
			<ConfirmPasswordResetRequestModal
				isOpen={showConfirmModal.show}
				requestId={showConfirmModal.requestId}
				onClose={hideConfirmModal}
			/>

			<div className="forgot-password">
				<div className="forgot-password_spacer-top">
					<AuthHeaderLogo/>
				</div>

				<div className="forgot-password_content">
					<h3 className="forgot-password_content_title">Reset Password</h3>

					<form onSubmit={submitForgotPassword}>
						<div className="forgot-password_content_email-container">
							<label>Email</label>
							<input
								type="email"
								placeholder="Enter your email address"
								value={forgotPasswordForm.email}
								onChange={inputOnChange("email")}
							/>
						</div>

						<FrameButton
							<React.ButtonHTMLAttributes<HTMLButtonElement>>
							color="gray"
							size="normal"
							className="forgot-password_content_submit-button"
							// forwardRef={submitButtonRef}
							forwardProps={{type: "submit"}}
						>
							Submit password reset
						</FrameButton>
					</form>

					<div className="forgot-password_content_other-actions">

						<p className="forgot-password_content_other-actions_log-in">
							Remembered your password?{" "}
							<Link to="/log-in">
								Log in
							</Link>
						</p>

						<p className="login-page_content_other-actions_owner">
							Are you trying to order food?{" "}
							<a
								href={process.env.REACT_APP_WEB_URL}
								rel="noopener noreferrer"
							>
								Click here
							</a>
						</p>
					</div>
				</div>

				<div className="forgot-password_spacer-bottom"/>
			</div>
		</React.Fragment>
	);
}

function connector() {
	return connect((store: IStore): StateProps => {
		return {
			fullToken: store.authStore.fullToken,
		}
	});
}

type ForgotPasswordPageProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(ForgotPasswordPage);
