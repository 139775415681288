"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetUsersSortToJSON = exports.GetUsersSortFromJSONTyped = exports.GetUsersSortFromJSON = exports.GetUsersSort = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var GetUsersSort;
(function (GetUsersSort) {
    GetUsersSort["CreatedAt"] = "createdAt";
})(GetUsersSort = exports.GetUsersSort || (exports.GetUsersSort = {}));
function GetUsersSortFromJSON(json) {
    return GetUsersSortFromJSONTyped(json, false);
}
exports.GetUsersSortFromJSON = GetUsersSortFromJSON;
function GetUsersSortFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.GetUsersSortFromJSONTyped = GetUsersSortFromJSONTyped;
function GetUsersSortToJSON(value) {
    return value;
}
exports.GetUsersSortToJSON = GetUsersSortToJSON;
