"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserRankDataToJSON = exports.UserRankDataFromJSONTyped = exports.UserRankDataFromJSON = void 0;
var runtime_1 = require("../runtime");
function UserRankDataFromJSON(json) {
    return UserRankDataFromJSONTyped(json, false);
}
exports.UserRankDataFromJSON = UserRankDataFromJSON;
function UserRankDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'nickname': json['nickname'],
        'pfpUrl': !(0, runtime_1.exists)(json, 'pfpUrl') ? undefined : json['pfpUrl'],
        'level': json['level'],
        'rank': json['rank'],
        'experiencePoints': json['experiencePoints'],
    };
}
exports.UserRankDataFromJSONTyped = UserRankDataFromJSONTyped;
function UserRankDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'nickname': value.nickname,
        'pfpUrl': value.pfpUrl,
        'level': value.level,
        'rank': value.rank,
        'experiencePoints': value.experiencePoints,
    };
}
exports.UserRankDataToJSON = UserRankDataToJSON;
