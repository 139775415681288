"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SimpleHashWebhookPayloadDataToJSON = exports.SimpleHashWebhookPayloadDataFromJSONTyped = exports.SimpleHashWebhookPayloadDataFromJSON = void 0;
var runtime_1 = require("../runtime");
function SimpleHashWebhookPayloadDataFromJSON(json) {
    return SimpleHashWebhookPayloadDataFromJSONTyped(json, false);
}
exports.SimpleHashWebhookPayloadDataFromJSON = SimpleHashWebhookPayloadDataFromJSON;
function SimpleHashWebhookPayloadDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'from_address': !(0, runtime_1.exists)(json, 'from_address') ? undefined : json['from_address'],
        'to_address': !(0, runtime_1.exists)(json, 'to_address') ? undefined : json['to_address'],
        'transaction': json['transaction'],
        'chain': json['chain'],
        'token_id': json['token_id'],
        'contract_address': json['contract_address'],
        'nft_id': json['nft_id'],
        'collection_id': json['collection_id'],
    };
}
exports.SimpleHashWebhookPayloadDataFromJSONTyped = SimpleHashWebhookPayloadDataFromJSONTyped;
function SimpleHashWebhookPayloadDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'from_address': value.from_address,
        'to_address': value.to_address,
        'transaction': value.transaction,
        'chain': value.chain,
        'token_id': value.token_id,
        'contract_address': value.contract_address,
        'nft_id': value.nft_id,
        'collection_id': value.collection_id,
    };
}
exports.SimpleHashWebhookPayloadDataToJSON = SimpleHashWebhookPayloadDataToJSON;
