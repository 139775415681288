"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokenPaymentMethodToJSON = exports.TokenPaymentMethodFromJSONTyped = exports.TokenPaymentMethodFromJSON = exports.TokenPaymentMethod = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var TokenPaymentMethod;
(function (TokenPaymentMethod) {
    TokenPaymentMethod["XP"] = "XP";
    TokenPaymentMethod["FIAT"] = "FIAT";
})(TokenPaymentMethod = exports.TokenPaymentMethod || (exports.TokenPaymentMethod = {}));
function TokenPaymentMethodFromJSON(json) {
    return TokenPaymentMethodFromJSONTyped(json, false);
}
exports.TokenPaymentMethodFromJSON = TokenPaymentMethodFromJSON;
function TokenPaymentMethodFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.TokenPaymentMethodFromJSONTyped = TokenPaymentMethodFromJSONTyped;
function TokenPaymentMethodToJSON(value) {
    return value;
}
exports.TokenPaymentMethodToJSON = TokenPaymentMethodToJSON;
