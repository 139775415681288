"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GenericCounterToJSON = exports.GenericCounterFromJSONTyped = exports.GenericCounterFromJSON = void 0;
var _1 = require("./");
function GenericCounterFromJSON(json) {
    return GenericCounterFromJSONTyped(json, false);
}
exports.GenericCounterFromJSON = GenericCounterFromJSON;
function GenericCounterFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'key': (0, _1.GenericCounterKeyFromJSON)(json['key']),
        'count': json['count'],
    };
}
exports.GenericCounterFromJSONTyped = GenericCounterFromJSONTyped;
function GenericCounterToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'key': (0, _1.GenericCounterKeyToJSON)(value.key),
        'count': value.count,
    };
}
exports.GenericCounterToJSON = GenericCounterToJSON;
