import React, {ChangeEventHandler, ReactElement} from "react";
import {EditBrandMapBodyFrontend} from "./BrandMapFormValues";
import UploadImagePreview from "../../../components/cards/UploadImagePreview";

interface Props {
	formValues: EditBrandMapBodyFrontend;
	onImageChange: (key: "promoCartHero" | "promoCartBackground") => ChangeEventHandler<HTMLInputElement>;
	onRemoveImage: (key: "promoCartHero" | "promoCartBackground") => void;
}

function EditBrandMapMainPromoCartImages(props: Props): ReactElement {

	return (
		<div className="create-map-page_container create-map-page_main-promo">

			<div className="create-map-page_container_inner create-map-page_main-promo_cart-images">
				<h5>
					Upload Main Promotion Cart Images
				</h5>
				<p>
					If the customer selects the main promotion, then they will see a card in the cart panel using
					the promotion image as a main graphic, and the background as its background.
					This will only appear if there is a main promotion set.
				</p>

				<div className="create-map-page_main-promo_cart-images_row">
					<div className="create-map-page_main-promo_cart-images_row_hero">
						<p>
							Preview of Promotion Cart Image
						</p>
						<UploadImagePreview
							<EditBrandMapBodyFrontend>
							onImageChange={props.onImageChange}
							onRemoveImage={props.onRemoveImage}
							image={props.formValues.promoCartHero}
							fieldName="promoCartHero"
							optional={true}
						/>
					</div>
					<div className="create-map-page_main-promo_cart-images_row_background">
						<p>
							Preview of Promotion Cart Background Image
						</p>
						<UploadImagePreview
							<EditBrandMapBodyFrontend>
							onImageChange={props.onImageChange}
							onRemoveImage={props.onRemoveImage}
							image={props.formValues.promoCartBackground}
							fieldName="promoCartBackground"
							optional={true}
							isBackground={true}
						/>
					</div>
				</div>
			</div>

		</div>
	);
}

export default EditBrandMapMainPromoCartImages;
