import React, {ButtonHTMLAttributes, ReactElement} from "react";
import {Address, BrandMap, Business, Restaurant} from "@devour/client";
import {PickFieldsOfType} from "../../../../utils/PickedFieldsOfType";
import {useSelector} from "react-redux";
import {IStore} from "../../../../redux/defaultStore";
import classNames from "classnames";
import FrameButton from "../../../../components/buttons/FrameButton";

export function addPotentiallyEmptyAddressSection(_address: Address, key: keyof PickFieldsOfType<Address, string>): string {
    if (_address[key]?.length > 0) {
        return " " + _address[key];
    }
    return "";
}

export function concatAddressFields(_address: Address, fields: Array<keyof PickFieldsOfType<Address, string>> = ["line1", "line2", "locality", "administrativeArea", "postalCode"]): string {
    return fields.slice(1).reduce((add, field) => {
        return add + addPotentiallyEmptyAddressSection(_address, field);
    }, _address[fields[0]] as string);
}

interface Props {
    business: Restaurant;
    onSelectClick: (restaurant: Restaurant) => void;
    isActive: boolean;

}

function MapLandingBusinessListCard(props: Props): ReactElement {

    return (
        <div
            className={classNames("map-landing-bus-list-card is-open", {
                "is-active": props.isActive,
            })}
            onClick={() => props.onSelectClick(props.business)}
        >
            <div className="map-landing-bus-list-card_image">
                <img
                    src={props.business.icon?.url || process.env.REACT_APP_CDN_URL + "/images/placeholderitem.webp"}
                    alt={props.business.name}
                />
            </div>

            <div className="map-landing-bus-list-card_content">
                <div className="map-landing-bus-list-card_content_title-row">
                    <h4 className="map-landing-bus-list-card_content_title-row_title">
                        {props.business.name}
                    </h4>
                </div>

                <div className="map-landing-bus-list-card_content_address">
                    {concatAddressFields(props.business.address)}
                </div>

            </div>

            {(props.isActive) && (
                <div className="map-landing-bus-list-card_btn-con">
                    <FrameButton
                        <ButtonHTMLAttributes<HTMLButtonElement>>
                        color="purple"
                        size="narrow"
                    >
                        Order from here
                    </FrameButton>
                </div>
            )}
        </div>
    );
}


export default MapLandingBusinessListCard;
