import React, {ReactElement, ReactNode} from "react";
import {connect, ConnectedProps} from "react-redux";
import {ApiError} from "@devour/client";
import {removeError, toggleGlobalContactModalVisible} from "../../../redux/meta/metaActions";
import FrameOneModal from "../modalComponents/FrameOneModal";
import FrameModalHeader from "../modalComponents/FrameModalHeader";
import FrameModalBody from "../modalComponents/FrameModalBody";
import FrameModalFooter from "../modalComponents/FrameModalFooter";
import FrameButton from "../../buttons/FrameButton";
import {IStore} from "../../../redux/defaultStore";
import {isFormError} from "../../../typeguards/isFormError";

interface Props {
	index: number;
	apiError: ApiError;
}

function ErrorModal(props: ErrorModalProps): ReactElement {

	/**
	 * Dismiss this specific error modal.
	 *
	 */
	function dismiss(): void {
		props.dispatch(removeError(props.index));
	}

	/**
	 * Display the global contact modal.
	 *
	 */
	function toggleContactModal(): void {
		props.dispatch(toggleGlobalContactModalVisible(true));
	}

	/**
	 * Render all errors & sub-errors.
	 *
	 * @param error
	 * @param i
	 */
	function createErrors(error: ApiError, i: number): ReactNode {
		return (
			<li key={`error_${i}`}>
				{error.message}
				{Array.isArray(error.errors) && (
					<ul>
						{error.errors.map(createErrors)}
					</ul>
				)}
			</li>
		);
	}

	/**
	 * Render all errors & sub-errors.
	 *
	 * @param error
	 * @param i
	 */
	function createFormErrors(error: ApiError, i: number): ReactNode {
		return (
			<li key={`error_${i}`}>
				{error.message}
				{isFormError(error) && (
					<React.Fragment>
						{Object.keys(error.formErrors)?.length > 0 && (
							<ul>
								{Object.values(error.formErrors).map(createFormErrors)}
							</ul>
						)}
					</React.Fragment>
				)}
			</li>
		);
	}

	return (
		<FrameOneModal
			isOpen={true}
			toggle={dismiss}
		>
			<FrameModalHeader
				title="Error"
				toggle={dismiss}
			/>

			<FrameModalBody>
				{isFormError(props.apiError) ? (
					<React.Fragment>
						<b>{props.apiError?.message}</b>
						{Object.keys(props.apiError?.formErrors)?.length > 0 && (
							<ul>
								{Object.values(props.apiError.formErrors).map(createFormErrors)}
							</ul>
						)}
					</React.Fragment>
				) : (
					<React.Fragment>
						<b>{props.apiError?.message}</b>
						{props.apiError?.errors?.length > 0 && (
							<ul>
								{props.apiError.errors.map(createErrors)}
							</ul>
						)}
					</React.Fragment>
				)}
			</FrameModalBody>

			<FrameModalFooter>
				<FrameButton
					color="purple"
					size="normal"
					onClick={toggleContactModal}
				>
					Contact Support
				</FrameButton>

				<FrameButton
					color="purple"
					size="normal"
					onClick={dismiss}
				>
					Dismiss
				</FrameButton>
			</FrameModalFooter>
		</FrameOneModal>
	);
}

function connector() {
	return connect((store: IStore, props: Props): Props => {
		return {
			...props,
		}
	});
}

type ErrorModalProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(ErrorModal);
