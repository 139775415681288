"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MutexLockToJSON = exports.MutexLockFromJSONTyped = exports.MutexLockFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function MutexLockFromJSON(json) {
    return MutexLockFromJSONTyped(json, false);
}
exports.MutexLockFromJSON = MutexLockFromJSON;
function MutexLockFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'type': (0, _1.MutexLockTypeFromJSON)(json['type']),
        'user': !(0, runtime_1.exists)(json, 'user') ? undefined : json['user'],
        'reference': !(0, runtime_1.exists)(json, 'reference') ? undefined : json['reference'],
    };
}
exports.MutexLockFromJSONTyped = MutexLockFromJSONTyped;
function MutexLockToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'type': (0, _1.MutexLockTypeToJSON)(value.type),
        'user': value.user,
        'reference': value.reference,
    };
}
exports.MutexLockToJSON = MutexLockToJSON;
