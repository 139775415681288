"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BusinessServiceAvailabilityBodyToJSON = exports.BusinessServiceAvailabilityBodyFromJSONTyped = exports.BusinessServiceAvailabilityBodyFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function BusinessServiceAvailabilityBodyFromJSON(json) {
    return BusinessServiceAvailabilityBodyFromJSONTyped(json, false);
}
exports.BusinessServiceAvailabilityBodyFromJSON = BusinessServiceAvailabilityBodyFromJSON;
function BusinessServiceAvailabilityBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'timeZone': json['timeZone'],
        'suspendUntil': !(0, runtime_1.exists)(json, 'suspendUntil') ? undefined : json['suspendUntil'],
        'serviceAvailabilities': (json['serviceAvailabilities'].map(_1.BusinessServiceAvailabilityFromJSON)),
    };
}
exports.BusinessServiceAvailabilityBodyFromJSONTyped = BusinessServiceAvailabilityBodyFromJSONTyped;
function BusinessServiceAvailabilityBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'timeZone': value.timeZone,
        'suspendUntil': value.suspendUntil,
        'serviceAvailabilities': (value.serviceAvailabilities.map(_1.BusinessServiceAvailabilityToJSON)),
    };
}
exports.BusinessServiceAvailabilityBodyToJSON = BusinessServiceAvailabilityBodyToJSON;
