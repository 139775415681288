"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BrandMapStatusToJSON = exports.BrandMapStatusFromJSONTyped = exports.BrandMapStatusFromJSON = exports.BrandMapStatus = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var BrandMapStatus;
(function (BrandMapStatus) {
    BrandMapStatus["DRAFT"] = "DRAFT";
    BrandMapStatus["PUBLISHED"] = "PUBLISHED";
    BrandMapStatus["DISABLED"] = "DISABLED";
})(BrandMapStatus = exports.BrandMapStatus || (exports.BrandMapStatus = {}));
function BrandMapStatusFromJSON(json) {
    return BrandMapStatusFromJSONTyped(json, false);
}
exports.BrandMapStatusFromJSON = BrandMapStatusFromJSON;
function BrandMapStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.BrandMapStatusFromJSONTyped = BrandMapStatusFromJSONTyped;
function BrandMapStatusToJSON(value) {
    return value;
}
exports.BrandMapStatusToJSON = BrandMapStatusToJSON;
