"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetTransactionsPaginatedResponseToJSON = exports.GetTransactionsPaginatedResponseFromJSONTyped = exports.GetTransactionsPaginatedResponseFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function GetTransactionsPaginatedResponseFromJSON(json) {
    return GetTransactionsPaginatedResponseFromJSONTyped(json, false);
}
exports.GetTransactionsPaginatedResponseFromJSON = GetTransactionsPaginatedResponseFromJSON;
function GetTransactionsPaginatedResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'balance': json['balance'],
        'dPayPricePerUsd': !(0, runtime_1.exists)(json, 'dPayPricePerUsd') ? undefined : json['dPayPricePerUsd'],
        'balanceInUsd': !(0, runtime_1.exists)(json, 'balanceInUsd') ? undefined : json['balanceInUsd'],
        'transactions': (0, _1.TransactionsPaginatedFromJSON)(json['transactions']),
    };
}
exports.GetTransactionsPaginatedResponseFromJSONTyped = GetTransactionsPaginatedResponseFromJSONTyped;
function GetTransactionsPaginatedResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'balance': value.balance,
        'dPayPricePerUsd': value.dPayPricePerUsd,
        'balanceInUsd': value.balanceInUsd,
        'transactions': (0, _1.TransactionsPaginatedToJSON)(value.transactions),
    };
}
exports.GetTransactionsPaginatedResponseToJSON = GetTransactionsPaginatedResponseToJSON;
