"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImportUserBodyItemToJSON = exports.ImportUserBodyItemFromJSONTyped = exports.ImportUserBodyItemFromJSON = void 0;
var runtime_1 = require("../runtime");
function ImportUserBodyItemFromJSON(json) {
    return ImportUserBodyItemFromJSONTyped(json, false);
}
exports.ImportUserBodyItemFromJSON = ImportUserBodyItemFromJSON;
function ImportUserBodyItemFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'devour_user_id': !(0, runtime_1.exists)(json, 'devour_user_id') ? undefined : json['devour_user_id'],
        'email': !(0, runtime_1.exists)(json, 'email') ? undefined : json['email'],
        'phone_number': !(0, runtime_1.exists)(json, 'phone_number') ? undefined : json['phone_number'],
        'first_name': !(0, runtime_1.exists)(json, 'first_name') ? undefined : json['first_name'],
        'last_name': !(0, runtime_1.exists)(json, 'last_name') ? undefined : json['last_name'],
    };
}
exports.ImportUserBodyItemFromJSONTyped = ImportUserBodyItemFromJSONTyped;
function ImportUserBodyItemToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'devour_user_id': value.devour_user_id,
        'email': value.email,
        'phone_number': value.phone_number,
        'first_name': value.first_name,
        'last_name': value.last_name,
    };
}
exports.ImportUserBodyItemToJSON = ImportUserBodyItemToJSON;
