"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.VerificationTypeToJSON = exports.VerificationTypeFromJSONTyped = exports.VerificationTypeFromJSON = exports.VerificationType = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var VerificationType;
(function (VerificationType) {
    VerificationType["PASSWORDRESET"] = "PASSWORD_RESET";
    VerificationType["PHONENUMBER"] = "PHONE_NUMBER";
})(VerificationType = exports.VerificationType || (exports.VerificationType = {}));
function VerificationTypeFromJSON(json) {
    return VerificationTypeFromJSONTyped(json, false);
}
exports.VerificationTypeFromJSON = VerificationTypeFromJSON;
function VerificationTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.VerificationTypeFromJSONTyped = VerificationTypeFromJSONTyped;
function VerificationTypeToJSON(value) {
    return value;
}
exports.VerificationTypeToJSON = VerificationTypeToJSON;
