"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.NftRewardPrizeToJSON = exports.NftRewardPrizeFromJSONTyped = exports.NftRewardPrizeFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function NftRewardPrizeFromJSON(json) {
    return NftRewardPrizeFromJSONTyped(json, false);
}
exports.NftRewardPrizeFromJSON = NftRewardPrizeFromJSON;
function NftRewardPrizeFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'name': json['name'],
        'prizeRevealName': json['prizeRevealName'],
        'quantity': json['quantity'],
        'wonQuantity': json['wonQuantity'],
        'emailOnWin': json['emailOnWin'],
        'amount': json['amount'],
        'winningImage': !(0, runtime_1.exists)(json, 'winningImage') ? undefined : (0, _1.AssetFromJSON)(json['winningImage']),
        'sortOrder': !(0, runtime_1.exists)(json, 'sortOrder') ? undefined : json['sortOrder'],
        'onlyAvailableAfterWins': !(0, runtime_1.exists)(json, 'onlyAvailableAfterWins') ? undefined : json['onlyAvailableAfterWins'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
    };
}
exports.NftRewardPrizeFromJSONTyped = NftRewardPrizeFromJSONTyped;
function NftRewardPrizeToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'name': value.name,
        'prizeRevealName': value.prizeRevealName,
        'quantity': value.quantity,
        'wonQuantity': value.wonQuantity,
        'emailOnWin': value.emailOnWin,
        'amount': value.amount,
        'winningImage': (0, _1.AssetToJSON)(value.winningImage),
        'sortOrder': value.sortOrder,
        'onlyAvailableAfterWins': value.onlyAvailableAfterWins,
        'description': value.description,
    };
}
exports.NftRewardPrizeToJSON = NftRewardPrizeToJSON;
