import {memo, ReactElement} from "react";
import {Restaurant} from "@devour/client";
import {GoogleMap, LoadScript, Marker} from "@react-google-maps/api";
import {isEqual} from "lodash";
import React from "react";
import {EditBrandMapBodyFrontend} from "../BrandMapFormValues";

interface Props {
    activeBusinessId: string;
    businessesToRender: Array<Restaurant>;
    brandId: string;
    formValues: EditBrandMapBodyFrontend;
    saveMapRef: (mapRef: google.maps.Map) => void;
    center: google.maps.LatLngLiteral;
    onMapPinClick: (business: Restaurant) => void;
}

function PreviewGoogleMapsWrapper(props: Props): ReactElement {

    /**
     * Determine a default zoom level for the map before user makes any changes.
     *
     */
    function getDefaultZoom(): number {
        // First check for city for highest zoom level.
        if (props.formValues?.defaultLocation?.locality) {
            return 11;
        }
        // Then, check for state for next level of zoom-out.
        else if (props.formValues?.defaultLocation?.administrativeArea) {
            return 8;
        }
        // Finally, if no city or state exists, implication is nation-wide, so use lower zoom.
        else {
            return 5;
        }
    }

    return (
        <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY_FRONTEND_ONLY}>
            <GoogleMap
                onLoad={props.saveMapRef}
                options={{
                    disableDefaultUI: true,
                    streetViewControl: true,
                    clickableIcons: false,
                    zoomControl: true,
                    styles: [
                        {
                            featureType: "poi",
                            stylers: [
                                {
                                    visibility: "off",
                                },
                            ],
                        },
                        {
                            featureType: "transit",
                            stylers: [
                                {
                                    visibility: "off",
                                },
                            ],
                        },
                    ]
                }}
                mapContainerStyle={{
                    width: "100%",
                    height: "100%",
                }}
                center={props.center}
                zoom={getDefaultZoom()}
            >
                {props.businessesToRender?.map((business: Restaurant) => {
                    const isActive = business.id === props.activeBusinessId;

                    return (
                        <Marker
                            key={`brand-map-business-marker_${business.id}`}
                            onClick={() => props.onMapPinClick(business)}
                            icon={(isActive) ? `${process.env.REACT_APP_CDN_URL}/images/map-pin-active.svg` : `${process.env.REACT_APP_CDN_URL}/images/map-pin.svg`}
                            position={{
                                lat: business.address.location.coordinates[1],
                                lng: business.address.location.coordinates[0],
                            }}
                            opacity={1}
                        />
                    );
                })}
            </GoogleMap>
        </LoadScript>
    );
}

export default memo(PreviewGoogleMapsWrapper, (prevProps: Readonly<Props>, nextProps: Readonly<Props>) => {
    const brandMapIdPrev = prevProps.brandId;
    const brandMapIdNext = nextProps.brandId;
    const brandMapActiveIdPrev = prevProps.activeBusinessId;
    const brandMapActiveIdNext = nextProps.activeBusinessId;
    const brandMapBusinessIdsPrev = prevProps.businessesToRender.map(b => b.id);
    const brandMapBusinessIdsNext = nextProps.businessesToRender.map(b => b.id);
    return brandMapIdPrev === brandMapIdNext && brandMapActiveIdPrev === brandMapActiveIdNext && isEqual(brandMapBusinessIdsPrev, brandMapBusinessIdsNext);
});
