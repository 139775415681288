import React, {ChangeEventHandler, ReactElement} from "react";
import {CreateBrandMapBodyFrontend} from "../../pages/brands/components/BrandMapFormValues";


interface Props {
    onChange: (key: keyof CreateBrandMapBodyFrontend) => ChangeEventHandler<HTMLInputElement>;
    value: string;
}

function MerchantBrandAutoMint(props: Props): ReactElement {

    return (
        <div className="merchant-brand-title-description">
            <div className="merchant-brand-title-description_content">
                <h5>
                    Auto-mint NFT
                </h5>
                <p className="merchant-brand-title-description_content_subtitle">
                    If filled with a valid contract address, then an NFT will be auto-minted to the user's DevourGO
                    wallet when they visit this brand map page, if they don't already hold the NFT. The user must be
                    signed in for this to trigger.
                </p>
                <p className="merchant-brand-title-description_content_subtitle">
                    This field is locked. Please contact customer support if you wish to change the contract address.
                </p>
                <input
                    placeholder="Contract Address"
                    value={props.value}
                    onChange={props.onChange("autoMintContract")}
                    disabled={true}
                />
            </div>
        </div>
    );
}

export default MerchantBrandAutoMint;
