"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.WithdrawStatusToJSON = exports.WithdrawStatusFromJSONTyped = exports.WithdrawStatusFromJSON = exports.WithdrawStatus = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var WithdrawStatus;
(function (WithdrawStatus) {
    WithdrawStatus["PENDING"] = "PENDING";
    WithdrawStatus["COMPLETED"] = "COMPLETED";
    WithdrawStatus["REJECTED"] = "REJECTED";
    WithdrawStatus["CANCELLED"] = "CANCELLED";
})(WithdrawStatus = exports.WithdrawStatus || (exports.WithdrawStatus = {}));
function WithdrawStatusFromJSON(json) {
    return WithdrawStatusFromJSONTyped(json, false);
}
exports.WithdrawStatusFromJSON = WithdrawStatusFromJSON;
function WithdrawStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.WithdrawStatusFromJSONTyped = WithdrawStatusFromJSONTyped;
function WithdrawStatusToJSON(value) {
    return value;
}
exports.WithdrawStatusToJSON = WithdrawStatusToJSON;
