"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MenuOrdersApi = void 0;
var runtime = __importStar(require("../runtime"));
var models_1 = require("../models");
/**
 * no description
 */
var MenuOrdersApi = /** @class */ (function (_super) {
    __extends(MenuOrdersApi, _super);
    function MenuOrdersApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Used to cancel menu order
     */
    MenuOrdersApi.prototype.cancelMenuOrderRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.id === null || requestParameters.id === undefined) {
                            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling cancelMenuOrder.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        return [4 /*yield*/, this.request({
                                path: "/menu-orders/{id}".replace("{".concat("id", "}"), encodeURIComponent(String(requestParameters.id))),
                                method: 'DELETE',
                                headers: headerParameters,
                                query: queryParameters,
                                body: (0, models_1.OrderCancelBodyToJSON)(requestParameters.orderCancelBody),
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.MenuOrderFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Used to cancel menu order
     */
    MenuOrdersApi.prototype.cancelMenuOrder = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.cancelMenuOrderRaw(requestParameters)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Used to do a preliminary check to see if menu order is cancellable
     */
    MenuOrdersApi.prototype.cancelMenuOrderCheckRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.id === null || requestParameters.id === undefined) {
                            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling cancelMenuOrderCheck.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/menu-orders/{id}/cancel".replace("{".concat("id", "}"), encodeURIComponent(String(requestParameters.id))),
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.InlineResponse20012FromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Used to do a preliminary check to see if menu order is cancellable
     */
    MenuOrdersApi.prototype.cancelMenuOrderCheck = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.cancelMenuOrderCheckRaw(requestParameters)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Used to set default values (tip amount, etc.) when customer goes to checkout.
     */
    MenuOrdersApi.prototype.checkoutMenuOrderRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.id === null || requestParameters.id === undefined) {
                            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling checkoutMenuOrder.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("User", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/menu-orders/{id}/checkout".replace("{".concat("id", "}"), encodeURIComponent(String(requestParameters.id))),
                                method: 'PUT',
                                headers: headerParameters,
                                query: queryParameters,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * Used to set default values (tip amount, etc.) when customer goes to checkout.
     */
    MenuOrdersApi.prototype.checkoutMenuOrder = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.checkoutMenuOrderRaw(requestParameters)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Used to create new menu order
     */
    MenuOrdersApi.prototype.createMenuOrderRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        if (requestParameters.skipOnError !== undefined) {
                            queryParameters['skipOnError'] = requestParameters.skipOnError;
                        }
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        return [4 /*yield*/, this.request({
                                path: "/menu-orders/create",
                                method: 'POST',
                                headers: headerParameters,
                                query: queryParameters,
                                body: (0, models_1.CreateMenuOrderBodyToJSON)(requestParameters.createMenuOrderBody),
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.CreateMenuOrderResponseFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Used to create new menu order
     */
    MenuOrdersApi.prototype.createMenuOrder = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.createMenuOrderRaw(requestParameters)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Used to get all dpay related data for the admin to generate csv reports.
     */
    MenuOrdersApi.prototype.generateMenuOrderDpayDataRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("Admin", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/menu-orders/admin/generate-dpay-data",
                                method: 'POST',
                                headers: headerParameters,
                                query: queryParameters,
                                body: (0, models_1.CreateDpayCsvDataBodyToJSON)(requestParameters.createDpayCsvDataBody),
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.GetMenuOrdersDpayDataResponseFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Used to get all dpay related data for the admin to generate csv reports.
     */
    MenuOrdersApi.prototype.generateMenuOrderDpayData = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.generateMenuOrderDpayDataRaw(requestParameters)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Used to get menu order
     */
    MenuOrdersApi.prototype.getMenuOrderRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.id === null || requestParameters.id === undefined) {
                            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getMenuOrder.');
                        }
                        queryParameters = {};
                        if (requestParameters.validateOrderItems !== undefined) {
                            queryParameters['validateOrderItems'] = requestParameters.validateOrderItems;
                        }
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/menu-orders/{id}".replace("{".concat("id", "}"), encodeURIComponent(String(requestParameters.id))),
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.MenuOrderFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Used to get menu order
     */
    MenuOrdersApi.prototype.getMenuOrder = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getMenuOrderRaw(requestParameters)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Used by admin to get menu order
     */
    MenuOrdersApi.prototype.getMenuOrderAdminRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.id === null || requestParameters.id === undefined) {
                            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getMenuOrderAdmin.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("Discriminated", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/menu-orders/{id}/admin".replace("{".concat("id", "}"), encodeURIComponent(String(requestParameters.id))),
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.MenuOrderFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Used by admin to get menu order
     */
    MenuOrdersApi.prototype.getMenuOrderAdmin = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getMenuOrderAdminRaw(requestParameters)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Used by customer to get the chat log between customer & support for a menu order
     */
    MenuOrdersApi.prototype.getMenuOrderChatRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.id === null || requestParameters.id === undefined) {
                            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getMenuOrderChat.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("Admin", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/menu-orders/{id}/chat".replace("{".concat("id", "}"), encodeURIComponent(String(requestParameters.id))),
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.InlineResponse20013FromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Used by customer to get the chat log between customer & support for a menu order
     */
    MenuOrdersApi.prototype.getMenuOrderChat = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getMenuOrderChatRaw(requestParameters)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Used by customer to get a list of qualifying discounts for their menu order
     */
    MenuOrdersApi.prototype.getMenuOrderDiscountsRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.id === null || requestParameters.id === undefined) {
                            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getMenuOrderDiscounts.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("User", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/menu-orders/{id}/discounts".replace("{".concat("id", "}"), encodeURIComponent(String(requestParameters.id))),
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.InlineResponse20014FromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Used by customer to get a list of qualifying discounts for their menu order
     */
    MenuOrdersApi.prototype.getMenuOrderDiscounts = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getMenuOrderDiscountsRaw(requestParameters)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Used to validate a menu order prior to calling blockchain functions.
     */
    MenuOrdersApi.prototype.getMenuOrderValidationRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.id === null || requestParameters.id === undefined) {
                            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getMenuOrderValidation.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("User", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/menu-orders/validate/{id}".replace("{".concat("id", "}"), encodeURIComponent(String(requestParameters.id))),
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * Used to validate a menu order prior to calling blockchain functions.
     */
    MenuOrdersApi.prototype.getMenuOrderValidation = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getMenuOrderValidationRaw(requestParameters)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Used by admins to get a list of all menu orders in the system
     */
    MenuOrdersApi.prototype.getMenuOrdersAllRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        if (requestParameters.orderId !== undefined) {
                            queryParameters['orderId'] = requestParameters.orderId;
                        }
                        if (requestParameters.business !== undefined) {
                            queryParameters['business'] = requestParameters.business;
                        }
                        if (requestParameters.discount !== undefined) {
                            queryParameters['discount'] = requestParameters.discount;
                        }
                        if (requestParameters.customer !== undefined) {
                            queryParameters['customer'] = requestParameters.customer;
                        }
                        if (requestParameters.recipient !== undefined) {
                            queryParameters['recipient'] = requestParameters.recipient;
                        }
                        if (requestParameters.dateStart !== undefined) {
                            queryParameters['dateStart'] = requestParameters.dateStart;
                        }
                        if (requestParameters.dateEnd !== undefined) {
                            queryParameters['dateEnd'] = requestParameters.dateEnd;
                        }
                        if (requestParameters.status) {
                            queryParameters['status'] = requestParameters.status;
                        }
                        if (requestParameters.sort !== undefined) {
                            queryParameters['sort'] = requestParameters.sort;
                        }
                        if (requestParameters.order !== undefined) {
                            queryParameters['order'] = requestParameters.order;
                        }
                        if (requestParameters.limit !== undefined) {
                            queryParameters['limit'] = requestParameters.limit;
                        }
                        if (requestParameters.offset !== undefined) {
                            queryParameters['offset'] = requestParameters.offset;
                        }
                        if (requestParameters.numberOfAvailableOffsets !== undefined) {
                            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
                        }
                        headerParameters = {};
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("Discriminated", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/menu-orders/all/search",
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.GetMenuOrdersResponseFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Used by admins to get a list of all menu orders in the system
     */
    MenuOrdersApi.prototype.getMenuOrdersAll = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getMenuOrdersAllRaw(requestParameters)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Used by user to get a list of all their menu orders
     */
    MenuOrdersApi.prototype.getMenuOrdersOwnRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        if (requestParameters.status) {
                            queryParameters['status'] = requestParameters.status;
                        }
                        if (requestParameters.sort !== undefined) {
                            queryParameters['sort'] = requestParameters.sort;
                        }
                        if (requestParameters.order !== undefined) {
                            queryParameters['order'] = requestParameters.order;
                        }
                        if (requestParameters.limit !== undefined) {
                            queryParameters['limit'] = requestParameters.limit;
                        }
                        if (requestParameters.offset !== undefined) {
                            queryParameters['offset'] = requestParameters.offset;
                        }
                        if (requestParameters.numberOfAvailableOffsets !== undefined) {
                            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
                        }
                        headerParameters = {};
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("User", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/menu-orders/own/search",
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.GetMenuOrdersOwnResponseFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Used by user to get a list of all their menu orders
     */
    MenuOrdersApi.prototype.getMenuOrdersOwn = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getMenuOrdersOwnRaw(requestParameters)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Used by admin to trigger an auto refund if the order has an error or was cancelled.
     */
    MenuOrdersApi.prototype.refundAutoMenuOrderRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.id === null || requestParameters.id === undefined) {
                            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling refundAutoMenuOrder.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("Discriminated", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/menu-orders/{id}/refund-auto".replace("{".concat("id", "}"), encodeURIComponent(String(requestParameters.id))),
                                method: 'POST',
                                headers: headerParameters,
                                query: queryParameters,
                                body: (0, models_1.RefundAutoMenuOrderBodyToJSON)(requestParameters.refundAutoMenuOrderBody),
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * Used by admin to trigger an auto refund if the order has an error or was cancelled.
     */
    MenuOrdersApi.prototype.refundAutoMenuOrder = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.refundAutoMenuOrderRaw(requestParameters)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Used by admin to refund a menu order.
     */
    MenuOrdersApi.prototype.refundMenuOrderRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.id === null || requestParameters.id === undefined) {
                            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling refundMenuOrder.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("Discriminated", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/menu-orders/{id}/refund".replace("{".concat("id", "}"), encodeURIComponent(String(requestParameters.id))),
                                method: 'POST',
                                headers: headerParameters,
                                query: queryParameters,
                                body: (0, models_1.OrderRefundBodyToJSON)(requestParameters.orderRefundBody),
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * Used by admin to refund a menu order.
     */
    MenuOrdersApi.prototype.refundMenuOrder = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.refundMenuOrderRaw(requestParameters)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Used to submit the menu order to the system
     */
    MenuOrdersApi.prototype.submitMenuOrderRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        if (requestParameters.brandMapSlug !== undefined) {
                            queryParameters['brandMapSlug'] = requestParameters.brandMapSlug;
                        }
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("User", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/menu-orders/submit",
                                method: 'POST',
                                headers: headerParameters,
                                query: queryParameters,
                                body: (0, models_1.SubmitMenuOrderBodyToJSON)(requestParameters.submitMenuOrderBody),
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.InlineResponse20015FromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Used to submit the menu order to the system
     */
    MenuOrdersApi.prototype.submitMenuOrder = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.submitMenuOrderRaw(requestParameters)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Used by admin to pay a merchant for a menu order that was paid to Devour.
     */
    MenuOrdersApi.prototype.transferPaymentMenuOrderRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.id === null || requestParameters.id === undefined) {
                            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling transferPaymentMenuOrder.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("Admin", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/menu-orders/{id}/transfer-payment".replace("{".concat("id", "}"), encodeURIComponent(String(requestParameters.id))),
                                method: 'POST',
                                headers: headerParameters,
                                query: queryParameters,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * Used by admin to pay a merchant for a menu order that was paid to Devour.
     */
    MenuOrdersApi.prototype.transferPaymentMenuOrder = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.transferPaymentMenuOrderRaw(requestParameters)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Used to update menu order
     */
    MenuOrdersApi.prototype.updateMenuOrderRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.id === null || requestParameters.id === undefined) {
                            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling updateMenuOrder.');
                        }
                        queryParameters = {};
                        if (requestParameters.backgroundCallback !== undefined) {
                            queryParameters['backgroundCallback'] = requestParameters.backgroundCallback;
                        }
                        if (requestParameters.skipOnError !== undefined) {
                            queryParameters['skipOnError'] = requestParameters.skipOnError;
                        }
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        return [4 /*yield*/, this.request({
                                path: "/menu-orders/{id}".replace("{".concat("id", "}"), encodeURIComponent(String(requestParameters.id))),
                                method: 'PUT',
                                headers: headerParameters,
                                query: queryParameters,
                                body: (0, models_1.CreateMenuOrderBodyToJSON)(requestParameters.createMenuOrderBody),
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.CreateMenuOrderResponseFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Used to update menu order
     */
    MenuOrdersApi.prototype.updateMenuOrder = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.updateMenuOrderRaw(requestParameters)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Verify that the payment has been successfully processed and redirects the customer to the order success / fail page.
     */
    MenuOrdersApi.prototype.verifyMenuOrderRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.id === null || requestParameters.id === undefined) {
                            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling verifyMenuOrder.');
                        }
                        queryParameters = {};
                        if (requestParameters.brandMapSlug !== undefined) {
                            queryParameters['brandMapSlug'] = requestParameters.brandMapSlug;
                        }
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/menu-orders/{id}/verify".replace("{".concat("id", "}"), encodeURIComponent(String(requestParameters.id))),
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * Verify that the payment has been successfully processed and redirects the customer to the order success / fail page.
     */
    MenuOrdersApi.prototype.verifyMenuOrder = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.verifyMenuOrderRaw(requestParameters)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return MenuOrdersApi;
}(runtime.BaseAPI));
exports.MenuOrdersApi = MenuOrdersApi;
