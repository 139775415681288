"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetSEOResponseToJSON = exports.GetSEOResponseFromJSONTyped = exports.GetSEOResponseFromJSON = void 0;
var runtime_1 = require("../runtime");
function GetSEOResponseFromJSON(json) {
    return GetSEOResponseFromJSONTyped(json, false);
}
exports.GetSEOResponseFromJSON = GetSEOResponseFromJSON;
function GetSEOResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'url': json['url'],
        'urlEntryExists': json['urlEntryExists'],
        'tags': !(0, runtime_1.exists)(json, 'tags') ? undefined : json['tags'],
    };
}
exports.GetSEOResponseFromJSONTyped = GetSEOResponseFromJSONTyped;
function GetSEOResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'url': value.url,
        'urlEntryExists': value.urlEntryExists,
        'tags': value.tags,
    };
}
exports.GetSEOResponseToJSON = GetSEOResponseToJSON;
