import React, {ChangeEventHandler, ReactElement} from "react";
import {CreateBrandMapBodyFrontend} from "../../pages/brands/components/BrandMapFormValues";


interface Props {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    value: string;
}

function MerchantBrandHandleName(props: Props): ReactElement {

    return (
        <div className="merchant-brand-title-description">
            <h4>Page Setup</h4>
            <div className="merchant-brand-title-description_content">
                <h5>
                    Handle Name
                    <span className="create-map-page_optional">
						Required
					</span>
                </h5>

                <p className="merchant-brand-title-description_content_subtitle">
                    Choose your unique handle name by adding letters and numbers. Full URL will look like this:
                </p>
                <input
                    placeholder="@brandname"
                    value={props.value}
                    onChange={props.onChange}
                />

                <div className="merchant-brand-handle-name_url-preview">
                    <p className="merchant-brand-handle-name_url-preview_subheader">URL Preview</p>
                    <div className="merchant-brand-handle-name_url-preview_container">
                        <p>{`${process.env.REACT_APP_WEB_URL}/brands/${props.value}`}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MerchantBrandHandleName;