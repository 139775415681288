"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GleamEntryUploadToJSON = exports.GleamEntryUploadFromJSONTyped = exports.GleamEntryUploadFromJSON = void 0;
function GleamEntryUploadFromJSON(json) {
    return GleamEntryUploadFromJSONTyped(json, false);
}
exports.GleamEntryUploadFromJSON = GleamEntryUploadFromJSON;
function GleamEntryUploadFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'original_url': json['original_url'],
        'thumb_url': json['thumb_url'],
        'post_url': json['post_url'],
        'post_text': json['post_text'],
        'content_type': json['content_type'],
    };
}
exports.GleamEntryUploadFromJSONTyped = GleamEntryUploadFromJSONTyped;
function GleamEntryUploadToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'original_url': value.original_url,
        'thumb_url': value.thumb_url,
        'post_url': value.post_url,
        'post_text': value.post_text,
        'content_type': value.content_type,
    };
}
exports.GleamEntryUploadToJSON = GleamEntryUploadToJSON;
