"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdatedOwnershipsToJSON = exports.UpdatedOwnershipsFromJSONTyped = exports.UpdatedOwnershipsFromJSON = void 0;
function UpdatedOwnershipsFromJSON(json) {
    return UpdatedOwnershipsFromJSONTyped(json, false);
}
exports.UpdatedOwnershipsFromJSON = UpdatedOwnershipsFromJSON;
function UpdatedOwnershipsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'isRecorded': json['isRecorded'],
        'trackerId': json['trackerId'],
        'trackerName': json['trackerName'],
        'createdOwnershipIds': json['createdOwnershipIds'],
        'deletedOwnershipIds': json['deletedOwnershipIds'],
    };
}
exports.UpdatedOwnershipsFromJSONTyped = UpdatedOwnershipsFromJSONTyped;
function UpdatedOwnershipsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'isRecorded': value.isRecorded,
        'trackerId': value.trackerId,
        'trackerName': value.trackerName,
        'createdOwnershipIds': value.createdOwnershipIds,
        'deletedOwnershipIds': value.deletedOwnershipIds,
    };
}
exports.UpdatedOwnershipsToJSON = UpdatedOwnershipsToJSON;
