"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RefundAutoMenuOrderBodyToJSON = exports.RefundAutoMenuOrderBodyFromJSONTyped = exports.RefundAutoMenuOrderBodyFromJSON = void 0;
var _1 = require("./");
function RefundAutoMenuOrderBodyFromJSON(json) {
    return RefundAutoMenuOrderBodyFromJSONTyped(json, false);
}
exports.RefundAutoMenuOrderBodyFromJSON = RefundAutoMenuOrderBodyFromJSON;
function RefundAutoMenuOrderBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'responsibility': (0, _1.OrderRefundResponsibilityFromJSON)(json['responsibility']),
        'errorMessage': json['errorMessage'],
    };
}
exports.RefundAutoMenuOrderBodyFromJSONTyped = RefundAutoMenuOrderBodyFromJSONTyped;
function RefundAutoMenuOrderBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'responsibility': (0, _1.OrderRefundResponsibilityToJSON)(value.responsibility),
        'errorMessage': value.errorMessage,
    };
}
exports.RefundAutoMenuOrderBodyToJSON = RefundAutoMenuOrderBodyToJSON;
