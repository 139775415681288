"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetUserCommunityMembershipResponseToJSON = exports.GetUserCommunityMembershipResponseFromJSONTyped = exports.GetUserCommunityMembershipResponseFromJSON = void 0;
function GetUserCommunityMembershipResponseFromJSON(json) {
    return GetUserCommunityMembershipResponseFromJSONTyped(json, false);
}
exports.GetUserCommunityMembershipResponseFromJSON = GetUserCommunityMembershipResponseFromJSON;
function GetUserCommunityMembershipResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'community': json['community'],
        'rankWithinCommunity': json['rankWithinCommunity'],
        'pointsEarnedForCommunity': json['pointsEarnedForCommunity'],
        'nftGroupingId': json['nftGroupingId'],
    };
}
exports.GetUserCommunityMembershipResponseFromJSONTyped = GetUserCommunityMembershipResponseFromJSONTyped;
function GetUserCommunityMembershipResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'community': value.community,
        'rankWithinCommunity': value.rankWithinCommunity,
        'pointsEarnedForCommunity': value.pointsEarnedForCommunity,
        'nftGroupingId': value.nftGroupingId,
    };
}
exports.GetUserCommunityMembershipResponseToJSON = GetUserCommunityMembershipResponseToJSON;
