"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OWTimeEventInfoToJSON = exports.OWTimeEventInfoFromJSONTyped = exports.OWTimeEventInfoFromJSON = void 0;
function OWTimeEventInfoFromJSON(json) {
    return OWTimeEventInfoFromJSONTyped(json, false);
}
exports.OWTimeEventInfoFromJSON = OWTimeEventInfoFromJSON;
function OWTimeEventInfoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'event': json['event'],
        'minutesPlayed': json['minutesPlayed'],
    };
}
exports.OWTimeEventInfoFromJSONTyped = OWTimeEventInfoFromJSONTyped;
function OWTimeEventInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'event': value.event,
        'minutesPlayed': value.minutesPlayed,
    };
}
exports.OWTimeEventInfoToJSON = OWTimeEventInfoToJSON;
