import React, {ReactElement} from "react";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameOneModal from "./modalComponents/FrameOneModal";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import {BrandMap, BrandMapStatus} from "@devour/client";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import FrameButton, {FrameOneButtonType} from "../buttons/FrameButton";
import {LinkProps} from "react-router-dom";

interface Props {
	isOpen: boolean;
	brandMap: BrandMap;
	new: boolean;
	originalBrandMap?: BrandMap;
	onContinueEditing?: () => void;
}

function BrandeMapSaveDraftSuccessModal(props: Props): ReactElement {
	const mapName = props.brandMap?.name;

	return (
		<FrameOneModal
			isOpen={props.isOpen}
			size="sm"
		>
			<FrameModalHeader title="Draft saved successfully!"/>
			<FrameModalBody>
				{props.new ? (
					<p>
						{`Your new map${mapName && `, '${mapName}'`} was successfully saved as a draft.`}
					</p>
				) : (
					<React.Fragment>
						{props.originalBrandMap?.status === BrandMapStatus.DRAFT ? (
							<p>
								{`Your map${mapName && `, '${mapName}'`} has been successfully updated.`}
							</p>
						) : (
							<p>
								{`Your map${mapName && `, '${mapName}'`} was updated & reverted to a draft.`}
							</p>
						)}
					</React.Fragment>
				)}
			</FrameModalBody>

			<FrameModalFooter>
				{props.new ? (
					<FrameButton
						<LinkProps>
						color="purple"
						size="normal"
						// to={`/brands/maps/${props.brandMap?.id}/edit`}
						forwardProps={{
							to: `/brands/maps/${props.brandMap?.slug}/edit`,
							replace: true
						}}
					>
						Continue Editing
					</FrameButton>
				) : (
					<FrameButton
						color="purple"
						size="normal"
						onClick={props.onContinueEditing}
					>
						Continue Editing
					</FrameButton>
				)}

				{/*<FrameButton*/}
				{/*	color="purple"*/}
				{/*	size="normal"*/}
				{/*	to={`/brands/maps/${props.brandMap?.id}/edit`}*/}
				{/*	onClick={props.onContinueEditing || undefined}*/}
				{/*>*/}
				{/*	Continue Editing*/}
				{/*</FrameButton>*/}

				<FrameButton
					color="purple-outline"
					size="normal"
					to={`/brands/${props.brandMap?.brand?.id}/maps`}
				>
					Return to My Maps
				</FrameButton>
			</FrameModalFooter>
		</FrameOneModal>
	);
}

export default BrandeMapSaveDraftSuccessModal;