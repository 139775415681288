"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValidateDeliveryDistanceResponseToJSON = exports.ValidateDeliveryDistanceResponseFromJSONTyped = exports.ValidateDeliveryDistanceResponseFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function ValidateDeliveryDistanceResponseFromJSON(json) {
    return ValidateDeliveryDistanceResponseFromJSONTyped(json, false);
}
exports.ValidateDeliveryDistanceResponseFromJSON = ValidateDeliveryDistanceResponseFromJSON;
function ValidateDeliveryDistanceResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'canDeliver': json['canDeliver'],
        'reason': !(0, runtime_1.exists)(json, 'reason') ? undefined : (0, _1.ValidateDeliveryDistanceReasonFromJSON)(json['reason']),
    };
}
exports.ValidateDeliveryDistanceResponseFromJSONTyped = ValidateDeliveryDistanceResponseFromJSONTyped;
function ValidateDeliveryDistanceResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'canDeliver': value.canDeliver,
        'reason': (0, _1.ValidateDeliveryDistanceReasonToJSON)(value.reason),
    };
}
exports.ValidateDeliveryDistanceResponseToJSON = ValidateDeliveryDistanceResponseToJSON;
