import React, {ReactElement} from "react";
import {AiOutlineSearch} from "react-icons/ai";
import {IconType} from "react-icons";
import {omit} from "lodash";
import {FiX} from "react-icons/fi";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
	icon?: IconType;
	onClear?: () => void;
}

function SearchInput(props: Props): ReactElement {

	const Icon = props.icon;

	return (
		<div className="search-input">
			<Icon/>

			<input {...omit(props, "icon", "onClear")}/>

			{props.value?.toString()?.length > 0 && props.onClear && (
				<div className="search-input_clearer">
					<FiX onClick={props.onClear}/>
				</div>
			)}
		</div>
	);
}

SearchInput.defaultProps = {
	icon: AiOutlineSearch,
	placeholder: "Search...",
} as Props;

export default SearchInput;