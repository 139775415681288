"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateMenuCategoryBodyToJSON = exports.CreateMenuCategoryBodyFromJSONTyped = exports.CreateMenuCategoryBodyFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function CreateMenuCategoryBodyFromJSON(json) {
    return CreateMenuCategoryBodyFromJSONTyped(json, false);
}
exports.CreateMenuCategoryBodyFromJSON = CreateMenuCategoryBodyFromJSON;
function CreateMenuCategoryBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'parent': !(0, runtime_1.exists)(json, 'parent') ? undefined : json['parent'],
        'business': !(0, runtime_1.exists)(json, 'business') ? undefined : json['business'],
        'name': json['name'],
        'sortOrder': !(0, runtime_1.exists)(json, 'sortOrder') ? undefined : json['sortOrder'],
        'isEnabled': !(0, runtime_1.exists)(json, 'isEnabled') ? undefined : json['isEnabled'],
        'serviceAvailabilities': !(0, runtime_1.exists)(json, 'serviceAvailabilities') ? undefined : (json['serviceAvailabilities'].map(_1.BusinessServiceAvailabilityFromJSON)),
    };
}
exports.CreateMenuCategoryBodyFromJSONTyped = CreateMenuCategoryBodyFromJSONTyped;
function CreateMenuCategoryBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'parent': value.parent,
        'business': value.business,
        'name': value.name,
        'sortOrder': value.sortOrder,
        'isEnabled': value.isEnabled,
        'serviceAvailabilities': value.serviceAvailabilities === undefined ? undefined : (value.serviceAvailabilities.map(_1.BusinessServiceAvailabilityToJSON)),
    };
}
exports.CreateMenuCategoryBodyToJSON = CreateMenuCategoryBodyToJSON;
