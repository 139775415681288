"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GameDataBodyToJSON = exports.GameDataBodyFromJSONTyped = exports.GameDataBodyFromJSON = void 0;
function GameDataBodyFromJSON(json) {
    return GameDataBodyFromJSONTyped(json, false);
}
exports.GameDataBodyFromJSON = GameDataBodyFromJSON;
function GameDataBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'gameId': json['gameId'],
        'gameData': json['gameData'],
    };
}
exports.GameDataBodyFromJSONTyped = GameDataBodyFromJSONTyped;
function GameDataBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'gameId': value.gameId,
        'gameData': value.gameData,
    };
}
exports.GameDataBodyToJSON = GameDataBodyToJSON;
