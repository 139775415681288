/**
 * Convert underscore to space and capitalize first letter of every word
 * @param str
 */
export function underscoreToCapitalize(str: string): string {
	const frags = str.toLowerCase().split('_');
	for (let i = 0; i < frags.length; i++) {
		frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
	}
	return frags.join(' ');
}
