"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BrandMapBannerImageTypeToJSON = exports.BrandMapBannerImageTypeFromJSONTyped = exports.BrandMapBannerImageTypeFromJSON = exports.BrandMapBannerImageType = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var BrandMapBannerImageType;
(function (BrandMapBannerImageType) {
    BrandMapBannerImageType["DIAMOND"] = "DIAMOND";
    BrandMapBannerImageType["RECTANGLE"] = "RECTANGLE";
})(BrandMapBannerImageType = exports.BrandMapBannerImageType || (exports.BrandMapBannerImageType = {}));
function BrandMapBannerImageTypeFromJSON(json) {
    return BrandMapBannerImageTypeFromJSONTyped(json, false);
}
exports.BrandMapBannerImageTypeFromJSON = BrandMapBannerImageTypeFromJSON;
function BrandMapBannerImageTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.BrandMapBannerImageTypeFromJSONTyped = BrandMapBannerImageTypeFromJSONTyped;
function BrandMapBannerImageTypeToJSON(value) {
    return value;
}
exports.BrandMapBannerImageTypeToJSON = BrandMapBannerImageTypeToJSON;
