"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetBrandMapsResponseToJSON = exports.GetBrandMapsResponseFromJSONTyped = exports.GetBrandMapsResponseFromJSON = void 0;
var _1 = require("./");
function GetBrandMapsResponseFromJSON(json) {
    return GetBrandMapsResponseFromJSONTyped(json, false);
}
exports.GetBrandMapsResponseFromJSON = GetBrandMapsResponseFromJSON;
function GetBrandMapsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return __assign(__assign({}, (0, _1.PaginatedResponseFromJSONTyped)(json, ignoreDiscriminator)), { 'brandMaps': (json['brandMaps'].map(_1.BrandMapFromJSON)) });
}
exports.GetBrandMapsResponseFromJSONTyped = GetBrandMapsResponseFromJSONTyped;
function GetBrandMapsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return __assign(__assign({}, (0, _1.PaginatedResponseToJSON)(value)), { 'brandMaps': (value.brandMaps.map(_1.BrandMapToJSON)) });
}
exports.GetBrandMapsResponseToJSON = GetBrandMapsResponseToJSON;
