"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ItsaCheckmateWebhookBodyToJSON = exports.ItsaCheckmateWebhookBodyFromJSONTyped = exports.ItsaCheckmateWebhookBodyFromJSON = void 0;
function ItsaCheckmateWebhookBodyFromJSON(json) {
    return ItsaCheckmateWebhookBodyFromJSONTyped(json, false);
}
exports.ItsaCheckmateWebhookBodyFromJSON = ItsaCheckmateWebhookBodyFromJSON;
function ItsaCheckmateWebhookBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'location_id': json['location_id'],
    };
}
exports.ItsaCheckmateWebhookBodyFromJSONTyped = ItsaCheckmateWebhookBodyFromJSONTyped;
function ItsaCheckmateWebhookBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'location_id': value.location_id,
    };
}
exports.ItsaCheckmateWebhookBodyToJSON = ItsaCheckmateWebhookBodyToJSON;
