"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommunityMemberToJSON = exports.CommunityMemberFromJSONTyped = exports.CommunityMemberFromJSON = void 0;
function CommunityMemberFromJSON(json) {
    return CommunityMemberFromJSONTyped(json, false);
}
exports.CommunityMemberFromJSON = CommunityMemberFromJSON;
function CommunityMemberFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'nickname': json['nickname'],
        'email': json['email'],
        'userRank': json['userRank'],
        'pointsEarnedForCommunity': json['pointsEarnedForCommunity'],
    };
}
exports.CommunityMemberFromJSONTyped = CommunityMemberFromJSONTyped;
function CommunityMemberToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'nickname': value.nickname,
        'email': value.email,
        'userRank': value.userRank,
        'pointsEarnedForCommunity': value.pointsEarnedForCommunity,
    };
}
exports.CommunityMemberToJSON = CommunityMemberToJSON;
