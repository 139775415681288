"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetBusinessOwnSortToJSON = exports.GetBusinessOwnSortFromJSONTyped = exports.GetBusinessOwnSortFromJSON = exports.GetBusinessOwnSort = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var GetBusinessOwnSort;
(function (GetBusinessOwnSort) {
    GetBusinessOwnSort["CreatedAt"] = "createdAt";
    GetBusinessOwnSort["Name"] = "name";
})(GetBusinessOwnSort = exports.GetBusinessOwnSort || (exports.GetBusinessOwnSort = {}));
function GetBusinessOwnSortFromJSON(json) {
    return GetBusinessOwnSortFromJSONTyped(json, false);
}
exports.GetBusinessOwnSortFromJSON = GetBusinessOwnSortFromJSON;
function GetBusinessOwnSortFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.GetBusinessOwnSortFromJSONTyped = GetBusinessOwnSortFromJSONTyped;
function GetBusinessOwnSortToJSON(value) {
    return value;
}
exports.GetBusinessOwnSortToJSON = GetBusinessOwnSortToJSON;
