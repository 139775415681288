"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchRestaurantsQueryToJSON = exports.SearchRestaurantsQueryFromJSONTyped = exports.SearchRestaurantsQueryFromJSON = void 0;
var runtime_1 = require("../runtime");
function SearchRestaurantsQueryFromJSON(json) {
    return SearchRestaurantsQueryFromJSONTyped(json, false);
}
exports.SearchRestaurantsQueryFromJSON = SearchRestaurantsQueryFromJSON;
function SearchRestaurantsQueryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'taxonomyIds': !(0, runtime_1.exists)(json, 'taxonomyIds') ? undefined : json['taxonomyIds'],
        'search': !(0, runtime_1.exists)(json, 'search') ? undefined : json['search'],
        'addressId': !(0, runtime_1.exists)(json, 'addressId') ? undefined : json['addressId'],
        'placeId': !(0, runtime_1.exists)(json, 'placeId') ? undefined : json['placeId'],
        'formattedAddress': !(0, runtime_1.exists)(json, 'formattedAddress') ? undefined : json['formattedAddress'],
        'handoff': !(0, runtime_1.exists)(json, 'handoff') ? undefined : json['handoff'],
    };
}
exports.SearchRestaurantsQueryFromJSONTyped = SearchRestaurantsQueryFromJSONTyped;
function SearchRestaurantsQueryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'taxonomyIds': value.taxonomyIds,
        'search': value.search,
        'addressId': value.addressId,
        'placeId': value.placeId,
        'formattedAddress': value.formattedAddress,
        'handoff': value.handoff,
    };
}
exports.SearchRestaurantsQueryToJSON = SearchRestaurantsQueryToJSON;
