"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChatMessageToJSON = exports.ChatMessageFromJSONTyped = exports.ChatMessageFromJSON = void 0;
var runtime_1 = require("../runtime");
function ChatMessageFromJSON(json) {
    return ChatMessageFromJSONTyped(json, false);
}
exports.ChatMessageFromJSON = ChatMessageFromJSON;
function ChatMessageFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'sender': !(0, runtime_1.exists)(json, 'sender') ? undefined : json['sender'],
        'senderName': json['senderName'],
        'menuOrder': !(0, runtime_1.exists)(json, 'menuOrder') ? undefined : json['menuOrder'],
        'message': json['message'],
        'isSupportAgent': json['isSupportAgent'],
    };
}
exports.ChatMessageFromJSONTyped = ChatMessageFromJSONTyped;
function ChatMessageToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'sender': value.sender,
        'senderName': value.senderName,
        'menuOrder': value.menuOrder,
        'message': value.message,
        'isSupportAgent': value.isSupportAgent,
    };
}
exports.ChatMessageToJSON = ChatMessageToJSON;
