"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetBrandsSortToJSON = exports.GetBrandsSortFromJSONTyped = exports.GetBrandsSortFromJSON = exports.GetBrandsSort = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var GetBrandsSort;
(function (GetBrandsSort) {
    GetBrandsSort["CreatedAt"] = "createdAt";
    GetBrandsSort["Name"] = "name";
})(GetBrandsSort = exports.GetBrandsSort || (exports.GetBrandsSort = {}));
function GetBrandsSortFromJSON(json) {
    return GetBrandsSortFromJSONTyped(json, false);
}
exports.GetBrandsSortFromJSON = GetBrandsSortFromJSON;
function GetBrandsSortFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.GetBrandsSortFromJSONTyped = GetBrandsSortFromJSONTyped;
function GetBrandsSortToJSON(value) {
    return value;
}
exports.GetBrandsSortToJSON = GetBrandsSortToJSON;
