"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateSEOBodyToJSON = exports.CreateSEOBodyFromJSONTyped = exports.CreateSEOBodyFromJSON = void 0;
function CreateSEOBodyFromJSON(json) {
    return CreateSEOBodyFromJSONTyped(json, false);
}
exports.CreateSEOBodyFromJSON = CreateSEOBodyFromJSON;
function CreateSEOBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'url': json['url'],
        'tags': json['tags'],
    };
}
exports.CreateSEOBodyFromJSONTyped = CreateSEOBodyFromJSONTyped;
function CreateSEOBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'url': value.url,
        'tags': value.tags,
    };
}
exports.CreateSEOBodyToJSON = CreateSEOBodyToJSON;
