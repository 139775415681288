"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RelationshipTypeToJSON = exports.RelationshipTypeFromJSONTyped = exports.RelationshipTypeFromJSON = exports.RelationshipType = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var RelationshipType;
(function (RelationshipType) {
    RelationshipType["REFERRAL"] = "REFERRAL";
    RelationshipType["AFFILIATE"] = "AFFILIATE";
})(RelationshipType = exports.RelationshipType || (exports.RelationshipType = {}));
function RelationshipTypeFromJSON(json) {
    return RelationshipTypeFromJSONTyped(json, false);
}
exports.RelationshipTypeFromJSON = RelationshipTypeFromJSON;
function RelationshipTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.RelationshipTypeFromJSONTyped = RelationshipTypeFromJSONTyped;
function RelationshipTypeToJSON(value) {
    return value;
}
exports.RelationshipTypeToJSON = RelationshipTypeToJSON;
