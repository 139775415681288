"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetUserStatsResponseToJSON = exports.GetUserStatsResponseFromJSONTyped = exports.GetUserStatsResponseFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function GetUserStatsResponseFromJSON(json) {
    return GetUserStatsResponseFromJSONTyped(json, false);
}
exports.GetUserStatsResponseFromJSON = GetUserStatsResponseFromJSON;
function GetUserStatsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'message': !(0, runtime_1.exists)(json, 'message') ? undefined : json['message'],
        'userStats': !(0, runtime_1.exists)(json, 'userStats') ? undefined : (json['userStats'].map(_1.OWUserStatFromJSON)),
        'uniqueGameIds': !(0, runtime_1.exists)(json, 'uniqueGameIds') ? undefined : json['uniqueGameIds'],
    };
}
exports.GetUserStatsResponseFromJSONTyped = GetUserStatsResponseFromJSONTyped;
function GetUserStatsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'message': value.message,
        'userStats': value.userStats === undefined ? undefined : (value.userStats.map(_1.OWUserStatToJSON)),
        'uniqueGameIds': value.uniqueGameIds,
    };
}
exports.GetUserStatsResponseToJSON = GetUserStatsResponseToJSON;
