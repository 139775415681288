"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.NftOwnershipInformationToJSON = exports.NftOwnershipInformationFromJSONTyped = exports.NftOwnershipInformationFromJSON = void 0;
var _1 = require("./");
function NftOwnershipInformationFromJSON(json) {
    return NftOwnershipInformationFromJSONTyped(json, false);
}
exports.NftOwnershipInformationFromJSON = NftOwnershipInformationFromJSON;
function NftOwnershipInformationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'userIds': json['userIds'],
        'nftOwnerships': (json['nftOwnerships'].map(_1.NftOwnershipMatrixFromJSON)),
        'myGroupings': (json['myGroupings'].map(_1.NftGroupingFromJSON)),
        'nftGroupings': (json['nftGroupings'].map(_1.NftGroupingFromJSON)),
        'nftTrackers': (json['nftTrackers'].map(_1.NftTrackerFromJSON)),
    };
}
exports.NftOwnershipInformationFromJSONTyped = NftOwnershipInformationFromJSONTyped;
function NftOwnershipInformationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'userIds': value.userIds,
        'nftOwnerships': (value.nftOwnerships.map(_1.NftOwnershipMatrixToJSON)),
        'myGroupings': (value.myGroupings.map(_1.NftGroupingToJSON)),
        'nftGroupings': (value.nftGroupings.map(_1.NftGroupingToJSON)),
        'nftTrackers': (value.nftTrackers.map(_1.NftTrackerToJSON)),
    };
}
exports.NftOwnershipInformationToJSON = NftOwnershipInformationToJSON;
