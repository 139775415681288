"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReferralToJSON = exports.ReferralFromJSONTyped = exports.ReferralFromJSON = void 0;
var runtime_1 = require("../runtime");
function ReferralFromJSON(json) {
    return ReferralFromJSONTyped(json, false);
}
exports.ReferralFromJSON = ReferralFromJSON;
function ReferralFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'referrerId': json['referrerId'],
        'referrerEmail': json['referrerEmail'],
        'count': !(0, runtime_1.exists)(json, 'count') ? undefined : json['count'],
        'referredUsers': json['referredUsers'],
    };
}
exports.ReferralFromJSONTyped = ReferralFromJSONTyped;
function ReferralToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'referrerId': value.referrerId,
        'referrerEmail': value.referrerEmail,
        'count': value.count,
        'referredUsers': value.referredUsers,
    };
}
exports.ReferralToJSON = ReferralToJSON;
