"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DisableBusinessBodyToJSON = exports.DisableBusinessBodyFromJSONTyped = exports.DisableBusinessBodyFromJSON = void 0;
function DisableBusinessBodyFromJSON(json) {
    return DisableBusinessBodyFromJSONTyped(json, false);
}
exports.DisableBusinessBodyFromJSON = DisableBusinessBodyFromJSON;
function DisableBusinessBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'disableReason': json['disableReason'],
    };
}
exports.DisableBusinessBodyFromJSONTyped = DisableBusinessBodyFromJSONTyped;
function DisableBusinessBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'disableReason': value.disableReason,
    };
}
exports.DisableBusinessBodyToJSON = DisableBusinessBodyToJSON;
