"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChowlyWebhookUniversalPayloadToJSON = exports.ChowlyWebhookUniversalPayloadFromJSONTyped = exports.ChowlyWebhookUniversalPayloadFromJSON = void 0;
function ChowlyWebhookUniversalPayloadFromJSON(json) {
    return ChowlyWebhookUniversalPayloadFromJSONTyped(json, false);
}
exports.ChowlyWebhookUniversalPayloadFromJSON = ChowlyWebhookUniversalPayloadFromJSON;
function ChowlyWebhookUniversalPayloadFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'entity_id': json['entity_id'],
        'error_message': json['error_message'],
    };
}
exports.ChowlyWebhookUniversalPayloadFromJSONTyped = ChowlyWebhookUniversalPayloadFromJSONTyped;
function ChowlyWebhookUniversalPayloadToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'entity_id': value.entity_id,
        'error_message': value.error_message,
    };
}
exports.ChowlyWebhookUniversalPayloadToJSON = ChowlyWebhookUniversalPayloadToJSON;
