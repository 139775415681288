"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetAssetsResponseAllOfToJSON = exports.GetAssetsResponseAllOfFromJSONTyped = exports.GetAssetsResponseAllOfFromJSON = void 0;
var _1 = require("./");
function GetAssetsResponseAllOfFromJSON(json) {
    return GetAssetsResponseAllOfFromJSONTyped(json, false);
}
exports.GetAssetsResponseAllOfFromJSON = GetAssetsResponseAllOfFromJSON;
function GetAssetsResponseAllOfFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'assets': (json['assets'].map(_1.AssetFromJSON)),
    };
}
exports.GetAssetsResponseAllOfFromJSONTyped = GetAssetsResponseAllOfFromJSONTyped;
function GetAssetsResponseAllOfToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'assets': (value.assets.map(_1.AssetToJSON)),
    };
}
exports.GetAssetsResponseAllOfToJSON = GetAssetsResponseAllOfToJSON;
