import {CreateMenuCategoryBody, CreateMenuItemBody} from "@devour/client"

interface LockedFieldsStructure {
	menuCategory: Array<keyof CreateMenuCategoryBody>;
	menuItem: Array<keyof CreateMenuItemBody>;
	// menuCustomization: Array<keyof CreateMenuCustomizationBody>; // TODO: Update API is not ready yet
	// menuCustomizationItem: Array<keyof CreateMenuCustomizationItemBody>;  // TODO: Update API is not ready yet
}

interface PosLockedFields {
	chowly: LockedFieldsStructure;
	itsaCheckmate: LockedFieldsStructure;
}

/**
 * This is the list of features that is controlled by the POS system.
 * Since we want the POS to be "source of truth" for the data, we will prevent the merchant from editing this data in the merchant portal.
 */
export const posLockedFields: PosLockedFields = {
	chowly: {
		menuCategory: [
			"business",
			"parent",
			"name",
			"isEnabled",
			"serviceAvailabilities",
		],
		menuItem: [
			"category",
			"images",
			"name",
			"description",
			"price",
			"taxRate",
			"isAlcohol",
			"isActive",
		],
	},
	itsaCheckmate: {
		menuCategory: [
			"business",
			"parent",
			"name",
			"sortOrder",
			"isEnabled",
			"serviceAvailabilities",
		],
		menuItem: [
			"category",
			"images",
			"name",
			"description",
			"sortOrder",
			"price",
			"taxRate",
			"isAlcohol",
			"isActive",
		],
	},
}
