"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AssetToJSON = exports.AssetFromJSONTyped = exports.AssetFromJSON = void 0;
var runtime_1 = require("../runtime");
function AssetFromJSON(json) {
    return AssetFromJSONTyped(json, false);
}
exports.AssetFromJSON = AssetFromJSON;
function AssetFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'fileName': json['fileName'],
        'url': json['url'],
        'pipeUrl': json['pipeUrl'],
        'sourcePipeUrl': json['sourcePipeUrl'],
        'redirectUrl': json['redirectUrl'],
        'sourceRedirectUrl': json['sourceRedirectUrl'],
        'base64Url': json['base64Url'],
        'sourceBase64Url': json['sourceBase64Url'],
        'cdnUrl': json['cdnUrl'],
        'name': !(0, runtime_1.exists)(json, 'name') ? undefined : json['name'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'contentType': json['contentType'],
        'originalContentType': !(0, runtime_1.exists)(json, 'originalContentType') ? undefined : json['originalContentType'],
        'size': json['size'],
        'owner': !(0, runtime_1.exists)(json, 'owner') ? undefined : json['owner'],
    };
}
exports.AssetFromJSONTyped = AssetFromJSONTyped;
function AssetToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'fileName': value.fileName,
        'url': value.url,
        'pipeUrl': value.pipeUrl,
        'sourcePipeUrl': value.sourcePipeUrl,
        'redirectUrl': value.redirectUrl,
        'sourceRedirectUrl': value.sourceRedirectUrl,
        'base64Url': value.base64Url,
        'sourceBase64Url': value.sourceBase64Url,
        'cdnUrl': value.cdnUrl,
        'name': value.name,
        'description': value.description,
        'contentType': value.contentType,
        'originalContentType': value.originalContentType,
        'size': value.size,
        'owner': value.owner,
    };
}
exports.AssetToJSON = AssetToJSON;
