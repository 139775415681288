import React, {OptionHTMLAttributes, ReactElement, ReactNode} from "react";

export interface ISelectOption<T> {
	value: OptionHTMLAttributes<T>["value"];
	label: string;
}

interface Props<T> {
	strings?: string[];
	options?: Array<ISelectOption<T>>;
}

function SelectOptionsFactory<T>(props: Props<T>): ReactElement {

	/**
	 * Render each select option for the parent drop-down.
	 *
	 * @param _options
	 */
	function makeSelectOptions(_options: Array<ISelectOption<T>>): ReactNode {
		return _options.map((_option: ISelectOption<T>, i: number) => {
			return (
				<option
					key={`select-option-${i}`}
					value={_option.value}
				>
					{_option.label}
				</option>
			);
		});
	}

	if (props.strings) {
		return (
			<React.Fragment>
				{makeSelectOptions(props.strings.map((s: string): ISelectOption<T> => {
					return {
						value: s,
						label: s,
					}
				}))}
			</React.Fragment>
		);
	} else if (props.options) {
		return (
			<React.Fragment>
				{makeSelectOptions(props.options)}
			</React.Fragment>
		);
	} else {
		return null;
	}
}

export default SelectOptionsFactory;
