"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlaceDetailsCacheToJSON = exports.PlaceDetailsCacheFromJSONTyped = exports.PlaceDetailsCacheFromJSON = void 0;
function PlaceDetailsCacheFromJSON(json) {
    return PlaceDetailsCacheFromJSONTyped(json, false);
}
exports.PlaceDetailsCacheFromJSON = PlaceDetailsCacheFromJSON;
function PlaceDetailsCacheFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'expiresAt': json['expiresAt'],
        'placeId': json['placeId'],
        'placeDetailsResponse': json['placeDetailsResponse'],
    };
}
exports.PlaceDetailsCacheFromJSONTyped = PlaceDetailsCacheFromJSONTyped;
function PlaceDetailsCacheToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'expiresAt': value.expiresAt,
        'placeId': value.placeId,
        'placeDetailsResponse': value.placeDetailsResponse,
    };
}
exports.PlaceDetailsCacheToJSON = PlaceDetailsCacheToJSON;
