"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.VelocityReportToJSON = exports.VelocityReportFromJSONTyped = exports.VelocityReportFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function VelocityReportFromJSON(json) {
    return VelocityReportFromJSONTyped(json, false);
}
exports.VelocityReportFromJSON = VelocityReportFromJSON;
function VelocityReportFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'lifetimePoints': json['lifetimePoints'],
        'weeklyReports': json['weeklyReports'],
        'employeeStats': ((0, runtime_1.mapValues)(json['employeeStats'], _1.EmployeeVelocityStatsFromJSON)),
    };
}
exports.VelocityReportFromJSONTyped = VelocityReportFromJSONTyped;
function VelocityReportToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'lifetimePoints': value.lifetimePoints,
        'weeklyReports': value.weeklyReports,
        'employeeStats': ((0, runtime_1.mapValues)(value.employeeStats, _1.EmployeeVelocityStatsToJSON)),
    };
}
exports.VelocityReportToJSON = VelocityReportToJSON;
