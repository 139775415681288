"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserLvlToJSON = exports.UserLvlFromJSONTyped = exports.UserLvlFromJSON = void 0;
var runtime_1 = require("../runtime");
function UserLvlFromJSON(json) {
    return UserLvlFromJSONTyped(json, false);
}
exports.UserLvlFromJSON = UserLvlFromJSON;
function UserLvlFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': json['name'],
        'level': json['level'],
        'email': json['email'],
        'id': !(0, runtime_1.exists)(json, 'id') ? undefined : json['id'],
    };
}
exports.UserLvlFromJSONTyped = UserLvlFromJSONTyped;
function UserLvlToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'level': value.level,
        'email': value.email,
        'id': value.id,
    };
}
exports.UserLvlToJSON = UserLvlToJSON;
