"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateMenuItemBodyToJSON = exports.CreateMenuItemBodyFromJSONTyped = exports.CreateMenuItemBodyFromJSON = void 0;
var runtime_1 = require("../runtime");
function CreateMenuItemBodyFromJSON(json) {
    return CreateMenuItemBodyFromJSONTyped(json, false);
}
exports.CreateMenuItemBodyFromJSON = CreateMenuItemBodyFromJSON;
function CreateMenuItemBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'category': json['category'],
        'images': !(0, runtime_1.exists)(json, 'images') ? undefined : json['images'],
        'name': json['name'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'sortOrder': !(0, runtime_1.exists)(json, 'sortOrder') ? undefined : json['sortOrder'],
        'price': json['price'],
        'taxRate': json['taxRate'],
        'isAlcohol': !(0, runtime_1.exists)(json, 'isAlcohol') ? undefined : json['isAlcohol'],
        'isActive': !(0, runtime_1.exists)(json, 'isActive') ? undefined : json['isActive'],
        'nftGroupings': !(0, runtime_1.exists)(json, 'nftGroupings') ? undefined : json['nftGroupings'],
    };
}
exports.CreateMenuItemBodyFromJSONTyped = CreateMenuItemBodyFromJSONTyped;
function CreateMenuItemBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'category': value.category,
        'images': value.images,
        'name': value.name,
        'description': value.description,
        'sortOrder': value.sortOrder,
        'price': value.price,
        'taxRate': value.taxRate,
        'isAlcohol': value.isAlcohol,
        'isActive': value.isActive,
        'nftGroupings': value.nftGroupings,
    };
}
exports.CreateMenuItemBodyToJSON = CreateMenuItemBodyToJSON;
