import React, {ReactElement, useEffect, useState} from "react";
import {
	Restaurant,
	ItsaCheckmateApi,
} from "@devour/client";
import getConfig from "../utils/getConfig";
import {Link, useSearchParams} from "react-router-dom";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/metaActions";
import {useDispatch} from "react-redux";

function ItsaCheckmateAuth(): ReactElement {
	const dispatch = useDispatch();
	const [restaurant, setRestaurant] = useState<Restaurant>();
	const [searchParams] = useSearchParams();
	const itsaCheckmateCode = searchParams.get("code");

	useEffect(() => {
		if (itsaCheckmateCode) {
			void activateItsaCheckmateLocation();
		}
	}, [itsaCheckmateCode]);


	/**
	 * Activate the new ItsaCheckmate location.
	 */
	async function activateItsaCheckmateLocation(): Promise<void> {
		dispatch(incrementLoading());
		try {
			const res = await new ItsaCheckmateApi(getConfig()).activateLocation({
				activateLocationBody: {
					code: itsaCheckmateCode,
				},
			});
			setRestaurant(res);
		} catch (e) {
			dispatch(await addError(e));
		} finally {
			dispatch(decrementLoading());
		}
	}

	function renderBody(): ReactElement {
		if (!itsaCheckmateCode) {
			return (
				<>
					<h2>
						ItsaCheckmate authorization code is missing.
					</h2>
					<p>
						Please try the authorization again from the ItsaCheckmate portal.
						If this error continues, please contact DevourGO support at <a
						href="mailto:onboarding@devourgo.io">onboarding@devourgo.io</a>.
					</p>
				</>
			);
		}
		if (!restaurant) {
			return (
				<>
					<h2>
						Activating Location
					</h2>
					<p>
						Please hold while your location is being built on DevourGO.
						This will take around a minute.
					</p>
				</>
			);
		}
		if (restaurant?.isEnabled && restaurant?.isActive) {
			return (
				<>
					<h2>
						Welcome to the DevourGO Family
					</h2>
					<p>
						Your location has been built and is available soon on DevourGO.
					</p>
					<p>
						You can now <Link to={`businesses/${restaurant.id}`}>manage the restaurant</Link> or <Link
						to={`${process.env.REACT_APP_WEB_URL}/restaurants/${restaurant.id}`}>view the
						restaurant</Link>.
					</p>
				</>
			);
		}
		return (
			<>
				<h2>
					Welcome to the DevourGO Family
				</h2>
				<p>
					Your location has been built and will be available soon on DevourGO.
				</p>
				<p>
					One of our staff members will review all the restaurant details and will let you know once it is
					live on our platform.
				</p>
			</>
		);

	}

	return (
		<div className="itsacheckmate-auth-page">
			<div className="itsacheckmate-auth-page_content">
				<div className="itsacheckmate-auth-page_logo">
					<img
						className="itsacheckmate-auth-page_logo_img"
						src={process.env.REACT_APP_CDN_URL + "/images/logo-itsacheckmate.png"}
						alt="ItsaCheckmate Logo"
					/>
				</div>
				<div className="itsacheckmate-auth-page_body">
					{renderBody()}
				</div>
			</div>
		</div>
	);
}

export default ItsaCheckmateAuth;
