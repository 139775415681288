"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoginResponseToJSON = exports.LoginResponseFromJSONTyped = exports.LoginResponseFromJSON = void 0;
var _1 = require("./");
function LoginResponseFromJSON(json) {
    return LoginResponseFromJSONTyped(json, false);
}
exports.LoginResponseFromJSON = LoginResponseFromJSON;
function LoginResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'token': (0, _1.TokenFromJSON)(json['token']),
        'user': (0, _1.UserFromJSON)(json['user']),
    };
}
exports.LoginResponseFromJSONTyped = LoginResponseFromJSONTyped;
function LoginResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'token': (0, _1.TokenToJSON)(value.token),
        'user': (0, _1.UserToJSON)(value.user),
    };
}
exports.LoginResponseToJSON = LoginResponseToJSON;
