"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetGeographyToJSON = exports.GetGeographyFromJSONTyped = exports.GetGeographyFromJSON = void 0;
var _1 = require("./");
function GetGeographyFromJSON(json) {
    return GetGeographyFromJSONTyped(json, false);
}
exports.GetGeographyFromJSON = GetGeographyFromJSON;
function GetGeographyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'countries': (json['countries'].map(_1.DialCodeFromJSON)),
        'states': json['states'],
        'provinces': json['provinces'],
    };
}
exports.GetGeographyFromJSONTyped = GetGeographyFromJSONTyped;
function GetGeographyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'countries': (value.countries.map(_1.DialCodeToJSON)),
        'states': value.states,
        'provinces': value.provinces,
    };
}
exports.GetGeographyToJSON = GetGeographyToJSON;
