"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorTypeToJSON = exports.ErrorTypeFromJSONTyped = exports.ErrorTypeFromJSON = exports.ErrorType = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var ErrorType;
(function (ErrorType) {
    ErrorType["APP"] = "APP";
    ErrorType["API"] = "API";
    ErrorType["FORM"] = "FORM";
})(ErrorType = exports.ErrorType || (exports.ErrorType = {}));
function ErrorTypeFromJSON(json) {
    return ErrorTypeFromJSONTyped(json, false);
}
exports.ErrorTypeFromJSON = ErrorTypeFromJSON;
function ErrorTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.ErrorTypeFromJSONTyped = ErrorTypeFromJSONTyped;
function ErrorTypeToJSON(value) {
    return value;
}
exports.ErrorTypeToJSON = ErrorTypeToJSON;
