"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetMerchantBrandsResponseAllOfToJSON = exports.GetMerchantBrandsResponseAllOfFromJSONTyped = exports.GetMerchantBrandsResponseAllOfFromJSON = void 0;
var _1 = require("./");
function GetMerchantBrandsResponseAllOfFromJSON(json) {
    return GetMerchantBrandsResponseAllOfFromJSONTyped(json, false);
}
exports.GetMerchantBrandsResponseAllOfFromJSON = GetMerchantBrandsResponseAllOfFromJSON;
function GetMerchantBrandsResponseAllOfFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'merchantBrands': (json['merchantBrands'].map(_1.BrandToMerchantPermissionsFromJSON)),
    };
}
exports.GetMerchantBrandsResponseAllOfFromJSONTyped = GetMerchantBrandsResponseAllOfFromJSONTyped;
function GetMerchantBrandsResponseAllOfToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'merchantBrands': (value.merchantBrands.map(_1.BrandToMerchantPermissionsToJSON)),
    };
}
exports.GetMerchantBrandsResponseAllOfToJSON = GetMerchantBrandsResponseAllOfToJSON;
