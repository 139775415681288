import {Address} from "@devour/client";
import {PickFieldsOfType} from "./PickedFieldsOfType";

export function addPotentiallyEmptyAddressSection(_address: Address, key: keyof PickFieldsOfType<Address, string>): string {
	if (_address[key]?.length > 0) {
		return " " + _address[key];
	}

	return "";
}

export function concatAddressFields(_address: Address, fields: Array<keyof PickFieldsOfType<Address, string>> = ["line1", "line2", "locality", "administrativeArea", "postalCode", "country"]): string {
	if (!_address) {
		return "";
	}
	return fields.slice(1).reduce((add, field) => {
		return add + addPotentiallyEmptyAddressSection(_address, field);
	}, _address[fields[0]] as string);
}
