"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetBusinessOwnActiveStatusToJSON = exports.GetBusinessOwnActiveStatusFromJSONTyped = exports.GetBusinessOwnActiveStatusFromJSON = exports.GetBusinessOwnActiveStatus = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var GetBusinessOwnActiveStatus;
(function (GetBusinessOwnActiveStatus) {
    GetBusinessOwnActiveStatus["ACTIVE"] = "ACTIVE";
    GetBusinessOwnActiveStatus["INACTIVE"] = "INACTIVE";
})(GetBusinessOwnActiveStatus = exports.GetBusinessOwnActiveStatus || (exports.GetBusinessOwnActiveStatus = {}));
function GetBusinessOwnActiveStatusFromJSON(json) {
    return GetBusinessOwnActiveStatusFromJSONTyped(json, false);
}
exports.GetBusinessOwnActiveStatusFromJSON = GetBusinessOwnActiveStatusFromJSON;
function GetBusinessOwnActiveStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.GetBusinessOwnActiveStatusFromJSONTyped = GetBusinessOwnActiveStatusFromJSONTyped;
function GetBusinessOwnActiveStatusToJSON(value) {
    return value;
}
exports.GetBusinessOwnActiveStatusToJSON = GetBusinessOwnActiveStatusToJSON;
