"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeletionVerificationCodeToJSON = exports.DeletionVerificationCodeFromJSONTyped = exports.DeletionVerificationCodeFromJSON = void 0;
var _1 = require("./");
function DeletionVerificationCodeFromJSON(json) {
    return DeletionVerificationCodeFromJSONTyped(json, false);
}
exports.DeletionVerificationCodeFromJSON = DeletionVerificationCodeFromJSON;
function DeletionVerificationCodeFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'code': json['code'],
        'expiration': json['expiration'],
        'user': (0, _1.UserFromJSON)(json['user']),
    };
}
exports.DeletionVerificationCodeFromJSONTyped = DeletionVerificationCodeFromJSONTyped;
function DeletionVerificationCodeToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'code': value.code,
        'expiration': value.expiration,
        'user': (0, _1.UserToJSON)(value.user),
    };
}
exports.DeletionVerificationCodeToJSON = DeletionVerificationCodeToJSON;
