import React, {ReactElement} from "react";
import {BrandMap, BrandMapStatus} from "@devour/client";
import {GrMap} from "react-icons/gr";
import {parseMapStatus} from "../../utils/parseMapStatus";
import FrameButton from "../buttons/FrameButton";
import moment from "moment";
import {concatAddressFields} from "../../utils/formatAddress";
import classNames from "classnames";
import {TbDiscountCheckFilled} from "react-icons/tb";

interface Props {
	brandMap: BrandMap;
}

function BrandMapCard(props: Props): ReactElement {
	return (
		<div className="brand-map-card">
			<div className="brand-map-card_branding">
				<div className="brand-map-card_branding_img">
					{props.brandMap.bannerImage ? (
						<img
							src={props.brandMap.bannerImage?.image?.url}
							alt={props.brandMap.name}
							className="brand-map-card_branding_img_map"
						/>
					) : (
						<GrMap/>
					)}
				</div>

				<div className="brand-map-card_branding_edit-btn">
					<FrameButton
						color="purple"
						size="normal"
						to={`/brands/maps/${props.brandMap.slug}/edit`}
					>
						Edit Map
					</FrameButton>
				</div>
			</div>

			<div className="brand-map-card_content">
				<h3 className="brand-map-card_content_name">
					{props.brandMap.name}
				</h3>

				<p className="brand-map-card_content_label">
					Brand
				</p>
				<p>
					{props.brandMap.brand.name}
				</p>

				<p className="brand-map-card_content_label">
					Map Status
				</p>
				<div
					className={classNames("brand-map-card_content_status", {
						"brand-map-card_content_status_draft": props.brandMap.status === BrandMapStatus.DRAFT,
						"brand-map-card_content_status_published": props.brandMap.status === BrandMapStatus.PUBLISHED,
					})}
				>
					<TbDiscountCheckFilled/>
					<p>
						{parseMapStatus(props.brandMap.status)}
					</p>
				</div>

				<p className="brand-map-card_content_label">
					Custom URL Slug
				</p>
				<p>
					{props.brandMap.slug != null ? `/${props.brandMap.slug}` : "-"}
				</p>

				<p className="brand-map-card_content_label">
					Live URL
				</p>
				<p>
					{props.brandMap.status === BrandMapStatus.PUBLISHED ? (
						<a
							href={`${process.env.REACT_APP_WEB_URL}/brands/${props.brandMap.slug}`}
							target="_blank"
							rel="noopener noreferrer"
						>
							{`${process.env.REACT_APP_WEB_URL}/brands/${props.brandMap.slug}`}
						</a>
					) : (
						"Only available when map is Published."
					)}
				</p>

				<p className="brand-map-card_content_label">
					Default Location
				</p>
				<p>
					{props.brandMap.defaultLocation ? concatAddressFields(props.brandMap.defaultLocation) : ("-")}
				</p>

				<p className="brand-map-card_content_label">
					Created
				</p>
				<p>
					{moment(props.brandMap.createdAt).format("MMMM DD YYYY")}
				</p>
			</div>
		</div>
	);
}

export default BrandMapCard;
