import React, {
    ChangeEventHandler,
    FormEvent,
    ReactElement,
    useEffect,
    useState
} from "react";
import {useLocation} from 'react-router-dom';
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {ResetPasswordBody, Token, VerificationsApi} from "@devour/client";
import FrameOneModal from "./modalComponents/FrameOneModal";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/metaActions";
import getConfig from "../../utils/getConfig";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameButton from "../buttons/FrameButton";
import ForgotPasswordResetSuccessModal from "./ForgotPasswordResetSuccessModal";

const defaultResetPasswordForm: ResetPasswordBody = {
    verificationCode: "",
    newPassword: "",
    confirmPassword: "",
};

interface Props {
    isOpen: boolean;
    requestId: string;
    onClose: () => void;
}

interface StateProps {
    fullToken: Token;
}

function ConfirmPasswordResetRequestModal(props: ConfirmPasswordResetRequestModalProps): ReactElement {

    const [resetPasswordForm, setResetPasswordForm] = useState<ResetPasswordBody>(defaultResetPasswordForm);
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search); // Find any query params in URL
    const verifyCode = queryParams.get('code'); // Find the query param for code

    useEffect(() => {
        if (props.isOpen) {
            setResetPasswordForm({
                ...defaultResetPasswordForm,
                verificationCode: verifyCode
            }); // If query param for code exists, set it. Else it'll be a blank string.
        }
    }, [props.isOpen]);

    /**
     * Handle all text input onChange events.
     *
     * @param key
     */
    function inputOnChange(key: keyof ResetPasswordBody): ChangeEventHandler<HTMLInputElement> {
        return (e) => {
            setResetPasswordForm((_resetPasswordForm) => {
                if (key === "verificationCode") {
                    return {
                        ..._resetPasswordForm,
                        verificationCode: e.target.value.trim(),
                    }
                }

                return {
                    ..._resetPasswordForm,
                    [key]: e.target.value,
                }
            });
        }
    }

    /**
     * Call the api to submit the password reset confirmation, close the form (this modal), and open the success modal.
     *
     * @param e
     */
    async function confirmPasswordReset(e: FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();
        props.dispatch(incrementLoading());

        try {
            await new VerificationsApi(getConfig()).verifyPasswordReset({
                id: props.requestId,
                resetPasswordBody: {
                    verificationCode: resetPasswordForm.verificationCode,
                    newPassword: resetPasswordForm.newPassword,
                    confirmPassword: resetPasswordForm.confirmPassword,
                },
            });

            props.onClose();
            setShowSuccessModal(true);
        } catch (e) {
            props.dispatch(await addError(e));
        } finally {
            props.dispatch(decrementLoading());
        }
    }

    return (
        <React.Fragment>
            <ForgotPasswordResetSuccessModal isOpen={showSuccessModal}/>

            <FrameOneModal
                isOpen={props.isOpen}
                contentClassName="confirm-password-reset-request-modal"
            >
                <FrameModalHeader
                    title="Verify Password Reset"
                    toggle={props.onClose}
                />

                <FrameModalBody className="confirm-password-reset-request-modal_body">
                    <form onSubmit={confirmPasswordReset}>
                        <div className="confirm-password-reset-request-modal_body_code-container">
                            <label>Reset confirmation code</label>
                            <input
                                type="text"
                                placeholder="Enter the code we emailed you"
                                value={resetPasswordForm.verificationCode}
                                onChange={inputOnChange("verificationCode")}
                                // ref={codeRef}
                            />
                        </div>

                        <div className="confirm-password-reset-request-modal_body_new-pass-container">
                            <label>New password</label>
                            <input
                                type="password"
                                placeholder="Enter your new password"
                                value={resetPasswordForm.newPassword}
                                onChange={inputOnChange("newPassword")}
                                // ref={newPassRef}
                            />
                        </div>

                        <div className="confirm-password-reset-request-modal_body_confirm-pass-container">
                            <label>Confirm new password</label>
                            <input
                                type="password"
                                placeholder="Confirm your new password"
                                value={resetPasswordForm.confirmPassword}
                                onChange={inputOnChange("confirmPassword")}
                                // ref={confirmPassRef}
                            />
                        </div>

                        <FrameButton
                            <React.ButtonHTMLAttributes<HTMLButtonElement>>
                            color="gray"
                            size="normal"
                            className="confirm-password-reset-request-modal_body_submit-button"
                            // forwardRef={submitButtonRef}
                            forwardProps={{
                                type: "submit",
                            }}
                        >
                            Confirm password reset
                        </FrameButton>
                    </form>
                </FrameModalBody>
            </FrameOneModal>
        </React.Fragment>
    );
}

function connector() {
    return connect((store: IStore, props: Props): StateProps & Props => {
        return {
            fullToken: store.authStore.fullToken,
            ...props,
        }
    });
}

type ConfirmPasswordResetRequestModalProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(ConfirmPasswordResetRequestModal);
