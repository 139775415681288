"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateNftRewardPrizeBodyToJSON = exports.UpdateNftRewardPrizeBodyFromJSONTyped = exports.UpdateNftRewardPrizeBodyFromJSON = void 0;
var runtime_1 = require("../runtime");
function UpdateNftRewardPrizeBodyFromJSON(json) {
    return UpdateNftRewardPrizeBodyFromJSONTyped(json, false);
}
exports.UpdateNftRewardPrizeBodyFromJSON = UpdateNftRewardPrizeBodyFromJSON;
function UpdateNftRewardPrizeBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': json['name'],
        'prizeRevealName': json['prizeRevealName'],
        'quantity': !(0, runtime_1.exists)(json, 'quantity') ? undefined : json['quantity'],
        'emailOnWin': json['emailOnWin'],
        'amount': json['amount'],
        'winningImage': !(0, runtime_1.exists)(json, 'winningImage') ? undefined : json['winningImage'],
        'sortOrder': !(0, runtime_1.exists)(json, 'sortOrder') ? undefined : json['sortOrder'],
        'onlyAvailableAfterWins': !(0, runtime_1.exists)(json, 'onlyAvailableAfterWins') ? undefined : json['onlyAvailableAfterWins'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
    };
}
exports.UpdateNftRewardPrizeBodyFromJSONTyped = UpdateNftRewardPrizeBodyFromJSONTyped;
function UpdateNftRewardPrizeBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'prizeRevealName': value.prizeRevealName,
        'quantity': value.quantity,
        'emailOnWin': value.emailOnWin,
        'amount': value.amount,
        'winningImage': value.winningImage,
        'sortOrder': value.sortOrder,
        'onlyAvailableAfterWins': value.onlyAvailableAfterWins,
        'description': value.description,
    };
}
exports.UpdateNftRewardPrizeBodyToJSON = UpdateNftRewardPrizeBodyToJSON;
