"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetMenuOrdersOwnResponseAllOfToJSON = exports.GetMenuOrdersOwnResponseAllOfFromJSONTyped = exports.GetMenuOrdersOwnResponseAllOfFromJSON = void 0;
var _1 = require("./");
function GetMenuOrdersOwnResponseAllOfFromJSON(json) {
    return GetMenuOrdersOwnResponseAllOfFromJSONTyped(json, false);
}
exports.GetMenuOrdersOwnResponseAllOfFromJSON = GetMenuOrdersOwnResponseAllOfFromJSON;
function GetMenuOrdersOwnResponseAllOfFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'menuOrders': (json['menuOrders'].map(_1.MenuOrderOwnFromJSON)),
    };
}
exports.GetMenuOrdersOwnResponseAllOfFromJSONTyped = GetMenuOrdersOwnResponseAllOfFromJSONTyped;
function GetMenuOrdersOwnResponseAllOfToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'menuOrders': (value.menuOrders.map(_1.MenuOrderOwnToJSON)),
    };
}
exports.GetMenuOrdersOwnResponseAllOfToJSON = GetMenuOrdersOwnResponseAllOfToJSON;
