"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GleamUserToJSON = exports.GleamUserFromJSONTyped = exports.GleamUserFromJSON = void 0;
var runtime_1 = require("../runtime");
function GleamUserFromJSON(json) {
    return GleamUserFromJSONTyped(json, false);
}
exports.GleamUserFromJSON = GleamUserFromJSON;
function GleamUserFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': json['name'],
        'first_name': json['first_name'],
        'last_name': json['last_name'],
        'email': json['email'],
        'country': json['country'],
        'region': json['region'],
        'city': json['city'],
        'country_code': json['country_code'],
        'actions_completed': !(0, runtime_1.exists)(json, 'actions_completed') ? undefined : json['actions_completed'],
        'details': json['details'],
    };
}
exports.GleamUserFromJSONTyped = GleamUserFromJSONTyped;
function GleamUserToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'first_name': value.first_name,
        'last_name': value.last_name,
        'email': value.email,
        'country': value.country,
        'region': value.region,
        'city': value.city,
        'country_code': value.country_code,
        'actions_completed': value.actions_completed,
        'details': value.details,
    };
}
exports.GleamUserToJSON = GleamUserToJSON;
