import React, {ReactElement} from "react";
import {MenuOrder} from "@devour/client";
import {concatAddressFields} from "../../utils/formatAddress";
import {parseMenuOrderStatus} from "../../utils/parseMenuOrderStatus";
import moment from "moment/moment";

interface Props {
	menuOrder: MenuOrder;
}

function MenuOrderDetails(props: Props): ReactElement {

	return (
		<div className="menu-order-customer">
			<p>
				Status: {parseMenuOrderStatus(props.menuOrder?.status)}
			</p>
			{(props.menuOrder?.scheduledTime) && (
				<p>
					Scheduled Time: {moment(props.menuOrder?.scheduledTime).format("LLL")}
				</p>
			)}
			<p>
				Recipient: {props.menuOrder?.firstName} {props.menuOrder?.lastName}
			</p>
			<p>
				Phone: {props.menuOrder.phoneNumber?.nationalNumber || "N/A"}
			</p>
			<p>
				Email: {props.menuOrder.email || "N/A"}
			</p>
			<p>
				Address:
				<a
					href={`https://www.google.com/maps/place/?q=place_id:${props.menuOrder.address?.placeId}`}
					target="_blank"
					rel="noopener noreferrer"
				>
					{concatAddressFields(props.menuOrder?.address)}
				</a>
			</p>
		</div>
	);
}

export default MenuOrderDetails;
