"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoginBodyToJSON = exports.LoginBodyFromJSONTyped = exports.LoginBodyFromJSON = void 0;
var runtime_1 = require("../runtime");
function LoginBodyFromJSON(json) {
    return LoginBodyFromJSONTyped(json, false);
}
exports.LoginBodyFromJSON = LoginBodyFromJSON;
function LoginBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'email': json['email'],
        'password': json['password'],
        'onboardingSignedUrlKey': !(0, runtime_1.exists)(json, 'onboardingSignedUrlKey') ? undefined : json['onboardingSignedUrlKey'],
    };
}
exports.LoginBodyFromJSONTyped = LoginBodyFromJSONTyped;
function LoginBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'email': value.email,
        'password': value.password,
        'onboardingSignedUrlKey': value.onboardingSignedUrlKey,
    };
}
exports.LoginBodyToJSON = LoginBodyToJSON;
