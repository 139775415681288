import React, {ReactElement} from "react";
import {BrandMapAdvertisementBlock} from "@devour/client";
import {EditBrandMapBodyFrontend} from "../BrandMapFormValues";
import {generateBackgroundUrl} from "../../../../utils/brands/generateImageUrl";

interface Props {
    formValues: EditBrandMapBodyFrontend;
}

function PreviewAdvertisementBlocks(props: Props): ReactElement {

    const advertisementBlocks = props.formValues.advertisementBlocks.filter(block => block.image);

    if (!advertisementBlocks.length) {
        return null;
    }

    /**
     * Render each "card" for each advertisement block.
     *
     * @param block
     * @param i
     */
    function renderAdvertisementBlock(block: BrandMapAdvertisementBlock, i: number): ReactElement {

        return (
            <li
                key={`advertisement-block-${i}`}
                className={`restaurant-map-landing_other-promos_list_item restaurant-map-landing_other-promos_list_item-${i}`}
            >
                {block.url ? (
                    <a
                        href={(block.url.indexOf("http") === 0) ? block.url : `https://${block.url}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            src={generateBackgroundUrl(block.image)}
                            alt={`${props.formValues.name} promotion`}
                        />
                    </a>
                ) : (
                    <img
                        src={generateBackgroundUrl(block.image)}
                        alt={`${props.formValues.name} promotion`}
                    />
                )}
            </li>
        );
    }

    return (
        <div className="restaurant-map-landing_other-promos">

            <h3 className="restaurant-map-landing_other-promos_title">
                Other Promotions
            </h3>

            <ul className="restaurant-map-landing_other-promos_list">
                {advertisementBlocks.filter(block => block.image).map(renderAdvertisementBlock)}
            </ul>
        </div>
    );
}

export default PreviewAdvertisementBlocks;
