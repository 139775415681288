import {resolve} from "ipfs-race";

// includes all values from...
// https://github.com/ethereum/EIPs/blob/master/EIPS/eip-721.md
// https://github.com/ethereum/EIPs/blob/master/EIPS/eip-1155.md#erc-1155-metadata-uri-json-schema
// https://docs.opensea.io/docs/metadata-standards
export interface INFTSchema {
    // https://github.com/ethereum/EIPs/blob/master/EIPS/eip-721.md
    name: string;
    description: string;
    image?: string;

    // https://docs.opensea.io/docs/metadata-standards
    image_data?: string;
    external_url?: string;
    background_color?: string; // Background color of the item on OpenSea. Must be a six-character hexadecimal without a pre-pended #.
    animation_url?: string;
    youtube_url?: string;
    attributes?: Array<{
        trait_type: string, // name of trait
        value: number | string, // value
        display_type?: "number" | "boost_percentage" | "boost_number" | "data" // only needed for numbers, not strings https://docs.opensea.io/docs/metadata-standards
        max_value?: number
    }>

    // https://github.com/ethereum/EIPs/blob/master/EIPS/eip-1155.md#erc-1155-metadata-uri-json-schema
    properties?: object;
    decimals?: number;
    localization?: {
        uri: string,
        default: string,
        locales: string[]
    }
}

export interface NftInformationFromTokenUriOutput {
    imageBase64?: string;
    imageResolveUrl?: string;
    metadataResolveUrl?: string;
    metadata: INFTSchema;
}

export async function getNftInformationFromTokenUri(tokenUri: string): Promise<NftInformationFromTokenUriOutput> {

    console.log(tokenUri);
    // try to resolve the tokenUri via the ipfs-race library
    const {response: metadataResponse, urlResolvedFrom: metadataResolveUrl} = await resolve(tokenUri, {
        ipfsGateways: [
            "https://ipfs.io",
            "https://gateway.ipfs.io",
            "https://gateway.pinata.cloud",
            "https://cloudflare-ipfs.com"
        ],
        fetchOverride: window.fetch.bind(window)
    });
    const metadata: INFTSchema = await metadataResponse.json();

    let imageBase64;
    let imageResolveUrl;

    // Check if an image or animation_url exists, and use the available one
    const imageUrl = metadata.image || metadata.animation_url;
    if (!imageUrl) {
        return { metadata };
    }

    // Resolve the link to the image or animation
    const {response: imageResponse, urlResolvedFrom: resolvedUrl} = await resolve(imageUrl, {fetchOverride: window.fetch.bind(window)});
    imageBase64 = await blobToBase64(await imageResponse.blob());
    imageResolveUrl = resolvedUrl;

    return {
        metadata,
        imageBase64,
        imageResolveUrl,
        metadataResolveUrl
    }
}

function blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.readAsDataURL(blob);
    });
}