"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.InlineResponse20010ToJSON = exports.InlineResponse20010FromJSONTyped = exports.InlineResponse20010FromJSON = void 0;
function InlineResponse20010FromJSON(json) {
    return InlineResponse20010FromJSONTyped(json, false);
}
exports.InlineResponse20010FromJSON = InlineResponse20010FromJSON;
function InlineResponse20010FromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'errorId': json['errorId'],
    };
}
exports.InlineResponse20010FromJSONTyped = InlineResponse20010FromJSONTyped;
function InlineResponse20010ToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'errorId': value.errorId,
    };
}
exports.InlineResponse20010ToJSON = InlineResponse20010ToJSON;
