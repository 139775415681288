"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValidateDeliveryDistanceReasonToJSON = exports.ValidateDeliveryDistanceReasonFromJSONTyped = exports.ValidateDeliveryDistanceReasonFromJSON = exports.ValidateDeliveryDistanceReason = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var ValidateDeliveryDistanceReason;
(function (ValidateDeliveryDistanceReason) {
    ValidateDeliveryDistanceReason["TooFar"] = "tooFar";
    ValidateDeliveryDistanceReason["DeliveryNotOffered"] = "deliveryNotOffered";
    ValidateDeliveryDistanceReason["GoogleApiError"] = "googleApiError";
})(ValidateDeliveryDistanceReason = exports.ValidateDeliveryDistanceReason || (exports.ValidateDeliveryDistanceReason = {}));
function ValidateDeliveryDistanceReasonFromJSON(json) {
    return ValidateDeliveryDistanceReasonFromJSONTyped(json, false);
}
exports.ValidateDeliveryDistanceReasonFromJSON = ValidateDeliveryDistanceReasonFromJSON;
function ValidateDeliveryDistanceReasonFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.ValidateDeliveryDistanceReasonFromJSONTyped = ValidateDeliveryDistanceReasonFromJSONTyped;
function ValidateDeliveryDistanceReasonToJSON(value) {
    return value;
}
exports.ValidateDeliveryDistanceReasonToJSON = ValidateDeliveryDistanceReasonToJSON;
