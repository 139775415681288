import React, {ReactElement} from "react";
import FrameButton from "../buttons/FrameButton";
import {BrandMapStatus} from "@devour/client";
import {BiSave} from "react-icons/bi";
import {ViewMode} from "../../pages/brands/EditBrandMapPage";
import {FaEye, FaPen} from "react-icons/fa";

interface Props {
	onSubmit: () => void;
	onSaveDraft: (status: BrandMapStatus.DRAFT) => Promise<void>;
	status?: BrandMapStatus;
	viewMode: ViewMode;
	toggleViewMode: () => void;
}

function MapCreationPageHeader(props: Props): ReactElement {

	return (
		<div className="map-creation-header">
			<div className="map-creation-header_inner">
				<div className="map-creation-header_instructions">
					<h3>
						{props.viewMode === ViewMode.EDITING ? "Create Your Brand Landing Page" : "This is a Preview of your Brand Page"}
					</h3>
					<p>
						{props.viewMode === ViewMode.EDITING ? "Customize and publish your brand landing page to share with your customers!" : "Your brand page will have live values and be interactive upon publishing."}
					</p>

				</div>
				<div className="map-creation-header_buttons">
					<FrameButton
						<React.ButtonHTMLAttributes<HTMLButtonElement>>
						color={props.viewMode === ViewMode.EDITING ? "purple-outline" : "purple"}
						size="normal"
						leftIcon={props.viewMode === ViewMode.EDITING ? FaEye : null}
						forwardProps={{type: "button"}}
						onClick={props.toggleViewMode}
					>
						{props.viewMode === ViewMode.EDITING ? "Preview" : "Back to Customize"}
					</FrameButton>
					{props.viewMode === ViewMode.EDITING && (
						<>
							<FrameButton
								color="purple-outline"
								size="normal"
								leftIcon={BiSave}
								onClick={() => props.onSaveDraft(BrandMapStatus.DRAFT)}
							>
								{props?.status === BrandMapStatus.DRAFT ? "Update Draft" : "Revert to Draft"}
							</FrameButton>
							<FrameButton
								<React.ButtonHTMLAttributes<HTMLButtonElement>>
								color="purple"
								size="normal"
								forwardProps={{type: "button"}}
								onClick={props.onSubmit}
							>
								Save and Publish
							</FrameButton>
						</>
					)}
				</div>
			</div>
		</div>
	);
}

export default MapCreationPageHeader;
