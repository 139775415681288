"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValidateAddressPremiseCacheToJSON = exports.ValidateAddressPremiseCacheFromJSONTyped = exports.ValidateAddressPremiseCacheFromJSON = void 0;
function ValidateAddressPremiseCacheFromJSON(json) {
    return ValidateAddressPremiseCacheFromJSONTyped(json, false);
}
exports.ValidateAddressPremiseCacheFromJSON = ValidateAddressPremiseCacheFromJSON;
function ValidateAddressPremiseCacheFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'expiresAt': json['expiresAt'],
        'formattedAddress': json['formattedAddress'],
        'valid': json['valid'],
    };
}
exports.ValidateAddressPremiseCacheFromJSONTyped = ValidateAddressPremiseCacheFromJSONTyped;
function ValidateAddressPremiseCacheToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'expiresAt': value.expiresAt,
        'formattedAddress': value.formattedAddress,
        'valid': value.valid,
    };
}
exports.ValidateAddressPremiseCacheToJSON = ValidateAddressPremiseCacheToJSON;
