import React, {PropsWithChildren, ReactElement} from "react";
import {FiX} from "react-icons/fi";

interface Props {
	title: string;
	toggle?: () => void;
}

function FrameModalHeader(props: PropsWithChildren<Props>): ReactElement {

	return (
		<div className="frame-one-modal_header">
			<div className="frame-one-modal_header_title-row">
				<h4 className="frame-one-modal_header_title-row_title">
					{props.title}
				</h4>

				{props.toggle && (
					<FiX
						className="frame-one-modal_header_title-row_close-icon"
						onClick={props.toggle}
					/>
				)}
			</div>

			{props.children && (
				<div className="frame-one-modal_header_children">
					{props.children}
				</div>
			)}
		</div>
	);
}

export default FrameModalHeader;
