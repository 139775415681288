"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoginTypeToJSON = exports.LoginTypeFromJSONTyped = exports.LoginTypeFromJSON = exports.LoginType = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var LoginType;
(function (LoginType) {
    LoginType["ADMIN"] = "ADMIN";
    LoginType["MERCHANT"] = "MERCHANT";
})(LoginType = exports.LoginType || (exports.LoginType = {}));
function LoginTypeFromJSON(json) {
    return LoginTypeFromJSONTyped(json, false);
}
exports.LoginTypeFromJSON = LoginTypeFromJSON;
function LoginTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.LoginTypeFromJSONTyped = LoginTypeFromJSONTyped;
function LoginTypeToJSON(value) {
    return value;
}
exports.LoginTypeToJSON = LoginTypeToJSON;
