"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.InlineResponse2008ToJSON = exports.InlineResponse2008FromJSONTyped = exports.InlineResponse2008FromJSON = void 0;
function InlineResponse2008FromJSON(json) {
    return InlineResponse2008FromJSONTyped(json, false);
}
exports.InlineResponse2008FromJSON = InlineResponse2008FromJSON;
function InlineResponse2008FromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'paymentMethodId': json['paymentMethodId'],
    };
}
exports.InlineResponse2008FromJSONTyped = InlineResponse2008FromJSONTyped;
function InlineResponse2008ToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'paymentMethodId': value.paymentMethodId,
    };
}
exports.InlineResponse2008ToJSON = InlineResponse2008ToJSON;
