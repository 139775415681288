"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AgreementTermTypeToJSON = exports.AgreementTermTypeFromJSONTyped = exports.AgreementTermTypeFromJSON = exports.AgreementTermType = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var AgreementTermType;
(function (AgreementTermType) {
    AgreementTermType["MERCHANT"] = "MERCHANT";
})(AgreementTermType = exports.AgreementTermType || (exports.AgreementTermType = {}));
function AgreementTermTypeFromJSON(json) {
    return AgreementTermTypeFromJSONTyped(json, false);
}
exports.AgreementTermTypeFromJSON = AgreementTermTypeFromJSON;
function AgreementTermTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.AgreementTermTypeFromJSONTyped = AgreementTermTypeFromJSONTyped;
function AgreementTermTypeToJSON(value) {
    return value;
}
exports.AgreementTermTypeToJSON = AgreementTermTypeToJSON;
