"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateBusinessesImagesBodyToJSON = exports.UpdateBusinessesImagesBodyFromJSONTyped = exports.UpdateBusinessesImagesBodyFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function UpdateBusinessesImagesBodyFromJSON(json) {
    return UpdateBusinessesImagesBodyFromJSONTyped(json, false);
}
exports.UpdateBusinessesImagesBodyFromJSON = UpdateBusinessesImagesBodyFromJSON;
function UpdateBusinessesImagesBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'businesses': !(0, runtime_1.exists)(json, 'businesses') ? undefined : (json['businesses'].map(_1.BusinessFromJSON)),
        'icon': !(0, runtime_1.exists)(json, 'icon') ? undefined : json['icon'],
        'headerImage': !(0, runtime_1.exists)(json, 'headerImage') ? undefined : json['headerImage'],
    };
}
exports.UpdateBusinessesImagesBodyFromJSONTyped = UpdateBusinessesImagesBodyFromJSONTyped;
function UpdateBusinessesImagesBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'businesses': value.businesses === undefined ? undefined : (value.businesses.map(_1.BusinessToJSON)),
        'icon': value.icon,
        'headerImage': value.headerImage,
    };
}
exports.UpdateBusinessesImagesBodyToJSON = UpdateBusinessesImagesBodyToJSON;
