import React, {ReactElement} from "react";
import {MenuOrder, OrderDiscountType} from "@devour/client";

interface Props {
	menuOrder: MenuOrder;
}

function MenuOrderPricing(props: Props): ReactElement {

	function renderDiscounts(type: OrderDiscountType): JSX.Element {
		const discounts = props.menuOrder.discounts?.filter((discount) => discount.type === type);

		if (!discounts.length) {
			return null;
		}

		return (
			<div className="menu-order-pricing_discounts">
				{discounts.map((discount, index) => (
					<div
						key={`discount-${index}`}
						className="menu-order-pricing_discounts_item"
					>
						<div className="menu-order-pricing_discounts_item_label">
							({type}) {discount.label} (Origin: {discount.origin})
						</div>
						<div className="menu-order-pricing_discounts_item_value">
							- ${discount.amount?.toFixed(2)}
						</div>
					</div>
				))}
			</div>
		)
	}

	function renderServiceFees(): JSX.Element {

		if (!props.menuOrder.serviceFees?.length) {
			return null;
		}

		return (
			<div className="menu-order-pricing_service-fees">
				{props.menuOrder.serviceFees.map((serviceFee, index) => (
					<div
						key={`discount-${index}`}
						className="menu-order-pricing_service-fees_item"
					>
						<div className="menu-order-pricing_service-fees_item_label">
							{serviceFee.type}
						</div>
						<div className="menu-order-pricing_row_value">
							${serviceFee.amount?.toFixed(2)}
						</div>
					</div>
				))}
			</div>
		)
	}

	return (
		<div className="menu-order-pricing">

			<div className="menu-order-pricing_row">
				<div className="menu-order-pricing_row_label">
					Subtotal
				</div>
				<div className="menu-order-pricing_row_value">
					${props.menuOrder.subtotal?.toFixed(2)}
				</div>
			</div>

			{renderDiscounts(OrderDiscountType.ORDER)}

			<div className="menu-order-pricing_row">
				<div className="menu-order-pricing_row_label">
					Tax
				</div>
				<div className="menu-order-pricing_row_value">
					${props.menuOrder.tax?.toFixed(2)}
				</div>
			</div>

			{renderDiscounts(OrderDiscountType.SALESTAX)}

			<div className="menu-order-pricing_row">
				<div className="menu-order-pricing_row_label">
					Service Fees
				</div>
				<div className="menu-order-pricing_row_value">
				</div>
			</div>

			{renderServiceFees()}

			<div className="menu-order-pricing_row">
				<div className="menu-order-pricing_row_label">
					Delivery Charge
				</div>
				<div className="menu-order-pricing_row_value">
					${props.menuOrder.deliveryCharge?.toFixed(2)}
				</div>
			</div>

			{renderDiscounts(OrderDiscountType.DELIVERY)}

			<div className="menu-order-pricing_row">
				<div className="menu-order-pricing_row_label">
					Restaurant Tip
				</div>
				<div className="menu-order-pricing_row_value">
					${props.menuOrder.tipRestaurant?.toFixed(2)}
				</div>
			</div>

			<div className="menu-order-pricing_row">
				<div className="menu-order-pricing_row_label">
					Delivery Tip
				</div>
				<div className="menu-order-pricing_row_value">
					${props.menuOrder.tipDelivery?.toFixed(2)}
				</div>
			</div>

			<div className="menu-order-pricing_row">
				<div className="menu-order-pricing_row_label">
					Grand Total
				</div>
				<div className="menu-order-pricing_row_value">
					${props.menuOrder.grandTotal?.toFixed(2)}
				</div>
			</div>
		</div>
	);
}

export default MenuOrderPricing;
