import {BusinessDays, BusinessPeriod, BusinessServiceAvailability, ErrorType} from "@devour/client";
import React, {ReactElement, useEffect, useState} from "react";
import {NumberFormatValues} from "react-number-format/types/types";
import {useDispatch} from "react-redux";
import FrameOneCheckbox from "../../../components/inputs/FrameOneCheckbox";
import {toTitleCase} from "../../../utils/toTitleCase";
import {cloneDeep} from "lodash";
import {BusinessBodyFormValues} from "./BusinessDetailsHours";
import moment from "moment/moment";
import {addError} from "../../../redux/meta/metaActions";
import {IoAlertCircleOutline, IoPencil} from "react-icons/io5";
import {CiCircleCheck, CiCircleRemove} from "react-icons/ci";
import {RxCross1} from "react-icons/rx";
import {AiOutlinePlus} from "react-icons/ai";
import {PatternFormat} from "react-number-format";

interface Props {
    day: BusinessDays;
    activeDay: BusinessDays | undefined; // used to block action if day is not being edited
    updateActiveDay: (day: BusinessDays | undefined) => void; // used to update or reset the active day
    serviceBlock: BusinessServiceAvailability;
    updateBusinessBodyWithNewAvailabilities: (newAvailabilities: Array<BusinessServiceAvailability>) => void;
    businessBody: BusinessBodyFormValues;
}

interface BusinessAvailabilitiesFormValues {
    timeStart: NumberFormatValues;
    timeEnd: NumberFormatValues;
}

const defaultValues: BusinessAvailabilitiesFormValues = {
    timeStart: {
        floatValue: undefined,
        formattedValue: "",
        value: ""
    },
    timeEnd: {
        floatValue: undefined,
        formattedValue: "",
        value: ""
    },
};

enum BusinessDetailsEditView {
    DEFAULT,
    EDIT
}

function BusinessAvailabilities(props: Props): ReactElement {

    const dispatch = useDispatch();

    // Used to add new time block
    const [formValues, setFormValues] = useState<BusinessAvailabilitiesFormValues>(defaultValues);

    // Make a copy of service availabilities to local state to edit
    const [editTimePeriods, setEditTimePeriods] = useState<Array<BusinessPeriod>>(props?.serviceBlock?.timePeriods ?
        props.serviceBlock.timePeriods : []);

    useEffect(() => {
        if (props?.serviceBlock?.timePeriods) {
            setEditTimePeriods(props.serviceBlock.timePeriods);
        }

    }, [props?.serviceBlock?.timePeriods]);

    /**
     * Resets the local edit state to the starting values, e.g. if a user cancels their edit.
     */
    function resetEditValues(): void {
        setEditTimePeriods(props?.serviceBlock?.timePeriods ? props.serviceBlock.timePeriods : []);
    }

    /**
     * Adds a time block to the local edit state.
     * Important: DOES NOT add it to the parent form unless updateBusinessBodyWithEditState is called.
     */
    async function addTimeBlockToEditState(): Promise<void> {
        // Validation
        if (formValues.timeEnd.value <= formValues.timeStart.value) {
            dispatch(await addError({
                type: ErrorType.APP,
                message: "Closing time is invalid, it needs to be after the opening time.",
            }));
            return;
        }
        if (!moment(formValues.timeStart.formattedValue, 'HH:mm', true).isValid()) {
            dispatch(await addError({
                type: ErrorType.APP,
                message: "Opening time is invalid. Times are in 24 hour format.",
            }));
            return;
        }
        if (!moment(formValues.timeEnd.formattedValue, 'HH:mm', true).isValid()) {
            dispatch(await addError({
                type: ErrorType.APP,
                message: "Closing time is invalid. Times are in 24 hour format.",
            }));
            return;
        }
        if (formValues.timeStart.formattedValue === "24:00" || formValues.timeEnd.formattedValue === "24:00") {
            dispatch(await addError({
                type: ErrorType.APP,
                message: "Time can not exceed 23:59",
            }));
            return;
        }

        const period: BusinessPeriod = {
            timeStart: formValues.timeStart.formattedValue,
            timeEnd: formValues.timeEnd.formattedValue,
        };

        setEditTimePeriods([...editTimePeriods, period]);
    }

    /**
     * Removes a time block to the local edit state.
     */
    function removeTimeBlockFromEditState(deleteIndex: number): void {
        const updatedTimeBlocks = cloneDeep(editTimePeriods);
        updatedTimeBlocks.splice(deleteIndex, 1);

        setEditTimePeriods(updatedTimeBlocks);
    }

    /**
     * Removes all availabilities, if the user flags the day as unavailable.
     */
    function removeAllTimeBlocksFromEditState(): void {
        setEditTimePeriods([]);
    }

    /**
     * Updates the businessBody (form in parent component) with the local edit state
     */
    function updateBusinessBodyWithEditState(): void {

        // Create new availability
        const newAvailability: BusinessServiceAvailability = {
            dayOfWeek: props.day,
            timePeriods: editTimePeriods
        };

        // First clone the existing availabilities
        const availabilities: Array<BusinessServiceAvailability> = cloneDeep(props.businessBody.serviceAvailabilities);

        // Replace the day with newAvailability
        const dayIndex = availabilities.findIndex((service) => service.dayOfWeek === props.day);
        availabilities[dayIndex] = newAvailability;

        // Call the props.updateBusinessBodyWithNewAvailabilities function to update parent form
        props.updateBusinessBodyWithNewAvailabilities(availabilities);

    }

    /**
     * Predicate that returns true if this day is currently being edited.
     */
    function isDayBeingEdited(): boolean {
        return props.activeDay && props.day === props.activeDay;
    }

    /**
     * Handle new time block start time.
     * @param timeStart
     */
    function timeStartOnChange(timeStart: NumberFormatValues): void {
        setFormValues({
            ...formValues,
            timeStart,
        });
    }

    /**
     * Handle new time block end time.
     * @param timeEnd
     */
    function timeEndOnChange(timeEnd: NumberFormatValues): void {
        setFormValues({
            ...formValues,
            timeEnd,
        });
    }

    return (
        <div
            key={props.day}
            className="service-availabilities-input_bd-days-container"
        >
            <div className="service-availabilities-input_bd-days-container_day-row">
                <p>{toTitleCase(props.day)}</p>
                <FrameOneCheckbox
                    onToggle={removeAllTimeBlocksFromEditState}
                    disabled={!isDayBeingEdited()}
                    checked={!editTimePeriods.length}
                >
                    Unavailable
                </FrameOneCheckbox>
            </div>

            {!editTimePeriods.length &&
                <div className="service-availabilities-input_bd-days-container_closed">
                    <IoAlertCircleOutline className="service-availabilities-input_bd-days-container_closed_icon"/>
                    <p>No availability - restaurant is closed on this day.</p>
                </div>
            }

            {editTimePeriods.map((period, index) => {
                return (
                    <div
                        className="service-availabilities-input_bd-days-container_dates"
                        key={`${props.day}-${index}`}
                    >
                        <div className="service-availabilities-input_bd-days-container_dates_inputs">
                            <input
                                value={period.timeStart}
                                placeholder="Start time"
                                disabled={true}
                            />
                            <div className="service-availabilities-input_bd-days-container_dates_inputs_horizontal"/>
                            <input
                                value={period.timeEnd}
                                placeholder="End time"
                                disabled={true}
                            />
                        </div>
                        {isDayBeingEdited() && (
                            <CiCircleRemove
                                onClick={() => removeTimeBlockFromEditState(index)}
                                className="service-availabilities-input_bd-days-container_dates_remove
                                service-availabilities-input_bd-days-container_dates_remove-active"
                            />
                        )}
                    </div>
                );
            })}

            {!props.activeDay && (
                <div
                    className="service-availabilities-input_bd-days-container_add-new
                    service-availabilities-input_bd-days-container_add-new_enabled"
                    onClick={() => {
                        props.updateActiveDay(props.day);
                    }}
                >
                    <IoPencil className="service-availabilities-input_bd-days-container_add-new_icon"/>
                    <p>Edit Availabilities</p>
                </div>
            )}

            {isDayBeingEdited() && (
                <>
                    <div className="service-availabilities-input_bd-days-container_dates_inputs">
                        <PatternFormat
                            className="service-availabilities-input_bd-days-container_active-inputs"
                            value={formValues.timeStart.value}
                            valueIsNumericString={true}
                            type="tel"
                            format="##:##"
                            placeholder="HH:MM"
                            onValueChange={timeStartOnChange}
                        />
                        <div className="service-availabilities-input_bd-days-container_dates_inputs_horizontal"/>
                        <PatternFormat
                            className="service-availabilities-input_bd-days-container_active-inputs"
                            value={formValues.timeEnd.value}
                            valueIsNumericString={true}
                            type="tel"
                            format="##:##"
                            placeholder="HH:MM"
                            onValueChange={timeEndOnChange}
                        />
                        <CiCircleCheck
                            className="service-availabilities-input_bd-days-container_dates_inputs_save"
                            onClick={async () => {
                                await addTimeBlockToEditState();
                                setFormValues(defaultValues);
                            }}
                        />
                    </div>
                    <div
                        className="service-availabilities-input_bd-days-container_add-new
                        service-availabilities-input_bd-days-container_add-new_enabled"
                        onClick={() => {
                            updateBusinessBodyWithEditState();
                            props.updateActiveDay(undefined);
                        }}
                    >
                        <AiOutlinePlus className="service-availabilities-input_bd-days-container_add-new_icon"/>
                        <p>Save Edits</p>
                    </div>
                    <div
                        className="service-availabilities-input_bd-days-container_add-new
                        service-availabilities-input_bd-days-container_add-new_warning"
                        onClick={() => {
                            resetEditValues();
                            props.updateActiveDay(undefined);
                        }}
                    >
                        <RxCross1 className="service-availabilities-input_bd-days-container_add-new_icon"/>
                        <p>Cancel Editing Availability</p>
                    </div>
                </>
            )}

        </div>
    );
}

export default BusinessAvailabilities;