import React, {ChangeEventHandler, ReactElement, useEffect, useState} from "react";
import FrameOneAddressInputGroup from "../../../components/inputs/FrameOneAddressInputGroup";
import {CreateBusinessBodyFrontend, UpdatedBusinessBodyFrontend} from "./BusinessFormValues";
import {Address, Business, BusinessType, HandoffOptions, PhoneNumberBody, UtilsApi} from "@devour/client";
import FrameOnePhoneNumberInput from "../../../components/inputs/FrameOnePhoneNumberInput";
import FrameOneCheckbox from "../../../components/inputs/FrameOneCheckbox";
import {cloneDeep} from "lodash";
import {IoIosOpen, IoIosSave} from "react-icons/io";
import FrameButton from "../../../components/buttons/FrameButton";

interface Props {
    business?: Business;
    businessBody: CreateBusinessBodyFrontend | UpdatedBusinessBodyFrontend;
    businessBodyOnChange: (updatedValues: Partial<CreateBusinessBodyFrontend>) => void;
    inputOnChange: (key: keyof Pick<CreateBusinessBodyFrontend, "email" | "internalName" | "prepTime" | "type" | "timeZone" | "name">) => ChangeEventHandler<HTMLInputElement | HTMLSelectElement>;
    contactPermission?: boolean;
    onSubmitBusiness?: () => Promise<void>;
}

function BusinessDetailsContactInfo(props: Props): ReactElement {
    const [timeZones, setTimeZones] = useState<Array<string>>([]);

    useEffect(() => {
        fetchTimeZones().then();
    }, []);

    async function fetchTimeZones(): Promise<void> {
        try {
            const res = await new UtilsApi().getTimeZones();
            setTimeZones(res);
        } catch (e) {
        }
    }

    function phoneNumberOnChange(phoneNumber: PhoneNumberBody): void {
        props.businessBodyOnChange({
            phoneNumber
        });
    }
    function addressOnChange(address: Address): void {
        props.businessBodyOnChange({
            address
        });
    }

    function handoffOnToggle(handoff: HandoffOptions): void {
        // Attempt to find this option in the checked items array
        const newHandoff: Array<HandoffOptions> = cloneDeep(props.businessBody.handoffOptions);
        const thisIndex = newHandoff.indexOf(handoff);

        if (thisIndex > -1) {
            // Remove this option from list of selected items
            newHandoff.splice(thisIndex, 1);
        } else {
            newHandoff.push(handoff);
        }

        props.businessBodyOnChange({
            handoffOptions: newHandoff,
        })
    }

    function openRestaurantPage(): void {
        const url = `${process.env.REACT_APP_WEB_URL}${props.business?.url}`;
        window.open(url);
    }

    function isRestaurant(): boolean {
        return props.business?.type === BusinessType.RESTAURANT || props.businessBody.type === BusinessType.RESTAURANT;
    }

    return (
        <>
            <div className="business-page_top-buttons">
                {isRestaurant() && (
                    <FrameButton 
                        color="purple"
                        size="narrow"
                        leftIcon={IoIosOpen}
                        disabled={!props.contactPermission}
                        onClick={openRestaurantPage}
                    >
                        View Restaurant
                    </FrameButton>
                )}
                {props.onSubmitBusiness && (
                    <FrameButton
                        color="purple"
                        size="narrow"
                        leftIcon={IoIosSave}
                        disabled={!props.contactPermission}
                        className="business-page_save-contact-button"
                        onClick={props.onSubmitBusiness}
                    >
                        Save Changes
                    </FrameButton>
                )}
            </div>
            <div className="business-page_contact-info">
                <div>
                    <div className="business-page_contact-info_section">
                        <h4>Business Name
                            <span className="create-map-page_optional">
                                Required
                            </span>
                        </h4>
                        <input
                            type="text"
                            placeholder="Name to be displayed to customers"
                            value={props.businessBody?.name}
                            onChange={props.inputOnChange("name")}
                            disabled={!props.contactPermission}
                        />
                    </div>
                    <div className="business-page_contact-info_section">
                        <h4>Type
                            <span className="create-map-page_optional">
                                Required
                            </span>
                        </h4>
                        <select
                            value={props.businessBody?.type}
                            onChange={props.inputOnChange("type")}
                            required={true}
                            disabled={!!props.business || !props.contactPermission}
                        >
                            <option value="">Select Business Type</option>
                            {(Object.values(BusinessType).map((type) => (
                                <option key={type} value={type}>
                                    {type}
                                </option>
                            )))}
                        </select>
                    </div>
                    {!props.business && (
                        <div className="business-page_contact-info_section">
                            <h4>Timezone
                                <span className="create-map-page_optional">
                                Required
                            </span>
                            </h4>
                            <select
                                value={props.businessBody?.timeZone}
                                onChange={props.inputOnChange("timeZone")}
                                required={true}
                            >
                                <option value="">Select Timezone</option>
                                {timeZones.map((zone, index) => (
                                    <option key={`zone-${index}`} value={zone}>
                                        {zone}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}
                    <div className="business-page_contact-info_address">
                        <h4>Address
                            <span className="create-map-page_optional">
                                Required
                            </span>
                        </h4>
                        <FrameOneAddressInputGroup
                            defaultMode="manual"
                            value={props.businessBody?.address}
                            onChange={addressOnChange}
                            disabled={!props.contactPermission}
                        />
                    </div>
                </div>
                <div>
                    <div className="business-page_contact-info_section">
                        <h4>Phone
                            <span className="create-map-page_optional">
                                Required
                            </span>
                        </h4>
                        <FrameOnePhoneNumberInput
                            value={props.businessBody?.phoneNumber}
                            onChange={phoneNumberOnChange}
                            disabled={!props.contactPermission}
                        />
                    </div>
                    <div className="business-page_contact-info_section">
                        <h4>Email address
                            <span className="create-map-page_optional">
                                Required
                            </span>
                        </h4>
                        <input
                            type="email"
                            placeholder="Business's main email address"
                            value={props.businessBody?.email}
                            onChange={props.inputOnChange("email")}
                            required={true}
                            disabled={!props.contactPermission}
                        />
                    </div>
                    <div className="business-page_contact-info_section">
                        <h4>Internal Name</h4>
                        <input
                            type="text"
                            placeholder="Internal store number or identifier"
                            value={props.businessBody?.internalName}
                            onChange={props.inputOnChange("internalName")}
                            disabled={!props.contactPermission}
                        />
                    </div>
                    {isRestaurant() && (
                        <>
                            <div className="business-page_contact-info_section">
                                <h4>Average Prep Time
                                    <span className="create-map-page_optional">
                                        Required
                                    </span>
                                </h4>
                                <input
                                    type="number"
                                    placeholder="The average prep time for orders"
                                    value={props.businessBody?.prepTime}
                                    onChange={props.inputOnChange("prepTime")}
                                    disabled={!props.contactPermission}
                                />
                            </div>
                            <div className="business-page_contact-info_handoff">
                                <h4>Handoff Options
                                    <span className="create-map-page_optional">
                                        Required
                                    </span>
                                </h4>
                                {Object.values(HandoffOptions).map((option) => (
                                    <FrameOneCheckbox
                                        key={option}
                                        onToggle={() => handoffOnToggle(option)}
                                        checked={props.businessBody?.handoffOptions.includes(option)}
                                        disabled={!props.contactPermission}
                                    >
                                        {option}
                                    </FrameOneCheckbox>
                                ))}
                            </div>
                        </>
                    )}
                    <div className="business-page_contact-info_special-instructions">
                        <h4>Enable special instructions</h4>
                        <div className="switch-input pretty p-switch p-fill">
                            <input
                                type="checkbox"
                                onChange={() => props.businessBodyOnChange({
                                    specialInstructions: !props.businessBody?.specialInstructions,
                                })
                                }
                                checked={props.businessBody?.specialInstructions}
                                disabled={!props.contactPermission}
                            />
                            <div className="state">
                                <label/>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

BusinessDetailsContactInfo.defaultProps = {
    contactPermission: true,
} as Props;

export default BusinessDetailsContactInfo;