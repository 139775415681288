"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DocumentToJSON = exports.DocumentFromJSONTyped = exports.DocumentFromJSON = void 0;
var runtime_1 = require("../runtime");
function DocumentFromJSON(json) {
    return DocumentFromJSONTyped(json, false);
}
exports.DocumentFromJSON = DocumentFromJSON;
function DocumentFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'fileName': json['fileName'],
        'url': json['url'],
        'redirectUrl': json['redirectUrl'],
        'name': !(0, runtime_1.exists)(json, 'name') ? undefined : json['name'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'contentType': json['contentType'],
        'size': json['size'],
    };
}
exports.DocumentFromJSONTyped = DocumentFromJSONTyped;
function DocumentToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'fileName': value.fileName,
        'url': value.url,
        'redirectUrl': value.redirectUrl,
        'name': value.name,
        'description': value.description,
        'contentType': value.contentType,
        'size': value.size,
    };
}
exports.DocumentToJSON = DocumentToJSON;
