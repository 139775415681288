"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddressBookToJSON = exports.AddressBookFromJSONTyped = exports.AddressBookFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function AddressBookFromJSON(json) {
    return AddressBookFromJSONTyped(json, false);
}
exports.AddressBookFromJSON = AddressBookFromJSON;
function AddressBookFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'location': !(0, runtime_1.exists)(json, 'location') ? undefined : (0, _1.GeoPointFromJSON)(json['location']),
        'line1': !(0, runtime_1.exists)(json, 'line1') ? undefined : json['line1'],
        'line2': !(0, runtime_1.exists)(json, 'line2') ? undefined : json['line2'],
        'locality': json['locality'],
        'administrativeArea': json['administrativeArea'],
        'postalCode': !(0, runtime_1.exists)(json, 'postalCode') ? undefined : json['postalCode'],
        'country': json['country'],
        'placeId': !(0, runtime_1.exists)(json, 'placeId') ? undefined : json['placeId'],
        'user': json['user'],
        'deliveryInstructions': !(0, runtime_1.exists)(json, 'deliveryInstructions') ? undefined : json['deliveryInstructions'],
        'isDefault': !(0, runtime_1.exists)(json, 'isDefault') ? undefined : json['isDefault'],
        'handoffInstructions': !(0, runtime_1.exists)(json, 'handoffInstructions') ? undefined : (0, _1.DeliveryHandoffInstructionsFromJSON)(json['handoffInstructions']),
    };
}
exports.AddressBookFromJSONTyped = AddressBookFromJSONTyped;
function AddressBookToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'location': (0, _1.GeoPointToJSON)(value.location),
        'line1': value.line1,
        'line2': value.line2,
        'locality': value.locality,
        'administrativeArea': value.administrativeArea,
        'postalCode': value.postalCode,
        'country': value.country,
        'placeId': value.placeId,
        'user': value.user,
        'deliveryInstructions': value.deliveryInstructions,
        'isDefault': value.isDefault,
        'handoffInstructions': (0, _1.DeliveryHandoffInstructionsToJSON)(value.handoffInstructions),
    };
}
exports.AddressBookToJSON = AddressBookToJSON;
