"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FrontendErrorBodyToJSON = exports.FrontendErrorBodyFromJSONTyped = exports.FrontendErrorBodyFromJSON = void 0;
var runtime_1 = require("../runtime");
function FrontendErrorBodyFromJSON(json) {
    return FrontendErrorBodyFromJSONTyped(json, false);
}
exports.FrontendErrorBodyFromJSON = FrontendErrorBodyFromJSON;
function FrontendErrorBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'route': !(0, runtime_1.exists)(json, 'route') ? undefined : json['route'],
        'message': !(0, runtime_1.exists)(json, 'message') ? undefined : json['message'],
        'stack': !(0, runtime_1.exists)(json, 'stack') ? undefined : json['stack'],
        'search': !(0, runtime_1.exists)(json, 'search') ? undefined : json['search'],
    };
}
exports.FrontendErrorBodyFromJSONTyped = FrontendErrorBodyFromJSONTyped;
function FrontendErrorBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'route': value.route,
        'message': value.message,
        'stack': value.stack,
        'search': value.search,
    };
}
exports.FrontendErrorBodyToJSON = FrontendErrorBodyToJSON;
