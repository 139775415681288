import {PasswordType} from "../types/PasswordType";
import {useState} from "react";

export function usePasswordType(defaultPasswordType: PasswordType = "password"): [PasswordType, () => void] {

	const [passwordType, setPasswordType] = useState<PasswordType>(defaultPasswordType);

	/**
	 * Toggle the "type" attribute for password inputs.
	 *
	 */
	function togglePasswordType(): void {
		setPasswordType(passwordType === "password" ? "text" : "password");
	}

	return [passwordType, togglePasswordType];
}
