import React, {ReactElement, ReactNode} from "react";
import {connect, ConnectedProps} from "react-redux";
import {ApiError} from "@devour/client";
import {IStore} from "../../../redux/defaultStore";
import ErrorModal from "./ErrorModal";

interface StateProps {
	errors?: Array<ApiError>;
}

function ErrorModalManager(props: ErrorModalManagerProps): ReactElement {

	/**
	 * Render a modal for each error found in Redux.
	 *
	 * @param e
	 * @param i
	 */
	function renderModal(e: ApiError, i: number): ReactNode {
		return (
			<ErrorModal
				key={`error-modal_${i}`}
				index={i}
				apiError={e}
			/>
		);
	}

	return (
		<React.Fragment>
			{props.errors?.map(renderModal)}
		</React.Fragment>
	);
}

function connector() {
	return connect((store: IStore): StateProps => {
		return {
			errors: store.metaStore.errors,
		}
	});
}

type ErrorModalManagerProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(ErrorModalManager);
