"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderDiscountFrequencyQualifierToJSON = exports.OrderDiscountFrequencyQualifierFromJSONTyped = exports.OrderDiscountFrequencyQualifierFromJSON = exports.OrderDiscountFrequencyQualifier = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var OrderDiscountFrequencyQualifier;
(function (OrderDiscountFrequencyQualifier) {
    OrderDiscountFrequencyQualifier["DAILY"] = "DAILY";
    OrderDiscountFrequencyQualifier["WEEKLY"] = "WEEKLY";
    OrderDiscountFrequencyQualifier["MONTHLY"] = "MONTHLY";
})(OrderDiscountFrequencyQualifier = exports.OrderDiscountFrequencyQualifier || (exports.OrderDiscountFrequencyQualifier = {}));
function OrderDiscountFrequencyQualifierFromJSON(json) {
    return OrderDiscountFrequencyQualifierFromJSONTyped(json, false);
}
exports.OrderDiscountFrequencyQualifierFromJSON = OrderDiscountFrequencyQualifierFromJSON;
function OrderDiscountFrequencyQualifierFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.OrderDiscountFrequencyQualifierFromJSONTyped = OrderDiscountFrequencyQualifierFromJSONTyped;
function OrderDiscountFrequencyQualifierToJSON(value) {
    return value;
}
exports.OrderDiscountFrequencyQualifierToJSON = OrderDiscountFrequencyQualifierToJSON;
