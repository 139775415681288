"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetNftGroupingsResponseAllOfToJSON = exports.GetNftGroupingsResponseAllOfFromJSONTyped = exports.GetNftGroupingsResponseAllOfFromJSON = void 0;
var _1 = require("./");
function GetNftGroupingsResponseAllOfFromJSON(json) {
    return GetNftGroupingsResponseAllOfFromJSONTyped(json, false);
}
exports.GetNftGroupingsResponseAllOfFromJSON = GetNftGroupingsResponseAllOfFromJSON;
function GetNftGroupingsResponseAllOfFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'nftGroupings': (json['nftGroupings'].map(_1.NftGroupingFromJSON)),
    };
}
exports.GetNftGroupingsResponseAllOfFromJSONTyped = GetNftGroupingsResponseAllOfFromJSONTyped;
function GetNftGroupingsResponseAllOfToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'nftGroupings': (value.nftGroupings.map(_1.NftGroupingToJSON)),
    };
}
exports.GetNftGroupingsResponseAllOfToJSON = GetNftGroupingsResponseAllOfToJSON;
