"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateBusinessImagesBodyToJSON = exports.UpdateBusinessImagesBodyFromJSONTyped = exports.UpdateBusinessImagesBodyFromJSON = void 0;
var runtime_1 = require("../runtime");
function UpdateBusinessImagesBodyFromJSON(json) {
    return UpdateBusinessImagesBodyFromJSONTyped(json, false);
}
exports.UpdateBusinessImagesBodyFromJSON = UpdateBusinessImagesBodyFromJSON;
function UpdateBusinessImagesBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'icon': !(0, runtime_1.exists)(json, 'icon') ? undefined : json['icon'],
        'headerImage': !(0, runtime_1.exists)(json, 'headerImage') ? undefined : json['headerImage'],
    };
}
exports.UpdateBusinessImagesBodyFromJSONTyped = UpdateBusinessImagesBodyFromJSONTyped;
function UpdateBusinessImagesBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'icon': value.icon,
        'headerImage': value.headerImage,
    };
}
exports.UpdateBusinessImagesBodyToJSON = UpdateBusinessImagesBodyToJSON;
