"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderDiscountCalculationToJSON = exports.OrderDiscountCalculationFromJSONTyped = exports.OrderDiscountCalculationFromJSON = exports.OrderDiscountCalculation = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var OrderDiscountCalculation;
(function (OrderDiscountCalculation) {
    OrderDiscountCalculation["FIXEDAMOUNT"] = "FIXED_AMOUNT";
    OrderDiscountCalculation["PERCENTAGE"] = "PERCENTAGE";
})(OrderDiscountCalculation = exports.OrderDiscountCalculation || (exports.OrderDiscountCalculation = {}));
function OrderDiscountCalculationFromJSON(json) {
    return OrderDiscountCalculationFromJSONTyped(json, false);
}
exports.OrderDiscountCalculationFromJSON = OrderDiscountCalculationFromJSON;
function OrderDiscountCalculationFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.OrderDiscountCalculationFromJSONTyped = OrderDiscountCalculationFromJSONTyped;
function OrderDiscountCalculationToJSON(value) {
    return value;
}
exports.OrderDiscountCalculationToJSON = OrderDiscountCalculationToJSON;
