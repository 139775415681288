"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DpayMenuOrdersToJSON = exports.DpayMenuOrdersFromJSONTyped = exports.DpayMenuOrdersFromJSON = void 0;
var runtime_1 = require("../runtime");
function DpayMenuOrdersFromJSON(json) {
    return DpayMenuOrdersFromJSONTyped(json, false);
}
exports.DpayMenuOrdersFromJSON = DpayMenuOrdersFromJSON;
function DpayMenuOrdersFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'grandTotalFiat': !(0, runtime_1.exists)(json, 'grandTotalFiat') ? undefined : json['grandTotalFiat'],
        'createdAt': json['createdAt'],
        'dpayUsed': !(0, runtime_1.exists)(json, 'dpayUsed') ? undefined : json['dpayUsed'],
        'onChainUsed': !(0, runtime_1.exists)(json, 'onChainUsed') ? undefined : json['onChainUsed'],
        'etherscanLink': !(0, runtime_1.exists)(json, 'etherscanLink') ? undefined : json['etherscanLink'],
        'vdpayUsed': !(0, runtime_1.exists)(json, 'vdpayUsed') ? undefined : json['vdpayUsed'],
        'walletType': !(0, runtime_1.exists)(json, 'walletType') ? undefined : json['walletType'],
        'dpayTotalFiat': !(0, runtime_1.exists)(json, 'dpayTotalFiat') ? undefined : json['dpayTotalFiat'],
    };
}
exports.DpayMenuOrdersFromJSONTyped = DpayMenuOrdersFromJSONTyped;
function DpayMenuOrdersToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'grandTotalFiat': value.grandTotalFiat,
        'createdAt': value.createdAt,
        'dpayUsed': value.dpayUsed,
        'onChainUsed': value.onChainUsed,
        'etherscanLink': value.etherscanLink,
        'vdpayUsed': value.vdpayUsed,
        'walletType': value.walletType,
        'dpayTotalFiat': value.dpayTotalFiat,
    };
}
exports.DpayMenuOrdersToJSON = DpayMenuOrdersToJSON;
