"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BrandMapBannerImageBodyToJSON = exports.BrandMapBannerImageBodyFromJSONTyped = exports.BrandMapBannerImageBodyFromJSON = void 0;
var runtime_1 = require("../runtime");
function BrandMapBannerImageBodyFromJSON(json) {
    return BrandMapBannerImageBodyFromJSONTyped(json, false);
}
exports.BrandMapBannerImageBodyFromJSON = BrandMapBannerImageBodyFromJSON;
function BrandMapBannerImageBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'image': !(0, runtime_1.exists)(json, 'image') ? undefined : json['image'],
        'imageType': !(0, runtime_1.exists)(json, 'imageType') ? undefined : json['imageType'],
    };
}
exports.BrandMapBannerImageBodyFromJSONTyped = BrandMapBannerImageBodyFromJSONTyped;
function BrandMapBannerImageBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'image': value.image,
        'imageType': value.imageType,
    };
}
exports.BrandMapBannerImageBodyToJSON = BrandMapBannerImageBodyToJSON;
