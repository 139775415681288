import React from "react";
import {BrandPageType} from "@devour/client";

interface Props {
    updateBrandPageType: (pageType: BrandPageType) => void;
    brandPageType: BrandPageType;
}

function MerchantBrandPageTypeSettings(props: Props) {
    const {updateBrandPageType, brandPageType} = props;
    const handleBrandPageTypeChange = (event) => {
        updateBrandPageType(event.target.value);
    };

    return (
        <div className="merchant-brand-title-description_content">
            <h5>
                Set Brand Page Type
            </h5>
            <p className="merchant-brand-title-description_content_subtitle">
                Set the brand page type: <br/>
                For PRIVATE, the brand map page will have the standard layout <br/>
                For PUBLIC, the brand page will have the regular DevourGO menu, header and navigation <br/>
                By default, the page type is set to "PRIVATE".
            </p>
            <select
                value={brandPageType}
                onChange={handleBrandPageTypeChange}
            >
                <option value={BrandPageType.PRIVATE}>Private</option>
                <option value={BrandPageType.PUBLIC}>Public</option>
            </select>
        </div>
    )
}

export default MerchantBrandPageTypeSettings;
