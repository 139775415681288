"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetBusinessesChildrenResponseToJSON = exports.GetBusinessesChildrenResponseFromJSONTyped = exports.GetBusinessesChildrenResponseFromJSON = void 0;
var _1 = require("./");
function GetBusinessesChildrenResponseFromJSON(json) {
    return GetBusinessesChildrenResponseFromJSONTyped(json, false);
}
exports.GetBusinessesChildrenResponseFromJSON = GetBusinessesChildrenResponseFromJSON;
function GetBusinessesChildrenResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'children': (json['children'].map(_1.BusinessFromJSON)),
    };
}
exports.GetBusinessesChildrenResponseFromJSONTyped = GetBusinessesChildrenResponseFromJSONTyped;
function GetBusinessesChildrenResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'children': (value.children.map(_1.BusinessToJSON)),
    };
}
exports.GetBusinessesChildrenResponseToJSON = GetBusinessesChildrenResponseToJSON;
