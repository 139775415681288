import React, {ReactElement} from "react";
import { FaArrowLeft } from "react-icons/fa";
import FrameButton from "../../../components/buttons/FrameButton";
import classNames from "classnames";
import {useNavigate} from "react-router-dom";
import {Business} from "@devour/client";
import {CreateBusinessBodyFrontend, UpdatedBusinessBodyFrontend} from "./BusinessFormValues";

interface Props {
    business?: Business
    businessBody: CreateBusinessBodyFrontend | UpdatedBusinessBodyFrontend;
    businessBodyOnChange: (updatedValues: Partial<CreateBusinessBodyFrontend>) => void;
    onSubmitBusiness?: () => Promise<void>;
}
function BusinessDetailsPageTopBar(props: Props): ReactElement {
    const history = useNavigate();
    return (
        <div className="business-page_top-bar_nav">
            <div>
                <FaArrowLeft
                    className="business-page_top-bar_nav_back"
                    onClick={() => history("/businesses")}
                />
                <h4>{props.business ? "Edit Business" : "New Business"}</h4>
            </div>
            {!props.business && (
                <div>
                    <FrameButton
                        color="purple"
                        size="narrow"
                        className="business-page_top-bar_nav_save"
                        onClick={props.onSubmitBusiness}
                    >
                        Save
                    </FrameButton>
                </div>
            )}
        </div>
    )
}

export default BusinessDetailsPageTopBar;