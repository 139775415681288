import React, {ReactElement, useEffect, useState} from "react";
import {AgreementTerm, AgreementTermsApi, AgreementTermType, CommerceApi, Merchant, Token} from "@devour/client";
import {connect, ConnectedProps} from "react-redux";
import FrameButton from "../../../components/buttons/FrameButton";
import {addError, decrementLoading, incrementLoading} from "../../../redux/meta/metaActions";
import getConfig from "../../../utils/getConfig";
import {StripeAccountObject} from "../../../types/Stripe";
import moment from "moment";
import MerchantAgreeTermsModal from "../../../components/modals/MerchantAgreeTermsModal";
import {IStore} from "../../../redux/defaultStore";

interface StateProps {
	fullToken: Token;
	currentUser: Merchant;
}

function StripeConnectedAccounts(props: StripeConnectedAccountsProps): ReactElement {

	const [accounts, setAccounts] = useState<Array<StripeAccountObject>>([]);
	const [latestMerchantAgreement, setLatestMerchantAgreement] = useState<AgreementTerm>(undefined);
	const [showAgreementTermsModal, setShowAgreementTermsModal] = useState(false);

	useEffect(() => {
		fetchAccounts().then();
	}, []);

	async function getLatestAgreement(): Promise<void> {
		try {
			const res = await new AgreementTermsApi(getConfig(props.fullToken)).getLatestAgreementTerm({
				type: AgreementTermType.MERCHANT,
			});
			setLatestMerchantAgreement(res.agreementTerm);
		} catch (e) {
			console.error(e);
		}
	}

	/**
	 * Get the details for this restaurant from our api.
	 */
	async function fetchAccounts(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new CommerceApi(getConfig()).stripeAccountsList();
			setAccounts(res.accounts as Array<StripeAccountObject>);
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}

	}

	/**
	 * Get Stripe connect link and redirect user browser.
	 * @param accountId
	 */
	async function onStripeAccountOnboarding(accountId: string): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new CommerceApi(getConfig()).stripeAccountsOnboarding({
				id: accountId,
			});
			window.location.href = res.accountUrl;
		} catch (e) {
			props.dispatch(await addError(e));
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * Get Stripe account login link and redirect user browser.
	 * @param accountId
	 */
	async function onStripeAccountLogin(accountId: string): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new CommerceApi(getConfig()).stripeAccountsLogin({
				id: accountId,
			});

			window.open(
				res.loginUrl,
				'_blank'
			);
		} catch (e) {
			props.dispatch(await addError(e));
		}

		props.dispatch(decrementLoading());
	}

	function toggleMerchantAgreeTermsModal(): void {
		setShowAgreementTermsModal(s => !s);
	}

	/**
	 * When user clicks the "Create New Stripe Merchant Account" button, check to make sure they've agreed to the latest
	 * legal doc, and if not, show them the modal prompting them to. If there is no document of this agreement type in
	 * the db yet, or if they have agreed, proceed with the Stripe stuff.
	 *
	 */
	async function createOnClick(): Promise<void> {
		props.dispatch(incrementLoading());
		try {
			const res = await new AgreementTermsApi(getConfig(props.fullToken)).getLatestAgreementTerm({
				type: AgreementTermType.MERCHANT,
			});

			if (res.agreementTerm && (props.currentUser?.latestMerchantAgreementId !== res.agreementTerm.id)) {
				setLatestMerchantAgreement(res.agreementTerm);
				setShowAgreementTermsModal(true);
			} else {
				await onNewAccount();
			}
		} catch (e) {
			console.error(e);
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	async function handleFinishAgree(): Promise<void> {
		setShowAgreementTermsModal(false);
		await onNewAccount();
	}

	async function onNewAccount(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new CommerceApi(getConfig()).stripeAccountsAdd();
			onStripeAccountOnboarding((res.account as StripeAccountObject).id).then();
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	function renderStripeAccountRow(account: StripeAccountObject): JSX.Element {
		return (
			<tr
				key={account.id}
				className="stripe-connected-accounts_table_row"
			>
				<td className="stripe-connected-accounts_table_row_id">
					{account.id}
				</td>
				<td className="stripe-connected-accounts_table_row_created">
					{moment(account.created * 1000).format("LLL")}
				</td>
				<td className="stripe-connected-accounts_table_row_payouts">
					{(account.payouts_enabled) ? "Yes" : "No"}
				</td>
				<td className="stripe-connected-accounts_table_row_account">
					{account.external_accounts?.data[0]?.last4}
				</td>
				<td className="stripe-connected-accounts_table_row_connect">
					<FrameButton
						<React.ButtonHTMLAttributes<HTMLButtonElement>>
						color="gray"
						size="normal"
						onClick={() => onStripeAccountOnboarding(account.id)}
						className="stripe-connected-accounts_table_row_connect_button"
						forwardProps={{type: "button"}}
					>
						Connect
					</FrameButton>
				</td>
				<td className="stripe-connected-accounts_table_row_login">
					{(account.payouts_enabled) && (
						<FrameButton
							<React.ButtonHTMLAttributes<HTMLButtonElement>>
							color="gray"
							size="normal"
							onClick={() => onStripeAccountLogin(account.id)}
							className="stripe-connected-accounts_table_row_login_button"
							forwardProps={{type: "button"}}
						>
							Login
						</FrameButton>
					)}
				</td>
			</tr>
		);
	}

	return (
		<React.Fragment>
			<MerchantAgreeTermsModal
				isOpen={showAgreementTermsModal}
				agreement={latestMerchantAgreement}
				onClose={toggleMerchantAgreeTermsModal}
				onDone={handleFinishAgree}
			/>

			{/*{latestMerchantAgreement === undefined ? (*/}
			{/*	<div className="account-page-loading-legal">*/}
			{/*		<div className="spinner"/>*/}
			{/*	</div>*/}
			{/*) : (*/}
				<div className="stripe-connected-accounts">
					<h3>Stripe Connected Merchant Accounts</h3>

					<FrameButton
						<React.ButtonHTMLAttributes<HTMLButtonElement>>
						color="gray"
						size="normal"
						onClick={createOnClick}
						className="stripe-connected-accounts_create-button"
						forwardProps={{type: "button"}}
					>
						Create New Stripe Merchant Account
					</FrameButton>

					<table className="stripe-connected-accounts_table">
						<thead>
						<tr>
							<th>Stripe Account ID</th>
							<th>Created</th>
							<th>Payout Enabled?</th>
							<th>Last 4 Account #</th>
							<th>Bank Account</th>
							<th>Dashboard</th>
						</tr>
						</thead>
						<tbody>
						{accounts.map(renderStripeAccountRow)}
						</tbody>
					</table>
				</div>
			{/*)}*/}

		</React.Fragment>
	);
}

function connector() {
	return connect((store: IStore): StateProps => {
		return {
			fullToken: store.authStore.fullToken,
			currentUser: store.metaStore.currentUser as Merchant,
		}
	});
}

type StripeConnectedAccountsProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(StripeConnectedAccounts);
