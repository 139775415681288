"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpsertNftRewardBodyToJSON = exports.UpsertNftRewardBodyFromJSONTyped = exports.UpsertNftRewardBodyFromJSON = void 0;
var runtime_1 = require("../runtime");
function UpsertNftRewardBodyFromJSON(json) {
    return UpsertNftRewardBodyFromJSONTyped(json, false);
}
exports.UpsertNftRewardBodyFromJSON = UpsertNftRewardBodyFromJSON;
function UpsertNftRewardBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'nftTracker': json['nftTracker'],
        'contractAbi': json['contractAbi'],
        'image': !(0, runtime_1.exists)(json, 'image') ? undefined : json['image'],
        'origin': !(0, runtime_1.exists)(json, 'origin') ? undefined : json['origin'],
        'title': !(0, runtime_1.exists)(json, 'title') ? undefined : json['title'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'isEnabled': !(0, runtime_1.exists)(json, 'isEnabled') ? undefined : json['isEnabled'],
        'redemptionAnimation': !(0, runtime_1.exists)(json, 'redemptionAnimation') ? undefined : json['redemptionAnimation'],
        'transitionEffectEnabled': !(0, runtime_1.exists)(json, 'transitionEffectEnabled') ? undefined : json['transitionEffectEnabled'],
        'prizes': json['prizes'],
    };
}
exports.UpsertNftRewardBodyFromJSONTyped = UpsertNftRewardBodyFromJSONTyped;
function UpsertNftRewardBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'nftTracker': value.nftTracker,
        'contractAbi': value.contractAbi,
        'image': value.image,
        'origin': value.origin,
        'title': value.title,
        'description': value.description,
        'isEnabled': value.isEnabled,
        'redemptionAnimation': value.redemptionAnimation,
        'transitionEffectEnabled': value.transitionEffectEnabled,
        'prizes': value.prizes,
    };
}
exports.UpsertNftRewardBodyToJSON = UpsertNftRewardBodyToJSON;
