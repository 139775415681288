import React, {FormEvent, ReactElement} from "react";
import {Business} from "@devour/client";
import {useDispatch} from "react-redux";
import FrameButton from "../buttons/FrameButton";
import {addError} from "../../redux/meta/metaActions";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameOneModal from "./modalComponents/FrameOneModal";
import FrameModalBody from "./modalComponents/FrameModalBody";

interface Props {
    business?: Business;
    onClose: () => void;
    onUpdate: () => void;
    isOpen: boolean;
}

function BusinessChildrenImageUpdateConfirmModal(props: Props): ReactElement {

    const dispatch = useDispatch();

    async function onFormSubmit(e: FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();
        try {
            props.onUpdate();
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            props.onClose();
        }
    }

    return (
        <FrameOneModal
            isOpen={props.isOpen}
            toggle={props.onClose}
            size="sm"
            contentClassName="business-disable-modal business-page-children-locations_modal"
        >
            <FrameModalHeader
                title="Confirm Image Update"
                toggle={props.onClose}
            />

            <FrameModalBody className="business-disable-modal_body">

                <form className="business-page-children-locations_form" onSubmit={onFormSubmit}>

                    <p>
                        Confirming will update the icon and header images of all child locations of this business. <br/>
                        The icon and header images will be updated to that of this business.
                    </p>

                    <FrameButton
                        <React.ButtonHTMLAttributes<HTMLButtonElement>>
                        color="success"
                        size="large"
                        className="business-page-children-locations_update-button"
                        forwardProps={{type: "submit"}}
                    >
                        Confirm Update
                    </FrameButton>
                </form>
            </FrameModalBody>

        </FrameOneModal>
    );
}

export default BusinessChildrenImageUpdateConfirmModal;
