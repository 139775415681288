"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderDiscountSearchEssentialToJSON = exports.OrderDiscountSearchEssentialFromJSONTyped = exports.OrderDiscountSearchEssentialFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function OrderDiscountSearchEssentialFromJSON(json) {
    return OrderDiscountSearchEssentialFromJSONTyped(json, false);
}
exports.OrderDiscountSearchEssentialFromJSON = OrderDiscountSearchEssentialFromJSON;
function OrderDiscountSearchEssentialFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'label': json['label'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'amount': json['amount'],
        'priority': json['priority'],
        'isDisplayedGoFrens': !(0, runtime_1.exists)(json, 'isDisplayedGoFrens') ? undefined : json['isDisplayedGoFrens'],
        'expiresAt': !(0, runtime_1.exists)(json, 'expiresAt') ? undefined : json['expiresAt'],
        'nftGroupings': !(0, runtime_1.exists)(json, 'nftGroupings') ? undefined : json['nftGroupings'],
        'calculation': (0, _1.OrderDiscountCalculationFromJSON)(json['calculation']),
    };
}
exports.OrderDiscountSearchEssentialFromJSONTyped = OrderDiscountSearchEssentialFromJSONTyped;
function OrderDiscountSearchEssentialToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'label': value.label,
        'description': value.description,
        'amount': value.amount,
        'priority': value.priority,
        'isDisplayedGoFrens': value.isDisplayedGoFrens,
        'expiresAt': value.expiresAt,
        'nftGroupings': value.nftGroupings,
        'calculation': (0, _1.OrderDiscountCalculationToJSON)(value.calculation),
    };
}
exports.OrderDiscountSearchEssentialToJSON = OrderDiscountSearchEssentialToJSON;
