"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SiteConfigurationToJSON = exports.SiteConfigurationFromJSONTyped = exports.SiteConfigurationFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function SiteConfigurationFromJSON(json) {
    return SiteConfigurationFromJSONTyped(json, false);
}
exports.SiteConfigurationFromJSON = SiteConfigurationFromJSON;
function SiteConfigurationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'configKey': (0, _1.SiteConfigurationKeyFromJSON)(json['configKey']),
        'asset': !(0, runtime_1.exists)(json, 'asset') ? undefined : (0, _1.AssetFromJSON)(json['asset']),
        'url': !(0, runtime_1.exists)(json, 'url') ? undefined : json['url'],
        'buttonText': !(0, runtime_1.exists)(json, 'buttonText') ? undefined : json['buttonText'],
        'title': !(0, runtime_1.exists)(json, 'title') ? undefined : json['title'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'data': !(0, runtime_1.exists)(json, 'data') ? undefined : json['data'],
        'percentage': !(0, runtime_1.exists)(json, 'percentage') ? undefined : json['percentage'],
        'amount': !(0, runtime_1.exists)(json, 'amount') ? undefined : json['amount'],
        'user': !(0, runtime_1.exists)(json, 'user') ? undefined : json['user'],
        'business': !(0, runtime_1.exists)(json, 'business') ? undefined : json['business'],
        'nftGroupings': !(0, runtime_1.exists)(json, 'nftGroupings') ? undefined : json['nftGroupings'],
        'startDate': !(0, runtime_1.exists)(json, 'startDate') ? undefined : json['startDate'],
        'endDate': !(0, runtime_1.exists)(json, 'endDate') ? undefined : json['endDate'],
        'bonusDpayType': !(0, runtime_1.exists)(json, 'bonusDpayType') ? undefined : (0, _1.BonusDpayOptionFromJSON)(json['bonusDpayType']),
        'phoneNumber': !(0, runtime_1.exists)(json, 'phoneNumber') ? undefined : (0, _1.PhoneNumberFromJSON)(json['phoneNumber']),
    };
}
exports.SiteConfigurationFromJSONTyped = SiteConfigurationFromJSONTyped;
function SiteConfigurationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'configKey': (0, _1.SiteConfigurationKeyToJSON)(value.configKey),
        'asset': (0, _1.AssetToJSON)(value.asset),
        'url': value.url,
        'buttonText': value.buttonText,
        'title': value.title,
        'description': value.description,
        'data': value.data,
        'percentage': value.percentage,
        'amount': value.amount,
        'user': value.user,
        'business': value.business,
        'nftGroupings': value.nftGroupings,
        'startDate': value.startDate,
        'endDate': value.endDate,
        'bonusDpayType': (0, _1.BonusDpayOptionToJSON)(value.bonusDpayType),
        'phoneNumber': (0, _1.PhoneNumberToJSON)(value.phoneNumber),
    };
}
exports.SiteConfigurationToJSON = SiteConfigurationToJSON;
