"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AgreeToLegalTermBodyToJSON = exports.AgreeToLegalTermBodyFromJSONTyped = exports.AgreeToLegalTermBodyFromJSON = void 0;
var _1 = require("./");
function AgreeToLegalTermBodyFromJSON(json) {
    return AgreeToLegalTermBodyFromJSONTyped(json, false);
}
exports.AgreeToLegalTermBodyFromJSON = AgreeToLegalTermBodyFromJSON;
function AgreeToLegalTermBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, _1.AgreementTermTypeFromJSON)(json['type']),
        'agreementTerm': json['agreementTerm'],
    };
}
exports.AgreeToLegalTermBodyFromJSONTyped = AgreeToLegalTermBodyFromJSONTyped;
function AgreeToLegalTermBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, _1.AgreementTermTypeToJSON)(value.type),
        'agreementTerm': value.agreementTerm,
    };
}
exports.AgreeToLegalTermBodyToJSON = AgreeToLegalTermBodyToJSON;
