type CharsetName = "lowercase" | "uppercase" | "numbers" | "symbols";

const charsets: Array<{ name: CharsetName; chars: string; }> = [
	{
		name: "lowercase",
		chars: "abcdefghjkmnopqrstuvwxyz",
	},
	{
		name: "uppercase",
		chars: "ABCDEFGHJKLMNOPQRSTUVWXYZ",
	},
	{
		name: "numbers",
		chars: "0123456789",
	},
	{
		name: "symbols",
		chars: "!$%&?+*#-/",
	}
];

export default function generateCrytoSecureString(charsetOptions: Array<CharsetName>, stringLength: number): string {
	const cryptoValues = window.crypto.getRandomValues(new Uint32Array(stringLength)) as Uint32Array as unknown as Array<number>;
	const charset: string = charsets
		.filter(element => charsetOptions.includes(element.name))
		.map(element => element.chars)
		.join("");
	let randomString: string = "";
	for (const randomInt of cryptoValues) {
		const randomCharacter: string = charset[randomInt % charset.length];
		randomString = randomString + randomCharacter;
	}
	return randomString;
}

