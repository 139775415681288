"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchRestaurantsResponseAllOfToJSON = exports.SearchRestaurantsResponseAllOfFromJSONTyped = exports.SearchRestaurantsResponseAllOfFromJSON = void 0;
var _1 = require("./");
function SearchRestaurantsResponseAllOfFromJSON(json) {
    return SearchRestaurantsResponseAllOfFromJSONTyped(json, false);
}
exports.SearchRestaurantsResponseAllOfFromJSON = SearchRestaurantsResponseAllOfFromJSON;
function SearchRestaurantsResponseAllOfFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'restaurantsPaginated': (json['restaurantsPaginated'].map(_1.RestaurantSearchResultFromJSON)),
        'restaurantsGoFrens': (json['restaurantsGoFrens'].map(_1.RestaurantSearchResultFromJSON)),
        'qualifiedPromos': (json['qualifiedPromos'].map(_1.OrderDiscountSearchEssentialFromJSON)),
        'secretMenuItems': (json['secretMenuItems'].map(_1.MenuItemSearchEssentialFromJSON)),
    };
}
exports.SearchRestaurantsResponseAllOfFromJSONTyped = SearchRestaurantsResponseAllOfFromJSONTyped;
function SearchRestaurantsResponseAllOfToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'restaurantsPaginated': (value.restaurantsPaginated.map(_1.RestaurantSearchResultToJSON)),
        'restaurantsGoFrens': (value.restaurantsGoFrens.map(_1.RestaurantSearchResultToJSON)),
        'qualifiedPromos': (value.qualifiedPromos.map(_1.OrderDiscountSearchEssentialToJSON)),
        'secretMenuItems': (value.secretMenuItems.map(_1.MenuItemSearchEssentialToJSON)),
    };
}
exports.SearchRestaurantsResponseAllOfToJSON = SearchRestaurantsResponseAllOfToJSON;
