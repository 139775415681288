import React, {ReactElement, useEffect, useState} from "react";
import {Business, BusinessesApi, GetBusinessesChildrenResponse} from "@devour/client";
import {useDispatch, useSelector} from "react-redux";
import {addError, decrementLoading, incrementLoading} from "../../../redux/meta/metaActions";
import getConfig from "../../../utils/getConfig";
import FrameButton from "../../../components/buttons/FrameButton";
import BusinessChildrenImageUpdateConfirmModal
    from "../../../components/modals/BusinessChildrenImageUpdateConfirmModal";
import {IStore} from "../../../redux/defaultStore";

interface Props {
    business: Business;
}

function BusinessDetailsChildLocations(props: Props): ReactElement {
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const dispatch = useDispatch();
    const [businessChildren, setBusinessChildren] = useState<GetBusinessesChildrenResponse>();
    const [businessParentName, setBusinessParentName] = useState<string>("");
    const [showConfirmUpdateImagesModal, setShowConfirmUpdateImagesModal] = useState<boolean>(false);

    useEffect(() => {
        if (props.business) {
            void fetchChildrenLocations();
        }
    }, [props.business]);

    useEffect(() => {
        if (props.business?.parent) {
            void fetchBusinessParentName();
        }
    }, [props.business?.parent]);

    /**
     * Get the details for this restaurant from our api.
     */
    async function fetchChildrenLocations(): Promise<void> {
        dispatch(incrementLoading());

        try {
            const res = await new BusinessesApi(getConfig()).getBusinessChildren({
                id: props.business?.id,
            });
            setBusinessChildren(res);

        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }
    }

    async function fetchBusinessParentName(): Promise<void> {
        try {
            const res = await new BusinessesApi(getConfig()).getBusiness({
                id: props.business?.parent,
            });

            setBusinessParentName(res.name);

        } catch (e) {
            dispatch(await addError(e));
        }
    }

    async function updateChildrenLocationImages(): Promise<void> {
        try {
            dispatch(incrementLoading());
            await new BusinessesApi(getConfig(fullToken)).updateBusinessesImages(
                {
                    updateBusinessesImagesBody: {
                        businesses: businessChildren.children,
                        icon: props.business.icon ? props.business.icon.id : null,
                        headerImage: props.business.headerImage ? props.business.headerImage.id : null
                    }
                });
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }
    }

    return (
        <>
            <BusinessChildrenImageUpdateConfirmModal
                isOpen={showConfirmUpdateImagesModal}
                business={props.business}
                onClose={() => setShowConfirmUpdateImagesModal(prev => !prev)}
                onUpdate={updateChildrenLocationImages}
            />

            <div className="business-page-children-locations">
                <h4>Parent Location: {props.business?.parent ? businessParentName : "n/a"}</h4>
                <br/>
                <h4>Number of Child Locations: {businessChildren?.children.length}</h4>
                <br/>

                <FrameButton
                    disabled={businessChildren?.children.length === 0}
                    onClick={() => setShowConfirmUpdateImagesModal(prev => !prev)} color={"success"}
                    size={"large"}
                >
                    Update Icon and Header Images of All Child Locations
                </FrameButton>
            </div>
        </>

    )
}

export default BusinessDetailsChildLocations;