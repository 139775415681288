"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ItsaCheckmateLocationToJSON = exports.ItsaCheckmateLocationFromJSONTyped = exports.ItsaCheckmateLocationFromJSON = void 0;
function ItsaCheckmateLocationFromJSON(json) {
    return ItsaCheckmateLocationFromJSONTyped(json, false);
}
exports.ItsaCheckmateLocationFromJSON = ItsaCheckmateLocationFromJSON;
function ItsaCheckmateLocationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'name': json['name'],
        'address': json['address'],
        'city': json['city'],
        'state': json['state'],
        'url': json['url'],
        'brand': json['brand'],
        'time_zone': json['time_zone'],
        'pos': json['pos'],
        'phone_number': json['phone_number'],
    };
}
exports.ItsaCheckmateLocationFromJSONTyped = ItsaCheckmateLocationFromJSONTyped;
function ItsaCheckmateLocationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'name': value.name,
        'address': value.address,
        'city': value.city,
        'state': value.state,
        'url': value.url,
        'brand': value.brand,
        'time_zone': value.time_zone,
        'pos': value.pos,
        'phone_number': value.phone_number,
    };
}
exports.ItsaCheckmateLocationToJSON = ItsaCheckmateLocationToJSON;
