import {ReactElement, useCallback, useEffect, useRef} from "react";
import {connect, ConnectedProps} from "react-redux";
import {useLocation} from "react-router-dom";
import {Token, TokensApi} from "@devour/client";
import {IStore} from "../../redux/defaultStore";
import getConfig from "../../utils/getConfig";
import {logout} from "../../redux/auth/authActions";

// Define the routes where the token manager should not be active
const tokenManagerHiddenRoutes: string[] = ["", "/"];
// Define the interval for checking the token validity
const tokenCheckInterval: number = 60000;

// Define the state properties
interface StateProps {
	fullToken: Token;
}

// Main function for the Token Manager component
function TokenManager(props: TokenManagerProps): ReactElement {

	// Get the current location from the router
	const location = useLocation();

	// Destructure the full token and dispatch function from the props
	const {fullToken, dispatch} = props;

	// Define a ref to track if a request is currently in flight
	const requestInFlight = useRef(false);

	// Define a function to check if the token is valid
	const checkTokenValid = useCallback(async () => {
		// If there's no token, return immediately
		if (!fullToken?.token) {
			return;
		}

		// If a request is already in flight, return immediately
		if (requestInFlight.current) {
			return;
		}

		// Set the request in flight ref to true
		requestInFlight.current = true;

		try {
			// Make a request to check if the token is expired
			const res = await new TokensApi(getConfig()).checkTokenExpiration({
				tokenBody: {
					token: fullToken.token,
				},
			});

			// If the token is expired, return true
			if (res.expired === true) {
				return true;
			}
		} catch (e) {
			// If an error occurs, handle it here
		} finally {
			// After the request is finished, set the request in flight ref to false
			requestInFlight.current = false;
		}

		// If the token is not expired, return false
		return false;
	}, [fullToken?.token]);

	// Use an effect to check the token validity when the location changes
	useEffect(() => {

		// If the current route is hidden from the token manager, return immediately
		if (!tokenManagerHiddenRoutes.includes(location.pathname)) {

			// Check if the token is valid
			checkTokenValid().then(isExpired => {

				// If the token is expired, dispatch a logout action
				if (isExpired) {
					dispatch(logout());
				}
			}).catch();

			// Set an interval to check the token validity
			const interval = setInterval(() => {
				checkTokenValid().then(isExpired => {

					// If the token is expired, dispatch a logout action
					if (isExpired) {
						dispatch(logout());
					}

				}).catch();
			}, tokenCheckInterval);

			// Clear the interval when the component unmounts
			return () => clearInterval(interval);
		}
	}, [location.pathname, checkTokenValid, dispatch]);

	// The component doesn't render anything
	return null;
}

function connector() {
	return connect((store: IStore): StateProps => {
		return {
			fullToken: store.authStore.fullToken,
		}
	});
}

type TokenManagerProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(TokenManager);
