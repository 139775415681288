"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DiscordInfoResponseToJSON = exports.DiscordInfoResponseFromJSONTyped = exports.DiscordInfoResponseFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function DiscordInfoResponseFromJSON(json) {
    return DiscordInfoResponseFromJSONTyped(json, false);
}
exports.DiscordInfoResponseFromJSON = DiscordInfoResponseFromJSON;
function DiscordInfoResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'discord': !(0, runtime_1.exists)(json, 'discord') ? undefined : (0, _1.DiscordInfoFromJSON)(json['discord']),
    };
}
exports.DiscordInfoResponseFromJSONTyped = DiscordInfoResponseFromJSONTyped;
function DiscordInfoResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'discord': (0, _1.DiscordInfoToJSON)(value.discord),
    };
}
exports.DiscordInfoResponseToJSON = DiscordInfoResponseToJSON;
