import React, {ReactElement, ReactNode, useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {Brand, BrandMap, BrandsApi, GetBrandMapsResponse, Token} from "@devour/client";
import {useParams} from "react-router";
import {useNavigate} from "react-router-dom";
import {addError} from "../../redux/meta/metaActions";
import getConfig from "../../utils/getConfig";
import AuthHeaderLogo from "../../components/auth/AuthHeaderLogo";
import BrandMapCard from "../../components/brands/BrandMapCard";
import {TfiMapAlt} from "react-icons/tfi";
import FrameButton from "../../components/buttons/FrameButton";

interface StateProps {
	fullToken: Token;
}

function ManageBrandMapsPage(props: ManageBrandMapsPageProps): ReactElement {

	const {brandId} = useParams<{brandId?: string}>();
	const history = useNavigate();
	const [localLoading, setLocalLoading] = useState(false);
	const [brand, setBrand] = useState<Brand>(undefined);
	const [mapsRes, setMapsRes] = useState<GetBrandMapsResponse>(undefined);

	/**
	 * On load or url param change, call api to get the maps for the specific brand, if the brand id exists.
	 *
	 */
	useEffect(() => {
		if (!brandId) {
			history("/brands");
			return;
		}

		void getBrandMaps();
	}, [brandId]);

	/**
	 * Gets the maps for this merchant & brand combo & saves them to state.
	 *
	 */
	async function getBrandMaps(): Promise<void> {
		setLocalLoading(true);

		try {
			const brandRes = await new BrandsApi(getConfig(props.fullToken)).getBrand({
				id: brandId,
			});
			const mapsRes = await new BrandsApi(getConfig(props.fullToken)).getBrandMaps({
				id: brandId,
			});

			setBrand(brandRes);
			setMapsRes(mapsRes);
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			setLocalLoading(false);
		}
	}

	function renderBrandMapCard(brandMap: BrandMap, i: number): ReactNode {
		return (
			<div
				key={`brand-${i}`}
				className="manage-brand-maps_content_map-list_card"
			>
				<BrandMapCard brandMap={brandMap}/>
			</div>
		);
	}

	return (
		<div className="manage-brand-maps">
			<div className="manage-brand-maps_spacer-top">
				<AuthHeaderLogo/>
			</div>

			<div className="manage-brand-maps_content">
				{mapsRes === undefined ? (
					<div className="manage-brand-maps_content_loading">
						<div className="spinner"/>
					</div>
				) : (
					<React.Fragment>
						<div className="manage-brand-maps_content_header">
							<h3>
								{`Viewing Maps for ${brand?.name}`}
							</h3>

							<FrameButton
								color="purple"
								size="normal"
								to={`/brands/${brandId}/maps/create`}
							>
								Create Map
							</FrameButton>
						</div>

						<div className="manage-brand-maps_content_map-list">
							{mapsRes?.brandMaps.length > 0 ? mapsRes?.brandMaps.map(renderBrandMapCard) : (
								<div className="manage-brand-maps_content_map-list_empty">
									<TfiMapAlt className="manage-brand-maps_content_map-list_empty_icon"/>

									<h4 className="manage-brand-maps_content_map-list_empty_message">
										You haven't created any maps for this brand yet.
										<br/>
										Use the "Create Map" button above to get started!
									</h4>
								</div>
							)}
						</div>
					</React.Fragment>
				)}
			</div>
		</div>
	);
}

function connector() {
	return connect((store: IStore): StateProps => {
		return {
			fullToken: store.authStore.fullToken,
		}
	});
}

type ManageBrandMapsPageProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(ManageBrandMapsPage);
