"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BrandMapColorThemeToJSON = exports.BrandMapColorThemeFromJSONTyped = exports.BrandMapColorThemeFromJSON = exports.BrandMapColorTheme = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var BrandMapColorTheme;
(function (BrandMapColorTheme) {
    BrandMapColorTheme["LIGHT"] = "LIGHT";
    BrandMapColorTheme["DARK"] = "DARK";
})(BrandMapColorTheme = exports.BrandMapColorTheme || (exports.BrandMapColorTheme = {}));
function BrandMapColorThemeFromJSON(json) {
    return BrandMapColorThemeFromJSONTyped(json, false);
}
exports.BrandMapColorThemeFromJSON = BrandMapColorThemeFromJSON;
function BrandMapColorThemeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.BrandMapColorThemeFromJSONTyped = BrandMapColorThemeFromJSONTyped;
function BrandMapColorThemeToJSON(value) {
    return value;
}
exports.BrandMapColorThemeToJSON = BrandMapColorThemeToJSON;
