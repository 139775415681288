import React, {ChangeEventHandler, ReactElement, FormEvent, useEffect, useState} from "react";
import {
	MenuCategory,
	MenuItem,
	CreateMenuItemBody,
	GetMenuResponse,
	MenusApi,
	UsersApi,
	NftOwnershipInformation,
} from "@devour/client";
import {useDispatch} from "react-redux";
import FrameButton from "../buttons/FrameButton";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/metaActions";
import getConfig from "../../utils/getConfig";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameOneModal from "./modalComponents/FrameOneModal";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameOneSelect from "../inputs/FrameOneSelect";
import SelectOptionsFactory, {ISelectOption} from "../inputs/SelectOptionsFactory";
import ImageUploadCard from "../cards/ImageUploadCard";
import FrameOneSwitchInput from "../inputs/FrameOneSwitchInput";
import SelectNftGroupingsModal from "./SelectNftGroupingsModal";
import {posLockedFields} from "../../utils/posLockedFields";

const defaultValues: CreateMenuItemBody = {
	category: "",
	images: [],
	name: "",
	description: "",
	sortOrder: 0,
	price: 0,
	taxRate: 0,
	isAlcohol: false,
	isActive: true,
	nftGroupings: [],
};

interface Props {
	menuPermission: boolean;
	isChowly: boolean;
	isItsaCheckmate: boolean;
	restaurantMenu: GetMenuResponse;
	menuItem: MenuItem;
	onClose: () => void;
	onUpdate: () => void;
}

function RestaurantMenuItemEditModal(props: Props): ReactElement {
	const dispatch = useDispatch();
	const [showGroupingsModal, setShowGroupingsModal] = useState<boolean>(false);
	const [formValues, setFormValues] = useState<CreateMenuItemBody>(defaultValues);
	const [nftOwnershipResponse, setNftOwnershipResponse] = useState<NftOwnershipInformation>(undefined);

	useEffect(() => {
		getDexPageData().then().catch();
	}, []);

	useEffect(() => {
		if (props.menuItem) {
			setFormValues({
				//@ts-ignore
				category: props.menuItem.category._id,
				images: props.menuItem.images?.map((item) => item.id),
				name: props.menuItem.name,
				description: props.menuItem.description,
				sortOrder: props.menuItem.sortOrder,
				price: props.menuItem.price,
				taxRate: props.menuItem.taxRate,
				isAlcohol: props.menuItem.isAlcohol,
				isActive: props.menuItem.isActive,
				nftGroupings: props.menuItem.nftGroupings,
			});
		}
	}, [props.menuItem]);

	async function getDexPageData(): Promise<void> {
		dispatch(incrementLoading());

		try {
			const res = await new UsersApi(getConfig()).getNftOwnershipsForUser();
			setNftOwnershipResponse(res);
		} catch (e) {
			dispatch(await addError(e));
		} finally {
			dispatch(decrementLoading());
		}
	}

	/**
	 * Handle all text input onChange events.
	 *
	 * @param key
	 */
	function inputOnChange(key: keyof CreateMenuItemBody): ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement> {
		return (e) => {
			setFormValues({
				...formValues,
				[key]: e.target.value,
			});
		}
	}

	async function onFormSubmit(e: FormEvent<HTMLFormElement>): Promise<void> {
		e.preventDefault();
		dispatch(incrementLoading());

		try {
			await new MenusApi(getConfig()).updateMenuItem({
				id: props.menuItem.id,
				createMenuItemBody: {
					...formValues,
					images: formValues.images.filter(i => i),// remove empty items without an ID
					sortOrder: Number(formValues.sortOrder),
					price: Number(formValues.price),
					taxRate: Number(formValues.taxRate),
					nftGroupings: formValues.nftGroupings.filter((gid) => nftOwnershipResponse?.nftGroupings.concat(nftOwnershipResponse?.myGroupings).find(bt => bt.id === gid)),
				},
			});
			setFormValues(defaultValues);
			props.onUpdate();
		} catch (e) {
			dispatch(await addError(e));
		} finally {
			dispatch(decrementLoading());
		}
	}

	/**
	 * For when the admin selects an nft grouping from the modal designed for such.
	 * If the grouping is already selected, it will be removed from the selected list,
	 * if the grouping is not already selected then it will be added to the list.
	 *
	 * @param id
	 */
	function handleSelectGrouping(id: string): void {
		const currentlySelectedGroupings = formValues.nftGroupings;
		const foundIndex = currentlySelectedGroupings.indexOf(id);
		if (foundIndex > -1) {
			currentlySelectedGroupings.splice(foundIndex, 1);
		} else {
			currentlySelectedGroupings.push(id);
		}

		setFormValues({
			...formValues,
			nftGroupings: currentlySelectedGroupings,
		});
	}

	function flattenCategories(options: Array<MenuCategory>, prefix: string = ""): Array<ISelectOption<string>> {
		let newMap: Array<ISelectOption<string>> = [];
		if (!options) {
			return newMap;
		}
		for (const option of options) {
			newMap.push({
				value: option.id,
				label: prefix + option.name,
			});
			if (option.menuSubcategories) {
				newMap = newMap.concat(flattenCategories(option.menuSubcategories, `${prefix + option.name} - `));
			}
		}
		return newMap;
	}

	async function onNewImage(assetId: string): Promise<void> {
		setFormValues({
			...formValues,
			images: [assetId],
		});
	}

	/**
	 * Handle the alcohol status inputs onChange.
	 */
	function alcoholStatusOnChange(): void {
		setFormValues({
			...formValues,
			isAlcohol: !formValues.isAlcohol,
		});
	}

	/**
	 * Handle the alcohol status inputs onChange.
	 */
	function activeStatusOnChange(): void {
		setFormValues({
			...formValues,
			isActive: !formValues.isActive,
		});
	}

	function onModalClose() {
		setShowGroupingsModal(false);
		props.onClose();
	}

	/**
	 * Decide whether field should be disabled.
	 * @param fieldName
	 * @return Since this is a double negative of disabled where `true` means it stops working, return `false` to allow it to work.
	 */
	function isDisabled(fieldName: keyof CreateMenuItemBody): boolean {
		if (!props.menuPermission) {
			return true;
		}
		if (props.isChowly && posLockedFields.chowly.menuItem.includes(fieldName)) {
			return true;
		}
		if (props.isItsaCheckmate && posLockedFields.itsaCheckmate.menuItem.includes(fieldName)) {
			return true;
		}

		return false;
	}

	return (
		<React.Fragment>
			<SelectNftGroupingsModal
				isOpen={showGroupingsModal}
				nftGroupings={nftOwnershipResponse?.nftGroupings.concat(nftOwnershipResponse?.myGroupings)}
				selectedGroupings={formValues?.nftGroupings}
				onSelect={handleSelectGrouping}
				onClose={() => setShowGroupingsModal(false)}
			/>

			<FrameOneModal
				isOpen={!!props.menuItem}
				size="md"
				toggle={onModalClose}
				contentClassName="restaurant-menu-item-edit-modal"
			>
				<FrameModalHeader
					title="Update Menu Item"
					toggle={onModalClose}
				/>

				<FrameModalBody className="restaurant-menu-item-edit-modal_body">

					<form onSubmit={onFormSubmit}>
						<div
							className="restaurant-menu-item-edit-modal_field restaurant-menu-item-edit-modal_category-container"
						>
							<label>Category *</label>
							<FrameOneSelect
								disabled={isDisabled("category")}
								value={formValues.category}
								onChange={inputOnChange("category")}
							>
								<option value="">Menu Category</option>
								<SelectOptionsFactory
									<string>
									options={flattenCategories(props.restaurantMenu?.menus)}
								/>
							</FrameOneSelect>
						</div>

						<div
							className="restaurant-menu-item-edit-modal_field restaurant-menu-item-edit-modal_name-container"
						>
							<label>Name *</label>
							<input
								type="text"
								disabled={isDisabled("name")}
								placeholder="Menu item name..."
								value={formValues.name}
								onChange={inputOnChange("name")}
								required={true}
							/>
						</div>

						<div
							className="restaurant-menu-item-edit-modal_field restaurant-menu-item-edit-modal_image-container"
						>
							<label>Image</label>
							<ImageUploadCard
								disabled={isDisabled("images")}
								asset={props.menuItem?.images?.[0]}
								assetName="Menu Item"
								assetDescription="Menu Item"
								label="Image"
								description="Ideal Image Aspect Ratio: 1:1"
								onNew={onNewImage}
							/>
						</div>

						<div
							className="restaurant-menu-item-edit-modal_field restaurant-menu-item-edit-modal_description-container"
						>
							<label>Description</label>
							<textarea
								disabled={isDisabled("description")}
								placeholder="Short description to describe this menu item to potential customers"
								value={formValues.description}
								onChange={inputOnChange("description")}
							/>
						</div>

						<div
							className="restaurant-menu-item-edit-modal_field restaurant-menu-item-edit-modal_price-container"
						>
							<label>Base Price *</label>
							<input
								type="number"
								placeholder="Price before tax..."
								value={formValues.price}
								onChange={inputOnChange("price")}
								disabled={isDisabled("price")}
								min={0}
								step={0.01}
								required={true}
							/>
						</div>

						<div
							className="restaurant-menu-item-edit-modal_field restaurant-menu-item-edit-modal_tax-rate-container"
						>
							<label>Tax Percentage *</label>
							<input
								type="number"
								placeholder="Tax Percentage..."
								value={formValues.taxRate}
								onChange={inputOnChange("taxRate")}
								disabled={isDisabled("taxRate")}
								min={0}
								step={0.001}
								required={true}
							/>
						</div>

						<div
							className="restaurant-menu-item-edit-modal_field restaurant-menu-item-edit-modal_sort-order-container"
						>
							<label>Sort Order</label>
							<input
								type="number"
								placeholder="Sort order..."
								value={formValues.sortOrder}
								onChange={inputOnChange("sortOrder")}
								disabled={isDisabled("sortOrder")}
								required={false}
							/>
						</div>

						<div
							className="restaurant-menu-item-edit-modal_field restaurant-menu-item-edit-modal_tax-rate-container"
						>
							<label>Alcohol Item?</label>
							<FrameOneSwitchInput
								<"alcohol" | "non-alcohol">
								name="schedule"
								disabled={isDisabled("isAlcohol")}
								value={(formValues.isAlcohol) ? "alcohol" : "non-alcohol"}
								onToggle={alcoholStatusOnChange}
								options={[
									{
										render: "Alcohol",
										value: "alcohol",
									},
									{
										render: "Non-Alcohol",
										value: "non-alcohol",
									},
								]}
							/>
						</div>

						<div
							className="restaurant-menu-item-edit-modal_field restaurant-menu-item-edit-modal_tax-rate-container"
						>
							<label>Active Item?</label>
							<FrameOneSwitchInput
								<"yes" | "no">
								name="isActive"
								disabled={isDisabled("isActive")}
								value={(formValues.isActive) ? "yes" : "no"}
								onToggle={activeStatusOnChange}
								options={[
									{
										render: "Active",
										value: "yes",
									},
									{
										render: "Inactive",
										value: "no",
									},
								]}
							/>
						</div>

						<div
							className="restaurant-menu-item-edit-modal_field restaurant-menu-item-edit-modal_nft-grouping-container"
						>
							<label>
								NFT Groupings
							</label>
							{formValues?.nftGroupings.length < 1 ? (
								<p>
									No nft groupings selected.
								</p>
							) : (
								<ul>
									{formValues?.nftGroupings.map((g, i) => {
										const grouping = nftOwnershipResponse?.nftGroupings.concat(nftOwnershipResponse?.myGroupings).find(bt => bt.id === g);
										if (!grouping) {
											return null;
										}
										return (
											<li key={`grouping_${i}`}>
												{grouping.name}
											</li>
										);
									})}
								</ul>
							)}
							{(props.menuPermission) && (
								<FrameButton
									<React.ButtonHTMLAttributes<HTMLButtonElement>>
									size="narrow"
									color="purple-outline"
									onClick={() => setShowGroupingsModal(true)}
									forwardProps={{
										type: "button",
										disabled: isDisabled("nftGroupings"),
									}}
								>
									Select NFT Groupings
								</FrameButton>
							)}

							<p className="form-tip">
								If configured, users will need to own an nft of one of the selected groupings to be
								eligible to order the item. Leave blank to allow all users to order this item.
							</p>
						</div>

						{(props.menuPermission) && (
							<FrameButton
								<React.ButtonHTMLAttributes<HTMLButtonElement>>
								color="purple"
								size="normal"
								className="restaurant-menu-item-edit-modal_submit-button"
								forwardProps={{type: "submit"}}
							>
								Submit
							</FrameButton>
						)}
					</form>
				</FrameModalBody>

			</FrameOneModal>
		</React.Fragment>
	);
}

export default RestaurantMenuItemEditModal;
