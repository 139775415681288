"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OWLogCategoryToJSON = exports.OWLogCategoryFromJSONTyped = exports.OWLogCategoryFromJSON = exports.OWLogCategory = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var OWLogCategory;
(function (OWLogCategory) {
    OWLogCategory["TIME"] = "TIME";
    OWLogCategory["EVENT"] = "EVENT";
})(OWLogCategory = exports.OWLogCategory || (exports.OWLogCategory = {}));
function OWLogCategoryFromJSON(json) {
    return OWLogCategoryFromJSONTyped(json, false);
}
exports.OWLogCategoryFromJSON = OWLogCategoryFromJSON;
function OWLogCategoryFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.OWLogCategoryFromJSONTyped = OWLogCategoryFromJSONTyped;
function OWLogCategoryToJSON(value) {
    return value;
}
exports.OWLogCategoryToJSON = OWLogCategoryToJSON;
