"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetMenuOrdersOwnSortToJSON = exports.GetMenuOrdersOwnSortFromJSONTyped = exports.GetMenuOrdersOwnSortFromJSON = exports.GetMenuOrdersOwnSort = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var GetMenuOrdersOwnSort;
(function (GetMenuOrdersOwnSort) {
    GetMenuOrdersOwnSort["CreatedAt"] = "createdAt";
    GetMenuOrdersOwnSort["UpdatedAt"] = "updatedAt";
    GetMenuOrdersOwnSort["Status"] = "status";
})(GetMenuOrdersOwnSort = exports.GetMenuOrdersOwnSort || (exports.GetMenuOrdersOwnSort = {}));
function GetMenuOrdersOwnSortFromJSON(json) {
    return GetMenuOrdersOwnSortFromJSONTyped(json, false);
}
exports.GetMenuOrdersOwnSortFromJSON = GetMenuOrdersOwnSortFromJSON;
function GetMenuOrdersOwnSortFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.GetMenuOrdersOwnSortFromJSONTyped = GetMenuOrdersOwnSortFromJSONTyped;
function GetMenuOrdersOwnSortToJSON(value) {
    return value;
}
exports.GetMenuOrdersOwnSortToJSON = GetMenuOrdersOwnSortToJSON;
