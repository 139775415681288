import React, {ReactElement} from "react";
import {MenuOrder} from "@devour/client";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameOneModal from "./modalComponents/FrameOneModal";
import FrameModalBody from "./modalComponents/FrameModalBody";
import MenuOrderItems from "../menuOrders/MenuOrderItems";
import MenuOrderDetails from "../menuOrders/MenuOrderDetails";
import MenuOrderPricing from "../menuOrders/MenuOrderPricing";

interface Props {
	menuOrder: MenuOrder;
	onClose: () => void;
}

function MenuOrderModal(props: Props): ReactElement {

	function onModalClose() {
		props.onClose();
	}

	if (!props.menuOrder) {
		return null;
	}

	return (
		<FrameOneModal
			isOpen={true}
			size="lg"
			toggle={onModalClose}
			contentClassName="menu-order-modal"
		>
			<FrameModalHeader
				title={`Menu Order ${props.menuOrder?.id}`}
				toggle={onModalClose}
			/>

			<FrameModalBody className="menu-order-modal_body">
				<div className="menu-order-modal_row">

					<div className="menu-order-modal_row_order-items">
						<MenuOrderItems menuOrder={props.menuOrder}/>
					</div>

					<div className="menu-order-modal_row_info">
						<MenuOrderDetails menuOrder={props.menuOrder}/>
						<MenuOrderPricing menuOrder={props.menuOrder}/>
					</div>

				</div>
			</FrameModalBody>

		</FrameOneModal>
	);
}

export default MenuOrderModal;
