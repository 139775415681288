"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateBusinessParentBodyToJSON = exports.UpdateBusinessParentBodyFromJSONTyped = exports.UpdateBusinessParentBodyFromJSON = void 0;
var runtime_1 = require("../runtime");
function UpdateBusinessParentBodyFromJSON(json) {
    return UpdateBusinessParentBodyFromJSONTyped(json, false);
}
exports.UpdateBusinessParentBodyFromJSON = UpdateBusinessParentBodyFromJSON;
function UpdateBusinessParentBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'parentId': json['parentId'],
        'purgeUsers': !(0, runtime_1.exists)(json, 'purgeUsers') ? undefined : json['purgeUsers'],
        'purgeStripe': !(0, runtime_1.exists)(json, 'purgeStripe') ? undefined : json['purgeStripe'],
    };
}
exports.UpdateBusinessParentBodyFromJSONTyped = UpdateBusinessParentBodyFromJSONTyped;
function UpdateBusinessParentBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'parentId': value.parentId,
        'purgeUsers': value.purgeUsers,
        'purgeStripe': value.purgeStripe,
    };
}
exports.UpdateBusinessParentBodyToJSON = UpdateBusinessParentBodyToJSON;
