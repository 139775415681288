"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BrandMapAdvertisementBlocksBodyToJSON = exports.BrandMapAdvertisementBlocksBodyFromJSONTyped = exports.BrandMapAdvertisementBlocksBodyFromJSON = void 0;
var runtime_1 = require("../runtime");
function BrandMapAdvertisementBlocksBodyFromJSON(json) {
    return BrandMapAdvertisementBlocksBodyFromJSONTyped(json, false);
}
exports.BrandMapAdvertisementBlocksBodyFromJSON = BrandMapAdvertisementBlocksBodyFromJSON;
function BrandMapAdvertisementBlocksBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'image': !(0, runtime_1.exists)(json, 'image') ? undefined : json['image'],
        'url': !(0, runtime_1.exists)(json, 'url') ? undefined : json['url'],
    };
}
exports.BrandMapAdvertisementBlocksBodyFromJSONTyped = BrandMapAdvertisementBlocksBodyFromJSONTyped;
function BrandMapAdvertisementBlocksBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'image': value.image,
        'url': value.url,
    };
}
exports.BrandMapAdvertisementBlocksBodyToJSON = BrandMapAdvertisementBlocksBodyToJSON;
