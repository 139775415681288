import React, {ChangeEvent, ReactElement, useState} from "react";
import {Asset, NftGrouping} from "@devour/client";
import FrameOneModal from "./modalComponents/FrameOneModal";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameOneTableContainer from "../tables/FrameOneTableContainer";
import FrameOneTableCellImagePreview from "../tables/cells/FrameOneTableCellImagePreview";
import FrameButton from "../buttons/FrameButton";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../../redux/defaultStore";

interface StateProps {
}

interface Props {
	isOpen: boolean;
	nftGroupings: Array<NftGrouping>;
	selectedGroupings: string[];
	onSelect: (id: string) => void;
	onClose: () => void;
}

function SelectNftGroupingsModal(props: SelectNftGroupingsModalProps): ReactElement {

	const [searchString, setSearchString] = useState("");

	function onSearchInputChange(e: ChangeEvent<HTMLInputElement>): void {
		setSearchString(e.target.value);
	}

	return (
		<FrameOneModal
			isOpen={props.isOpen}
			toggle={props.onClose}
			size="lg"
			contentClassName="select-nft-groupings-modal"
		>
			<FrameModalHeader
				title="Select Categories"
				toggle={props.onClose}
			/>

			<FrameModalBody className="select-nft-groupings-modal_body">
				<div className="select-nft-groupings-modal_body_input-container">
					<label>
						Search NFT Groupings
					</label>
					<input
						placeholder="Search NFT Groupings..."
						value={searchString}
						onChange={onSearchInputChange}
					/>
				</div>

				{/*<FrameOneTableContainer*/}
				{/*	data={props?.nftGroupings?.filter((t) => {*/}
				{/*		return t.name?.toLowerCase().includes(searchString.toLowerCase())*/}
				{/*			|| t.description?.toLowerCase().includes(searchString.toLowerCase())*/}
				{/*			|| t.websiteUrl?.toLowerCase().includes(searchString.toLowerCase())*/}
				{/*	})}*/}
				{/*	columnOptions={[*/}
				{/*		{*/}
				{/*			key: "icon",*/}
				{/*			headerValue: "Icon",*/}
				{/*			cellRender: (asset: Asset) => {*/}
				{/*				return (*/}
				{/*					<FrameOneTableCellImagePreview asset={asset}/>*/}
				{/*				);*/}
				{/*			}*/}
				{/*		},*/}
				{/*		{*/}
				{/*			key: "name",*/}
				{/*			headerValue: "Name",*/}
				{/*		},*/}
				{/*		{*/}
				{/*			key: undefined,*/}
				{/*			headerValue: "Select/De-select",*/}
				{/*			cellRender: (t: NftGrouping) => {*/}
				{/*				const isSelected: boolean = props.selectedGroupings.indexOf(t.id) > -1;*/}

				{/*				function clickHelper(): void {*/}
				{/*					props.onSelect(t.id);*/}
				{/*				}*/}

				{/*				return (*/}
				{/*					<div className="frame-one-table-cell">*/}
				{/*						<FrameButton*/}
				{/*							color={isSelected ? "danger" : "success"}*/}
				{/*							size="narrow"*/}
				{/*							onClick={clickHelper}*/}
				{/*						>*/}
				{/*							{isSelected ? "Remove" : "Add"}*/}
				{/*						</FrameButton>*/}
				{/*					</div>*/}
				{/*				);*/}
				{/*			}*/}
				{/*		},*/}
				{/*		{*/}
				{/*			key: "description",*/}
				{/*			headerValue: "Description",*/}
				{/*			cellRender: (d: string) => {*/}
				{/*				return (*/}
				{/*					<div className="table-description-field">*/}
				{/*						{d}*/}
				{/*					</div>*/}
				{/*				);*/}
				{/*			}*/}
				{/*		},*/}
				{/*	]}*/}
				{/*/>*/}
			</FrameModalBody>

			<FrameModalFooter>
				<FrameButton
					color="purple"
					size="normal"
					onClick={props.onClose}
				>
					Done
				</FrameButton>
			</FrameModalFooter>
		</FrameOneModal>
	);
}

function connector() {
	return connect((store: IStore, props: Props): StateProps & Props => {
		return {
			...props,
		}
	});
}

type SelectNftGroupingsModalProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(SelectNftGroupingsModal);
