import React, {ChangeEventHandler, MouseEventHandler, ReactElement} from "react";
import {Asset} from "@devour/client";
import {isAsset} from "../../utils/typeGuards";
import FileInputButton from "../inputs/FileInputButton";
import {IoImageOutline} from "react-icons/io5";
import {FileWithSRC} from "../../utils/renderAssetsHelper";
import FrameButton from "../buttons/FrameButton";
import {FaPlus} from "react-icons/fa";
import classNames from "classnames";
import {FiX} from "react-icons/fi";
import getImageUploadAcceptedTypes from "../../utils/getImageUploadAcceptedTypes";

interface Props<T> {
    image?: Asset | FileWithSRC;
    onImageChange: (key: keyof T) => ChangeEventHandler<HTMLInputElement>;
    onRemoveImage: (key: keyof T) => void;
    fieldName: keyof T;
    optional?: boolean;
    isBackground?: boolean;
    isToastPreview?: boolean;
}

function UploadImagePreview<T>(props: Props<T>): ReactElement {

    const backgroundImage = (props?.image) ? (isAsset(props.image)) ? props.image.url : props.image.imageSRC : undefined;

    return (
            <div
                className={classNames("upload-image-preview has-image", {
                    "is-main": !props.isBackground,
                    "is-background": props.isBackground,
                    "is-toast-preview": props.isToastPreview
                })}
                style={{
                    backgroundImage: (backgroundImage) ? `url(${backgroundImage})` :
                        `url(${process.env.REACT_APP_CDN_URL + (props.isBackground ? "/images/brand-map-promo-bg-image-upload.svg" :
                            "/images/brand-map-advertisement-img-upload-bg.svg")})`,
                }}
            >
                {(!props?.image) ? (
                    <>
                        <IoImageOutline className="upload-image-preview_icon"/>
                        {(props.optional) && (
                            <div className="upload-image-preview_optional">
                                Optional
                            </div>
                        )}
                    </>
                ) : (
                    <FiX
                        className="create-map-page_img-remove-icon"
                        onClick={() => props.onRemoveImage(props.fieldName)}
                    />
                )}
                <FileInputButton
                    accept={getImageUploadAcceptedTypes()}
                    multiple={false}
                    onChange={props.onImageChange(props.fieldName)}
                >
                    <FrameButton
                        color="white-drop-shadow"
                        size="narrow"
                        className="upload-image-preview_button"
                        leftIcon={FaPlus}
                    >
                        Upload Image
                    </FrameButton>
                </FileInputButton>
            </div>
    );
}

export default UploadImagePreview;
