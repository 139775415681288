import React, {ReactElement, useEffect, useState} from "react";
import {Business, BusinessesApi, CommerceApi, Merchant} from "@devour/client";
import {StripeAccountObject} from "../../../types/Stripe";
import {useDispatch} from "react-redux";
import {addError, decrementLoading, incrementLoading} from "../../../redux/meta/metaActions";
import getConfig from "../../../utils/getConfig";
import BusinessDetailsPayoutAccount from "./BusinessDetailsPayoutAccount";
import BusinessInfoPayouts from "../../../components/businesses/BusinessInfoPayouts";

interface Props {
    business: Business;
}

export interface GetBusinessBillingResponse {
    stripeAccount?: StripeAccountObject;
    user?: Merchant;
    isAccountConnected: boolean;
    clientSecret?: string;
}

function BusinessDetailsPayoutSettings(props: Props): ReactElement {
    const dispatch = useDispatch();

    const [currentAccount, setCurrentAccount] = useState<GetBusinessBillingResponse>(undefined);
    const [isLoadingBillingDetails, setIsLoadingBillingDetails] = useState<boolean>(false);
    const [stripeAccounts, setStripeAccounts] = useState<Array<StripeAccountObject>>([]);

    useEffect(() => {
        if (props.business) {
            fetchStripeAccounts().then();
            fetchBillingDetails().then();
        }
    }, [props.business]);

    /**
     * Get the details for this restaurant from our api.
     */
    async function fetchBillingDetails(): Promise<void> {
        dispatch(incrementLoading());
        setIsLoadingBillingDetails(true);
        try {
            const res = await new BusinessesApi(getConfig()).getBusinessBilling({
                id: props.business?.id,
            });
            setCurrentAccount(res as GetBusinessBillingResponse);
            setIsLoadingBillingDetails(false);
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }

    }

    /**
     * Get all the Stripe account options.
     */
    async function fetchStripeAccounts(): Promise<void> {
        dispatch(incrementLoading());

        try {
            const res = await new CommerceApi(getConfig()).stripeAccountsList();
            setStripeAccounts(res.accounts as Array<StripeAccountObject>);
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }

    }

    /**
     * Submit updated billing account
     */
    async function billingAccountOnChange(stripeAccountId: string): Promise<void> {
        dispatch(incrementLoading());
        try {
            await new BusinessesApi(getConfig()).updateBusinessBilling({
                id: props.business?.id,
                updateBusinessBillingBody: {
                    stripeAccountId: stripeAccountId,
                    currency: props.business?.currency,
                },
            });
            void fetchBillingDetails();
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }
    }


    return (
        <div className="business-page_payout-settings">
            <BusinessDetailsPayoutAccount
                stripeAccounts={stripeAccounts}
                business={props.business}
                billingAccountOnChange={billingAccountOnChange}
                currentAccount={currentAccount}
                isLoadingBillingDetails={isLoadingBillingDetails}
            />
            <div className={(!isLoadingBillingDetails && !currentAccount?.isAccountConnected) ? "business-page_payout-settings_payouts_no-account" : ""}>
                {(!isLoadingBillingDetails && currentAccount?.clientSecret) ? (
                        <BusinessInfoPayouts
                            business={props.business}
                            currentAccount={currentAccount}
                        />
                ) : (
                    <p>
                        No account connected. <br/>
                        Connect a bank account to this restaurant to view the payout history.
                    </p>
                )}
            </div>
        </div>
    )
}

export default BusinessDetailsPayoutSettings;