"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetAssetsSortToJSON = exports.GetAssetsSortFromJSONTyped = exports.GetAssetsSortFromJSON = exports.GetAssetsSort = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var GetAssetsSort;
(function (GetAssetsSort) {
    GetAssetsSort["CreatedAt"] = "createdAt";
    GetAssetsSort["UpdatedAt"] = "updatedAt";
    GetAssetsSort["Name"] = "name";
    GetAssetsSort["Owner"] = "owner";
})(GetAssetsSort = exports.GetAssetsSort || (exports.GetAssetsSort = {}));
function GetAssetsSortFromJSON(json) {
    return GetAssetsSortFromJSONTyped(json, false);
}
exports.GetAssetsSortFromJSON = GetAssetsSortFromJSON;
function GetAssetsSortFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.GetAssetsSortFromJSONTyped = GetAssetsSortFromJSONTyped;
function GetAssetsSortToJSON(value) {
    return value;
}
exports.GetAssetsSortToJSON = GetAssetsSortToJSON;
