import React, {ReactElement, useState} from "react";
import {EditBrandMapBodyFrontend} from "./components/BrandMapFormValues";
import PreviewRestaurantHeader from "./components/preview/PreviewRestaurantHeader";
import classNames from "classnames";
import PreviewMainPromo from "./components/preview/PreviewMainPromo";
import {Asset, Brand, BrandMapColorTheme, Business, OrderDiscount} from "@devour/client";
import PreviewLocations from "./components/preview/PreviewLocations";
import FrameButton from "../../components/buttons/FrameButton";
import PreviewSecondaryPromos from "./components/preview/PreviewSecondaryPromos";
import PreviewAdvertisementBlocks from "./components/preview/PreviewAdvertisementBlocks";
import PreviewBrandMapFooter from "./components/preview/PreviewBrandMapFooter";

interface Props {
    formValues: EditBrandMapBodyFrontend;
    businesses: Array<Business>;
    brandImage: Asset;
    brandName: string;
    selectedBusinesses: Array<string>;
    brandId: string;
    promos: Array<OrderDiscount>;
}

function BrandMapPreviewMode(props: Props): ReactElement {

    const [activeBusinessId, setActiveBusinessId] = useState<string>(props.formValues.defaultBusiness);

    function toggleActiveBusinessId(id: string): void {
        setActiveBusinessId(id);
    }

    function getSelectedBusinesses(): Array<Business> {
        return props.businesses.filter((business) => props.selectedBusinesses.includes(business.id));
    }

    return (
        <div className={classNames("restaurant-map-landing", `theme-${props.formValues.colorTheme}`)}>

            <div className="preview-logo">
                <img
                    src={process.env.REACT_APP_CDN_URL + `/images/DevourGO_Final-${props.formValues.colorTheme === BrandMapColorTheme.DARK ? "01" : "White"}.webp`}
                    alt="DevourGO Logo"
                />
            </div>

            <div
                className="restaurant-map-landing_content"
                style={{
                    backgroundColor: props.formValues.colorBackground,
                }}
            >
                <PreviewRestaurantHeader formValues={props.formValues} />
                <div className="restaurant-map-landing_content_wrapper">

                    {props.formValues?.mainPromo?.length > 0 && (
                        <>
                            <h3 id="restaurant-map-landing_content_claim-rewards">
                                Claim Rewards
                            </h3>
                            <PreviewMainPromo formValues={props.formValues} />
                        </>
                    )}

                    <PreviewLocations
                        formValues={props.formValues}
                        businesses={getSelectedBusinesses()}
                        brandImage={props.brandImage}
                        brandName={props.brandName}
                        brandId={props.brandId}
                        promos={props.promos}
                        setActiveBusinessId={toggleActiveBusinessId}
                        activeBusinessId={activeBusinessId}
                    />

                    <PreviewSecondaryPromos
                        promos={props.promos}
                        formValues={props.formValues}
                        activeBusinessId={activeBusinessId}
                    />

                    <PreviewAdvertisementBlocks
                        formValues={props.formValues}
                    />

                    <PreviewBrandMapFooter />
                </div>
            </div>
        </div>
    );
}

export default BrandMapPreviewMode;
