import React, {ReactElement} from "react";
import {PaginationInfo} from "@devour/client";
import FrameOnePaginator from "./FrameOnePaginator";
import {TableOptions, useReactTable, flexRender, SortingState, OnChangeFn} from '@tanstack/react-table';
import {FaSort, FaSortDown, FaSortUp} from "react-icons/fa";
import classNames from "classnames";

interface Props<TData> {
	pagination?: PaginationInfo;
	onPaginationChange?: (newPagination: PaginationInfo) => void;
	sorting?: SortingState;
	onSort?: OnChangeFn<SortingState>;
	tableOptions: TableOptions<TData>;
	noResultsRender?: ReactElement;
}

function FrameOneTableContainer<TData>(props: Props<TData>): ReactElement {
	const table = useReactTable({
		state: {
			sorting: props.sorting,
		},
		onSortingChange: props.onSort,
		manualSorting: true,
		enableSortingRemoval: false,
		defaultColumn: {
			enableSorting: false,
		},
		...props.tableOptions,
	});

	return (
		<div>
			<div className="frame-one-table-container">
				<table className="frame-one-table-container_table">
					<thead className="frame-one-table-container_table_header">
					{table.getHeaderGroups().map(headerGroup => (
						<tr
							key={headerGroup.id}
							className="frame-one-table-container_table_header_row"
						>
							{headerGroup.headers.map(header => (
								<th
									key={header.id}
									className="frame-one-table-container_table_header_cell"
								>
									<div
										onClick={header.column.getToggleSortingHandler()}
										className={classNames({
											"is-sortable": header.column.getCanSort(),
											"is-sorted": header.column.getIsSorted(),
										})}
									>
										{header.isPlaceholder
											? null
											: flexRender(
												header.column.columnDef.header,
												header.getContext()
											)}
										{(header.column.getCanSort()) && (
											<span className="frame-one-sorting-icons-container">
												{(header.column.getIsSorted() === "asc") ? (
													<FaSortUp/>
												) : (header.column.getIsSorted() === "desc") ? (
													<FaSortDown/>
												) : (
													<FaSort/>
												)}
											</span>
										)}
									</div>
								</th>
							))}
						</tr>
					))}
					</thead>
					<tbody className="frame-one-table-container_table_body">
					{table.getRowModel().rows.map(row => (
						<tr
							key={row.id}
							className="frame-one-table-container_table_body_row"
						>
							{row.getVisibleCells().map(cell => (
								<td
									key={cell.id}
									className="frame-one-table-container_table_body_row_cell"
								>
									{flexRender(cell.column.columnDef.cell, cell.getContext())}
								</td>
							))}
						</tr>
					))}
					</tbody>
					<tfoot>
					{table.getFooterGroups().map(footerGroup => (
						<tr key={footerGroup.id}>
							{footerGroup.headers.map(header => (
								<th key={header.id}>
									{header.isPlaceholder
										? null
										: flexRender(
											header.column.columnDef.footer,
											header.getContext()
										)}
								</th>
							))}
						</tr>
					))}
					</tfoot>
				</table>
			</div>

			{(props.tableOptions.data?.length === 0) && props.noResultsRender}

			{props.pagination?.total !== undefined && props.onPaginationChange && (
				<FrameOnePaginator
					{...props.pagination}
					onPaginationChange={props.onPaginationChange}
				/>
			)}
		</div>
	);
}

export default FrameOneTableContainer;
