"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionToJSON = exports.TransactionFromJSONTyped = exports.TransactionFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function TransactionFromJSON(json) {
    return TransactionFromJSONTyped(json, false);
}
exports.TransactionFromJSON = TransactionFromJSON;
function TransactionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'subject': (0, _1.TransactionSubjectFromJSON)(json['subject']),
        'notes': !(0, runtime_1.exists)(json, 'notes') ? undefined : json['notes'],
        'adminNotes': !(0, runtime_1.exists)(json, 'adminNotes') ? undefined : json['adminNotes'],
        'user': json['user'],
        'type': (0, _1.TransactionTypeFromJSON)(json['type']),
        'amount': json['amount'],
        'reference': !(0, runtime_1.exists)(json, 'reference') ? undefined : json['reference'],
    };
}
exports.TransactionFromJSONTyped = TransactionFromJSONTyped;
function TransactionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'subject': (0, _1.TransactionSubjectToJSON)(value.subject),
        'notes': value.notes,
        'adminNotes': value.adminNotes,
        'user': value.user,
        'type': (0, _1.TransactionTypeToJSON)(value.type),
        'amount': value.amount,
        'reference': value.reference,
    };
}
exports.TransactionToJSON = TransactionToJSON;
