"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OWUserSessionEventTypeToJSON = exports.OWUserSessionEventTypeFromJSONTyped = exports.OWUserSessionEventTypeFromJSON = exports.OWUserSessionEventType = void 0;
/**
 * The type of overwolf user session event
 * @export
 * @enum {string}
 */
var OWUserSessionEventType;
(function (OWUserSessionEventType) {
    OWUserSessionEventType["LOGIN"] = "USER_LOGIN";
    OWUserSessionEventType["SIGNUP"] = "USER_SIGNUP";
    OWUserSessionEventType["RETURNING"] = "USER_RETURNING";
})(OWUserSessionEventType = exports.OWUserSessionEventType || (exports.OWUserSessionEventType = {}));
function OWUserSessionEventTypeFromJSON(json) {
    return OWUserSessionEventTypeFromJSONTyped(json, false);
}
exports.OWUserSessionEventTypeFromJSON = OWUserSessionEventTypeFromJSON;
function OWUserSessionEventTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.OWUserSessionEventTypeFromJSONTyped = OWUserSessionEventTypeFromJSONTyped;
function OWUserSessionEventTypeToJSON(value) {
    return value;
}
exports.OWUserSessionEventTypeToJSON = OWUserSessionEventTypeToJSON;
