"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetDpayPurchasesResponseAllOfToJSON = exports.GetDpayPurchasesResponseAllOfFromJSONTyped = exports.GetDpayPurchasesResponseAllOfFromJSON = void 0;
var _1 = require("./");
function GetDpayPurchasesResponseAllOfFromJSON(json) {
    return GetDpayPurchasesResponseAllOfFromJSONTyped(json, false);
}
exports.GetDpayPurchasesResponseAllOfFromJSON = GetDpayPurchasesResponseAllOfFromJSON;
function GetDpayPurchasesResponseAllOfFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'dpayPurchases': (json['dpayPurchases'].map(_1.DpayPurchaseFromJSON)),
    };
}
exports.GetDpayPurchasesResponseAllOfFromJSONTyped = GetDpayPurchasesResponseAllOfFromJSONTyped;
function GetDpayPurchasesResponseAllOfToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'dpayPurchases': (value.dpayPurchases.map(_1.DpayPurchaseToJSON)),
    };
}
exports.GetDpayPurchasesResponseAllOfToJSON = GetDpayPurchasesResponseAllOfToJSON;
