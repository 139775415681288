"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.NftRewardMetadataToJSON = exports.NftRewardMetadataFromJSONTyped = exports.NftRewardMetadataFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function NftRewardMetadataFromJSON(json) {
    return NftRewardMetadataFromJSONTyped(json, false);
}
exports.NftRewardMetadataFromJSON = NftRewardMetadataFromJSON;
function NftRewardMetadataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'nftTracker': (0, _1.NftTrackerFromJSON)(json['nftTracker']),
        'contractAbi': json['contractAbi'],
        'origin': !(0, runtime_1.exists)(json, 'origin') ? undefined : json['origin'],
        'title': !(0, runtime_1.exists)(json, 'title') ? undefined : json['title'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'isEnabled': !(0, runtime_1.exists)(json, 'isEnabled') ? undefined : json['isEnabled'],
        'image': !(0, runtime_1.exists)(json, 'image') ? undefined : (0, _1.AssetFromJSON)(json['image']),
        'redemptionAnimation': !(0, runtime_1.exists)(json, 'redemptionAnimation') ? undefined : (0, _1.AssetFromJSON)(json['redemptionAnimation']),
        'transitionEffectEnabled': !(0, runtime_1.exists)(json, 'transitionEffectEnabled') ? undefined : json['transitionEffectEnabled'],
    };
}
exports.NftRewardMetadataFromJSONTyped = NftRewardMetadataFromJSONTyped;
function NftRewardMetadataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'nftTracker': (0, _1.NftTrackerToJSON)(value.nftTracker),
        'contractAbi': value.contractAbi,
        'origin': value.origin,
        'title': value.title,
        'description': value.description,
        'isEnabled': value.isEnabled,
        'image': (0, _1.AssetToJSON)(value.image),
        'redemptionAnimation': (0, _1.AssetToJSON)(value.redemptionAnimation),
        'transitionEffectEnabled': value.transitionEffectEnabled,
    };
}
exports.NftRewardMetadataToJSON = NftRewardMetadataToJSON;
