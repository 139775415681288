"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReferralRewardThresholdToJSON = exports.ReferralRewardThresholdFromJSONTyped = exports.ReferralRewardThresholdFromJSON = void 0;
var runtime_1 = require("../runtime");
function ReferralRewardThresholdFromJSON(json) {
    return ReferralRewardThresholdFromJSONTyped(json, false);
}
exports.ReferralRewardThresholdFromJSON = ReferralRewardThresholdFromJSON;
function ReferralRewardThresholdFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'disabled': json['disabled'],
        'hidden': json['hidden'],
        'expiration': !(0, runtime_1.exists)(json, 'expiration') ? undefined : json['expiration'],
        'amount': json['amount'],
        'minPaymentRequired': json['minPaymentRequired'],
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
    };
}
exports.ReferralRewardThresholdFromJSONTyped = ReferralRewardThresholdFromJSONTyped;
function ReferralRewardThresholdToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'disabled': value.disabled,
        'hidden': value.hidden,
        'expiration': value.expiration,
        'amount': value.amount,
        'minPaymentRequired': value.minPaymentRequired,
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
    };
}
exports.ReferralRewardThresholdToJSON = ReferralRewardThresholdToJSON;
