"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RallyChampionRequestBodyToJSON = exports.RallyChampionRequestBodyFromJSONTyped = exports.RallyChampionRequestBodyFromJSON = void 0;
function RallyChampionRequestBodyFromJSON(json) {
    return RallyChampionRequestBodyFromJSONTyped(json, false);
}
exports.RallyChampionRequestBodyFromJSON = RallyChampionRequestBodyFromJSON;
function RallyChampionRequestBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'position': json['position'],
        'track_played': json['track_played'],
    };
}
exports.RallyChampionRequestBodyFromJSONTyped = RallyChampionRequestBodyFromJSONTyped;
function RallyChampionRequestBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'position': value.position,
        'track_played': value.track_played,
    };
}
exports.RallyChampionRequestBodyToJSON = RallyChampionRequestBodyToJSON;
