"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccountExperienceTransactionTypeToJSON = exports.AccountExperienceTransactionTypeFromJSONTyped = exports.AccountExperienceTransactionTypeFromJSON = exports.AccountExperienceTransactionType = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var AccountExperienceTransactionType;
(function (AccountExperienceTransactionType) {
    AccountExperienceTransactionType["CREDIT"] = "CREDIT";
    AccountExperienceTransactionType["DEBIT"] = "DEBIT";
})(AccountExperienceTransactionType = exports.AccountExperienceTransactionType || (exports.AccountExperienceTransactionType = {}));
function AccountExperienceTransactionTypeFromJSON(json) {
    return AccountExperienceTransactionTypeFromJSONTyped(json, false);
}
exports.AccountExperienceTransactionTypeFromJSON = AccountExperienceTransactionTypeFromJSON;
function AccountExperienceTransactionTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.AccountExperienceTransactionTypeFromJSONTyped = AccountExperienceTransactionTypeFromJSONTyped;
function AccountExperienceTransactionTypeToJSON(value) {
    return value;
}
exports.AccountExperienceTransactionTypeToJSON = AccountExperienceTransactionTypeToJSON;
