"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.LatLngToAddressCacheToJSON = exports.LatLngToAddressCacheFromJSONTyped = exports.LatLngToAddressCacheFromJSON = void 0;
function LatLngToAddressCacheFromJSON(json) {
    return LatLngToAddressCacheFromJSONTyped(json, false);
}
exports.LatLngToAddressCacheFromJSON = LatLngToAddressCacheFromJSON;
function LatLngToAddressCacheFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'expiresAt': json['expiresAt'],
        'reverseGeoCodeResponse': json['reverseGeoCodeResponse'],
        'lat': json['lat'],
        'lng': json['lng'],
    };
}
exports.LatLngToAddressCacheFromJSONTyped = LatLngToAddressCacheFromJSONTyped;
function LatLngToAddressCacheToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'expiresAt': value.expiresAt,
        'reverseGeoCodeResponse': value.reverseGeoCodeResponse,
        'lat': value.lat,
        'lng': value.lng,
    };
}
exports.LatLngToAddressCacheToJSON = LatLngToAddressCacheToJSON;
