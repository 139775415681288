import React, {ReactElement} from "react";
import {FiMenu} from "react-icons/fi";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {toggleSidebar} from "../../redux/meta/metaActions";

interface StateProps {
	sidebarOpen: boolean;
}

function DevourHamburgerHelper(props: DevourHamburgerHelperProps): ReactElement {

	function toggleSidebarHelper(): void {
		props.dispatch(toggleSidebar(!props.sidebarOpen));
	}

	return (
		<div className="devour-hamburger-helper">
			<FiMenu onClick={toggleSidebarHelper}/>
		</div>
	);
}

function connector() {
	return connect((store: IStore): StateProps => {
		return {
			sidebarOpen: store.metaStore.sidebarOpen,
		}
	});
}

type DevourHamburgerHelperProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(DevourHamburgerHelper);
