"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderDiscountOriginToJSON = exports.OrderDiscountOriginFromJSONTyped = exports.OrderDiscountOriginFromJSON = exports.OrderDiscountOrigin = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var OrderDiscountOrigin;
(function (OrderDiscountOrigin) {
    OrderDiscountOrigin["DEVOUR"] = "DEVOUR";
    OrderDiscountOrigin["MERCHANT"] = "MERCHANT";
    OrderDiscountOrigin["DPAY"] = "DPAY";
})(OrderDiscountOrigin = exports.OrderDiscountOrigin || (exports.OrderDiscountOrigin = {}));
function OrderDiscountOriginFromJSON(json) {
    return OrderDiscountOriginFromJSONTyped(json, false);
}
exports.OrderDiscountOriginFromJSON = OrderDiscountOriginFromJSON;
function OrderDiscountOriginFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.OrderDiscountOriginFromJSONTyped = OrderDiscountOriginFromJSONTyped;
function OrderDiscountOriginToJSON(value) {
    return value;
}
exports.OrderDiscountOriginToJSON = OrderDiscountOriginToJSON;
