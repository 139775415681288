"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateBusinessBillingBodyToJSON = exports.UpdateBusinessBillingBodyFromJSONTyped = exports.UpdateBusinessBillingBodyFromJSON = void 0;
function UpdateBusinessBillingBodyFromJSON(json) {
    return UpdateBusinessBillingBodyFromJSONTyped(json, false);
}
exports.UpdateBusinessBillingBodyFromJSON = UpdateBusinessBillingBodyFromJSON;
function UpdateBusinessBillingBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'stripeAccountId': json['stripeAccountId'],
        'currency': json['currency'],
    };
}
exports.UpdateBusinessBillingBodyFromJSONTyped = UpdateBusinessBillingBodyFromJSONTyped;
function UpdateBusinessBillingBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'stripeAccountId': value.stripeAccountId,
        'currency': value.currency,
    };
}
exports.UpdateBusinessBillingBodyToJSON = UpdateBusinessBillingBodyToJSON;
