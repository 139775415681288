import React, {ReactElement, useRef} from "react";
import {omit} from "lodash";
import classNames from "classnames";

interface Props extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "type"> {
	children: ReactElement;
}

function FileInputButton(props: Props): ReactElement {

	const inputRef = useRef<HTMLInputElement>(null);

	/**
	 * Manually call the click function on the hidden input element.
	 *
	 */
	function onClickHelper(): void {
		inputRef?.current?.click();
	}

	return (
		<div className={classNames("file-input-button", props.className)}>
			<input
				{...omit(props, "className", "children")}
				value={undefined}
				ref={inputRef}
				type="file"
				className="file-input-button_input"
			/>

			{React.cloneElement(props.children, {
				...props.children?.props,
				onClick: onClickHelper,
			})}
		</div>
	);
}

export default FileInputButton;
