"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetWithdrawsOwnSortToJSON = exports.GetWithdrawsOwnSortFromJSONTyped = exports.GetWithdrawsOwnSortFromJSON = exports.GetWithdrawsOwnSort = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var GetWithdrawsOwnSort;
(function (GetWithdrawsOwnSort) {
    GetWithdrawsOwnSort["CreatedAt"] = "createdAt";
    GetWithdrawsOwnSort["UpdatedAt"] = "updatedAt";
    GetWithdrawsOwnSort["Status"] = "status";
})(GetWithdrawsOwnSort = exports.GetWithdrawsOwnSort || (exports.GetWithdrawsOwnSort = {}));
function GetWithdrawsOwnSortFromJSON(json) {
    return GetWithdrawsOwnSortFromJSONTyped(json, false);
}
exports.GetWithdrawsOwnSortFromJSON = GetWithdrawsOwnSortFromJSON;
function GetWithdrawsOwnSortFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.GetWithdrawsOwnSortFromJSONTyped = GetWithdrawsOwnSortFromJSONTyped;
function GetWithdrawsOwnSortToJSON(value) {
    return value;
}
exports.GetWithdrawsOwnSortToJSON = GetWithdrawsOwnSortToJSON;
