"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetNftGroupingsSortToJSON = exports.GetNftGroupingsSortFromJSONTyped = exports.GetNftGroupingsSortFromJSON = exports.GetNftGroupingsSort = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var GetNftGroupingsSort;
(function (GetNftGroupingsSort) {
    GetNftGroupingsSort["SortPriority"] = "sortPriority";
    GetNftGroupingsSort["CreatedAt"] = "createdAt";
})(GetNftGroupingsSort = exports.GetNftGroupingsSort || (exports.GetNftGroupingsSort = {}));
function GetNftGroupingsSortFromJSON(json) {
    return GetNftGroupingsSortFromJSONTyped(json, false);
}
exports.GetNftGroupingsSortFromJSON = GetNftGroupingsSortFromJSON;
function GetNftGroupingsSortFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.GetNftGroupingsSortFromJSONTyped = GetNftGroupingsSortFromJSONTyped;
function GetNftGroupingsSortToJSON(value) {
    return value;
}
exports.GetNftGroupingsSortToJSON = GetNftGroupingsSortToJSON;
