"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BusinessKeysToJSON = exports.BusinessKeysFromJSONTyped = exports.BusinessKeysFromJSON = void 0;
var runtime_1 = require("../runtime");
function BusinessKeysFromJSON(json) {
    return BusinessKeysFromJSONTyped(json, false);
}
exports.BusinessKeysFromJSON = BusinessKeysFromJSON;
function BusinessKeysFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'firstDeliveryId': !(0, runtime_1.exists)(json, 'firstDeliveryId') ? undefined : json['firstDeliveryId'],
        'chowlyApi': !(0, runtime_1.exists)(json, 'chowlyApi') ? undefined : json['chowlyApi'],
        'itsaCheckmateAccess': !(0, runtime_1.exists)(json, 'itsaCheckmateAccess') ? undefined : json['itsaCheckmateAccess'],
        'isPos': json['isPos'],
        'stripeAccountId': !(0, runtime_1.exists)(json, 'stripeAccountId') ? undefined : json['stripeAccountId'],
        'uberEatsUUID': !(0, runtime_1.exists)(json, 'uberEatsUUID') ? undefined : json['uberEatsUUID'],
        'uberEatsUrl': !(0, runtime_1.exists)(json, 'uberEatsUrl') ? undefined : json['uberEatsUrl'],
        'oloVendorId': !(0, runtime_1.exists)(json, 'oloVendorId') ? undefined : json['oloVendorId'],
    };
}
exports.BusinessKeysFromJSONTyped = BusinessKeysFromJSONTyped;
function BusinessKeysToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'firstDeliveryId': value.firstDeliveryId,
        'chowlyApi': value.chowlyApi,
        'itsaCheckmateAccess': value.itsaCheckmateAccess,
        'isPos': value.isPos,
        'stripeAccountId': value.stripeAccountId,
        'uberEatsUUID': value.uberEatsUUID,
        'uberEatsUrl': value.uberEatsUrl,
        'oloVendorId': value.oloVendorId,
    };
}
exports.BusinessKeysToJSON = BusinessKeysToJSON;
