"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateServiceFeeBodyToJSON = exports.CreateServiceFeeBodyFromJSONTyped = exports.CreateServiceFeeBodyFromJSON = void 0;
var runtime_1 = require("../runtime");
function CreateServiceFeeBodyFromJSON(json) {
    return CreateServiceFeeBodyFromJSONTyped(json, false);
}
exports.CreateServiceFeeBodyFromJSON = CreateServiceFeeBodyFromJSON;
function CreateServiceFeeBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'percentageDelivery': json['percentageDelivery'],
        'percentagePickup': json['percentagePickup'],
        'amountMax': json['amountMax'],
        'priority': json['priority'],
        'name': !(0, runtime_1.exists)(json, 'name') ? undefined : json['name'],
        'description': json['description'],
        'nftGroupings': !(0, runtime_1.exists)(json, 'nftGroupings') ? undefined : json['nftGroupings'],
    };
}
exports.CreateServiceFeeBodyFromJSONTyped = CreateServiceFeeBodyFromJSONTyped;
function CreateServiceFeeBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'percentageDelivery': value.percentageDelivery,
        'percentagePickup': value.percentagePickup,
        'amountMax': value.amountMax,
        'priority': value.priority,
        'name': value.name,
        'description': value.description,
        'nftGroupings': value.nftGroupings,
    };
}
exports.CreateServiceFeeBodyToJSON = CreateServiceFeeBodyToJSON;
