"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetBrandBusinessesResponseAllOfToJSON = exports.GetBrandBusinessesResponseAllOfFromJSONTyped = exports.GetBrandBusinessesResponseAllOfFromJSON = void 0;
var _1 = require("./");
function GetBrandBusinessesResponseAllOfFromJSON(json) {
    return GetBrandBusinessesResponseAllOfFromJSONTyped(json, false);
}
exports.GetBrandBusinessesResponseAllOfFromJSON = GetBrandBusinessesResponseAllOfFromJSON;
function GetBrandBusinessesResponseAllOfFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'brandBusinesses': (json['brandBusinesses'].map(_1.BusinessFromJSON)),
        'businessesWithMultipleBrands': (json['businessesWithMultipleBrands'].map(_1.BusinessFromJSON)),
    };
}
exports.GetBrandBusinessesResponseAllOfFromJSONTyped = GetBrandBusinessesResponseAllOfFromJSONTyped;
function GetBrandBusinessesResponseAllOfToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'brandBusinesses': (value.brandBusinesses.map(_1.BusinessToJSON)),
        'businessesWithMultipleBrands': (value.businessesWithMultipleBrands.map(_1.BusinessToJSON)),
    };
}
exports.GetBrandBusinessesResponseAllOfToJSON = GetBrandBusinessesResponseAllOfToJSON;
