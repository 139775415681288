"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.LevelUpEntryToJSON = exports.LevelUpEntryFromJSONTyped = exports.LevelUpEntryFromJSON = void 0;
function LevelUpEntryFromJSON(json) {
    return LevelUpEntryFromJSONTyped(json, false);
}
exports.LevelUpEntryFromJSON = LevelUpEntryFromJSON;
function LevelUpEntryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'level': json['level'],
        'requiredExperienceFromPreviousLevel': json['requiredExperienceFromPreviousLevel'],
        'totalExperienceRequiredForNextLevel': json['totalExperienceRequiredForNextLevel'],
        'requiresIndustryNftCollection': json['requiresIndustryNftCollection'],
    };
}
exports.LevelUpEntryFromJSONTyped = LevelUpEntryFromJSONTyped;
function LevelUpEntryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'level': value.level,
        'requiredExperienceFromPreviousLevel': value.requiredExperienceFromPreviousLevel,
        'totalExperienceRequiredForNextLevel': value.totalExperienceRequiredForNextLevel,
        'requiresIndustryNftCollection': value.requiresIndustryNftCollection,
    };
}
exports.LevelUpEntryToJSON = LevelUpEntryToJSON;
