"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ItsaCheckmateAccessToJSON = exports.ItsaCheckmateAccessFromJSONTyped = exports.ItsaCheckmateAccessFromJSON = void 0;
var runtime_1 = require("../runtime");
function ItsaCheckmateAccessFromJSON(json) {
    return ItsaCheckmateAccessFromJSONTyped(json, false);
}
exports.ItsaCheckmateAccessFromJSON = ItsaCheckmateAccessFromJSON;
function ItsaCheckmateAccessFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'locationId': !(0, runtime_1.exists)(json, 'locationId') ? undefined : json['locationId'],
        'expiresAt': json['expiresAt'],
        'tokenType': json['tokenType'],
        'scope': json['scope'],
        'business': !(0, runtime_1.exists)(json, 'business') ? undefined : json['business'],
    };
}
exports.ItsaCheckmateAccessFromJSONTyped = ItsaCheckmateAccessFromJSONTyped;
function ItsaCheckmateAccessToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'locationId': value.locationId,
        'expiresAt': value.expiresAt,
        'tokenType': value.tokenType,
        'scope': value.scope,
        'business': value.business,
    };
}
exports.ItsaCheckmateAccessToJSON = ItsaCheckmateAccessToJSON;
