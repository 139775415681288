"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmployeeVelocityStatsToJSON = exports.EmployeeVelocityStatsFromJSONTyped = exports.EmployeeVelocityStatsFromJSON = void 0;
function EmployeeVelocityStatsFromJSON(json) {
    return EmployeeVelocityStatsFromJSONTyped(json, false);
}
exports.EmployeeVelocityStatsFromJSON = EmployeeVelocityStatsFromJSON;
function EmployeeVelocityStatsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'lifetimePoints': json['lifetimePoints'],
        'averageVelocity': json['averageVelocity'],
    };
}
exports.EmployeeVelocityStatsFromJSONTyped = EmployeeVelocityStatsFromJSONTyped;
function EmployeeVelocityStatsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'lifetimePoints': value.lifetimePoints,
        'averageVelocity': value.averageVelocity,
    };
}
exports.EmployeeVelocityStatsToJSON = EmployeeVelocityStatsToJSON;
