"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetDevourStatsResponseDataToJSON = exports.GetDevourStatsResponseDataFromJSONTyped = exports.GetDevourStatsResponseDataFromJSON = void 0;
var runtime_1 = require("../runtime");
function GetDevourStatsResponseDataFromJSON(json) {
    return GetDevourStatsResponseDataFromJSONTyped(json, false);
}
exports.GetDevourStatsResponseDataFromJSON = GetDevourStatsResponseDataFromJSON;
function GetDevourStatsResponseDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'goVipLevel': !(0, runtime_1.exists)(json, 'goVipLevel') ? undefined : json['goVipLevel'],
        'goVipXP': !(0, runtime_1.exists)(json, 'goVipXP') ? undefined : json['goVipXP'],
        'industryPassHolder': !(0, runtime_1.exists)(json, 'industryPassHolder') ? undefined : json['industryPassHolder'],
    };
}
exports.GetDevourStatsResponseDataFromJSONTyped = GetDevourStatsResponseDataFromJSONTyped;
function GetDevourStatsResponseDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'goVipLevel': value.goVipLevel,
        'goVipXP': value.goVipXP,
        'industryPassHolder': value.industryPassHolder,
    };
}
exports.GetDevourStatsResponseDataToJSON = GetDevourStatsResponseDataToJSON;
