"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateAgreementTermBodyToJSON = exports.CreateAgreementTermBodyFromJSONTyped = exports.CreateAgreementTermBodyFromJSON = void 0;
var _1 = require("./");
function CreateAgreementTermBodyFromJSON(json) {
    return CreateAgreementTermBodyFromJSONTyped(json, false);
}
exports.CreateAgreementTermBodyFromJSON = CreateAgreementTermBodyFromJSON;
function CreateAgreementTermBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, _1.AgreementTermTypeFromJSON)(json['type']),
        'file': json['file'],
    };
}
exports.CreateAgreementTermBodyFromJSONTyped = CreateAgreementTermBodyFromJSONTyped;
function CreateAgreementTermBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, _1.AgreementTermTypeToJSON)(value.type),
        'file': value.file,
    };
}
exports.CreateAgreementTermBodyToJSON = CreateAgreementTermBodyToJSON;
