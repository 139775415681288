"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppErrorToJSON = exports.AppErrorFromJSONTyped = exports.AppErrorFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function AppErrorFromJSON(json) {
    return AppErrorFromJSONTyped(json, false);
}
exports.AppErrorFromJSON = AppErrorFromJSON;
function AppErrorFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, _1.ErrorTypeFromJSON)(json['type']),
        'level': !(0, runtime_1.exists)(json, 'level') ? undefined : (0, _1.ErrorLevelFromJSON)(json['level']),
        'message': json['message'],
        'date': !(0, runtime_1.exists)(json, 'date') ? undefined : json['date'],
        'errors': !(0, runtime_1.exists)(json, 'errors') ? undefined : (json['errors'].map(AppErrorFromJSON)),
    };
}
exports.AppErrorFromJSONTyped = AppErrorFromJSONTyped;
function AppErrorToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, _1.ErrorTypeToJSON)(value.type),
        'level': (0, _1.ErrorLevelToJSON)(value.level),
        'message': value.message,
        'date': value.date,
        'errors': value.errors === undefined ? undefined : (value.errors.map(AppErrorToJSON)),
    };
}
exports.AppErrorToJSON = AppErrorToJSON;
