"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateUserBodyToJSON = exports.CreateUserBodyFromJSONTyped = exports.CreateUserBodyFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function CreateUserBodyFromJSON(json) {
    return CreateUserBodyFromJSONTyped(json, false);
}
exports.CreateUserBodyFromJSON = CreateUserBodyFromJSON;
function CreateUserBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'password': !(0, runtime_1.exists)(json, 'password') ? undefined : json['password'],
        'confirmPassword': !(0, runtime_1.exists)(json, 'confirmPassword') ? undefined : json['confirmPassword'],
        'email': json['email'],
        'phoneNumber': !(0, runtime_1.exists)(json, 'phoneNumber') ? undefined : (0, _1.PhoneNumberBodyFromJSON)(json['phoneNumber']),
        'firstName': !(0, runtime_1.exists)(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !(0, runtime_1.exists)(json, 'lastName') ? undefined : json['lastName'],
        'referralCode': !(0, runtime_1.exists)(json, 'referralCode') ? undefined : json['referralCode'],
        'onboardingSignedUrlKey': !(0, runtime_1.exists)(json, 'onboardingSignedUrlKey') ? undefined : json['onboardingSignedUrlKey'],
    };
}
exports.CreateUserBodyFromJSONTyped = CreateUserBodyFromJSONTyped;
function CreateUserBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'password': value.password,
        'confirmPassword': value.confirmPassword,
        'email': value.email,
        'phoneNumber': (0, _1.PhoneNumberBodyToJSON)(value.phoneNumber),
        'firstName': value.firstName,
        'lastName': value.lastName,
        'referralCode': value.referralCode,
        'onboardingSignedUrlKey': value.onboardingSignedUrlKey,
    };
}
exports.CreateUserBodyToJSON = CreateUserBodyToJSON;
