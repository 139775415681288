"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GleamSocialLinkToJSON = exports.GleamSocialLinkFromJSONTyped = exports.GleamSocialLinkFromJSON = void 0;
function GleamSocialLinkFromJSON(json) {
    return GleamSocialLinkFromJSONTyped(json, false);
}
exports.GleamSocialLinkFromJSON = GleamSocialLinkFromJSON;
function GleamSocialLinkFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'provider': json['provider'],
        'uid': json['uid'],
        'reference': json['reference'],
    };
}
exports.GleamSocialLinkFromJSONTyped = GleamSocialLinkFromJSONTyped;
function GleamSocialLinkToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'provider': value.provider,
        'uid': value.uid,
        'reference': value.reference,
    };
}
exports.GleamSocialLinkToJSON = GleamSocialLinkToJSON;
