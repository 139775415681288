"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BrandMapBannerImageToJSON = exports.BrandMapBannerImageFromJSONTyped = exports.BrandMapBannerImageFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function BrandMapBannerImageFromJSON(json) {
    return BrandMapBannerImageFromJSONTyped(json, false);
}
exports.BrandMapBannerImageFromJSON = BrandMapBannerImageFromJSON;
function BrandMapBannerImageFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'image': !(0, runtime_1.exists)(json, 'image') ? undefined : (0, _1.AssetFromJSON)(json['image']),
        'imageType': !(0, runtime_1.exists)(json, 'imageType') ? undefined : (0, _1.BrandMapBannerImageTypeFromJSON)(json['imageType']),
    };
}
exports.BrandMapBannerImageFromJSONTyped = BrandMapBannerImageFromJSONTyped;
function BrandMapBannerImageToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'image': (0, _1.AssetToJSON)(value.image),
        'imageType': (0, _1.BrandMapBannerImageTypeToJSON)(value.imageType),
    };
}
exports.BrandMapBannerImageToJSON = BrandMapBannerImageToJSON;
