"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetTransactionsPaginatedSortToJSON = exports.GetTransactionsPaginatedSortFromJSONTyped = exports.GetTransactionsPaginatedSortFromJSON = exports.GetTransactionsPaginatedSort = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var GetTransactionsPaginatedSort;
(function (GetTransactionsPaginatedSort) {
    GetTransactionsPaginatedSort["CreatedAt"] = "createdAt";
    GetTransactionsPaginatedSort["UpdatedAt"] = "updatedAt";
})(GetTransactionsPaginatedSort = exports.GetTransactionsPaginatedSort || (exports.GetTransactionsPaginatedSort = {}));
function GetTransactionsPaginatedSortFromJSON(json) {
    return GetTransactionsPaginatedSortFromJSONTyped(json, false);
}
exports.GetTransactionsPaginatedSortFromJSON = GetTransactionsPaginatedSortFromJSON;
function GetTransactionsPaginatedSortFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.GetTransactionsPaginatedSortFromJSONTyped = GetTransactionsPaginatedSortFromJSONTyped;
function GetTransactionsPaginatedSortToJSON(value) {
    return value;
}
exports.GetTransactionsPaginatedSortToJSON = GetTransactionsPaginatedSortToJSON;
