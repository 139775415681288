"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OloErrorToJSON = exports.OloErrorFromJSONTyped = exports.OloErrorFromJSON = void 0;
var runtime_1 = require("../runtime");
function OloErrorFromJSON(json) {
    return OloErrorFromJSONTyped(json, false);
}
exports.OloErrorFromJSON = OloErrorFromJSON;
function OloErrorFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'message': json['message'],
        'num': json['num'],
        'optionid': !(0, runtime_1.exists)(json, 'optionid') ? undefined : json['optionid'],
        'productid': !(0, runtime_1.exists)(json, 'productid') ? undefined : json['productid'],
    };
}
exports.OloErrorFromJSONTyped = OloErrorFromJSONTyped;
function OloErrorToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'message': value.message,
        'num': value.num,
        'optionid': value.optionid,
        'productid': value.productid,
    };
}
exports.OloErrorToJSON = OloErrorToJSON;
