"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GoVipActionTypeToJSON = exports.GoVipActionTypeFromJSONTyped = exports.GoVipActionTypeFromJSON = exports.GoVipActionType = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var GoVipActionType;
(function (GoVipActionType) {
    GoVipActionType["SPIN2WIN"] = "SPIN2WIN";
    GoVipActionType["GLEAMREWARD"] = "GLEAM_REWARD";
    GoVipActionType["ALLGAMES"] = "ALL_GAMES";
    GoVipActionType["CANDYBLAST"] = "CANDY_BLAST";
    GoVipActionType["PIZZACAFE"] = "PIZZA_CAFE";
    GoVipActionType["BURGERCAFE"] = "BURGER_CAFE";
    GoVipActionType["RALLYCHAMPION"] = "RALLY_CHAMPION";
    GoVipActionType["INTHEWEEDS"] = "IN_THE_WEEDS";
    GoVipActionType["MENUORDERREWARD"] = "MENU_ORDER_REWARD";
    GoVipActionType["LOADDPAY"] = "LOAD_DPAY";
    GoVipActionType["INDUSTRYPASSPURCHASEREWARD"] = "INDUSTRY_PASS_PURCHASE_REWARD";
    GoVipActionType["REFERAFRIENDFIRSTORDERREWARD"] = "REFER_A_FRIEND_FIRST_ORDER_REWARD";
    GoVipActionType["SIGNUP"] = "SIGN_UP";
})(GoVipActionType = exports.GoVipActionType || (exports.GoVipActionType = {}));
function GoVipActionTypeFromJSON(json) {
    return GoVipActionTypeFromJSONTyped(json, false);
}
exports.GoVipActionTypeFromJSON = GoVipActionTypeFromJSON;
function GoVipActionTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.GoVipActionTypeFromJSONTyped = GoVipActionTypeFromJSONTyped;
function GoVipActionTypeToJSON(value) {
    return value;
}
exports.GoVipActionTypeToJSON = GoVipActionTypeToJSON;
