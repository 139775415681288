import React, {ButtonHTMLAttributes, ReactElement} from "react";
import {EditBrandMapBodyFrontend} from "../BrandMapFormValues";
import {generateBackgroundUrl} from "../../../../utils/brands/generateImageUrl";
import FrameButton from "../../../../components/buttons/FrameButton";
import classNames from "classnames";

interface Props {
    formValues: EditBrandMapBodyFrontend;
}

function PreviewMainPromo(props: Props): ReactElement {

    return (
        <div
            className={classNames("restaurant-map-landing_main-promo", {
                "restaurant-map-landing_main-promo_no-bg": !props.formValues?.promoBackground
            })}
            style={{
                backgroundImage: !props.formValues?.promoBackground ? "" : `url(${generateBackgroundUrl(props.formValues.promoBackground)})`
            }}
        >
            <div className="restaurant-map-landing_main-promo_hero">
                <img
                    src={generateBackgroundUrl(props?.formValues?.promoHero, process.env.REACT_APP_CDN_URL + "/images/landing-background.webp")}
                    alt="preview-promo-hero"
                />
            </div>

            <div className="restaurant-map-landing_main-promo_details">
                <div className="restaurant-map-landing_main-promo_pill">
                    Available
                </div>
                <h2 className="restaurant-map-landing_main-promo_title">
                    {props.formValues?.promoTitle?.length ? props.formValues.promoTitle : "PROMO TITLE"}
                </h2>

                <h3 className="restaurant-map-landing_main-promo_tagline">
                    {props.formValues?.promoTagline?.length ? props.formValues.promoTagline : "This is a placeholder promotion description"}
                </h3>

                <div className="restaurant-map-landing_main-promo_redeem">
                    <FrameButton
                        <ButtonHTMLAttributes<HTMLButtonElement>>
                        color="purple"
                        size="large"
                    >
                        Redeem Now
                    </FrameButton>
                </div>

                <div className="restaurant-map-landing_main-promo_description">
                    {props.formValues?.promoDescription?.length ?
                        props.formValues.promoDescription :
                        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus."
                    }
                </div>
            </div>

        </div>
    );
}

export default PreviewMainPromo;
