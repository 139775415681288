"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImportBusinessBodyItemToJSON = exports.ImportBusinessBodyItemFromJSONTyped = exports.ImportBusinessBodyItemFromJSON = void 0;
var runtime_1 = require("../runtime");
function ImportBusinessBodyItemFromJSON(json) {
    return ImportBusinessBodyItemFromJSONTyped(json, false);
}
exports.ImportBusinessBodyItemFromJSON = ImportBusinessBodyItemFromJSON;
function ImportBusinessBodyItemFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'devour_merchant_id': !(0, runtime_1.exists)(json, 'devour_merchant_id') ? undefined : json['devour_merchant_id'],
        'olo_vendor_id': !(0, runtime_1.exists)(json, 'olo_vendor_id') ? undefined : json['olo_vendor_id'],
        'chowly_api_key': !(0, runtime_1.exists)(json, 'chowly_api_key') ? undefined : json['chowly_api_key'],
        'merchant_account_email': !(0, runtime_1.exists)(json, 'merchant_account_email') ? undefined : json['merchant_account_email'],
        'merchant_parent_id': !(0, runtime_1.exists)(json, 'merchant_parent_id') ? undefined : json['merchant_parent_id'],
        'merchant_name': !(0, runtime_1.exists)(json, 'merchant_name') ? undefined : json['merchant_name'],
        'merchant_internal_name': !(0, runtime_1.exists)(json, 'merchant_internal_name') ? undefined : json['merchant_internal_name'],
        'merchant_description': !(0, runtime_1.exists)(json, 'merchant_description') ? undefined : json['merchant_description'],
        'merchant_email': !(0, runtime_1.exists)(json, 'merchant_email') ? undefined : json['merchant_email'],
        'merchant_phone': !(0, runtime_1.exists)(json, 'merchant_phone') ? undefined : json['merchant_phone'],
        'merchant_address_line1': !(0, runtime_1.exists)(json, 'merchant_address_line1') ? undefined : json['merchant_address_line1'],
        'merchant_address_line2': !(0, runtime_1.exists)(json, 'merchant_address_line2') ? undefined : json['merchant_address_line2'],
        'merchant_address_city': !(0, runtime_1.exists)(json, 'merchant_address_city') ? undefined : json['merchant_address_city'],
        'merchant_address_state': !(0, runtime_1.exists)(json, 'merchant_address_state') ? undefined : json['merchant_address_state'],
        'merchant_address_zipcode': !(0, runtime_1.exists)(json, 'merchant_address_zipcode') ? undefined : json['merchant_address_zipcode'],
        'merchant_address_country': !(0, runtime_1.exists)(json, 'merchant_address_country') ? undefined : json['merchant_address_country'],
        'merchant_address_longitude': !(0, runtime_1.exists)(json, 'merchant_address_longitude') ? undefined : json['merchant_address_longitude'],
        'merchant_address_latitude': !(0, runtime_1.exists)(json, 'merchant_address_latitude') ? undefined : json['merchant_address_latitude'],
        'merchant_address_place_id': !(0, runtime_1.exists)(json, 'merchant_address_place_id') ? undefined : json['merchant_address_place_id'],
        'merchant_timezone': !(0, runtime_1.exists)(json, 'merchant_timezone') ? undefined : json['merchant_timezone'],
        'merchant_brands': !(0, runtime_1.exists)(json, 'merchant_brands') ? undefined : json['merchant_brands'],
        'merchant_categories': !(0, runtime_1.exists)(json, 'merchant_categories') ? undefined : json['merchant_categories'],
        'merchant_prep_time': !(0, runtime_1.exists)(json, 'merchant_prep_time') ? undefined : json['merchant_prep_time'],
        'merchant_handoff': !(0, runtime_1.exists)(json, 'merchant_handoff') ? undefined : json['merchant_handoff'],
        'merchant_delivery_distance': !(0, runtime_1.exists)(json, 'merchant_delivery_distance') ? undefined : json['merchant_delivery_distance'],
        'merchant_brand_size': !(0, runtime_1.exists)(json, 'merchant_brand_size') ? undefined : json['merchant_brand_size'],
    };
}
exports.ImportBusinessBodyItemFromJSONTyped = ImportBusinessBodyItemFromJSONTyped;
function ImportBusinessBodyItemToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'devour_merchant_id': value.devour_merchant_id,
        'olo_vendor_id': value.olo_vendor_id,
        'chowly_api_key': value.chowly_api_key,
        'merchant_account_email': value.merchant_account_email,
        'merchant_parent_id': value.merchant_parent_id,
        'merchant_name': value.merchant_name,
        'merchant_internal_name': value.merchant_internal_name,
        'merchant_description': value.merchant_description,
        'merchant_email': value.merchant_email,
        'merchant_phone': value.merchant_phone,
        'merchant_address_line1': value.merchant_address_line1,
        'merchant_address_line2': value.merchant_address_line2,
        'merchant_address_city': value.merchant_address_city,
        'merchant_address_state': value.merchant_address_state,
        'merchant_address_zipcode': value.merchant_address_zipcode,
        'merchant_address_country': value.merchant_address_country,
        'merchant_address_longitude': value.merchant_address_longitude,
        'merchant_address_latitude': value.merchant_address_latitude,
        'merchant_address_place_id': value.merchant_address_place_id,
        'merchant_timezone': value.merchant_timezone,
        'merchant_brands': value.merchant_brands,
        'merchant_categories': value.merchant_categories,
        'merchant_prep_time': value.merchant_prep_time,
        'merchant_handoff': value.merchant_handoff,
        'merchant_delivery_distance': value.merchant_delivery_distance,
        'merchant_brand_size': value.merchant_brand_size,
    };
}
exports.ImportBusinessBodyItemToJSON = ImportBusinessBodyItemToJSON;
