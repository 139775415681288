"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DevourIQWebhookBodyUberEatsResultToJSON = exports.DevourIQWebhookBodyUberEatsResultFromJSONTyped = exports.DevourIQWebhookBodyUberEatsResultFromJSON = void 0;
function DevourIQWebhookBodyUberEatsResultFromJSON(json) {
    return DevourIQWebhookBodyUberEatsResultFromJSONTyped(json, false);
}
exports.DevourIQWebhookBodyUberEatsResultFromJSON = DevourIQWebhookBodyUberEatsResultFromJSON;
function DevourIQWebhookBodyUberEatsResultFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'orderURL': json['orderURL'],
        'orderUUID': json['orderUUID'],
    };
}
exports.DevourIQWebhookBodyUberEatsResultFromJSONTyped = DevourIQWebhookBodyUberEatsResultFromJSONTyped;
function DevourIQWebhookBodyUberEatsResultToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'orderURL': value.orderURL,
        'orderUUID': value.orderUUID,
    };
}
exports.DevourIQWebhookBodyUberEatsResultToJSON = DevourIQWebhookBodyUberEatsResultToJSON;
