"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RestaurantBrandSizeToJSON = exports.RestaurantBrandSizeFromJSONTyped = exports.RestaurantBrandSizeFromJSON = exports.RestaurantBrandSize = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var RestaurantBrandSize;
(function (RestaurantBrandSize) {
    RestaurantBrandSize["LOCAL"] = "LOCAL";
    RestaurantBrandSize["REGIONAL"] = "REGIONAL";
    RestaurantBrandSize["NATIONAL"] = "NATIONAL";
})(RestaurantBrandSize = exports.RestaurantBrandSize || (exports.RestaurantBrandSize = {}));
function RestaurantBrandSizeFromJSON(json) {
    return RestaurantBrandSizeFromJSONTyped(json, false);
}
exports.RestaurantBrandSizeFromJSON = RestaurantBrandSizeFromJSON;
function RestaurantBrandSizeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.RestaurantBrandSizeFromJSONTyped = RestaurantBrandSizeFromJSONTyped;
function RestaurantBrandSizeToJSON(value) {
    return value;
}
exports.RestaurantBrandSizeToJSON = RestaurantBrandSizeToJSON;
