"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ManageCommunityInfoToJSON = exports.ManageCommunityInfoFromJSONTyped = exports.ManageCommunityInfoFromJSON = void 0;
var _1 = require("./");
function ManageCommunityInfoFromJSON(json) {
    return ManageCommunityInfoFromJSONTyped(json, false);
}
exports.ManageCommunityInfoFromJSON = ManageCommunityInfoFromJSON;
function ManageCommunityInfoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'communityRank': json['communityRank'],
        'communityMembers': (json['communityMembers'].map(_1.CommunityMemberFromJSON)),
        'totalPoints': json['totalPoints'],
    };
}
exports.ManageCommunityInfoFromJSONTyped = ManageCommunityInfoFromJSONTyped;
function ManageCommunityInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'communityRank': value.communityRank,
        'communityMembers': (value.communityMembers.map(_1.CommunityMemberToJSON)),
        'totalPoints': value.totalPoints,
    };
}
exports.ManageCommunityInfoToJSON = ManageCommunityInfoToJSON;
