"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetWithdrawFeeAmountResponseToJSON = exports.GetWithdrawFeeAmountResponseFromJSONTyped = exports.GetWithdrawFeeAmountResponseFromJSON = void 0;
function GetWithdrawFeeAmountResponseFromJSON(json) {
    return GetWithdrawFeeAmountResponseFromJSONTyped(json, false);
}
exports.GetWithdrawFeeAmountResponseFromJSON = GetWithdrawFeeAmountResponseFromJSON;
function GetWithdrawFeeAmountResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'withdrawFeeInUsd': json['withdrawFeeInUsd'],
        'withdrawFeeInDpay': json['withdrawFeeInDpay'],
        'dpayPriceInUsd': json['dpayPriceInUsd'],
    };
}
exports.GetWithdrawFeeAmountResponseFromJSONTyped = GetWithdrawFeeAmountResponseFromJSONTyped;
function GetWithdrawFeeAmountResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'withdrawFeeInUsd': value.withdrawFeeInUsd,
        'withdrawFeeInDpay': value.withdrawFeeInDpay,
        'dpayPriceInUsd': value.dpayPriceInUsd,
    };
}
exports.GetWithdrawFeeAmountResponseToJSON = GetWithdrawFeeAmountResponseToJSON;
