"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateBusinessChowlyBodyToJSON = exports.UpdateBusinessChowlyBodyFromJSONTyped = exports.UpdateBusinessChowlyBodyFromJSON = void 0;
function UpdateBusinessChowlyBodyFromJSON(json) {
    return UpdateBusinessChowlyBodyFromJSONTyped(json, false);
}
exports.UpdateBusinessChowlyBodyFromJSON = UpdateBusinessChowlyBodyFromJSON;
function UpdateBusinessChowlyBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'chowlyApi': json['chowlyApi'],
    };
}
exports.UpdateBusinessChowlyBodyFromJSONTyped = UpdateBusinessChowlyBodyFromJSONTyped;
function UpdateBusinessChowlyBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'chowlyApi': value.chowlyApi,
    };
}
exports.UpdateBusinessChowlyBodyToJSON = UpdateBusinessChowlyBodyToJSON;
