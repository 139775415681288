"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BusinessServiceAvailabilityOverrideToJSON = exports.BusinessServiceAvailabilityOverrideFromJSONTyped = exports.BusinessServiceAvailabilityOverrideFromJSON = void 0;
var _1 = require("./");
function BusinessServiceAvailabilityOverrideFromJSON(json) {
    return BusinessServiceAvailabilityOverrideFromJSONTyped(json, false);
}
exports.BusinessServiceAvailabilityOverrideFromJSON = BusinessServiceAvailabilityOverrideFromJSON;
function BusinessServiceAvailabilityOverrideFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'date': json['date'],
        'timePeriods': (json['timePeriods'].map(_1.BusinessPeriodFromJSON)),
    };
}
exports.BusinessServiceAvailabilityOverrideFromJSONTyped = BusinessServiceAvailabilityOverrideFromJSONTyped;
function BusinessServiceAvailabilityOverrideToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'date': value.date,
        'timePeriods': (value.timePeriods.map(_1.BusinessPeriodToJSON)),
    };
}
exports.BusinessServiceAvailabilityOverrideToJSON = BusinessServiceAvailabilityOverrideToJSON;
