import React, {ReactElement, ReactNode, useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {BrandsApi, BrandToMerchantPermissions, GetMerchantBrandsResponse, Token} from "@devour/client";
import AuthHeaderLogo from "../../components/auth/AuthHeaderLogo";
import {addError} from "../../redux/meta/metaActions";
import getConfig from "../../utils/getConfig";
import MerchantBrandPermissionCard from "../../components/brands/MerchantBrandPermissionCard";
import {IoStorefrontSharp} from "react-icons/io5";

interface StateProps {
	fullToken: Token;
}

function ManageBrandsPage(props: ManageBrandsPageProps): ReactElement {

	const [brandsRes, setBrandsRes] = useState<GetMerchantBrandsResponse>(undefined);

	useEffect(() => {
		void getMerchantOwnBrands();
	}, []);

	async function getMerchantOwnBrands(): Promise<void> {
		try {
			const res = await new BrandsApi(getConfig(props.fullToken)).getMerchantBrands({
				offset: 0,
				limit: 10000,
			});

			setBrandsRes(res);
		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
		}
	}

	function renderBrandCard(brandPermission: BrandToMerchantPermissions, i: number): ReactNode {
		return (
			<div
				key={`brand-${i}`}
				className="manage-brands_content_brand-list_card"
			>
				<MerchantBrandPermissionCard brandPermission={brandPermission}/>
			</div>
		);
	}

	return (
		<div className="manage-brands">
			<div className="manage-brands_spacer-top">
				<AuthHeaderLogo/>
			</div>

			<div className="manage-brands_content">
				<h3>
					Your Brands
				</h3>

				{brandsRes === undefined ? (
					<div className="manage-brands_content_loading">
						<div className="spinner"/>
					</div>
				) : (
					<div className="manage-brands_content_brand-list">
						{brandsRes?.merchantBrands.length > 0 ? brandsRes?.merchantBrands.map(renderBrandCard) : (
							<div className="manage-brands_content_brand-list_empty">
								<IoStorefrontSharp className="manage-brands_content_brand-list_empty_icon"/>

								<h4 className="manage-brands_content_brand-list_empty_message">
									You have no brands associated with your account.
									<br/>
									Please contact us if you need assistance.
								</h4>
							</div>
						)}
					</div>
				)}
			</div>
		</div>
	);
}

function connector() {
	return connect((store: IStore): StateProps => {
		return {
			fullToken: store.authStore.fullToken,
		}
	});
}

type ManageBrandsPageProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(ManageBrandsPage);
