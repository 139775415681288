import {GetMenuResponse, ItsaCheckmateApi, MenuCategory, MenuItem, Restaurant} from "@devour/client";
import React, {ReactElement, useEffect, useState} from "react";
import { FaChevronRight } from "react-icons/fa";
import {useDispatch} from "react-redux";
import {addError, decrementLoading, incrementLoading} from "../../../redux/meta/metaActions";
import getConfig from "../../../utils/getConfig";
import RestaurantMenuCategoryEditModal from "../../../components/modals/RestaurantMenuCategoryEditModal";
import RestaurantMenuItemEditModal from "../../../components/modals/RestaurantMenuItemEditModal";

interface Props {
    business: Restaurant;
    restaurantMenu: GetMenuResponse;
    menuPermission: boolean;
    onUpdateMenu: () => Promise<void>;
}

interface ItsaCheckmateData {
    itsaCheckmateAccess: string;
    locationId?: number;
}

function BusinessDetailsMenus(props: Props): ReactElement {
    const dispatch = useDispatch();
    const [menuCategoryEdit, setMenuCategoryEdit] = useState<MenuCategory>();
    const [menuItemEdit, setMenuItemEdit] = useState<MenuItem>();

    const [itsaCheckmateData, setItsaCheckmateData] = useState<ItsaCheckmateData>({itsaCheckmateAccess: ""});

    useEffect(() => {
        void fetchItsaCheckmateAccess();
    }, [props.business.id]);

    /**
     * Get the ItsaCheckmate access for this business.
     */
    async function fetchItsaCheckmateAccess(): Promise<void> {
        dispatch(incrementLoading());
        try {
            const res = await new ItsaCheckmateApi(getConfig()).getAuthStatus({
                id: props.business.id,
            });
            setItsaCheckmateData({
                itsaCheckmateAccess: res?.itsaCheckmateAccess,
                locationId: res?.locationId,
            });

        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }
    }


    function onMenuCategoryUpdate() {
        setMenuCategoryEdit(undefined);
        void props.onUpdateMenu();
    }

    function onMenuItemUpdate() {
        setMenuItemEdit(undefined);
        void props.onUpdateMenu();
    }


    function renderMenuCategory(category: MenuCategory, depth = 0): ReactElement {
        return (
            <div
                key={category.id}
                className="business-page_menus_category"
            >
                <div className="business-page_menus_category_header">
                    <div
                        className="business-page_menus_category_header_title"
                        style={{fontSize: `${1.125 - depth * 0.125}rem`}}
                        onClick={() => setMenuCategoryEdit(category)}
                    >
                        {category.name}
                    </div>
                    <FaChevronRight />
                </div>
                {(category.menuItems?.length > 0) && (
                    <ul className="business-page_menus_category_list">
                        {category.menuItems.sort((a, b) => a.sortOrder - b.sortOrder).map(menuItem=> (
                            <li
                                key={menuItem.id}
                                className="business-page_menus_category_list_item"
                                onClick={() => setMenuItemEdit(menuItem)}
                            >
                                {menuItem.name}
                            </li>
                        ))}
                    </ul>
                )}
                <div className="business-page_menus_category_subcategory">
                    {category.menuSubcategories
                        .sort((a, b) => a.sortOrder - b.sortOrder)
                        .map(category => renderMenuCategory(category, depth + 1))
                    }
                </div>
            </div>
        )
    }
    return (
        <div className="business-page_menus">
            <RestaurantMenuCategoryEditModal
                menuPermission={props.menuPermission}
                isChowly={props.business.isChowly}
                isItsaCheckmate={!!itsaCheckmateData.itsaCheckmateAccess}
                restaurantMenu={props.restaurantMenu}
                menuCategory={menuCategoryEdit}
                onUpdate={onMenuCategoryUpdate}
                onClose={() => setMenuCategoryEdit(undefined)}
            />
            <RestaurantMenuItemEditModal
                menuPermission={props.menuPermission}
                isChowly={props.business.isChowly}
                isItsaCheckmate={!!itsaCheckmateData.itsaCheckmateAccess}
                restaurantMenu={props.restaurantMenu}
                menuItem={menuItemEdit}
                onUpdate={onMenuItemUpdate}
                onClose={() => setMenuItemEdit(undefined)}
            />
            <div className="business-page_menus_chowly">
                <strong>Restaurant Chowly Enabled?</strong>
                {(props.business.isChowly) ? " Yes" : " No"}
            </div>
            <div className="business-page_menus_content">
                {props.restaurantMenu?.menus
                    .sort((a,b) => a.sortOrder - b.sortOrder)
                    .map(category => renderMenuCategory(category, 0))
                }
            </div>
        </div>
    )
}

export default BusinessDetailsMenus;
