import React, {ChangeEventHandler, FormEvent, ReactElement, useState} from "react";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {connect, ConnectedProps} from "react-redux";
import {LoginBody, Token, UsersApi, UserType, ErrorType, LoginType} from "@devour/client";
import {IStore} from "../redux/defaultStore";
import FrameButton from "../components/buttons/FrameButton";
import AuthHeaderLogo from "../components/auth/AuthHeaderLogo";
import {useVerifyTokenExpiry, VerifyTokenStatus} from "../hooks/useVerifyTokenExpiry";
import {usePasswordType} from "../hooks/usePasswordType";
import {addError, decrementLoading, incrementLoading, updateCurrentUser} from "../redux/meta/metaActions";
import {login} from "../redux/auth/authActions";
import getConfig from "../utils/getConfig";

const defaultLoginBody: LoginBody = {
	email: "",
	password: "",
};

interface StateProps {
	fullToken: Token;
}

function Login(props: LoginProps): ReactElement {

	const history = useNavigate();
	const [searchParams] = useSearchParams();
	const itsaCheckmateCode = searchParams.get("code");
	const tokenStatus = useVerifyTokenExpiry((itsaCheckmateCode) ? `/itsacheckmate-auth?code=${itsaCheckmateCode}` : "/businesses");
	const [passwordType, togglePasswordType] = usePasswordType();
	const [loginBody, setLoginBody] = useState<LoginBody>(defaultLoginBody);

	/**
	 * Handle all text input onChange events.
	 *
	 * @param key
	 */
	function inputOnChange(key: keyof Omit<LoginBody, "phoneNumber">): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setLoginBody({
				...loginBody,
				[key]: e.target.value,
			});
		}
	}

	async function submitLogin(e: FormEvent<HTMLFormElement>): Promise<void> {
		e.preventDefault();
		props.dispatch(incrementLoading());

		try {
			const loginTokenRes = await new UsersApi(getConfig()).login({
				type: LoginType.MERCHANT,
				loginBody: {
					email: loginBody.email || undefined,
					password: loginBody.password || undefined,
				},
			});

			const userRes = await new UsersApi(getConfig(loginTokenRes)).getProfile();

			if (userRes.user.type === UserType.MERCHANT) {

				if (itsaCheckmateCode) {
					history(`/itsacheckmate-auth?code=${itsaCheckmateCode}`);
				} else {
					history("/businesses");
				}

				await props.dispatch(login(loginTokenRes));
				await props.dispatch(updateCurrentUser(userRes.user));

			} else {
				props.dispatch(await addError({
					type: ErrorType.APP,
					message: "Your account is not a merchant account. Only merchants can use this portal.",
				}));
			}

		} catch (e) {
			props.dispatch(await addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	// Render nothing while we verify if the user's got a token and if it's valid.
	if (tokenStatus !== VerifyTokenStatus.NO_TOKEN) {
		return null;
	}

	return (
		<div className="login-page">
			<div className="login-page_spacer-top">
				<AuthHeaderLogo/>
			</div>

			<div className="login-page_content">
				<h3 className="login-page_content_title">Sign in to DevourGO Merchant Portal</h3>

				{(itsaCheckmateCode) && (
					<p>
						Login here to manage your DevourGO connection with ItsaCheckmate.
						You will have the option to add a new location, re-authorize an existing location or to clear and reset the entire menu for an existing location.
						We are always here to assist, should you have any questions, please contact <a href="mailto:merchant-help@devourgo.io">merchant-help@devourgo.io</a>.
					</p>
				)}

				<form onSubmit={submitLogin}>
					<div className="login-page_content_email-container">
						<label>Email</label>
						<input
							type="email"
							placeholder="Enter your email address"
							value={loginBody.email}
							onChange={inputOnChange("email")}
						/>
					</div>

					<div className="login-page_content_password-container">
						<div className="login-page_content_password-container_label-row">
							<label>Password</label>
							<p className="login-page_content_password-container_label-row_toggler">
								<span
									onClick={togglePasswordType}
									className="a-like"
								>
									{passwordType === "password" ? "Show" : "Hide"}
								</span>
							</p>
						</div>
						<input
							type={passwordType}
							placeholder="Enter your password"
							value={loginBody.password}
							onChange={inputOnChange("password")}
						/>
					</div>

					<FrameButton
						<React.ButtonHTMLAttributes<HTMLButtonElement>>
						color="gray"
						size="normal"
						className="login-page_content_login-button"
						forwardProps={{type: "submit"}}
					>
						Log in
					</FrameButton>
				</form>

				<div className="login-page_content_other-actions">
					<p className="login-page_content_other-actions_forgot">
						<Link to="/forgot-password">
							Forgot Password?
						</Link>
					</p>

					{/*<p className="login-page_content_other-actions_sign-up">*/}
					{/*	Don't have an account?{" "}*/}
					{/*	<Link to="/sign-up">*/}
					{/*		Sign up*/}
					{/*	</Link>*/}
					{/*</p>*/}

					<p className="login-page_content_other-actions_owner">
						Are you trying to order food?{" "}
						<a
							href={process.env.REACT_APP_WEB_URL}
							rel="noopener noreferrer"
						>
							Click here
						</a>
					</p>
				</div>

				<div className="login-page_content_legal">
					<hr/>
					<p className="login-page_content_legal_p">
						By clicking “Log in” above, you acknowledge that you have read and understood, and agree to
						DevourGO’s{" "}
						<Link to="/terms-and-conditions">Terms & Conditions</Link> and{" "}
						<Link to="/privacy-policy">Privacy Policy</Link>
					</p>
				</div>
			</div>

			<div className="login-page_spacer-bottom"/>
		</div>
	);
}

function connector() {
	return connect((store: IStore): StateProps => {
		return {
			fullToken: store.authStore.fullToken,
		}
	});
}

type LoginProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(Login);
