import {isAsset} from "../typeGuards";
import {FileWithSRC} from "../renderAssetsHelper";
import {Asset} from "@devour/client";

export function generateBackgroundUrl(image: Asset | FileWithSRC, placeholder?: string): string {
    if (image) {
        if (isAsset(image)) {
            return image.url;
        }
        return image.imageSRC as string;
    }
    if (placeholder) {
        return placeholder;
    }
    return null;
}