"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderRefundToJSON = exports.OrderRefundFromJSONTyped = exports.OrderRefundFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function OrderRefundFromJSON(json) {
    return OrderRefundFromJSONTyped(json, false);
}
exports.OrderRefundFromJSON = OrderRefundFromJSON;
function OrderRefundFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'admin': json['admin'],
        'menuOrder': json['menuOrder'],
        'reason': (0, _1.OrderRefundReasonFromJSON)(json['reason']),
        'responsibility': (0, _1.OrderRefundResponsibilityFromJSON)(json['responsibility']),
        'responsiblePartyAmount': json['responsiblePartyAmount'],
        'notes': !(0, runtime_1.exists)(json, 'notes') ? undefined : json['notes'],
        'refundId': !(0, runtime_1.exists)(json, 'refundId') ? undefined : json['refundId'],
        'fiat': json['fiat'],
        'dpay': json['dpay'],
        'dpayTransaction': !(0, runtime_1.exists)(json, 'dpayTransaction') ? undefined : json['dpayTransaction'],
        'coinbaseDpay': !(0, runtime_1.exists)(json, 'coinbaseDpay') ? undefined : json['coinbaseDpay'],
        'coinbaseDpayTransaction': !(0, runtime_1.exists)(json, 'coinbaseDpayTransaction') ? undefined : json['coinbaseDpayTransaction'],
        'rewardsClawback': json['rewardsClawback'],
        'rewardsClawbackTransaction': !(0, runtime_1.exists)(json, 'rewardsClawbackTransaction') ? undefined : json['rewardsClawbackTransaction'],
        'transferReverseAmount': json['transferReverseAmount'],
        'transferReverseId': !(0, runtime_1.exists)(json, 'transferReverseId') ? undefined : json['transferReverseId'],
    };
}
exports.OrderRefundFromJSONTyped = OrderRefundFromJSONTyped;
function OrderRefundToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'admin': value.admin,
        'menuOrder': value.menuOrder,
        'reason': (0, _1.OrderRefundReasonToJSON)(value.reason),
        'responsibility': (0, _1.OrderRefundResponsibilityToJSON)(value.responsibility),
        'responsiblePartyAmount': value.responsiblePartyAmount,
        'notes': value.notes,
        'refundId': value.refundId,
        'fiat': value.fiat,
        'dpay': value.dpay,
        'dpayTransaction': value.dpayTransaction,
        'coinbaseDpay': value.coinbaseDpay,
        'coinbaseDpayTransaction': value.coinbaseDpayTransaction,
        'rewardsClawback': value.rewardsClawback,
        'rewardsClawbackTransaction': value.rewardsClawbackTransaction,
        'transferReverseAmount': value.transferReverseAmount,
        'transferReverseId': value.transferReverseId,
    };
}
exports.OrderRefundToJSON = OrderRefundToJSON;
