"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetBrandMapMainPromoResponseAllOfToJSON = exports.GetBrandMapMainPromoResponseAllOfFromJSONTyped = exports.GetBrandMapMainPromoResponseAllOfFromJSON = void 0;
var runtime_1 = require("../runtime");
function GetBrandMapMainPromoResponseAllOfFromJSON(json) {
    return GetBrandMapMainPromoResponseAllOfFromJSONTyped(json, false);
}
exports.GetBrandMapMainPromoResponseAllOfFromJSON = GetBrandMapMainPromoResponseAllOfFromJSON;
function GetBrandMapMainPromoResponseAllOfFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'lastRedeemedAt': !(0, runtime_1.exists)(json, 'lastRedeemedAt') ? undefined : json['lastRedeemedAt'],
        'lastRedeemedOrderId': !(0, runtime_1.exists)(json, 'lastRedeemedOrderId') ? undefined : json['lastRedeemedOrderId'],
    };
}
exports.GetBrandMapMainPromoResponseAllOfFromJSONTyped = GetBrandMapMainPromoResponseAllOfFromJSONTyped;
function GetBrandMapMainPromoResponseAllOfToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'lastRedeemedAt': value.lastRedeemedAt,
        'lastRedeemedOrderId': value.lastRedeemedOrderId,
    };
}
exports.GetBrandMapMainPromoResponseAllOfToJSON = GetBrandMapMainPromoResponseAllOfToJSON;
