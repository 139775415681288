import React, {ChangeEventHandler, ReactElement, useEffect, useState} from "react";
import {
    Asset,
    AssetsApi,
    BusinessesApi,
    BusinessTaxonomiesApi,
    BusinessTaxonomy,
    BusinessType,
    CommerceApi,
    HandoffOptions,
} from "@devour/client";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/metaActions";
import getConfig from "../../utils/getConfig";
import {useNavigate} from "react-router-dom";
import {CreateBusinessBodyFrontend} from "./components/BusinessFormValues";
import {SectionViews} from "./BusinessDetailsPage";
import {addURLsToFiles} from "../../utils/renderAssetsHelper";
import BusinessDetailsPageTopBar from "./components/BusinessDetailsPageTopBar";
import BusinessDetailsHeader from "./components/ BusinessDetailsHeader";
import BusinessDetailsSectionTabs from "./components/BusinessDetailsSectionTabs";
import BusinessDetailsContactInfo from "./components/BusinessDetailsContactInfo";
import BusinessDetailsCategories from "./components/BusinessDetailsCategories";
import BusinessDetailsPayoutAccount from "./components/BusinessDetailsPayoutAccount";
import {StripeAccountObject} from "../../types/Stripe";

const defaultValues: CreateBusinessBodyFrontend = {
    type: BusinessType.RESTAURANT,
    parent: "",
    name: "",
    internalName: "",
    timeZone: "",
    currency: "USD",
    description: "",
    email: "",
    website: "",
    prepTime: 20,
    specialInstructions: true,
    phoneNumber: {
        countryCode: "US",
        nationalNumber: "",
    },
    address: {
        line1: "",
        line2: "",
        locality: "",
        administrativeArea: "",
        postalCode: "",
        country: "US",
    },
    taxonomies: [],
    handoffOptions: Object.values(HandoffOptions),
    icon: undefined,
    headerImage: undefined,
};


function BusinessCreatePage(): ReactElement {
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const dispatch = useDispatch();
    const history = useNavigate();
    const urlParams = new URLSearchParams(window.location.search);
    const viewParam = urlParams.get('view');

    const [businessBody, setBusinessBody] = useState<CreateBusinessBodyFrontend>(defaultValues);
    const [businessesTaxonomies, setBusinessesTaxonomies] = useState<Array<BusinessTaxonomy>>([]);
    const [sectionView, setSectionView] = useState<SectionViews>(SectionViews.CONTACT);

    const [stripeAccounts, setStripeAccounts] = useState<Array<StripeAccountObject>>([]);

    useEffect(() => {
        fetchBusinessTaxonomies().then();
        fetchStripeAccounts().then();
        
        if (viewParam === "payouts") {
            setSectionView(SectionViews.PAYOUTS);
        }
    }, []);


    /**
    /**
     * Get all the Stripe account options.
     */
    async function fetchStripeAccounts(): Promise<void> {
        dispatch(incrementLoading());

        try {
            const res = await new CommerceApi(getConfig()).stripeAccountsList();
            setStripeAccounts(res.accounts as Array<StripeAccountObject>);
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }

    }

    /**
     * Get the list of businesses categories from our api.
     */
    async function fetchBusinessTaxonomies(): Promise<void> {
        try {
            const res = await new BusinessTaxonomiesApi().getBusinessTaxonomies();
            setBusinessesTaxonomies(res.businessTaxonomies);
        } catch (e) {
        }
    }

    function businessBodyOnChange(updatedValues: Partial<CreateBusinessBodyFrontend>): void {
        setBusinessBody(prevState => {
            return {...prevState, ...updatedValues}
        });
    }

    /**
     * Handle all text input onChange events.
     *
     * @param key
     */
    function inputOnChange(key: keyof Omit<CreateBusinessBodyFrontend, "icon" | "headerImage">): ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement> {
        return (e) => {
            setBusinessBody({
                ...businessBody,
                [key]: e.target.value,
            })
        }
    }

    function onImageChange(key: keyof Pick<CreateBusinessBodyFrontend, "icon" | "headerImage">): ChangeEventHandler<HTMLInputElement> {
        return async (e) => {
            const newAsset = (await addURLsToFiles(e.target.files))[0];
            setBusinessBody({
                ...businessBody,
                [key]: newAsset
            })
        }
    }

    function onImageRemove(key: keyof Pick<CreateBusinessBodyFrontend, "icon" | "headerImage">): void {
        setBusinessBody({
            ...businessBody,
            [key]: undefined
        })
    }

    async function submitNewBusiness(): Promise<void> {
        dispatch(incrementLoading());

        try {
            let icon: Asset;
            if (businessBody.icon) {
                icon = await new AssetsApi(getConfig(fullToken)).createAsset({
                    asset: businessBody.icon,
                });
            }

            let headerImage: Asset;
            if (businessBody.headerImage) {
                headerImage = await new AssetsApi(getConfig(fullToken)).createAsset({
                    asset: businessBody.headerImage,
                });
            }

            const business = await new BusinessesApi(getConfig()).createBusiness({
                createBusinessBody: {
                    ...businessBody,
                    prepTime: (businessBody.prepTime) ? Number(businessBody.prepTime) : undefined,
                    icon: icon ? icon.id : undefined,
                    headerImage: headerImage ? headerImage.id : undefined,
                },
            });

            history(`/businesses/${business.id}/details`);
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }
    }

    return (
        <div className="business-details-page">
            <BusinessDetailsPageTopBar
                businessBody={businessBody}
                businessBodyOnChange={businessBodyOnChange}
                onSubmitBusiness={submitNewBusiness}
            />

            <BusinessDetailsHeader
                businessBody={businessBody}
                onImageChange={onImageChange}
                onImageRemove={onImageRemove}
                inputOnChange={inputOnChange}
            />

            <div className="business-page_section-margin">
                <BusinessDetailsSectionTabs
                    sectionView={sectionView}
                    setSectionView={setSectionView}
                    businessBody={businessBody}
                />
                {sectionView === SectionViews.CONTACT && (
                    <BusinessDetailsContactInfo
                        businessBody={businessBody}
                        businessBodyOnChange={businessBodyOnChange}
                        inputOnChange={inputOnChange}
                    />
                )}
                {sectionView === SectionViews.CATEGORIES && (
                    <BusinessDetailsCategories
                        businessTaxonomies={businessesTaxonomies}
                        businessBody={businessBody}
                        businessBodyOnChange={businessBodyOnChange}
                    />
                )}
                {sectionView === SectionViews.PAYOUTS && (
                    <div className="business-create_payout-settings">
                        <BusinessDetailsPayoutAccount
                            billingAccountOnChange={(stripeAccountId) => businessBodyOnChange({stripeAccountId})}
                            stripeAccounts={stripeAccounts}
                            businessBody={businessBody}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}

export default BusinessCreatePage;
