"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeliveryHandoffInstructionsToJSON = exports.DeliveryHandoffInstructionsFromJSONTyped = exports.DeliveryHandoffInstructionsFromJSON = exports.DeliveryHandoffInstructions = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var DeliveryHandoffInstructions;
(function (DeliveryHandoffInstructions) {
    DeliveryHandoffInstructions["HANDITTOME"] = "HAND_IT_TO_ME";
    DeliveryHandoffInstructions["LEAVEITATMYDOOR"] = "LEAVE_IT_AT_MY_DOOR";
    DeliveryHandoffInstructions["OTHER"] = "OTHER";
})(DeliveryHandoffInstructions = exports.DeliveryHandoffInstructions || (exports.DeliveryHandoffInstructions = {}));
function DeliveryHandoffInstructionsFromJSON(json) {
    return DeliveryHandoffInstructionsFromJSONTyped(json, false);
}
exports.DeliveryHandoffInstructionsFromJSON = DeliveryHandoffInstructionsFromJSON;
function DeliveryHandoffInstructionsFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.DeliveryHandoffInstructionsFromJSONTyped = DeliveryHandoffInstructionsFromJSONTyped;
function DeliveryHandoffInstructionsToJSON(value) {
    return value;
}
exports.DeliveryHandoffInstructionsToJSON = DeliveryHandoffInstructionsToJSON;
