"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionSubjectToJSON = exports.TransactionSubjectFromJSONTyped = exports.TransactionSubjectFromJSON = exports.TransactionSubject = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var TransactionSubject;
(function (TransactionSubject) {
    TransactionSubject["WITHDRAW"] = "WITHDRAW";
    TransactionSubject["WITHDRAWCANCELLED"] = "WITHDRAW_CANCELLED";
    TransactionSubject["WITHDRAWREJECTED"] = "WITHDRAW_REJECTED";
    TransactionSubject["DEPOSIT"] = "DEPOSIT";
    TransactionSubject["REWARD"] = "REWARD";
    TransactionSubject["LEVELUPREWARD"] = "LEVEL_UP_REWARD";
    TransactionSubject["REFUND"] = "REFUND";
    TransactionSubject["COINBASEREFUND"] = "COINBASE_REFUND";
    TransactionSubject["OTHER"] = "OTHER";
    TransactionSubject["ADMINDEBIT"] = "ADMIN_DEBIT";
    TransactionSubject["ADMINCREDIT"] = "ADMIN_CREDIT";
    TransactionSubject["INDUSTRYCOLLECTIONOWNERSHIPREWARD"] = "INDUSTRY_COLLECTION_OWNERSHIP_REWARD";
    TransactionSubject["MENUORDERPAYMENT"] = "MENU_ORDER_PAYMENT";
    TransactionSubject["MENUORDERREWARD"] = "MENU_ORDER_REWARD";
    TransactionSubject["MENUORDERINDUSTRYWALLETREWARD"] = "MENU_ORDER_INDUSTRY_WALLET_REWARD";
    TransactionSubject["MENUORDERDPAYPAYMENTREWARD"] = "MENU_ORDER_DPAY_PAYMENT_REWARD";
    TransactionSubject["MENUORDERREWARDCLAWBACK"] = "MENU_ORDER_REWARD_CLAWBACK";
    TransactionSubject["LOOTBOXBURNED"] = "LOOTBOX_BURNED";
    TransactionSubject["REWARDFORREFERRINGUSER"] = "REWARD_FOR_REFERRING_USER";
    TransactionSubject["FIATPURCHASEDPAY"] = "FIAT_PURCHASE_DPAY";
    TransactionSubject["MINTINDUSTRYPASSPURCHASE"] = "MINT_INDUSTRY_PASS_PURCHASE";
    TransactionSubject["MINTINDUSTRYPASSREFUND"] = "MINT_INDUSTRY_PASS_REFUND";
    TransactionSubject["ONCHAINREFUNDFORSTRIPEFAIL"] = "ONCHAIN_REFUND_FOR_STRIPE_FAIL";
    TransactionSubject["NFTREDEEMDPAYREWARD"] = "NFT_REDEEM_DPAY_REWARD";
    TransactionSubject["XPPURCHASEDPAY"] = "XP_PURCHASE_DPAY";
})(TransactionSubject = exports.TransactionSubject || (exports.TransactionSubject = {}));
function TransactionSubjectFromJSON(json) {
    return TransactionSubjectFromJSONTyped(json, false);
}
exports.TransactionSubjectFromJSON = TransactionSubjectFromJSON;
function TransactionSubjectFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.TransactionSubjectFromJSONTyped = TransactionSubjectFromJSONTyped;
function TransactionSubjectToJSON(value) {
    return value;
}
exports.TransactionSubjectToJSON = TransactionSubjectToJSON;
