"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetNftTrackerResponseToJSON = exports.GetNftTrackerResponseFromJSONTyped = exports.GetNftTrackerResponseFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function GetNftTrackerResponseFromJSON(json) {
    return GetNftTrackerResponseFromJSONTyped(json, false);
}
exports.GetNftTrackerResponseFromJSON = GetNftTrackerResponseFromJSON;
function GetNftTrackerResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'nftTracker': !(0, runtime_1.exists)(json, 'nftTracker') ? undefined : (0, _1.NftTrackerFromJSON)(json['nftTracker']),
        'nftTrackers': !(0, runtime_1.exists)(json, 'nftTrackers') ? undefined : (json['nftTrackers'].map(_1.NftTrackerFromJSON)),
    };
}
exports.GetNftTrackerResponseFromJSONTyped = GetNftTrackerResponseFromJSONTyped;
function GetNftTrackerResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'nftTracker': (0, _1.NftTrackerToJSON)(value.nftTracker),
        'nftTrackers': value.nftTrackers === undefined ? undefined : (value.nftTrackers.map(_1.NftTrackerToJSON)),
    };
}
exports.GetNftTrackerResponseToJSON = GetNftTrackerResponseToJSON;
