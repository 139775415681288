import React, {ChangeEventHandler, ReactElement, useEffect, useRef, useState} from "react";
import {useParams} from "react-router";
import {useNavigate} from "react-router-dom";
import {
    Address,
    Asset,
    AssetsApi, Brand,
    BrandMap,
    BrandMapAdvertisementBlocksBody, BrandMapBannerImageBody,
    BrandMapColorTheme,
    BrandMapStatus, BrandPageType,
    BrandsApi,
    Business,
    GetBrandBusinessesResponse, LoginAccessPoint,
    OrderDiscount,
} from "@devour/client";
import {addURLsToFiles, FileWithSRC} from "../../utils/renderAssetsHelper";
import getConfig from "../../utils/getConfig";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/metaActions";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import CreateBrandMapEditAddressModal from "../../components/modals/CreateBrandMapEditAddressModal";
import BrandeMapSaveDraftSuccessModal from "../../components/modals/BrandeMapSaveDraftSuccessModal";
import BrandMapSavePublishedSuccessModal from "../../components/modals/BrandMapSavePublishedSuccessModal";
import MapCreationPageHeader from "../../components/brands/MapCreationPageHeader";
import {isAsset} from "../../utils/typeGuards";
import {EditBrandMapBodyFrontend} from "./components/BrandMapFormValues";
import EditBrandMapMainPromoSelection from "./components/EditBrandMapMainPromoSelection";
import MerchantBrandHandleName from "../../components/brands/MerchantBrandHandleName";
import MerchantBrandTitleDescription from "../../components/brands/MerchantBrandTitleDescription";
import MerchantBrandUploadBannerImage from "../../components/brands/MerchantBrandUploadBannerImage";
import EditBrandMapMainPromoTagline from "./components/EditBrandMapMainPromoTagline";
import EditBrandMapMainPromoDescription from "./components/EditBrandMapMainPromoDescription";
import EditBrandMapMainPromoImages from "./components/EditBrandMapMainPromoImages";
import EditBrandMapMainPromoCartImages from "./components/EditBrandMapMainPromoCartImages";
import MerchantBrandAdvertisementImages from "../../components/brands/MerchantBrandAdvertisementImages";
import MerchantBrandPageTheme from "../../components/brands/MerchantBrandPageTheme";
import MerchantBrandButtons from "../../components/brands/MerchantBrandButtons";
import MerchantBrandLocationSelection from "../../components/brands/MerchantBrandLocationSelection";
import EditBrandMapMainPromoTitle from "./components/EditBrandMapMainPromoTitle";
import BrandMapPreviewMode from "./BrandMapPreviewMode";
import classNames from "classnames";
import MerchantBrandAutoMint from "../../components/brands/MerchantBrandAutoMint";
import MerchantBrandMapPostAutoMintToast from "../../components/brands/MerchantBrandMapPostAutoMintToast";
import BrandMapToastTextColor from "../../components/brands/BrandMapToastTextColor";
import MerchantBrandDescription from "../../components/brands/MerchantBrandDescription";
import MerchantBrandHeaders from "../../components/brands/MerchantBrandHeaders";
import MerchantBrandMapPreAutoMintToast from "../../components/brands/MerchantBrandMapPreAutoMintToast";
import MerchantBrandUserLoginSettings from "../../components/brands/MerchantBrandUserLoginSettings";
import MerchantBrandPageTypeSettings from "../../components/brands/MerchantBrandPageTypeSettings";

const defaultEditBrandMapForm: EditBrandMapBodyFrontend = {
    name: "",
    slug: "",
    bannerImage: undefined,
    defaultLocation: undefined,
    businesses: [],
    mainPromo: undefined,
    promos: [],
    promoHero: undefined,
    promoBackground: undefined,
    promoCartHero: undefined,
    promoCartBackground: undefined,
    promoDescription: "",
    promoTagline: "",
    promoTitle: "",
    colorTheme: BrandMapColorTheme.DARK,
    colorBackground: "",
    advertisementTitle: "",
    advertisementBlocks: [],
    loginAccessPoint: LoginAccessPoint.PAGE,
    defaultBusiness: undefined,
    autoMintContract: "",
    autoMintChain: undefined,
    autoMintToastMessage: "",
    autoMintToastBackground: undefined,
    description: "",
    autoMintToastTextColor: BrandMapColorTheme.DARK,
    preAutoMintToastMessage: "",
    preAutoMintToastBackground: undefined,
    allowMarketplaceAccess: false,
    promoHeader: {
        title: "",
        description: "",
    },
    htmlSectionFirstRow: [
        {
            content: "",
            widthPercentage: 100,
        },
        {
            content: "",
            widthPercentage: 0,
        }
    ],
    htmlSectionSecondRow: [
        {
            content: "",
            widthPercentage: 100,
        },
        {
            content: "",
            widthPercentage: 0,
        }
    ],
};

export enum ViewMode {
    EDITING,
    PREVIEW
}

function EditBrandMapPage(): ReactElement {

    const {slug} = useParams<{ slug?: string }>();
    const history = useNavigate();
    const dispatch = useDispatch();
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const [businessesRes, setBusinessesRes] = useState<GetBrandBusinessesResponse>(undefined);
    const [brandMapOriginal, setBrandMapOriginal] = useState<BrandMap>(undefined);
    const [brandMap, setBrandMap] = useState<BrandMap>(undefined);
    const [editBrandMapForm, setEditBrandMapForm] = useState<EditBrandMapBodyFrontend>(defaultEditBrandMapForm);
    const [showAddressModal, setShowAddressModal] = useState(false);
    const [showDraftSuccess, setShowDraftSuccess] = useState(false);
    const [showPublishSuccess, setShowPublishSuccess] = useState(false);
    const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.EDITING);

    const selectedBusinessesRefs = useRef<Array<string>>([]);
    const markerRefs = useRef<{ [key: string]: google.maps.Marker }>({});

    /**
     * On load or url param change, call api to get the businesses for the specific brand, if the brand id exists.
     */
    useEffect(() => {
        if (!slug) {
            history("/brands");
            return;
        }

        void getDataForMapForm();
    }, [slug]);

    /**
     * Because we need the Map to be a pure-component that never re-renders (it will keep focusing on its default center if so),
     * we use refs to the markers to control their opacity, where {opacity: 1} represents businesses that are selected,
     * and {opacity: 0.5} represents businesses that are deselected. Taking into account if the merchant is using the whitelist method
     * or not, so if whitelist = true, turn opacity to 1 for businesses that appear in the list, otherwise if whitelist is false,
     * the businesses that appear in the array should be toggled to opacity 0.5.
     *
     * So, everytime the list of selected businesses or whitelist changes, iterate of the keys of the useRef of the marker refs
     * (business ids), and use the Google Maps built in method to change the opacity.
     *
     */
    useEffect(() => {
        selectedBusinessesRefs.current = editBrandMapForm.businesses;
        Object.keys(markerRefs.current).forEach((markerKey) => {
            if (editBrandMapForm.businesses.indexOf(markerKey) > -1) {
                markerRefs.current[markerKey].setOpacity(1);
            } else {
                markerRefs.current[markerKey].setOpacity(0.5);
            }
        })
    }, [editBrandMapForm.businesses]);

    /**
     * Get raw map as it currently exists & the businesses for the brand.
     *
     */
    async function getDataForMapForm(): Promise<void> {
        try {
            const mapRes = await new BrandsApi(getConfig(fullToken)).getBrandMap({
                slug: slug,
            });

            const _businessesRes = await new BrandsApi(getConfig(fullToken)).getBrandBusinesses({
                id: mapRes.brand.id,
                offset: 0,
                limit: 100000,
            });

            setEditBrandMapForm({
                name: mapRes.name,
                slug: mapRes.slug,
                bannerImage: mapRes.bannerImage,
                defaultLocation: mapRes.defaultLocation,
                businesses: mapRes.businesses,
                mainPromo: mapRes.mainPromo?.id,
                promos: mapRes.promos.map(b => b.id),
                promoHero: mapRes.promoHero,
                promoBackground: mapRes.promoBackground,
                promoCartHero: mapRes.promoCartHero,
                promoCartBackground: mapRes.promoCartBackground,
                promoTagline: mapRes.promoTagline,
                promoTitle: mapRes.promoTitle,
                promoDescription: mapRes.promoDescription,
                colorTheme: mapRes.colorTheme,
                colorBackground: mapRes.colorBackground,
                advertisementTitle: mapRes.advertisementTitle,
                advertisementBlocks: mapRes.advertisementBlocks,
                autoMintContract: mapRes.autoMintContract,
                autoMintChain: mapRes.autoMintChain,
                autoMintToastMessage: mapRes.autoMintToastMessage,
                autoMintToastBackground: mapRes.autoMintToastBackground,
                description: mapRes.description,
                autoMintToastTextColor: mapRes.autoMintToastTextColor,
                preAutoMintToastMessage: mapRes.preAutoMintToastMessage,
                preAutoMintToastBackground: mapRes.preAutoMintToastBackground,
                loginAccessPoint: mapRes.loginAccessPoint,
                allowMarketplaceAccess: mapRes.allowMarketplaceAccess,
                promoHeader: mapRes.promoHeader || defaultEditBrandMapForm.promoHeader,
                htmlSectionFirstRow: mapRes.htmlSectionFirstRow?.length ? mapRes.htmlSectionFirstRow : defaultEditBrandMapForm.htmlSectionFirstRow,
                htmlSectionSecondRow: mapRes.htmlSectionSecondRow?.length ? mapRes.htmlSectionSecondRow : defaultEditBrandMapForm.htmlSectionSecondRow,
            });
            setBrandMap(mapRes);
            setBrandMapOriginal(mapRes);
            setBusinessesRes(_businessesRes);
        } catch (e) {
            dispatch(await addError(e));
        } finally {

        }
    }

    /**
     * Handle onChange event for the image asset upload input.
     *
     * @param key
     */
    function onImageChange(key: keyof Pick<
        EditBrandMapBodyFrontend,
        "bannerImage" | "promoHero" | "promoBackground" | "promoCartHero" | "promoCartBackground" | "autoMintToastBackground" | "preAutoMintToastBackground"
    >): ChangeEventHandler<HTMLInputElement> {
        return async (e) => {
            const newAsset = (await addURLsToFiles(e.target.files))[0];
            setEditBrandMapForm({
                ...editBrandMapForm,
                [key]: newAsset,
            });
        }
    }

    /**
     * Handle all text input onChange events.
     *
     * @param key
     */
    function inputOnChange(key: keyof EditBrandMapBodyFrontend): ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> {
        return (e) => {
            setEditBrandMapForm({
                ...editBrandMapForm,
                [key]: e.target.value,
            });
        }
    }

    /**
     * Handle description Quill onChange events.
     *
     * @param description
     */
    function descriptionOnChange(description: string): void {
        setEditBrandMapForm({
            ...editBrandMapForm,
            description,
        });
    }
    /**
     * Handle map slug input onChange events.
     *
     * @param e
     */
    function slugOnChange(e: React.ChangeEvent<HTMLInputElement>): void {
        setEditBrandMapForm({
            ...editBrandMapForm,
            slug: e.target.value.toLowerCase().replace(/\W/g, ''),
        });
    }

    /**
     * Handle color theme onChange events.
     *
     * @param theme
     */
    function colorThemeOnChange(theme: BrandMapColorTheme): void {
        setEditBrandMapForm({
            ...editBrandMapForm,
            colorTheme: theme,
        });
    }

    /**
     * Handle auto mint toast text color change.
     *
     * @param color
     */
    function autoMintToastTextColorOnChange(color: BrandMapColorTheme): void {
        setEditBrandMapForm({
            ...editBrandMapForm,
            autoMintToastTextColor: color,
        });
    }

    /**
     * Handle main promo onChange events.
     *
     * @param promo
     */
    function mainPromoOnChange(promo: OrderDiscount): void {
        if (editBrandMapForm.mainPromo === promo.id) {
            setEditBrandMapForm({
                ...editBrandMapForm,
                mainPromo: undefined,
            });
        } else {
            let updatedPromos = editBrandMapForm.promos;

            if (editBrandMapForm.promos.includes(promo.id)) {
                updatedPromos = editBrandMapForm.promos.filter((id) => id !== promo.id);
            }

            setEditBrandMapForm({
                ...editBrandMapForm,
                promos: updatedPromos,
                mainPromo: promo.id,
            });
        }
    }

    /**
     * Handle regular promo onChange events.
     *
     * @param promo
     */
    function promoOnChange(promo: OrderDiscount): void {
        if (editBrandMapForm.promos.includes(promo.id)) {
            const updatedPromos = editBrandMapForm.promos.filter((id) => id !== promo.id);
            setEditBrandMapForm({
                ...editBrandMapForm,
                promos: updatedPromos,
            });
        } else {
            setEditBrandMapForm({
                ...editBrandMapForm,
                promos: [...editBrandMapForm.promos, promo.id],
            });
        }
    }

    /**
     * Submit the updated map with the desired status. On completed save, show one of two modals;
     * 1. If merchant chose draft, show them the modal to either "continue editing" or "back to maps".
     * 2. If merchant chose published, show them the modal to either "view the live map", "back to maps", or "continue editing".
     *
     * @param status
     */
    function submitUpdatedMap(status: BrandMapStatus.DRAFT | BrandMapStatus.PUBLISHED): () => Promise<void> {
        return async () => {
            dispatch(incrementLoading());

            try {
                let bannerImage: BrandMapBannerImageBody;

                let bannerImageAsset: Asset | FileWithSRC = editBrandMapForm.bannerImage.image;

                if (bannerImageAsset && !isAsset(bannerImageAsset)) {
                    bannerImageAsset = await new AssetsApi(getConfig(fullToken)).createAsset({
                        asset: bannerImageAsset,
                    });
                }

                const assetId = (bannerImageAsset as Asset)?.id;

                bannerImage = {
                    image: assetId,
                    imageType: editBrandMapForm.bannerImage.imageType,
                };

                let promoHero: Asset | FileWithSRC = editBrandMapForm.promoHero;
                if (promoHero && !isAsset(editBrandMapForm.promoHero)) {
                    promoHero = await new AssetsApi(getConfig(fullToken)).createAsset({
                        asset: editBrandMapForm.promoHero,
                    });
                }

                let promoBackground: Asset | FileWithSRC = editBrandMapForm.promoBackground;
                if (promoBackground && !isAsset(editBrandMapForm.promoBackground)) {
                    promoBackground = await new AssetsApi(getConfig(fullToken)).createAsset({
                        asset: editBrandMapForm.promoBackground,
                    });
                }

                let promoCartHero: Asset | FileWithSRC = editBrandMapForm.promoCartHero;
                if (promoCartHero && !isAsset(editBrandMapForm.promoCartHero)) {
                    promoCartHero = await new AssetsApi(getConfig(fullToken)).createAsset({
                        asset: editBrandMapForm.promoCartHero,
                    });
                }

                let promoCartBackground: Asset | FileWithSRC = editBrandMapForm.promoCartBackground;
                if (promoCartBackground && !isAsset(editBrandMapForm.promoCartBackground)) {
                    promoCartBackground = await new AssetsApi(getConfig(fullToken)).createAsset({
                        asset: editBrandMapForm.promoCartBackground,
                    });
                }

                let autoMintToastBackground: Asset | FileWithSRC = editBrandMapForm.autoMintToastBackground;
                if (autoMintToastBackground && !isAsset(editBrandMapForm.autoMintToastBackground)) {
                    autoMintToastBackground = await new AssetsApi(getConfig(fullToken)).createAsset({
                        asset: editBrandMapForm.autoMintToastBackground,
                    });
                }

                let preAutoMintToastBackground: Asset | FileWithSRC = editBrandMapForm.preAutoMintToastBackground;
                if (preAutoMintToastBackground && !isAsset(editBrandMapForm.preAutoMintToastBackground)) {
                    preAutoMintToastBackground = await new AssetsApi(getConfig(fullToken)).createAsset({
                        asset: editBrandMapForm.preAutoMintToastBackground,
                    });
                }

                const advertisementBlocks: Array<BrandMapAdvertisementBlocksBody> = await Promise.all(editBrandMapForm.advertisementBlocks.map(async (block) => {
                    const blockImage = block.image;
                    let asset: Asset;
                    if (blockImage) {
                        asset = (isAsset(blockImage)) ?
                            blockImage :
                            await new AssetsApi(getConfig(fullToken)).createAsset({
                                asset: blockImage,
                            });
                    }

                    return {
                        ...block,
                        image: asset?.id,
                    };
                }));

                const res = await new BrandsApi(getConfig(fullToken)).updateBrandMap({
                    id: brandMap.id,
                    updateBrandMapBody: {
                        name: editBrandMapForm.name,
                        slug: editBrandMapForm.slug || undefined,
                        bannerImage: bannerImage,
                        defaultLocation: editBrandMapForm.defaultLocation,
                        status,
                        businesses: editBrandMapForm.businesses,
                        mainPromo: editBrandMapForm.mainPromo,
                        promos: editBrandMapForm.promos,
                        promoHero: promoHero ? (isAsset(promoHero) ? promoHero.id : (editBrandMapForm.promoHero as Asset)?.id) : undefined,
                        promoBackground: promoBackground ? (isAsset(promoBackground) ? promoBackground.id : (editBrandMapForm.promoBackground as Asset)?.id) : undefined,
                        promoCartHero: promoCartHero ? (isAsset(promoCartHero) ? promoCartHero.id : (editBrandMapForm.promoCartHero as Asset)?.id) : undefined,
                        promoCartBackground: promoCartBackground ? (isAsset(promoCartBackground) ? promoCartBackground.id : (editBrandMapForm.promoCartBackground as Asset)?.id) : undefined,
                        promoTagline: editBrandMapForm.promoTagline,
                        promoTitle: editBrandMapForm.promoTitle,
                        promoDescription: editBrandMapForm.promoDescription,
                        colorTheme: editBrandMapForm.colorTheme,
                        loginAccessPoint: editBrandMapForm.loginAccessPoint,
                        colorBackground: editBrandMapForm.colorBackground,
                        advertisementTitle: editBrandMapForm.advertisementTitle,
                        advertisementBlocks: advertisementBlocks,
                        defaultBusiness: editBrandMapForm.defaultBusiness,
                        autoMintContract: editBrandMapForm.autoMintContract,
                        autoMintChain: editBrandMapForm.autoMintChain,
                        autoMintToastMessage: editBrandMapForm.autoMintToastMessage,
                        autoMintToastBackground: autoMintToastBackground
                            ? (isAsset(autoMintToastBackground)
                                ? autoMintToastBackground.id
                                : (editBrandMapForm.autoMintToastBackground as Asset)?.id)
                            : undefined,
                        description: editBrandMapForm.description,
                        autoMintToastTextColor: editBrandMapForm.autoMintToastTextColor,
                        preAutoMintToastMessage: editBrandMapForm.preAutoMintToastMessage,
                        preAutoMintToastBackground: editBrandMapForm.preAutoMintToastBackground
                            ? (isAsset(preAutoMintToastBackground)
                                ? preAutoMintToastBackground.id
                                : (editBrandMapForm.preAutoMintToastBackground as Asset)?.id)
                            : undefined,
                        allowMarketplaceAccess: editBrandMapForm.allowMarketplaceAccess,
                        promoHeader: editBrandMapForm.promoHeader,
                        htmlSectionFirstRow: editBrandMapForm.htmlSectionFirstRow,
                        htmlSectionSecondRow: editBrandMapForm.htmlSectionSecondRow,
                    },
                });

                // Reset all of this so that if "continue editing" is chosen in the modal, everything should still work as expected.
                setBrandMap(res);
                setBrandMapOriginal(res);

                setEditBrandMapForm({
                    name: res.name,
                    slug: res.slug,
                    bannerImage: res.bannerImage,
                    defaultLocation: res.defaultLocation,
                    businesses: res.businesses,
                    mainPromo: res.mainPromo?.id,
                    promos: res.promos.map(b => b.id),
                    promoHero: res.promoHero,
                    promoBackground: res.promoBackground,
                    promoCartHero: res.promoCartHero,
                    promoCartBackground: res.promoCartBackground,
                    promoTagline: res.promoTagline,
                    promoTitle: res.promoTitle,
                    colorTheme: res.colorTheme,
                    loginAccessPoint: res.loginAccessPoint,
                    colorBackground: res.colorBackground,
                    advertisementTitle: res.advertisementTitle,
                    advertisementBlocks: res.advertisementBlocks,
                    defaultBusiness: res.defaultBusiness,
                    autoMintContract: res.autoMintContract,
                    autoMintChain: res.autoMintChain,
                    autoMintToastMessage: res.autoMintToastMessage,
                    autoMintToastBackground: res.autoMintToastBackground,
                    description: res.description,
                    autoMintToastTextColor: res.autoMintToastTextColor,
                    preAutoMintToastMessage: res.preAutoMintToastMessage,
                    preAutoMintToastBackground: res.preAutoMintToastBackground,
                    allowMarketplaceAccess: res.allowMarketplaceAccess,
                    promoHeader: res.promoHeader,
                    htmlSectionFirstRow: res.htmlSectionFirstRow,
                    htmlSectionSecondRow: res.htmlSectionSecondRow,
                });

                if (res.status === BrandMapStatus.DRAFT) {
                    setShowDraftSuccess(true);
                } else if (res.status === BrandMapStatus.PUBLISHED) {
                    setShowPublishSuccess(true);
                }
            } catch (e) {
                dispatch(await addError(e));
            } finally {
                dispatch(decrementLoading());
            }
        }
    }

    /**
     * Show or hide the modal for editing the default location.
     *
     */
    function toggleAddressModal(): void {
        setShowAddressModal(s => !s);
    }

    /**
     * When merchant confirms changes in the default-location-modal, save new address to the form state. & close the modal.
     *
     * @param newAddress
     */
    function onDoneAddressModel(newAddress: Address): void {
        setShowAddressModal(false);
        setEditBrandMapForm((_editBrandMapForm) => {
            return {
                ..._editBrandMapForm,
                defaultLocation: newAddress,
            }
        });
    }

    /**
     * Helper for toggling selected businesses; we use a copy of the form data in a ref to maintain our ability to reference &
     * modify their opacity as needed.
     *
     * @param business
     */
    function toggleLocationHelper(business: Business): void {

        const businesses = [...selectedBusinessesRefs.current];
        const selected = businesses.indexOf(business.id) > -1;
        const originalLength = businesses.length;

        if (selected) {
            businesses.splice(businesses.indexOf(business.id), 1);
        } else {
            businesses.push(business.id);
        }

        if (!originalLength && businesses.length === 1) {
            setEditBrandMapForm((_editBrandMapForm) => {
                return {
                    ..._editBrandMapForm,
                    businesses,
                    defaultBusiness: business.id,
                    defaultLocation: business.address
                }
            });
        } else {
            setEditBrandMapForm((_editBrandMapForm) => {
                return {
                    ..._editBrandMapForm,
                    businesses,
                }
            });
        }

    }

    /**
     * Updates the form with the selected businesses
     * @param businesses
     */
    function updateSelectedBusinesses(businesses: Array<string>): void {
        setEditBrandMapForm((_editBrandMapForm) => {
            return {
                ..._editBrandMapForm,
                businesses,
            }
        });
    }

    /**
     * Toggle business as default location
     * @param business
     */
    function toggleDefault(business: Business): void {
        setEditBrandMapForm((_editBrandMapForm) => {
            return {
                ..._editBrandMapForm,
                defaultLocation: business?.address,
                defaultBusiness: business?.id,
            }
        });
    }

    /**
     * Updates the user's login access point
     * @param loginAccessPoint
     */
    function updateLoginAccessPoint(loginAccessPoint: LoginAccessPoint): void {
        setEditBrandMapForm((_editBrandMapForm) => {
            return {
                ..._editBrandMapForm,
                loginAccessPoint,
            }
        });
    }

    /**
     * Updates the brandmap page type
     * @param brandPageType
     */
    function updateBrandPageType(brandPageType: BrandPageType): void {
        setEditBrandMapForm((_editBrandMapForm) => {
            return {
                ..._editBrandMapForm,
                brandPageType,
            }
        });
    }

    /**
     * Hide the successfully-saved-draft modal so user can continue editing without redirect.
     *
     */
    function dismissSuccessModal(): void {
        setShowDraftSuccess(false);
        setShowPublishSuccess(false);
    }

    function toggleViewMode() {
        if (viewMode === ViewMode.PREVIEW) {
            setViewMode(ViewMode.EDITING);
        } else {
            setViewMode(ViewMode.PREVIEW);
        }
    }

    function onRemoveImage(key: keyof Pick<
        EditBrandMapBodyFrontend,
        "bannerImage" | "promoHero" | "promoBackground" | "promoCartHero" | "promoCartBackground" | "autoMintToastBackground" | "preAutoMintToastBackground"
    >): void {
        setEditBrandMapForm({
            ...editBrandMapForm,
            [key]: undefined,
        })
    }

    /**
     * Resets default location
     */
    function removeDefault(): void {
        setEditBrandMapForm((_editBrandMapForm) => {
            return {
                ..._editBrandMapForm,
                defaultLocation: undefined,
                defaultBusiness: undefined,
            }
        });
    }

    return (
        <React.Fragment>
            <CreateBrandMapEditAddressModal
                isOpen={showAddressModal}
                onClose={toggleAddressModal}
                address={editBrandMapForm.defaultLocation}
                onDone={onDoneAddressModel}
            />

            <BrandeMapSaveDraftSuccessModal
                isOpen={showDraftSuccess}
                brandMap={brandMap}
                new={false}
                onContinueEditing={dismissSuccessModal}
                originalBrandMap={brandMapOriginal}
            />

            <BrandMapSavePublishedSuccessModal
                isOpen={showPublishSuccess}
                brandMap={brandMap}
                new={false}
                onContinueEditing={dismissSuccessModal}
                originalBrandMap={brandMapOriginal}
            />

            <div
                className={classNames("create-map-page", {
                    "bg-color-editing": viewMode === ViewMode.EDITING
                })}
                style={{backgroundColor: viewMode === ViewMode.PREVIEW ? editBrandMapForm.colorBackground : ""}}
            >
                {(!brandMap && !businessesRes) ? (
                    <div className="create-map-page_loading">
                        <div className="spinner"/>
                    </div>
                ) : (
                    <>
                        <MapCreationPageHeader
                            onSubmit={submitUpdatedMap(BrandMapStatus.PUBLISHED)}
                            onSaveDraft={submitUpdatedMap(BrandMapStatus.DRAFT)}
                            status={brandMap?.status}
                            viewMode={viewMode}
                            toggleViewMode={toggleViewMode}
                        />

                        {viewMode === ViewMode.EDITING ? (
                                <div className="create-map-page_content">
                                    <MerchantBrandHandleName
                                        onChange={slugOnChange}
                                        value={editBrandMapForm.slug}
                                    />

                                    <MerchantBrandTitleDescription
                                        value={editBrandMapForm.name}
                                        onChange={inputOnChange}
                                    />

                                    <MerchantBrandDescription
                                        onChange={descriptionOnChange}
                                        value={editBrandMapForm.description}
                                    />

                                    <MerchantBrandUploadBannerImage
                                        onChange={onImageChange}
                                        onRemoveImage={onRemoveImage}
                                        image={editBrandMapForm?.bannerImage.image}
                                    />

                                    <MerchantBrandPageTheme
                                        textColorValue={editBrandMapForm.colorTheme}
                                        onTextColorChange={colorThemeOnChange}
                                        colorBackgroundValue={editBrandMapForm.colorBackground}
                                        onColorBackgroundChange={inputOnChange}
                                    />

                                    <EditBrandMapMainPromoSelection
                                        brand={brandMap.brand}
                                        formValues={editBrandMapForm}
                                        mainPromoOnChange={mainPromoOnChange}
                                        secondaryPromoOnChange={promoOnChange}
                                    />

                                    {editBrandMapForm?.mainPromo && (
                                        <>
                                            <EditBrandMapMainPromoTitle
                                                formValues={editBrandMapForm}
                                                inputOnChange={inputOnChange}
                                            />

                                            <EditBrandMapMainPromoTagline
                                                formValues={editBrandMapForm}
                                                inputOnChange={inputOnChange}
                                            />

                                            <EditBrandMapMainPromoDescription
                                                formValues={editBrandMapForm}
                                                inputOnChange={inputOnChange}
                                            />

                                            <EditBrandMapMainPromoImages
                                                formValues={editBrandMapForm}
                                                onImageChange={onImageChange}
                                                onRemoveImage={onRemoveImage}
                                            />

                                            <EditBrandMapMainPromoCartImages
                                                formValues={editBrandMapForm}
                                                onImageChange={onImageChange}
                                                onRemoveImage={onRemoveImage}
                                            />
                                        </>
                                    )}

                                    <MerchantBrandLocationSelection
                                        businessesToRender={businessesRes?.brandBusinesses}
                                        defaultAddress={editBrandMapForm.defaultLocation}
                                        selectedBusinesses={editBrandMapForm.businesses}
                                        onToggle={toggleLocationHelper}
                                        onSetDefault={toggleDefault}
                                        updateBusinesses={updateSelectedBusinesses}
                                        onRemoveDefault={removeDefault}
                                    />

                                    <MerchantBrandAdvertisementImages
                                        advertisementBlocks={editBrandMapForm.advertisementBlocks}
                                        onChange={setEditBrandMapForm}
                                    />

                                    <MerchantBrandUserLoginSettings
                                        updateLoginMode={updateLoginAccessPoint}
                                        loginAccessPoint={editBrandMapForm.loginAccessPoint}
                                    />

                                    <MerchantBrandPageTypeSettings
                                        updateBrandPageType={updateBrandPageType}
                                        brandPageType={editBrandMapForm.brandPageType}
                                    />

                                    <MerchantBrandAutoMint
                                        value={editBrandMapForm.autoMintContract}
                                        onChange={inputOnChange}
                                    />

                                    {editBrandMapForm.autoMintContract && (
                                        <>
                                            <MerchantBrandMapPreAutoMintToast
                                                formValues={editBrandMapForm}
                                                onInputChange={inputOnChange}
                                                onImageChange={onImageChange}
                                                onRemoveImage={onRemoveImage}
                                            />

                                            <MerchantBrandMapPostAutoMintToast
                                                formValues={editBrandMapForm}
                                                onInputChange={inputOnChange}
                                                onImageChange={onImageChange}
                                                onRemoveImage={onRemoveImage}
                                            />

                                            <BrandMapToastTextColor
                                                textColorValue={editBrandMapForm.autoMintToastTextColor}
                                                onTextColorChange={autoMintToastTextColorOnChange}
                                            />
                                        </>
                                    )}

                                    <MerchantBrandButtons
                                        status={brandMap?.status}
                                        onPreview={toggleViewMode}
                                        onSaveDraft={submitUpdatedMap(BrandMapStatus.DRAFT)}
                                        onPublish={submitUpdatedMap(BrandMapStatus.PUBLISHED)}
                                    />
                                </div>
                            ) :
                            <BrandMapPreviewMode
                                formValues={editBrandMapForm}
                                businesses={businessesRes?.brandBusinesses}
                                selectedBusinesses={editBrandMapForm.businesses}
                                brandImage={brandMap.brand.image}
                                brandName={brandMap.brand.name}
                                brandId={brandMap.brand.id}
                                promos={[brandMap?.mainPromo, ...brandMap.promos]}
                            />
                        }
                    </>
                )}
            </div>
        </React.Fragment>
    );
}

export default EditBrandMapPage;

