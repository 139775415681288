"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PreviousMenuOrderItemToJSON = exports.PreviousMenuOrderItemFromJSONTyped = exports.PreviousMenuOrderItemFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function PreviousMenuOrderItemFromJSON(json) {
    return PreviousMenuOrderItemFromJSONTyped(json, false);
}
exports.PreviousMenuOrderItemFromJSON = PreviousMenuOrderItemFromJSON;
function PreviousMenuOrderItemFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'externalId': !(0, runtime_1.exists)(json, 'externalId') ? undefined : json['externalId'],
        'name': json['name'],
        'price': json['price'],
        'quantity': json['quantity'],
        'taxRate': json['taxRate'],
        'notes': !(0, runtime_1.exists)(json, 'notes') ? undefined : json['notes'],
        'menuItemId': !(0, runtime_1.exists)(json, 'menuItemId') ? undefined : json['menuItemId'],
        'subtotal': json['subtotal'],
        'tax': json['tax'],
        'customizations': !(0, runtime_1.exists)(json, 'customizations') ? undefined : (json['customizations'].map(_1.MenuOrderSubItemFromJSON)),
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
    };
}
exports.PreviousMenuOrderItemFromJSONTyped = PreviousMenuOrderItemFromJSONTyped;
function PreviousMenuOrderItemToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'externalId': value.externalId,
        'name': value.name,
        'price': value.price,
        'quantity': value.quantity,
        'taxRate': value.taxRate,
        'notes': value.notes,
        'menuItemId': value.menuItemId,
        'subtotal': value.subtotal,
        'tax': value.tax,
        'customizations': value.customizations === undefined ? undefined : (value.customizations.map(_1.MenuOrderSubItemToJSON)),
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
    };
}
exports.PreviousMenuOrderItemToJSON = PreviousMenuOrderItemToJSON;
