"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetGoVipPointMultiplierSortToJSON = exports.GetGoVipPointMultiplierSortFromJSONTyped = exports.GetGoVipPointMultiplierSortFromJSON = exports.GetGoVipPointMultiplierSort = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var GetGoVipPointMultiplierSort;
(function (GetGoVipPointMultiplierSort) {
    GetGoVipPointMultiplierSort["CreatedAt"] = "createdAt";
})(GetGoVipPointMultiplierSort = exports.GetGoVipPointMultiplierSort || (exports.GetGoVipPointMultiplierSort = {}));
function GetGoVipPointMultiplierSortFromJSON(json) {
    return GetGoVipPointMultiplierSortFromJSONTyped(json, false);
}
exports.GetGoVipPointMultiplierSortFromJSON = GetGoVipPointMultiplierSortFromJSON;
function GetGoVipPointMultiplierSortFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.GetGoVipPointMultiplierSortFromJSONTyped = GetGoVipPointMultiplierSortFromJSONTyped;
function GetGoVipPointMultiplierSortToJSON(value) {
    return value;
}
exports.GetGoVipPointMultiplierSortToJSON = GetGoVipPointMultiplierSortToJSON;
