"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GleamWebhookPayloadToJSON = exports.GleamWebhookPayloadFromJSONTyped = exports.GleamWebhookPayloadFromJSON = void 0;
var _1 = require("./");
function GleamWebhookPayloadFromJSON(json) {
    return GleamWebhookPayloadFromJSONTyped(json, false);
}
exports.GleamWebhookPayloadFromJSON = GleamWebhookPayloadFromJSON;
function GleamWebhookPayloadFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'campaign': (0, _1.GleamCampaignFromJSON)(json['campaign']),
        'reward': (0, _1.GleamRewardFromJSON)(json['reward']),
        'coupon': (0, _1.GleamCouponFromJSON)(json['coupon']),
        'user': (0, _1.GleamUserFromJSON)(json['user']),
        'entry': (0, _1.GleamEntryFromJSON)(json['entry']),
        'social_links': (json['social_links'].map(_1.GleamSocialLinkFromJSON)),
    };
}
exports.GleamWebhookPayloadFromJSONTyped = GleamWebhookPayloadFromJSONTyped;
function GleamWebhookPayloadToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'campaign': (0, _1.GleamCampaignToJSON)(value.campaign),
        'reward': (0, _1.GleamRewardToJSON)(value.reward),
        'coupon': (0, _1.GleamCouponToJSON)(value.coupon),
        'user': (0, _1.GleamUserToJSON)(value.user),
        'entry': (0, _1.GleamEntryToJSON)(value.entry),
        'social_links': (value.social_links.map(_1.GleamSocialLinkToJSON)),
    };
}
exports.GleamWebhookPayloadToJSON = GleamWebhookPayloadToJSON;
