import React, {
	ReactElement, useCallback,
	useEffect,
	useState
} from "react";
import {
	Business,
	BusinessesApi,
	GetMenuOrdersResponse,
	GetMenuOrdersSort,
	MenuOrder,
	SortOrder
} from "@devour/client";
import {defaultFrontendPagination, FrontendPagination} from "../../../components/tables/FrameOnePaginator";
import {addError, decrementLoading, incrementLoading} from "../../../redux/meta/metaActions";
import getConfig from "../../../utils/getConfig";
import moment from "moment/moment";
import FrameOneTableContainer from "../../../components/tables/FrameOneTableContainer";
import {useDispatch} from "react-redux";
import {createColumnHelper, getCoreRowModel, SortingState} from "@tanstack/react-table";
import {concatAddressFields} from "../../../utils/formatAddress";
import {AiOutlineSearch} from "react-icons/ai";
import _ from "lodash";
import {underscoreToCapitalize} from "../../../utils/underscoreToCapitalize";
import {parseMenuOrderStatusGroup} from "../../../utils/parseMenuOrderStatus";
import MenuOrderModal from "../../../components/modals/MenuOrderModal";

interface Props {
	business: Business;
}

const columnHelper = createColumnHelper<MenuOrder>();
const currentDate = Date.now();
const dateFilter: { [key: number]: string } = {
	[currentDate - (30 * 24 * 60 * 60 * 1000)]: "Past month",
	[currentDate - (90 * 24 * 60 * 60 * 1000)]: "Past 3 months",
	[currentDate - (180 * 24 * 60 * 60 * 1000)]: "Past 6 months",
	[currentDate - (364 * 24 * 60 * 60 * 1000)]: "Past year",
}

function BusinessDetailsOrders(props: Props): ReactElement {
	const dispatch = useDispatch();
	const [activeMenuOrder, setActiveMenuOrder] = useState<MenuOrder>();
	const [searchValue, setSearchValue] = useState<string>(undefined);
	const [sorting, setSorting] = useState<SortingState>([{id: GetMenuOrdersSort.UpdatedAt, desc: true}]);
	const [menuOrders, setMenuOrders] = useState<GetMenuOrdersResponse>();
	const [frontendPagination, setFrontendPagination] = useState<FrontendPagination>(defaultFrontendPagination);
	const [dateStart, setDateStart] = useState<number>(currentDate - (90 * 24 * 60 * 60 * 1000));
	const debounceLoadData = useCallback(_.debounce(fetchMenuOrders, 1000), []);
	const columns = [
		columnHelper.accessor("updatedAt", {
			id: GetMenuOrdersSort.UpdatedAt,
			cell: (info) => moment(info.getValue()).format("LLL"),
			enableSorting: true,
			sortDescFirst: true,
			header: "Date",
		}),
		columnHelper.accessor("id", {
			id: GetMenuOrdersSort.OrderId,
			enableSorting: true,
			header: "Order",
			cell: (info) => (
				<button
					className="reset-link"
					onClick={() => setActiveMenuOrder(info.row.original)}
				>
					{info.getValue()}
				</button>
			),
		}),
		columnHelper.accessor("status", {
			cell: (info) => (
				<span
					className={`business-page_menu-orders_status status-${parseMenuOrderStatusGroup(info.getValue())}`}
				>
					{underscoreToCapitalize(info.getValue())}
				</span>
			),
			header: "Status",
		}),
		columnHelper.accessor("firstName", {
			cell: (info) => `${info.getValue() || ""} ${info.row.original.lastName || ""}`,
			header: "Recipient",
		}),
		columnHelper.accessor("address", {
			cell: (info) => concatAddressFields(info.getValue()),
			header: "Address",
		}),
		columnHelper.accessor("handoff", {
			header: "Handoff",
		}),
		columnHelper.accessor("grandTotal", {
			header: "Total",
			cell: (info) => `$${info.getValue().toFixed(2)}`,
		}),
		columnHelper.accessor("stripeCheckout", {
			header: "Stripe Checkout",
			cell: (info) => info.getValue() ? "Yes" : "No",
		}),
	];

	useEffect(() => {
		if (searchValue != null) {
			void debounceLoadData(searchValue);
		}
	}, [searchValue]);

	useEffect(() => {
		void fetchMenuOrders();
	}, [props.business.id, frontendPagination, sorting, dateStart]);

	/**
	 * Fetch new list of menu orders.
	 * @param newSearchValue Pass in the new search value on debounce
	 */
	async function fetchMenuOrders(newSearchValue?: string): Promise<void> {
		dispatch(incrementLoading());
		try {
			const res = await new BusinessesApi(getConfig()).getBusinessMenuOrders({
				id: props.business.id,
				limit: frontendPagination.limit,
				offset: frontendPagination.offset,
				sort: sorting[0]?.id as GetMenuOrdersSort,
				order: (sorting[0]?.desc) ? SortOrder.Desc : SortOrder.Asc,
				searchValue: newSearchValue || searchValue,
				dateStart: dateStart.toString(),
			});
			setMenuOrders(res);
		} catch (e) {
			dispatch(await addError(e));
		} finally {
			dispatch(decrementLoading());
		}
	}

	if (!menuOrders) {
		return null;
	}

	return (
		<div className="business-page_menu-orders">
			<MenuOrderModal
				menuOrder={activeMenuOrder}
				onClose={() => setActiveMenuOrder(undefined)}
			/>
			<div className="business-page_menu-orders_header">
				<div className="business-page_menu-orders_search">
					<AiOutlineSearch/>
					<input
						type="search"
						value={searchValue}
						onChange={(e) => setSearchValue(e.target.value)}
						placeholder="Search order number, user, email, or phone"
					/>
				</div>
				<div className="business-page_menu-orders_date">
					<select
						value={dateStart}
						onChange={(e) => setDateStart(Number(e.target.value))}
					>
						{Object.keys(dateFilter)?.map((dateStartOption, i: number) => {
							const label = dateFilter[dateStartOption];
							return (
								<option
									key={`limiter-option_${i}`}
									value={dateStartOption}
								>
									{label}
								</option>
							);
						})}
					</select>
				</div>
			</div>
			<FrameOneTableContainer
				<MenuOrder>
				tableOptions={{
					columns,
					data: menuOrders.menuOrders,
					getCoreRowModel: getCoreRowModel(),
				}}
				sorting={sorting}
				onSort={setSorting}
				pagination={{
					...menuOrders?.paginationInfo,
					...frontendPagination,
				}}
				onPaginationChange={setFrontendPagination}
				noResultsRender={(<p>No orders available.</p>)}
			/>
		</div>
	);
}

export default BusinessDetailsOrders;
