import InitialMetaStore, {IMetaStore} from "./InitialMetaStore";
import {IAction} from "../IAction";
import {cloneDeep} from "lodash";
import {AuthAction} from "../auth/authReducer";

export enum MetaAction {
	LOADING = "LOADING",
	ADD_ERROR = "ADD_ERROR",
	REMOVE_ERROR = "REMOVE_ERROR",
	SIDEBAR = "SIDEBAR",
	GLOBAL_CONTACT_MODAL = "GLOBAL_CONTACT_MODAL",
	UPDATE_CURRENT_USER = "UPDATE_CURRENT_USER",
	MODAL_COUNT = "MODAL_COUNT",
}

export default function metaReducer(store: IMetaStore = InitialMetaStore, a: IAction<MetaAction | AuthAction, any>): IMetaStore {

	const n: IMetaStore = cloneDeep(store);

	switch(a.type) {
		case AuthAction.LOGOUT:
			delete n.currentUser;
			break;

		case MetaAction.LOADING:
			if (n.loadingIncrement + a.payload >= 0) {
				n.loadingIncrement += a.payload;
			} else {
				n.loadingIncrement = 0;
			}
			break;
		case MetaAction.ADD_ERROR:
			n.errors.push(a.payload);
			break;
		case MetaAction.REMOVE_ERROR:
			n.errors.splice(a.payload, 1);
			break;
		case MetaAction.SIDEBAR:
			n.sidebarOpen = a.payload;
			break;
		case MetaAction.GLOBAL_CONTACT_MODAL:
			n.globalContactModalVisible = a.payload;
			break;
		case MetaAction.UPDATE_CURRENT_USER:
			n.currentUser = a.payload;
			break;
		case MetaAction.MODAL_COUNT:
			if (n.activeModals + a.payload >= 0) {
				n.activeModals += a.payload;
			} else {
				n.activeModals = 0;
			}
			break;
		default:
			break;
	}

	return n;
}
