import React from "react";
import FrameOneSwitchInput from "../inputs/FrameOneSwitchInput";
import {BrandMapColorTheme} from "@devour/client";

interface Props {
    textColorValue: BrandMapColorTheme;
    onTextColorChange: (theme: BrandMapColorTheme) => void;
}

function BrandMapToastTextColor(props: Props) {
    return (
        <div className="create-map-page_container">
            <div className="create-map-page_container_inner create-map-page_main-promo_title">
                <h5>
                    Toast Text Color
                </h5>
                <p className="merchant-brand-title-description_content_subtitle">
                    Customize your toast with white colored text or black colored text.
                </p>

                <FrameOneSwitchInput
                    <BrandMapColorTheme>
                    name="business-list-type"
                    className="merchant-brand-page-theme_top_switch"
                    value={props.textColorValue}
                    onToggle={props.onTextColorChange}
                    options={[
                        {
                            render: "White",
                            value: BrandMapColorTheme.LIGHT,
                        },
                        {
                            render: "Black",
                            value: BrandMapColorTheme.DARK,
                        },
                    ]}
                />
            </div>
        </div>
    )
}

export default BrandMapToastTextColor;