import scssVariables from "./scssVariables.module.scss";

interface SCSSVariables {
	"f1-sidebar-width": string,
	"f1-sidebar-width-docked": string;
	"f1-sidebar-docked-at": string;

	"frame-one-modal-transition-timing": string;
}

export default scssVariables as unknown as SCSSVariables;
