"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetMenuOrdersSortToJSON = exports.GetMenuOrdersSortFromJSONTyped = exports.GetMenuOrdersSortFromJSON = exports.GetMenuOrdersSort = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var GetMenuOrdersSort;
(function (GetMenuOrdersSort) {
    GetMenuOrdersSort["CreatedAt"] = "createdAt";
    GetMenuOrdersSort["UpdatedAt"] = "updatedAt";
    GetMenuOrdersSort["Status"] = "status";
    GetMenuOrdersSort["Business"] = "business";
    GetMenuOrdersSort["Customer"] = "customer";
    GetMenuOrdersSort["OrderId"] = "orderId";
})(GetMenuOrdersSort = exports.GetMenuOrdersSort || (exports.GetMenuOrdersSort = {}));
function GetMenuOrdersSortFromJSON(json) {
    return GetMenuOrdersSortFromJSONTyped(json, false);
}
exports.GetMenuOrdersSortFromJSON = GetMenuOrdersSortFromJSON;
function GetMenuOrdersSortFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.GetMenuOrdersSortFromJSONTyped = GetMenuOrdersSortFromJSONTyped;
function GetMenuOrdersSortToJSON(value) {
    return value;
}
exports.GetMenuOrdersSortToJSON = GetMenuOrdersSortToJSON;
