"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetBusinessOwnSearchCategoryToJSON = exports.GetBusinessOwnSearchCategoryFromJSONTyped = exports.GetBusinessOwnSearchCategoryFromJSON = exports.GetBusinessOwnSearchCategory = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var GetBusinessOwnSearchCategory;
(function (GetBusinessOwnSearchCategory) {
    GetBusinessOwnSearchCategory["ALL"] = "ALL";
    GetBusinessOwnSearchCategory["NAME"] = "NAME";
    GetBusinessOwnSearchCategory["POSTAL"] = "POSTAL";
    GetBusinessOwnSearchCategory["CITY"] = "CITY";
    GetBusinessOwnSearchCategory["STATE"] = "STATE";
    GetBusinessOwnSearchCategory["BRAND"] = "BRAND";
})(GetBusinessOwnSearchCategory = exports.GetBusinessOwnSearchCategory || (exports.GetBusinessOwnSearchCategory = {}));
function GetBusinessOwnSearchCategoryFromJSON(json) {
    return GetBusinessOwnSearchCategoryFromJSONTyped(json, false);
}
exports.GetBusinessOwnSearchCategoryFromJSON = GetBusinessOwnSearchCategoryFromJSON;
function GetBusinessOwnSearchCategoryFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.GetBusinessOwnSearchCategoryFromJSONTyped = GetBusinessOwnSearchCategoryFromJSONTyped;
function GetBusinessOwnSearchCategoryToJSON(value) {
    return value;
}
exports.GetBusinessOwnSearchCategoryToJSON = GetBusinessOwnSearchCategoryToJSON;
