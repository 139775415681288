"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OnboardingApi = void 0;
var runtime = __importStar(require("../runtime"));
var models_1 = require("../models");
/**
 * no description
 */
var OnboardingApi = /** @class */ (function (_super) {
    __extends(OnboardingApi, _super);
    function OnboardingApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Creates a new signed-url for onboarding a user from one platform to DevourGo.
     */
    OnboardingApi.prototype.createOnboardingSignedUrlRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        return [4 /*yield*/, this.request({
                                path: "/onboarding/signed-url",
                                method: 'POST',
                                headers: headerParameters,
                                query: queryParameters,
                                body: (0, models_1.CreateOnboardingSignedUrlBodyToJSON)(requestParameters.createOnboardingSignedUrlBody),
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.OnboardingSignedUrlFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Creates a new signed-url for onboarding a user from one platform to DevourGo.
     */
    OnboardingApi.prototype.createOnboardingSignedUrl = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.createOnboardingSignedUrlRaw(requestParameters)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Creates a new Onboarding Third Party and generates the public and secret keys. This will return the secret key, which needs to be saved, as it is the only time it will be shown.
     */
    OnboardingApi.prototype.createOnboardingThirdPartyRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("Admin", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/onboarding/third-party",
                                method: 'POST',
                                headers: headerParameters,
                                query: queryParameters,
                                body: (0, models_1.CreateOnboardingThirdPartyBodyToJSON)(requestParameters.createOnboardingThirdPartyBody),
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.OnboardingThirdPartyFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * Creates a new Onboarding Third Party and generates the public and secret keys. This will return the secret key, which needs to be saved, as it is the only time it will be shown.
     */
    OnboardingApi.prototype.createOnboardingThirdParty = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.createOnboardingThirdPartyRaw(requestParameters)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Deletes an Onboarding Third Party by ID.
     */
    OnboardingApi.prototype.deleteOnboardingThirdPartyRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.id === null || requestParameters.id === undefined) {
                            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling deleteOnboardingThirdParty.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("Admin", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/onboarding/third-party/{id}".replace("{".concat("id", "}"), encodeURIComponent(String(requestParameters.id))),
                                method: 'DELETE',
                                headers: headerParameters,
                                query: queryParameters,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * Deletes an Onboarding Third Party by ID.
     */
    OnboardingApi.prototype.deleteOnboardingThirdParty = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.deleteOnboardingThirdPartyRaw(requestParameters)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * gets the information associated with a signedUrlKey.
     */
    OnboardingApi.prototype.getOnboardingInformationRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.signedUrlKey === null || requestParameters.signedUrlKey === undefined) {
                            throw new runtime.RequiredError('signedUrlKey', 'Required parameter requestParameters.signedUrlKey was null or undefined when calling getOnboardingInformation.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        return [4 /*yield*/, this.request({
                                path: "/onboarding/signed-url/information/{signedUrlKey}".replace("{".concat("signedUrlKey", "}"), encodeURIComponent(String(requestParameters.signedUrlKey))),
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.GetOnboardingInformationResponseFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     * gets the information associated with a signedUrlKey.
     */
    OnboardingApi.prototype.getOnboardingInformation = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getOnboardingInformationRaw(requestParameters)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     */
    OnboardingApi.prototype.getOnboardingThirdPartiesRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        if (requestParameters.search !== undefined) {
                            queryParameters['search'] = requestParameters.search;
                        }
                        if (requestParameters.sort !== undefined) {
                            queryParameters['sort'] = requestParameters.sort;
                        }
                        if (requestParameters.order !== undefined) {
                            queryParameters['order'] = requestParameters.order;
                        }
                        if (requestParameters.limit !== undefined) {
                            queryParameters['limit'] = requestParameters.limit;
                        }
                        if (requestParameters.offset !== undefined) {
                            queryParameters['offset'] = requestParameters.offset;
                        }
                        if (requestParameters.numberOfAvailableOffsets !== undefined) {
                            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
                        }
                        headerParameters = {};
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("Admin", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/onboarding/third-parties",
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return (0, models_1.GetOnboardingThirdPartiesResponseFromJSON)(jsonValue); })];
                }
            });
        });
    };
    /**
     */
    OnboardingApi.prototype.getOnboardingThirdParties = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getOnboardingThirdPartiesRaw(requestParameters)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * gets the information associated with a signedUrlKey.
     */
    OnboardingApi.prototype.mergeUserWithSignedUrlKeyRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, token, tokenString, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.signedUrlKey === null || requestParameters.signedUrlKey === undefined) {
                            throw new runtime.RequiredError('signedUrlKey', 'Required parameter requestParameters.signedUrlKey was null or undefined when calling mergeUserWithSignedUrlKey.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.accessToken) {
                            token = this.configuration.accessToken;
                            tokenString = typeof token === 'function' ? token("User", []) : token;
                            if (tokenString) {
                                headerParameters["Authorization"] = "Bearer ".concat(tokenString);
                            }
                        }
                        return [4 /*yield*/, this.request({
                                path: "/onboarding/signed-url/merge-user/{signedUrlKey}".replace("{".concat("signedUrlKey", "}"), encodeURIComponent(String(requestParameters.signedUrlKey))),
                                method: 'PUT',
                                headers: headerParameters,
                                query: queryParameters,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * gets the information associated with a signedUrlKey.
     */
    OnboardingApi.prototype.mergeUserWithSignedUrlKey = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.mergeUserWithSignedUrlKeyRaw(requestParameters)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return OnboardingApi;
}(runtime.BaseAPI));
exports.OnboardingApi = OnboardingApi;
