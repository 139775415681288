import React, {ReactElement} from "react";
import {MenuOrder, MenuOrderItem, MenuOrderSubItem} from "@devour/client";

interface Props {
	menuOrder: MenuOrder;
}

function MenuOrderItems(props: Props): ReactElement {

	function renderOrderCustomizationItem(customizationItem: MenuOrderSubItem, index: number): JSX.Element {
		return (
			<li key={`order-customization-${index}`}>
				<div>
					<strong>{customizationItem.name}</strong>
					{(customizationItem.price > 0) && ` + $${customizationItem.price.toFixed(2)}`}
				</div>
				{(customizationItem.customizations?.length > 0) && (
					<ul>
						{customizationItem.customizations.map(renderOrderCustomizationItem)}
					</ul>
				)}
			</li>
		)
	}

	function renderOrderItem(orderItem: MenuOrderItem, index: number): JSX.Element {
		return (
			<li key={`orderitem-${index}`}>
				<div>
					<strong>{orderItem.name}</strong>
				</div>
				<div>
					{orderItem.notes}
				</div>
				<div>
					Quantity: {orderItem.quantity}
				</div>
				<div>
					Base price: ${orderItem.price.toFixed(2)}
				</div>
				<div>
					Tax rate: {orderItem.taxRate}%
				</div>
				{(orderItem.customizations?.length > 0) && (
					<ul>
						{orderItem.customizations.map(renderOrderCustomizationItem)}
					</ul>
				)}
			</li>
		)
	}

	return (
		<div className="menu-order-items">
			<h3>
				Order Items
			</h3>
			{(props.menuOrder.orderItems?.length) ? (
				<ul>
					{props.menuOrder.orderItems.map(renderOrderItem)}
				</ul>
			) : (
				<p>
					No order items in cart.
				</p>
			)}
		</div>
	);
}

export default MenuOrderItems;
