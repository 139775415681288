"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DiscordInfoToJSON = exports.DiscordInfoFromJSONTyped = exports.DiscordInfoFromJSON = void 0;
var runtime_1 = require("../runtime");
function DiscordInfoFromJSON(json) {
    return DiscordInfoFromJSONTyped(json, false);
}
exports.DiscordInfoFromJSON = DiscordInfoFromJSON;
function DiscordInfoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !(0, runtime_1.exists)(json, 'id') ? undefined : json['id'],
        'username': !(0, runtime_1.exists)(json, 'username') ? undefined : json['username'],
    };
}
exports.DiscordInfoFromJSONTyped = DiscordInfoFromJSONTyped;
function DiscordInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'username': value.username,
    };
}
exports.DiscordInfoToJSON = DiscordInfoToJSON;
