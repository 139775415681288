"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DpayPurchaseToJSON = exports.DpayPurchaseFromJSONTyped = exports.DpayPurchaseFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function DpayPurchaseFromJSON(json) {
    return DpayPurchaseFromJSONTyped(json, false);
}
exports.DpayPurchaseFromJSON = DpayPurchaseFromJSON;
function DpayPurchaseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'paymentMethod': (0, _1.TokenPaymentMethodFromJSON)(json['paymentMethod']),
        'user': json['user'],
        'price': json['price'],
        'fiat': json['fiat'],
        'dpay': json['dpay'],
        'xp': json['xp'],
        'expiresAt': json['expiresAt'],
        'isComplete': json['isComplete'],
        'isPending': json['isPending'],
        'dpayTransaction': !(0, runtime_1.exists)(json, 'dpayTransaction') ? undefined : json['dpayTransaction'],
        'paymentMethodId': !(0, runtime_1.exists)(json, 'paymentMethodId') ? undefined : json['paymentMethodId'],
        'paymentIntentId': !(0, runtime_1.exists)(json, 'paymentIntentId') ? undefined : json['paymentIntentId'],
        'experienceRewardsTransaction': !(0, runtime_1.exists)(json, 'experienceRewardsTransaction') ? undefined : json['experienceRewardsTransaction'],
        'errorMessage': !(0, runtime_1.exists)(json, 'errorMessage') ? undefined : json['errorMessage'],
    };
}
exports.DpayPurchaseFromJSONTyped = DpayPurchaseFromJSONTyped;
function DpayPurchaseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'paymentMethod': (0, _1.TokenPaymentMethodToJSON)(value.paymentMethod),
        'user': value.user,
        'price': value.price,
        'fiat': value.fiat,
        'dpay': value.dpay,
        'xp': value.xp,
        'expiresAt': value.expiresAt,
        'isComplete': value.isComplete,
        'isPending': value.isPending,
        'dpayTransaction': value.dpayTransaction,
        'paymentMethodId': value.paymentMethodId,
        'paymentIntentId': value.paymentIntentId,
        'experienceRewardsTransaction': value.experienceRewardsTransaction,
        'errorMessage': value.errorMessage,
    };
}
exports.DpayPurchaseToJSON = DpayPurchaseToJSON;
