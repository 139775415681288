import React, {ReactElement} from "react";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../../redux/defaultStore";

interface StateProps {
	loadingIncrement: number;
}

interface Props {
	forceRender?: boolean; // For use on individual component levels.
}

function LoadingManager(props: LoadingManagerProps): ReactElement {

	if (props.loadingIncrement > 0 || props.forceRender) {
		return (
			<div className="loading-manager">
				{/*<object*/}
				{/*	data="/svg/frame-one-loader.svg"*/}
				{/*	className="loading-manager_svg"*/}
				{/*	aria-label="Loading Spinner"*/}
				{/*/>*/}
				<div className="spinner"/>
			</div>
		);
	}

	return null;
}

function connector() {
	return connect((store: IStore, props: Props): StateProps & Props => {
		return {
			loadingIncrement: store.metaStore.loadingIncrement,
			...props,
		}
	});
}

type LoadingManagerProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(LoadingManager);
