"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.InlineResponse20011ToJSON = exports.InlineResponse20011FromJSONTyped = exports.InlineResponse20011FromJSON = void 0;
var runtime_1 = require("../runtime");
function InlineResponse20011FromJSON(json) {
    return InlineResponse20011FromJSONTyped(json, false);
}
exports.InlineResponse20011FromJSON = InlineResponse20011FromJSON;
function InlineResponse20011FromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'itsaCheckmateAccess': json['itsaCheckmateAccess'],
        'locationId': !(0, runtime_1.exists)(json, 'locationId') ? undefined : json['locationId'],
    };
}
exports.InlineResponse20011FromJSONTyped = InlineResponse20011FromJSONTyped;
function InlineResponse20011ToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'itsaCheckmateAccess': value.itsaCheckmateAccess,
        'locationId': value.locationId,
    };
}
exports.InlineResponse20011ToJSON = InlineResponse20011ToJSON;
