"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SiteConfigurationKeyToJSON = exports.SiteConfigurationKeyFromJSONTyped = exports.SiteConfigurationKeyFromJSON = exports.SiteConfigurationKey = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var SiteConfigurationKey;
(function (SiteConfigurationKey) {
    SiteConfigurationKey["RESTAURANTSEARCHHERO"] = "RESTAURANT_SEARCH_HERO";
    SiteConfigurationKey["GOFRENSHERO"] = "GO_FRENS_HERO";
    SiteConfigurationKey["GOVIPHERO"] = "GO_VIP_HERO";
    SiteConfigurationKey["DPAYHERO"] = "DPAY_HERO";
    SiteConfigurationKey["INDUSTRYPAGEHERO"] = "INDUSTRY_PAGE_HERO";
    SiteConfigurationKey["REWARDSDPAYMENUORDERS"] = "REWARDS_DPAY_MENU_ORDERS";
    SiteConfigurationKey["MENUORDERDELIVERYCHARGE"] = "MENU_ORDER_DELIVERY_CHARGE";
    SiteConfigurationKey["DEFAULTMARKETPLACEFEEDELIVERY"] = "DEFAULT_MARKETPLACE_FEE_DELIVERY";
    SiteConfigurationKey["DEFAULTMARKETPLACEFEEPICKUP"] = "DEFAULT_MARKETPLACE_FEE_PICKUP";
    SiteConfigurationKey["INDUSTRYPROMOBANNER"] = "INDUSTRY_PROMO_BANNER";
    SiteConfigurationKey["DEVOURIQ"] = "DEVOURIQ";
    SiteConfigurationKey["DPAYLOADSREWARD"] = "DPAY_LOADS_REWARD";
    SiteConfigurationKey["INDUSTRYPASSPURCHASEREWARD"] = "INDUSTRY_PASS_PURCHASE_REWARD";
    SiteConfigurationKey["REFERAFRIENDFIRSTORDERREWARD"] = "REFER_A_FRIEND_FIRST_ORDER_REWARD";
    SiteConfigurationKey["SIGNUPREWARD"] = "SIGN_UP_REWARD";
    SiteConfigurationKey["LEVELUPDPAYREWARD"] = "LEVEL_UP_DPAY_REWARD";
    SiteConfigurationKey["LEVELUPGOVIPPOINTREWARD"] = "LEVEL_UP_GO_VIP_POINT_REWARD";
})(SiteConfigurationKey = exports.SiteConfigurationKey || (exports.SiteConfigurationKey = {}));
function SiteConfigurationKeyFromJSON(json) {
    return SiteConfigurationKeyFromJSONTyped(json, false);
}
exports.SiteConfigurationKeyFromJSON = SiteConfigurationKeyFromJSON;
function SiteConfigurationKeyFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.SiteConfigurationKeyFromJSONTyped = SiteConfigurationKeyFromJSONTyped;
function SiteConfigurationKeyToJSON(value) {
    return value;
}
exports.SiteConfigurationKeyToJSON = SiteConfigurationKeyToJSON;
