"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateBusinessSpecialInstructionsBodyToJSON = exports.UpdateBusinessSpecialInstructionsBodyFromJSONTyped = exports.UpdateBusinessSpecialInstructionsBodyFromJSON = void 0;
function UpdateBusinessSpecialInstructionsBodyFromJSON(json) {
    return UpdateBusinessSpecialInstructionsBodyFromJSONTyped(json, false);
}
exports.UpdateBusinessSpecialInstructionsBodyFromJSON = UpdateBusinessSpecialInstructionsBodyFromJSON;
function UpdateBusinessSpecialInstructionsBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'specialInstructions': json['specialInstructions'],
    };
}
exports.UpdateBusinessSpecialInstructionsBodyFromJSONTyped = UpdateBusinessSpecialInstructionsBodyFromJSONTyped;
function UpdateBusinessSpecialInstructionsBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'specialInstructions': value.specialInstructions,
    };
}
exports.UpdateBusinessSpecialInstructionsBodyToJSON = UpdateBusinessSpecialInstructionsBodyToJSON;
